import bedroom_basic from "../../../utils/pictures/bedroom_basic.png";
import diningroom_basic from "../../../utils/pictures/diningroom_basic.png";
import livingroom_basic from "../../../utils/pictures/livingroom_basic.png";

import MddButton from "../../reusableElements/MddButton";

export default function SingleType({
  rooms,
  type,
  roomsFilter,
  setRoomsFilter,
  roomsTemperature,
  setRoomsTemperature,
}) {
  const textBuilder = () => {
    let stockText = ``;
    stockText = stockText + `${rooms.length} `;
    switch (type) {
      case "bedroom":
        stockText = stockText + `chambre${rooms.length > 1 ? "s" : ""}`;
        break;
      case "diningroom":
        stockText = stockText + `restaurant${rooms.length > 1 ? "s" : ""}`;
        break;
      case "livingroom":
        stockText = stockText + `salon${rooms.length > 1 ? "s" : ""}`;
        break;
      default:
        stockText = stockText + `pièce${rooms.length > 1 ? "s" : ""}`;
        break;
    }
    return stockText;
  };

  const findingErrors = () => {
    let stockErrors = rooms.filter((room) => {
      return room.error === true;
    });
    if (stockErrors.length > 0) {
      let stockElements = [];
      stockElements.push(<i className="bi bi-thermometer-high statusAlert" />);
      stockElements.push(<p className="statusAlert">{stockErrors.length}</p>);
      return stockElements;
    } else {
      return <div style={{ width: "7rem", height: "25px" }} />;
    }
  };

  const logoFinder = () => {
    switch (type) {
      case "bedroom":
        return <img src={bedroom_basic} alt="bedrooms" />;
      case "diningroom":
        return <img src={diningroom_basic} alt="diningrooms" />;
      case "livingroom":
        return <img src={livingroom_basic} alt="livingrooms" />;
      default:
        return <img src={bedroom_basic} alt="bedrooms" />;
    }
  };

  return (
    <article
      className="hotelSingleType"
      onClick={(e) => {
        e.stopPropagation();
        setRoomsFilter(roomsFilter === type ? "" : type);
      }}
    >
      <div>
        <MddButton
          iconName={roomsFilter === type ? "bi bi-x-lg" : "bi bi-chevron-left"}
          buttonSize="small"
          smallButtonDescription="Filter rooms"
          buttonActionFunctionOne={setRoomsFilter}
          buttonActionPropOne={roomsFilter === type ? "" : type}
          iconColor="altButtonColor"
        />
        {logoFinder()}
        <p>{textBuilder()}</p>
      </div>
      <div>
        <input
          type="number"
          min="0"
          max="30"
          value={roomsTemperature}
          onChange={(e) => {
            setRoomsTemperature(Number(e.target.value));
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          disabled
        />
        <p>°</p>
      </div>
      <div className="hotelAlertsContainer">{findingErrors()}</div>
    </article>
  );
}
