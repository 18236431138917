import { useEffect, useState } from "react";

import MddButton from "../../reusableElements/MddButton";
import LogLine from "./LogLine";

export default function LogsNode({
  log,
  handlingFullScreen,
  nodeKey,
  mergeLogs,
  setLogsPage,
  localeTime,
  logsNumber,
  activeNode,
  setScrollDirection,
  pagesList,
  setPagesList,
}) {
  const [displayLogs, setDisplayLogs] = useState(null);

  const returningLogs = () => {
    let stockLog = activeNode ? activeNode : log;
    let stockLogs = [];
    if (stockLog.length > 0) {
      //stockLogs.push(<div className="reloadLogsContainer" id={`topReload-${nodeKey}`} />);
      for (let i = 0; i < stockLog.length; i++) {
        stockLogs.push(<LogLine log={stockLog[i]} localeTime={localeTime} />);
      }
      //stockLogs.push(<div className="reloadLogsContainer" />);
    }
    setDisplayLogs(stockLogs);
  };

  const handlingScroll = (e) => {
      /*
    console.log(
      "handling scroll : ",
      e.target.scrollTop,
      e.target.offsetHeight,
      e.target.scrollHeight
    );*/
    let stockList = [...pagesList];
    if (
      e.target.scrollTop + e.target.offsetHeight >=
      e.target.scrollHeight + 7
    ) {
      stockList.unshift(stockList[0] - 1);
      setPagesList([...stockList]);
      setLogsPage(stockList[0] - 1);
      setScrollDirection("down");
    } else if (e.target.scrollTop === 0) {
      stockList.push(stockList[stockList.length - 1] + 1);
      setPagesList([...stockList]);
      setLogsPage(stockList[stockList.length - 1] + 1);
      setScrollDirection("up");
    }
  };

  function settingScrollPosition() {
    let stockElement = document.getElementById(`innerNode-${nodeKey}`);
    let stockReload = document.getElementById(`topReload-${nodeKey}`);
    if (stockReload) {
      stockElement.scrollTop = stockReload.scrollHeight;
    }
  }

  const buildingInnerNodeStyle = () => {
    let stockStyle = {};
    stockStyle.height = "calc(100vh - 15rem)";
   
    return stockStyle;
  };

  useEffect(() => {
    log && returningLogs();
  }, [log, localeTime]);

  useEffect(() => {
    log && log.length > 0 && settingScrollPosition();
  }, [displayLogs]);

  return (
    <article
      className={`logsNode${activeNode ? " fullScreenNode" : ""}`}
      id={`innerNode-${nodeKey}`}
      style={
        activeNode
          ? { height: "calc(100vh - 8rem)" }
          : mergeLogs
          ? { height: "calc(100vh - 17rem)" }
          : { height: `calc(((100vh - 17rem) / ${logsNumber}) - 0.5rem)` }
      }
      onScroll={(e) => handlingScroll(e)}
    >
      {!log || log.length === 0 && (
        <p class="info">Pas de log pour cette période</p>
      )}
      <div style={buildingInnerNodeStyle()}>{displayLogs}</div>
      <MddButton
        iconName="bi bi-aspect-ratio"
        buttonSize="small"
        smallButtonDescription="See clause logs"
        buttonActionFunctionOne={handlingFullScreen}
        buttonActionPropOne={activeNode ? null : log}
        iconColor="altButtonColor"
      />      
    </article>
  );
}
