import { useState } from "react";
import LogField from "./LogField";

export default function LogLine({ log, localeTime }) {
  const [showDetail, setShowDetail] = useState(false);
  
  const statusPicker = (log) => {
    switch (log.lvl) {
      case "WARN":
        return " statusWarning";
      case "ERROR":
        return " statusAlert";
      case "FATAL":
        return " statusFatal";
      default:
        return "";
    }
  };

  const returningFields = (log) => {
    let fields = [];
    let entries = Object.entries(log.fields);
    for (const [key, value] of entries) {
      fields.push(<LogField name={key} value={value} />);     
    }

    /*
    for (let i=0; i<entries.length; i++) {
      fields.push(<LogField key={entries[i][0]} value={entries[i][1]} />);  
    }
    */
    return fields;
  };
  
  return (
    <div className="logLine">
      <div 
        class={`line ${statusPicker(log)}`}
        onClick={() => { setShowDetail( ! showDetail);}}
        >
        <div class="col-date">{log.dt.replace('T', ' ')}</div>
        <div class="col-level">{log.lvl.length < 5 ? log.lvl + " " : log.lvl}</div>
        <div class="col-node">{log.nde}</div>
        <div class="col-flow">{log.fl}</div>
        <div class="col-lambda">{log.edp}</div>
        <div class="col-message">{log.fields.msg}</div>
      </div>  
      {showDetail && Object.keys(log.fields).length > 1 && ( 
      <div class="detail">
        <span class="fields">
          <table>{returningFields(log)}</table>
        </span>        
      </div> 
      )} 
      {showDetail && Object.keys(log.fields).length==1 && ( 
      <div class="detail">
        <span class="message"><pre>{log.fields.msg}</pre></span>
      </div> 
      )}   
    </div>    
  );
}
