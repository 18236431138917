import { useContext } from "react";

import { MddContext } from "../../../../context/MddContext";
import "../../../../styles/streamStore/detailedStream/timelines.css";

export default function LifeCycle({
  setDateSelector,
  dateSelector,
  streamPreOps,
  streamProd,
  streamEndOfSupport,
  streamEndOfLife,
  versionPeriod,
  versionDays,
  activeVersion,
  invalidTime,
  setCalendarVerticalPosition,
}) {
  const { activeStream } = useContext(MddContext);

  const durationCalculation = (dateOne, dateTwo) => {
    const firstDate = new Date(dateOne);
    const lastDate = new Date(dateTwo);
    const fullDuration = lastDate - firstDate;
    const monthsRounding = Math.round(fullDuration / (1000 * 3600 * 24 * 30));
    if (monthsRounding > 0) {
      return `${monthsRounding} mois`;
    } else {
      return `Moins d'1 mois`;
    }
  };

  const classNameBuilder = (cycle) => {
    let stockClass = "lifeCycleItem";
    if (cycle === versionPeriod) {
      stockClass = stockClass + " activeCycle";
    }
    return stockClass;
  };

  const containerNameBuilder = (cycle) => {
    if (invalidTime && invalidTime.length > 0) {
      for (let i = 0; i < invalidTime.length; i++) {
        if (cycle === invalidTime[i].name) {
          return "invalidTime";
        }
      }
    }
    return null;
  };

  const daysBuilder = (cycle) => {
    if (cycle === versionPeriod && versionDays === 0) {
      return <p>en cours</p>;
    } else if (cycle === versionPeriod) {
      return <p>{versionDays} jours restants</p>;
    } else {
      return <p>&nbsp;</p>;
    }
  };

  const timelineClassNameBuilder = () => {
    let stockName = "lifeCycleTimeLine";
    if (!activeStream || !activeStream.editable) {
      stockName = stockName + " subTimeLine";
    } else if (!activeVersion) {
      stockName = stockName + " inactiveTimeLine";
    }
    return stockName;
  };

  const parsingDisplayDates = (dateInput) => {
    let day = dateInput.slice(3, 5);
    let month = dateInput.slice(0, 2);
    let year = dateInput.slice(6, 10);
    return `${day}/${month}/${year}`;
  };

  return (
    <article className="lifeCycleContainer">
      <div className={timelineClassNameBuilder()} />
      <div className={containerNameBuilder("pre-production")}>
        <p>Pré-production</p>
        <p>
          {streamPreOps
            ? activeVersion.period === "development"
              ? `Le ${parsingDisplayDates(streamPreOps)} (dans ${
                  activeVersion.days * -1
                } jours)`
              : activeVersion.period === "pre-production"
              ? `Depuis le ${parsingDisplayDates(streamPreOps)}`
              : `Le ${parsingDisplayDates(streamPreOps)}`
            : !activeStream || !activeStream.editable || !activeVersion
            ? "Pas de date pour l'instant"
            : "Sélectionnez une date"}
        </p>
        <button
          type="button"
          className={classNameBuilder("pre-production")}
          onClick={(e) => {
            setDateSelector(
              dateSelector === "preOpsDate" ? null : "preOpsDate"
            );
            setCalendarVerticalPosition(e.pageY);
          }}
          disabled={!activeStream || !activeStream.editable}
        />
        {streamPreOps && streamProd ? (
          <p style={{ marginTop: "2rem" }}>
            {durationCalculation(streamPreOps, streamProd)}
          </p>
        ) : (
          <p style={{ marginTop: "2rem" }}>&nbsp;</p>
        )}
        {daysBuilder("pre-production")}
      </div>
      <div className={containerNameBuilder("production")}>
        <p>Production</p>
        <p>
          {streamProd
            ? activeVersion.period === "production"
              ? `Depuis le ${parsingDisplayDates(streamProd)}`
              : `Le ${parsingDisplayDates(streamProd)}`
            : !activeStream || !activeStream.editable || !activeVersion
            ? "Pas de date pour l'instant"
            : "Sélectionnez une date"}
        </p>
        <button
          type="button"
          className={classNameBuilder("production")}
          onClick={(e) => {
            setDateSelector(
              dateSelector === "releaseDate" ? null : "releaseDate"
            );
            setCalendarVerticalPosition(e.pageY);
          }}
          disabled={!activeStream || !activeStream.editable}
        />
        {streamEndOfSupport && streamProd ? (
          <p style={{ marginTop: "2rem" }}>
            {durationCalculation(streamProd, streamEndOfSupport)}
          </p>
        ) : (
          <p style={{ marginTop: "2rem" }}>&nbsp;</p>
        )}
        {daysBuilder("production")}
      </div>
      <div className={containerNameBuilder("end-of-support")}>
        <p>Fin de support</p>
        <p>
          {streamEndOfSupport
            ? activeVersion.period === "end-of-support"
              ? `Depuis le ${parsingDisplayDates(streamEndOfSupport)}`
              : `Le ${parsingDisplayDates(streamEndOfSupport)}`
            : !activeStream || !activeStream.editable || !activeVersion
            ? "Pas de date pour l'instant"
            : "Sélectionnez une date"}
        </p>
        <button
          type="button"
          className={classNameBuilder("end-of-support")}
          onClick={(e) => {
            setDateSelector(
              dateSelector === "endOfSupportDate" ? null : "endOfSupportDate"
            );
            setCalendarVerticalPosition(e.pageY);
          }}
          disabled={!activeStream || !activeStream.editable}
        />
        {streamEndOfSupport && streamEndOfLife ? (
          <p style={{ marginTop: "2rem" }}>
            {durationCalculation(streamEndOfSupport, streamEndOfLife)}
          </p>
        ) : (
          <p style={{ marginTop: "2rem" }}>&nbsp;</p>
        )}
        {daysBuilder("end-of-support")}
      </div>
      <div className={containerNameBuilder("end-of-life")}>
        <p>Fin de vie</p>
        <p>
          {streamEndOfLife
            ? activeVersion.period === "end-of-life"
              ? `Depuis le ${parsingDisplayDates(streamEndOfLife)}`
              : `Le ${parsingDisplayDates(streamEndOfLife)}`
            : !activeStream || !activeStream.editable || !activeVersion
            ? "Pas de date pour l'instant"
            : "Sélectionnez une date"}
        </p>
        <button
          type="button"
          className={classNameBuilder("end-of-life")}
          onClick={(e) => {
            setDateSelector(
              dateSelector === "endOfLifeDate" ? null : "endOfLifeDate"
            );
            setCalendarVerticalPosition(e.pageY);
          }}
          disabled={!activeStream || !activeStream.editable}
        />
        <p style={{ marginTop: "2rem" }}>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </article>
  );
}
