import { useContext, useEffect, useState, useRef } from "react";

export default function DatavizNumberInput({
  label,
  displayValue,
  input,
  min,
  max,
  step,
  path,
  updater,
  outputValue,
}) {
  const parsingInput = (inputNumber) => {
    switch (outputValue[0]) {
      case "multiply":
        return inputNumber * outputValue[1];
      case "divide":
        return inputNumber / outputValue[1];
      default:
        return inputNumber;
    }
  };

  const [innerValue, setInnerValue] = useState(
    input && typeof input === "number"
      ? parsingInput(input)
      : displayValue && typeof displayValue === "number"
      ? parsingInput(displayValue)
      : 5
  );

  const parsingOutput = (inputNumber) => {
    switch (outputValue[0]) {
      case "multiply":
        return inputNumber / outputValue[1];
      case "divide":
        return inputNumber * outputValue[1];
      default:
        return inputNumber;
    }
  };

  return (
    <div className="datavizInput">
      <label>{label}</label>
      <input
        type="range"
        min={min ? min : "0"}
        max={max ? max : "10"}
        step={step ? step : "1"}
        value={innerValue}
        onChange={(e) => {
          setInnerValue(e.target.value);
        }}
        onMouseUp={(e) => {
          parsingOutput(e.target.value) !== input &&
            updater(path, parsingOutput(e.target.value));
        }}
      />
    </div>
  );
}
