export default function returningInputWidth(textInput, smallCheck) {
  let stockTitle = textInput.split("");
  let stockLength = 0;
  for (let i = 0; i < stockTitle.length; i++) {
    if (stockTitle[i] === stockTitle[i].toUpperCase()) {
      stockLength += 1.175;
    } else {
      stockLength += 0.75;
    }
  }
  if (smallCheck) {
    stockLength *= 0.75;
  }
  return stockLength;
}
