import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import "../../styles/demo/dashboard/dashboard.css";
import revertingToken from "../../utils/auth/revertingToken";

import Dashboard from "../../components/Dashboard";
import DashboardCard from "../components/home/DashboardCard";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import MddLoader from "../../components/reusableElements/MddLoader";
import MddButton from "../../components/reusableElements/MddButton";

const defaultDemos = [
  { title: "Arrivées des vols", reference: "flights-arrivals" },
  { title: "Gestion d'hôtels", reference: "hotels-management" },
  { title: "Panneaux solaires", reference: "solar-panels" },
  // { title: "test", reference: "test" },
  // { name: "Dataviz", reference: "dashboard" },
];

export default function DatavizHome() {
  const {
    handling401,
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    closingSession,
    userToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [dashboards, setDashboards] = useState([]);
  const [dashboardsAssigned, setDashboardsAssigned] = useState(true);

  const retrievingDashboards = () => {
    if (localStorage.getItem("mddTheme")) {
      if (closingSession()) {
        history.push("/login");
      } else {
        mddStreamStoreApi
          .get(`/dataviz`, {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            const informations = response.data.data;
            setDashboards(informations);
            setDashboardsAssigned(true);
            console.log("retrieving dashboards : ", response.data.data);
          })
          .catch((err) => {
            console.log("erreur au niveau de la requete API GET : ", err);
            handling401(err, history);
          });
      }
    }
  };

  const returningCards = () => {
    let stockCards = [];
    for (let i = 0; i < defaultDemos.length; i++) {
      stockCards.push(
        <DashboardCard dashboard={defaultDemos[i]} key={`demo ${i}`} />
      );
    }
    for (let i = 0; i < dashboards.length; i++) {
      stockCards.push(
        <DashboardCard dashboard={dashboards[i]} key={`dashboard ${i}`} />
      );
    }
    return stockCards;
  };

  const creatingDashboard = () => {
    const defaultDashboardSettings = {
      version: "1.0.0",
      title: "Mon Dashboard",
      owner: "team",
      published: false,
      description: "",
      navigation: {
        from: "now-1h",
        to: "now",
        delay: "5s",
        refresh: "10s",
      },
      vars: [],
      style: {
        header: {
          backgroundColor: "",
        },
        title: {
          color: "",
          fontSize: "",
          fontFamily: "",
          fontWeight: "",
          fontType: "",
          textAlign: "",
        },
        body: {
          backgroundColor: "",
          fontSize: "",
          fontFamily: "",
          fontWeight: "",
          fontType: "",
        },
      },
      widgets: [],
      queries: [],
    };
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(`/dataviz`, defaultDashboardSettings, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          console.log("retrieving dashboard : ", stock);
          history.push(`dataviz/dashboard/${stock.id}`);
          feedBackBuilder(true, "Dashboard créé", "Dataviz");
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          // setChallengedCall(() => retrievingStream());
          handling401(err, history, "Le dashboard n'a pas été créé");
        });
    }
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
    retrievingDashboards();
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer">
      <div className="allCardsContainer allDemosContainer">
        <div className="topDashboardContainer">
          <MddBreadCrumb
            backPagesNames={[{ name: "Home", url: "/" }]}
            currentPage="Demos"
          />
          <MddButton
            buttonInnerText="Créer un dashboard"
            buttonSize="long"
            iconColor="altButtonColor"
            buttonActionFunctionOne={creatingDashboard}
            iconName="bi bi-plus-circle"
          />
        </div>
        {dashboardsAssigned ? returningCards() : <MddLoader />}
      </div>
    </Dashboard>
  );
}
