import MddBreadCrumb from "../../reusableElements/MddBreadCrumb";
import MddButton from "../../reusableElements/MddButton";

export default function SolarHeader({
  solarNav,
  setSolarNav,
  retrievingProbeData,
}) {
  return (
    <>
      <MddBreadCrumb
        backPagesNames={[
          { name: "Home", url: "/" },
          { name: "Demos", url: "/dataviz" },
        ]}
        currentPage="Panneaux solaires"
      />
      <section className="solarHeader">
        <MddButton
          buttonInnerText="indicateurs"
          buttonSize="long"
          buttonActionFunctionOne={setSolarNav}
          buttonActionPropOne="indicators"
          buttonActionFunctionTwo={retrievingProbeData}
          iconColor={
            solarNav === "indicators" ? "basicButtonColor" : "altButtonColor"
          }
        />
        <MddButton
          buttonInnerText="production"
          buttonSize="long"
          buttonActionFunctionOne={setSolarNav}
          buttonActionPropOne="production"
          buttonActionFunctionTwo={retrievingProbeData}
          buttonActionPropTwo="panels"
          iconColor={
            solarNav === "production" ? "basicButtonColor" : "altButtonColor"
          }
        />
        <MddButton
          buttonInnerText="batteries"
          buttonSize="long"
          buttonActionFunctionOne={setSolarNav}
          buttonActionPropOne="batteries"
          buttonActionFunctionTwo={retrievingProbeData}
          buttonActionPropTwo="batteries"
          iconColor={
            solarNav === "batteries" ? "basicButtonColor" : "altButtonColor"
          }
        />
      </section>
    </>
  );
}
