import { useReducer, useContext, useState, useEffect } from "react";

import { MddContext } from "../../context/MddContext";
import "../../styles/reusableElements/searchBar.css";

import MddButton from "./MddButton";

export default function MddSearchBar({ filtersDatalist, refreshCallback }) {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const {
    filters,
    activeFilter,
    setFilters,
    setActiveFilter,
    filterElements,
    setFilterElements,
  } = useContext(MddContext);

  const [focusedSearchBar, setFocusedSearchBar] = useState(false);
  const [currentlyTyping, setCurrentlyTyping] = useState("");

  const savingFilter = () => {
    let stockFilter = filterElements.filter((element) => {
      return element.criteria;
    });
    if (stockFilter.length > 0) {
      setFilters([
        ...filters,
        {
          key: filters.length + 1,
          name: `${stockFilter[0].field}${stockFilter[0].operator}${stockFilter[0].criteria}`,
          elements: stockFilter,
        },
      ]);
    }
    setFilterElements([]);
    setActiveFilter(filters.length + 1);
  };

  const removingFilterElements = (index) => {
    let stockFiltering = filterElements;
    stockFiltering.splice(index, 1);
    stockFiltering.length === 0 && setActiveFilter(null);
    setFilterElements(stockFiltering);
    forceUpdate();
  };

  const returningFilters = () => {
    return filterElements.map((filter, index) => {
      let stockElements = [
        filter.field && <p key={`${index} ${filter.field}`}>{filter.field}</p>,
        filter.operator && (
          <p key={`${index} ${filter.operator}`}>{filter.operator}</p>
        ),
        filter.criteria && (
          <p key={`${index} ${filter.criteria}`}>
            {filter.criteria}{" "}
            <i
              className="bi bi-x-lg"
              onClick={() => {
                removingFilterElements(filterElements.indexOf(filter));
              }}
            />
          </p>
        ),
      ];
      return stockElements;
    });
  };

  const handlingCriteriaEnter = () => {
    let stock = currentlyTyping;
    setCurrentlyTyping(`> Chercher ${stock}`);
  };

  const handlingSubmit = () => {
    if (activeFilter) {
      editingFilter();
    } else {
      savingFilter();
    }
  };

  const editingFilter = () => {
    let stockFilters = [...filters];
    let currentFilter = filters.find((f) => f.key === activeFilter);
    let stockIndex = stockFilters.findIndex((f) => f.key === activeFilter);
    let stockFilter = filterElements.filter((element) => {
      return element.criteria;
    });
    let filterToSave = {
      key: stockIndex + 1,
      name: currentFilter.name,
      elements: stockFilter,
    };
    stockFilters.splice(stockIndex, 1, filterToSave);
    setActiveFilter(null);
    setFilters(stockFilters);
    setActiveFilter(stockIndex + 1);
  };
  const clearingFilter = () => {
    setFilterElements([]);
    setActiveFilter(null);
  };

  const addingFilterElement = () => {
    const options = document.getElementById("filterOptions").childNodes;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === currentlyTyping) {
        let stockFilter = filterElements;
        if (
          filterElements.length === 0 ||
          filterElements[filterElements.length - 1].criteria
        ) {
          stockFilter.push({ field: currentlyTyping });
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        } else if (
          filterElements[filterElements.length - 1].field &&
          !filterElements[filterElements.length - 1].operator
        ) {
          stockFilter[stockFilter.length - 1].operator =
            currentlyTyping === "contient" || currentlyTyping === "="
              ? "="
              : "!=";
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        }
      } else if (
        currentlyTyping.includes("> Chercher ") &&
        filterElements[filterElements.length - 1].operator &&
        !filterElements[filterElements.length - 1].criteria
      ) {
        let stockFilter = filterElements;
        stockFilter[stockFilter.length - 1].criteria = currentlyTyping.slice(
          11,
          currentlyTyping.length
        );
        // .toLowerCase();
        setFilterElements(stockFilter);
        setCurrentlyTyping("");
        break;
      }
    }
  };

  useEffect(() => {
    document.getElementById("filterOptions") && addingFilterElement();
  }, [currentlyTyping]);

  useEffect(() => {
    console.log("search bar use effect : ", activeFilter);
  }, [activeFilter]);

  return (
    <form
      className={`searchContainer${focusedSearchBar ? " focusedSearch" : ""}`}
      onSubmit={(e) => {
        e.preventDefault();
        // filterElements.length > 0 &&
        //   filterElements[filterElements.length - 1].criteria &&
        //   handlingSubmit();
      }}
    >
      {filterElements && returningFilters()}
      <input
        className="form-control"
        list="filterOptions"
        id="streamSearch"
        name="streamSearch"
        placeholder="Recherche"
        value={currentlyTyping}
        onFocus={() => {
          setFocusedSearchBar(true);
        }}
        onBlur={() => {
          setFocusedSearchBar(false);
        }}
        onChange={(e) => {
          setCurrentlyTyping(e.target.value);
        }}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            filterElements.length > 0 &&
            filterElements[filterElements.length - 1].operator &&
            !filterElements[filterElements.length - 1].criteria &&
            handlingCriteriaEnter();
          (e.key === "Enter" || e.keyCode === 13) &&
            currentlyTyping === "" &&
            filterElements.length === 0 &&
            refreshCallback &&
            refreshCallback();
          (e.key === "Enter" || e.keyCode === 13) &&
            filterElements.length > 0 &&
            filterElements[filterElements.length - 1].criteria &&
            handlingSubmit();
        }}
      />
      {(filterElements.length === 0 ||
        filterElements[filterElements.length - 1].criteria) && (
        <datalist id="filterOptions">
          {filtersDatalist.fields.map((f) => (
            <option value={f} />
          ))}
        </datalist>
      )}
      {filterElements.length > 0 &&
        filterElements[filterElements.length - 1].field &&
        !filterElements[filterElements.length - 1].operator && (
          <datalist id="filterOptions">
            <option value="contient" />
            <option value="ne contient pas" />
            <option value="=" />
            <option value="!=" />
          </datalist>
        )}
      {filterElements.length > 0 &&
        filterElements[filterElements.length - 1].operator &&
        !filterElements[filterElements.length - 1].criteria && (
          <datalist id="filterOptions">
            <option value={`> Chercher ${currentlyTyping}`} />
          </datalist>
        )}
      {filterElements.length > 0 && (
        <MddButton
          iconName="bi-x"
          buttonSize="small"
          smallButtonDescription="Clear filter"
          buttonActionFunctionOne={clearingFilter}
          iconColor="altButtonColor"
        />
      )}
    </form>
  );
}
