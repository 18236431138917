import { useContext, useEffect, useState, useRef } from "react";

import MddButton from "../../../../../components/reusableElements/MddButton";

export default function OptionsCategory({ children, name }) {
  const [openedCategory, setOpenedCategory] = useState(false);

  const returningMaxHeight = () => {
    if (openedCategory) {
      const stockCategory = document.getElementById(`openedCategory-${name}`);
      stockCategory.style.maxHeight =
        Math.round(stockCategory.childNodes.length * 2.45 + 0.5) + "rem";
    } else {
      const stockCategory = document.getElementById(`${name}`);
      stockCategory.style.maxHeight = "2.2rem";
    }
  };

  useEffect(() => {
    returningMaxHeight();
  }, [openedCategory]);

  return (
    <article
      className={`optionsCategory${openedCategory ? " openedCategory" : ""}`}
      id={`${openedCategory ? "openedCategory-" : ""}${name}`}
    >
      <div
        onClick={() => {
          setOpenedCategory(!openedCategory);
        }}
      >
        <MddButton
          iconName="bi bi-caret-down"
          buttonSize="small"
          smallButtonDescription="open category"
          buttonActionFunctionOne={setOpenedCategory}
          buttonActionPropOne={!openedCategory}
          iconColor="altButtonColor"
        />
        <h5>{name}</h5>
      </div>
      {children}
    </article>
  );
}
