import { useState, useEffect, useReducer, useContext } from "react";

import { MddContext } from "../../context/MddContext";
import { profileRegex } from "../../utils/profile/profileRegex";
import classingIncorrectInput from "../../utils/classingIncorrectInput";

import MddButton from "../reusableElements/MddButton";
import TeamsDropdown from "./TeamsDropdown";

export default function UserForm({
  user,
  selectingUser,
  modifyingUser,
  usersSelected,
  editingUser,
  setEditingUser,
  usersTeams,
  modifyingEmail,
}) {
  const { feedBackBuilder } = useContext(MddContext);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [userEmail, setUserEmail] = useState(user ? user.login : "");
  const [userFirstName, setUserFirstName] = useState(
    user ? user.firstname : ""
  );
  const [userLastName, setUserLastName] = useState(user ? user.lastname : "");
  const [userRole, setUserRole] = useState(user ? user.role : "");
  const [userTeams, setUserTeams] = useState(
    user ? user.scopes.split(",") : []
  );
  const [userOrganization, setUserOrganization] = useState(
    user ? user.organization : ""
  );
  const [selectedUser, setSelectedUser] = useState(false);
  const [userValidInputs, setUserValidInputs] = useState([
    "email",
    "firstname",
    "lastname",
    "organization",
  ]);
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [tempTeam, setTempTeam] = useState("");

  const buildingClassName = () => {
    let builtClass = "userFormContainer";
    if (user && user.state) {
      builtClass = builtClass + ` ${user.state}User`;
    }
    if (selectedUser) {
      builtClass = builtClass + " selectedUser";
    }
    if (!user) {
      builtClass = builtClass + " addForm";
    }
    return builtClass;
  };

  const handlingSelect = () => {
    editingUser === user.account && setEditingUser(null);
  };

  const buildingUser = () => {
    let stockUser = {
      // email: userEmail,
      role: userRole !== "" ? userRole : "user",
      scopes: userTeams.length > 0 ? userTeams.join(",") : "team",
    };
    if (userFirstName !== "") {
      stockUser.firstname = userFirstName;
    }
    if (userLastName !== "") {
      stockUser.lastname = userLastName;
    }
    if (userOrganization !== "") {
      stockUser.organization = userOrganization;
    }
    return stockUser;
  };

  const checkingEditedStatus = (control, input) => {
    if (
      userEmail !== "" &&
      input !== control &&
      userEmail === user.login &&
      userValidInputs.length === 4
    ) {
      modifyingUser(buildingUser(), userEmail);
    } else if (
      userEmail !== "" &&
      userEmail !== user.login &&
      userValidInputs.length === 4
    ) {
      modifyingEmail(userEmail, user.login);
    } else if (userValidInputs.length !== 4) {
      let stockMessage = "";
      if (
        !userValidInputs.some((element) => {
          return element === "email";
        })
      ) {
        stockMessage = stockMessage + " L'adresse email n'est pas valide.";
      }
      if (
        !userValidInputs.some((element) => {
          return element === "firstname";
        })
      ) {
        stockMessage = stockMessage + " Le prénom n'est pas valide.";
      }
      if (
        !userValidInputs.some((element) => {
          return element === "lastname";
        })
      ) {
        stockMessage = stockMessage + " Le nom n'est pas valide.";
      }
      feedBackBuilder(false, stockMessage, "Utilisateurs");
    }
  };
  const handlingEditToggle = () => {
    if (editingUser === user.account) {
      setEditingUser(null);
    } else {
      setEditingUser(user.account);
    }
  };

  const checkingInputs = () => {
    let stockInputs = userValidInputs;
    const addValidInput = (inputName) => {
      if (
        !stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs.push(inputName);
        setUserValidInputs(stockInputs);
      }
    };
    const removeInvalidInput = (inputName) => {
      if (
        stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs = stockInputs.filter((element) => {
          return element !== inputName;
        });
        setUserValidInputs(stockInputs);
      }
    };
    if (profileRegex.email.test(userEmail)) {
      addValidInput("email");
    } else {
      removeInvalidInput("email");
    }
    if (
      profileRegex.firstname.test(userFirstName) ||
      userFirstName.length === 0
    ) {
      addValidInput("firstname");
    } else {
      removeInvalidInput("firstname");
    }
    if (profileRegex.lastname.test(userLastName) || userLastName.length === 0) {
      addValidInput("lastname");
    } else {
      removeInvalidInput("lastname");
    }
    if (
      profileRegex.organization.test(userOrganization) ||
      userOrganization.length === 0
    ) {
      addValidInput("organization");
    } else {
      removeInvalidInput("organization");
    }
    forceUpdate();
  };

  const cleaningData = () => {
    setUserEmail("");
    setUserFirstName("");
    setUserLastName("");
    setUserRole("");
    setUserTeams("");
  };

  const handlingAdding = () => {
    if (userEmail !== "") {
      modifyingUser(buildingUser(), userEmail, cleaningData);
    } else {
      feedBackBuilder(
        false,
        "Vous devez entrer une adresse email",
        "Utilisateurs"
      );
    }
  };

  const suggestingName = () => {
    let stockEmail = userEmail.split("@");
    if (stockEmail.length > 0) {
      let stockFullname = stockEmail[0].split(".");
      if (stockFullname.length > 0) {
        setUserFirstName(stockFullname[0]);
        if (stockFullname.length > 1) {
          setUserLastName(stockFullname[1]);
        }
      }
    }
  };

  useEffect(() => {
    checkingInputs();
  }, [userEmail, userFirstName, userLastName]);

  useEffect(() => {
    if (user) {
      checkingEditedStatus(user.scopes, userTeams.join(","));
    }
  }, [userTeams]);

  useEffect(() => {
    if (user) {
      checkingEditedStatus(user.role, userRole);
    }
  }, [userRole]);

  useEffect(() => {
    if (user) {
      if (
        usersSelected.some((singleUser) => {
          return singleUser.account === user.account;
        })
      ) {
        setSelectedUser(true);
      } else {
        setSelectedUser(false);
      }
    }
  }, [usersSelected]);

  useEffect(() => {
    !user && suggestingName();
  }, [userEmail]);

  return (
    <article
      className={buildingClassName()}
      onClick={() => handlingEditToggle()}
    >
      {user ? (
        selectedUser ? (
          <MddButton
            iconName="bi bi-check"
            buttonSize="small"
            smallButtonDescription="add user"
            buttonActionFunctionOne={handlingSelect}
            buttonActionFunctionTwo={selectingUser}
            buttonActionPropTwo={user}
            iconColor="altButtonColor"
            noPropagation
          />
        ) : (
          <MddButton
            iconName=""
            buttonSize="small"
            smallButtonDescription="add user"
            buttonActionFunctionOne={handlingSelect}
            buttonActionFunctionTwo={selectingUser}
            buttonActionPropTwo={user}
            noPropagation
          />
        )
      ) : (
        <MddButton
          iconName="bi bi-file-plus"
          buttonSize="small"
          smallButtonDescription="add user"
          buttonActionFunctionOne={handlingAdding}
          noPropagation
        />
      )}
      {!user || editingUser === user.account ? (
        <form
          onSubmit={(e) => {
            !user && handlingAdding(e);
          }}
        >
          <input
            type="email"
            id="emailForm"
            className={
              userEmail.length > 0
                ? classingIncorrectInput("email", userValidInputs)
                : ""
            }
            required
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={(e) => {
              user && checkingEditedStatus(user.login, userEmail);
            }}
            onKeyUp={(e) => {
              (e.key === "Enter" || e.keyCode === 13) &&
                user &&
                checkingEditedStatus(user.login, userEmail);
            }}
          />
          <input
            type="text"
            id="firstNameForm"
            className={
              userFirstName.length > 0
                ? classingIncorrectInput("firstname", userValidInputs)
                : ""
            }
            value={userFirstName}
            onChange={(e) => setUserFirstName(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={(e) => {
              user && checkingEditedStatus(user.firstname, userFirstName);
            }}
            onKeyUp={(e) => {
              (e.key === "Enter" || e.keyCode === 13) &&
                user &&
                checkingEditedStatus(user.firstname, userFirstName);
            }}
          />
          <input
            type="text"
            id="lastNameForm"
            className={
              userLastName.length > 0
                ? classingIncorrectInput("lastname", userValidInputs)
                : ""
            }
            value={userLastName}
            onChange={(e) => setUserLastName(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={(e) => {
              user && checkingEditedStatus(user.lastname, userLastName);
            }}
            onKeyUp={(e) => {
              (e.key === "Enter" || e.keyCode === 13) &&
                user &&
                checkingEditedStatus(user.lastname, userLastName);
            }}
          />
          <select
            name="selectRoleForm"
            id="selectRoleForm"
            className="usersSelect"
            onChange={(e) => setUserRole(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          >
            <option
              value="user"
              selected={userRole === "user" || userRole === ""}
            >
              utilisateur
            </option>
            <option value="provider" selected={userRole === "provider"}>
              fournisseur
            </option>
            <option value="admin" selected={userRole === "admin"}>
              administrateur
            </option>
          </select>
          {creatingTeam ? (
            <input
              type="text"
              id="teamForm"
              value={tempTeam}
              onChange={(e) => setTempTeam(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              onBlur={() => {
                setUserTeams([...userTeams, tempTeam]);
                setCreatingTeam(false);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter" || e.keyCode === 13) {
                  setUserTeams([...userTeams, tempTeam]);
                  setCreatingTeam(false);
                }
              }}
            />
          ) : (
            <TeamsDropdown
              userTeams={userTeams}
              setUserTeams={setUserTeams}
              usersTeams={usersTeams}
              setCreatingTeam={setCreatingTeam}
            />
          )}
          <input
            type="text"
            id="organizationForm"
            className={
              userLastName.length > 0
                ? classingIncorrectInput("organization", userValidInputs)
                : ""
            }
            value={userOrganization}
            onChange={(e) => setUserOrganization(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onBlur={(e) => {
              user && checkingEditedStatus(user.organization, userOrganization);
            }}
            onKeyUp={(e) => {
              (e.key === "Enter" || e.keyCode === 13) &&
                user &&
                checkingEditedStatus(user.organization, userOrganization);
            }}
          />
        </form>
      ) : (
        <div>
          <p>{userEmail}</p>
          <p>{userFirstName}</p>
          <p>{userLastName}</p>
          <p>
            {userRole === "admin"
              ? "administrateur"
              : userRole === "provider"
              ? "fournisseur"
              : "utilisateur"}
          </p>
          <p>
            {userTeams
              ? userTeams.join(", ").length > 17
                ? `${userTeams.join(", ").slice(0, 17)}...`
                : userTeams.join(", ")
              : ""}
          </p>
          <p>{userOrganization}</p>
        </div>
      )}
    </article>
  );
}
