import { useContext, useEffect, useState, useRef } from "react";

import OptionsCategory from "../OptionsCategory";
import DatavizColorPicker from "../inputs/DatavizColorPicker";
import DatavizList from "../inputs/DatavizList";
import DatavizNumberInput from "../inputs/DatavizNumberInput";
import DatavizSwitch from "../inputs/DatavizSwitch";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizIconsSwitch from "../inputs/DatavizIconsSwitch";

export default function BarChart({
  datavizSidebar,
  updatingWidget,
  set,
  index,
}) {
  return (
    <OptionsCategory name={`Dataset : ${set.label}`}>
      <p>Barres</p>
      <DatavizColorPicker
        label="couleur"
        input={set.backgroundColor}
        path={["component", "settings", "datasets", index, "backgroundColor"]}
        updater={updatingWidget}
      />
      <DatavizColorPicker
        label="bords"
        input={set.borderColor}
        path={["component", "settings", "datasets", index, "borderColor"]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label="angles"
        displayValue={5}
        outputValue={["multiply", 2]}
        input={set.borderRadius}
        path={["component", "settings", "datasets", index, "borderRadius"]}
        updater={updatingWidget}
      />
      <DatavizIconsSwitch
        label="axe"
        displayValue={[
          "bi bi-align-start",
          "bi bi-align-center",
          "bi bi-align-end",
        ]}
        outputValue={["left", "center", "right"]}
        input={
          datavizSidebar.widget.component.config.scales[set.yAxisID].position
        }
        path={["component", "config", "scales", set.yAxisID, "position"]}
        updater={updatingWidget}
      />
    </OptionsCategory>
  );
}
