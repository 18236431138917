import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { MddContext } from "../context/MddContext";
import mddlogo from "../utils/pictures/mddlogo.png";
import "../styles/noPage.css";

import Dashboard from "../components/Dashboard";

export default function NoPage() {
  const { fullScreen, setFullScreen, setShowSideBar, showSideBar } =
    useContext(MddContext);

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer">
      <div className="noPageGlobalContainer">
        <img src={mddlogo} alt="Logo de l'entreprise"></img>
        <h5>La page demandée n'existe pas</h5>
        <Link to={`/`}>Retour à la page d'accueil</Link>
      </div>
    </Dashboard>
  );
}
