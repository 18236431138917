import returningRandomData from "./returningRandomData";
import returningRandomColors from "./returningRandomColors";

export const pie = {
  settings: {
    datasets: [
      {
        label: "Suivi",
        type: "pie",
        backgroundColor: returningRandomColors(10),
        data: returningRandomData(false, "pie"),
        hoverOffset: 4,
      },
    ],
  },
  config: {
    parsing: {
      key: "nested.value",
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Suivi : ${context.formattedValue}`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  },
};
