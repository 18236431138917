import { useContext } from "react";

import { MddContext } from "../../context/MddContext";

import MddButton from "../reusableElements/MddButton";

export default function UsersActions({
  usersSelected,
  setCreatingUser,
  usersObjects,
  setUserAction,
  selectingAll,
  invitingUsers,
}) {
  const { setConfirmModal } = useContext(MddContext);

  const buildingButtonName = (textInput) => {
    let stockUsers =
      textInput === "inviter"
        ? usersSelected
        : textInput === "supprimer" || textInput === "suspendre"
        ? usersSelected.filter((user) => {
            return user.state === "active";
          })
        : usersSelected.filter((user) => {
            return user.state === "suspended";
          });
    if (stockUsers.length === 1) {
      return `${textInput} 1 utilisateur`;
    } else if (stockUsers.length > 1) {
      return `${textInput} ${stockUsers.length} utilisateurs`;
    } else {
      return `${textInput}`;
    }

    // let activeUsers = usersSelected.filter((user) => {
    //   return user.state === "active";
    // });
    // let suspendedUsers = usersSelected.filter((user) => {
    //   return user.state === "suspended";
    // });
    // if (usersSelected.length === 1) {
    //   return `${textInput} 1 utilisateur`;
    // } else if (
    //   usersSelected.length > 1 &&
    //   activeUsers.length + suspendedUsers.length === usersSelected.length
    // ) {
    //   return `${textInput} ${usersSelected.length} utilisateurs`;
    // } else if (activeUsers.length > 0) {
    //   return `${textInput} ${activeUsers.length} utilisateurs`;
    // } else if (suspendedUsers.length > 0) {
    //   return `${textInput} ${suspendedUsers.length} utilisateurs`;
    // } else {
    //   return `${textInput}`;
    // }
  };

  const sortingActivateButton = () => {
    let activeUsers = usersSelected.filter((user) => {
      return user.state === "active";
    });
    let suspendedUsers = usersSelected.filter((user) => {
      return user.state === "suspended";
    });
    if (activeUsers.length > 0 && suspendedUsers.length === 0) {
      return buildingButtonName("suspendre");
    } else if (suspendedUsers.length > 0 && activeUsers.length === 0) {
      return buildingButtonName("activer");
    } else {
      return "activer / suspendre";
    }
  };

  const sortingInactiveActivate = () => {
    let activeUsers = usersSelected.filter((user) => {
      return user.state === "active";
    });
    let suspendedUsers = usersSelected.filter((user) => {
      return user.state === "suspended";
    });
    return (
      usersSelected.length === 0 ||
      activeUsers.length + suspendedUsers.length === 0
    );
  };

  const sortingDelete = () => {
    let activeUsers = usersSelected.filter((user) => {
      return user.state === "active";
    });
    return usersSelected.length === 0 || activeUsers.length === 0;
  };

  return (
    <article className="userActionsContainer">
      <MddButton
        buttonInnerText="ajouter"
        iconColor="basicButtonColor"
        buttonActionFunctionOne={setCreatingUser}
        buttonActionPropOne={true}
      />
      <div>
        <MddButton
          buttonInnerText="sélectionner"
          iconColor="basicButtonColor"
          inactive={usersObjects.length < 1}
          buttonActionFunctionOne={selectingAll}
        />
        <MddButton
          buttonInnerText={buildingButtonName("supprimer")}
          buttonSize="long"
          iconColor="basicButtonColor"
          inactive={sortingDelete()}
          buttonActionFunctionOne={setConfirmModal}
          buttonActionPropOne={true}
          buttonActionFunctionTwo={setUserAction}
          buttonActionPropTwo={"delete"}
        />
        <MddButton
          buttonInnerText={sortingActivateButton()}
          buttonSize="long"
          iconColor="basicButtonColor"
          inactive={sortingInactiveActivate()}
          buttonActionFunctionOne={setConfirmModal}
          buttonActionPropOne={true}
          buttonActionFunctionTwo={setUserAction}
          buttonActionPropTwo={"activate"}
        />
        <MddButton
          buttonInnerText={buildingButtonName("inviter")}
          buttonActionFunctionOne={invitingUsers}
          buttonSize="long"
          iconName="bi bi-people"
          inactive={usersSelected.length < 1}
        />
      </div>
    </article>
  );
}
