import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddAuth from "../../api/mddAuth";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";
import MddLoader from "../../components/reusableElements/MddLoader";

export default function ChangeLogin() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    tempToken,
    feedBackBuilder,
    setCurrentUser,
  } = useContext(MddContext);
  let history = useHistory();

  const [changeSuccess, setChangeSuccess] = useState("");

  const modifyingLogin = () => {
    mddAuth
      .post(
        `/change-login`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        }
      )
      .then((response) => {
        feedBackBuilder(
          true,
          "Votre email a bien été modifié",
          "Authentification"
        );
        setCurrentUser(response.data.data.email);
        setChangeSuccess("good");
      })
      .catch((err) => {
        if (err.response.status === 401) {
          feedBackBuilder(
            false,
            `Votre demande de changement de login a expiré`,
            "Authentification"
          );
        } else {
          feedBackBuilder(
            false,
            `Le login n'a pas pu être modifié`,
            "Authentification"
          );
        }
        setChangeSuccess("bad");
        console.log("erreur au niveau de la requête : ", err);
      });
  };

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  useEffect(() => {
    if (tempToken) {
      tempToken && history.push(`/account/change-login?token=${tempToken}`);
      modifyingLogin();
    }
  }, [tempToken]);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className="subscribeGlobalContainer">
        {changeSuccess ? (
          changeSuccess === "good" ? (
            <article>
              <h5>Votre email a bien été modifié</h5>
              <p>
                Vous pouvez désormais vous connecter avec votre nouvel email.
              </p>
              <MddButton
                buttonInnerText="me connecter"
                buttonActionFunctionOne={history.push}
                buttonActionPropOne={"/login"}
              />
            </article>
          ) : (
            <article>
              <h5>Votre email n'a pas été modifié</h5>
              <p>Veuillez réessayer</p>
              <MddButton
                buttonInnerText="me connecter"
                buttonActionFunctionOne={history.push}
                buttonActionPropOne={"/login"}
              />
            </article>
          )
        ) : (
          <MddLoader />
        )}
      </div>
    </Dashboard>
  );
}
