import { useContext, useEffect, useState } from "react";

import { MddContext } from "../../../context/MddContext";

import MddBreadCrumb from "../../reusableElements/MddBreadCrumb";
import MddButton from "../../reusableElements/MddButton";
import MddSearchBar from "../../reusableElements/MddSearchBar";

const flightsFilters = {
  fields: [
    "destination",
    "provenance",
    "arrivée estimée",
    "arrivée prévue",
    "compagnie",
    "vol",
    "numéro d'appareil",
    "piste",
    "départ",
  ],
};

export default function ArrivalsHeader({
  flightsNav,
  positioningNav,
  setFlightsNav,
  liveRefresh,
  positioningToggle,
  setLiveRefresh,
  retrievingFlights,
}) {
  const { filters, activeFilter, setFilterElements } = useContext(MddContext);

  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);

  return (
    <>
      <section className="topDashboardContainer">
        <MddBreadCrumb
          backPagesNames={[
            { name: "Home", url: "/" },
            { name: "Demos", url: "/dataviz" },
          ]}
          currentPage="Arrivées des vols"
        />
        <div>
          <div
            className={`exportNavBackground ${flightsNav}`}
            style={positioningNav()}
          />
          <MddButton
            buttonInnerText="arrivées estimées"
            buttonSize="long"
            buttonActionFunctionOne={setFlightsNav}
            buttonActionPropOne="estimations"
            iconColor={
              flightsNav === "estimations"
                ? "activeAltButton"
                : "altButtonColor"
            }
          />
          <MddButton
            buttonInnerText="previsions initiales"
            buttonSize="long"
            buttonActionFunctionOne={setFlightsNav}
            buttonActionPropOne="previsions"
            iconColor={
              flightsNav === "previsions" ? "activeAltButton" : "altButtonColor"
            }
          />
        </div>
        <article className="dataRefreshToggle">
          <div
            className={`exportNavBackground ${liveRefresh ? "live" : "off"}`}
            style={positioningToggle()}
          />
          <MddButton
            buttonInnerText="off"
            buttonActionFunctionOne={setLiveRefresh}
            buttonActionPropOne={false}
            buttonActionFunctionTwo={retrievingFlights}
            iconColor={!liveRefresh ? "activeAltButton" : "altButtonColor"}
          />
          <MddButton
            buttonInnerText="live"
            buttonActionFunctionOne={setLiveRefresh}
            buttonActionPropOne={true}
            iconColor={liveRefresh ? "activeAltButton" : "altButtonColor"}
          />
        </article>
      </section>
      <MddSearchBar
        filtersDatalist={flightsFilters}
        refreshCallback={retrievingFlights}
      />
    </>
  );
}
