import "../styles/sideBar.css";
import { useContext, useEffect, useState } from "react";
import { MddContext } from "../context/MddContext";

import MddButton from "./reusableElements/MddButton";

export default function SideBar({ children }) {
  // const [viewPortWidth, setViewPortWidth] = useState(null);
  const { fullSideBar, setFullSideBar, typeOfScreen, windowWidth } =
    useContext(MddContext);

  // const handlingResize = () => {
  //   console.log("handling resize : ", window.innerWidth, window.innerHeight);
  // };

  // useEffect(() => {
  //   setViewPortWidth(window.innerWidth);
  //   // window.onresize = handlingResize;
  // }, [window.innerWidth]);
  return (
    <>
      {fullSideBar ? (
        <div
          className={
            typeOfScreen === "logs"
              ? "fullSideBarContainer logsSideBarContainer"
              : "fullSideBarContainer"
          }
        >
          {children}
          <MddButton
            iconName={
              windowWidth < 761
                ? "bi bi-arrow-up-square"
                : "bi bi-arrow-left-square"
            }
            buttonSize="small"
            smallButtonDescription="Open sidebar"
            buttonActionFunctionOne={setFullSideBar}
            buttonActionPropOne={false}
            iconColor="altButtonColor"
          />
        </div>
      ) : (
        <div className="sideBarContainer">
          <MddButton
            iconName={
              windowWidth < 761
                ? "bi bi-arrow-down-square"
                : "bi bi-arrow-right-square"
            }
            buttonSize="small"
            smallButtonDescription="Open sidebar"
            buttonActionFunctionOne={setFullSideBar}
            buttonActionPropOne={true}
            iconColor="altButtonColor"
          />
        </div>
      )}
    </>
  );
}
