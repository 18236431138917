import "../../../styles/streamStore/dashboard/streamCard.css";
import { useHistory } from "react-router-dom";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function DashboardCard({ dashboard }) {
  let history = useHistory();

  const parsingTitle = () => {
    // let stockTitle = dashboard.title.toLowerCase();
    // stockTitle = stockTitle.replace(" ", "-");
    return "dashboard/" + dashboard.id;
  };

  const handlingNavigation = () => {
    history.push(
      `/dataviz/${dashboard.reference ? dashboard.reference : parsingTitle()}`
    );
  };

  return (
    <div
      className="card streamCardContainer demoCardContainer"
      onClick={() => {
        handlingNavigation();
      }}
    >
      <div className="topCardContainer">
        {dashboard.imageURL ? (
          <img
            src={`${REACT_APP_BASE_URL}/assets${dashboard.imageURL}`}
            alt="Illustration du stream"
          />
        ) : (
          <i className="bi bi-tv" />
        )}
      </div>
      <p className="card-title">{dashboard.title}</p>
      <div>
        <div className={`${dashboard.title}PlaceHolder`} />
        <div className={`${dashboard.title}PlaceHolder`} />
        <div className={`${dashboard.title}PlaceHolder`} />
      </div>
    </div>
  );
}
