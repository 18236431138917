import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function Unsubscribed() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    currentUser,
  } = useContext(MddContext);
  let history = useHistory();

  useEffect(() => {
    currentUser && history.push(`/`);
  }, [currentUser]);

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className={"subscribeGlobalContainer"}>
        <article>
          <h5>Votre compte est désormais fermé</h5>
          <p>
            Si vous souhaitez ouvrir un nouveau compte, inscrivez-vous ou
            demandez une invitation à l'administrateur.
          </p>
          <MddButton
            buttonInnerText="accueil"
            buttonActionFunctionOne={history.push}
            buttonActionPropOne={"/login"}
            iconColor="secondaryButton"
          />
        </article>
      </div>
    </Dashboard>
  );
}
