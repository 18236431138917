import { useContext, useEffect, useState, useRef } from "react";

export default function DatavizTextInput({
  label,
  displayValue,
  input,
  path,
  updater,
}) {
  const [innerdisplayValue, setInnerdisplayValue] = useState(
    input ? input : displayValue ? displayValue : ""
  );

  return (
    <div className="datavizInput">
      <label>{label}</label>
      <input
        type="text"
        title=""
        placeholder={label}
        value={innerdisplayValue}
        onChange={(e) => {
          setInnerdisplayValue(e.target.value);
        }}
        onBlur={() => {
          innerdisplayValue !== input && updater(path, innerdisplayValue);
        }}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            innerdisplayValue !== input &&
            updater(path, innerdisplayValue);
        }}
      />
    </div>
  );
}
