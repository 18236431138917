import returningRandomData from "./returningRandomData";
import returningRandomColors from "./returningRandomColors";

export const line = {
  settings: {
    datasets: [
      {
        label: "Suivi",
        type: "line",
        backgroundColor: returningRandomColors(1),
        borderColor: returningRandomColors(1),
        pointBackgroundColor: returningRandomColors(1),
        pointBorderColor: returningRandomColors(1),
        pointRadius: 0,
        data: returningRandomData(),
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        fill: {
          target: "false",
          above: "#ffffffa4",
          below: "#ffffffa4",
        },
        yAxisID: "1st-y-axis",
      },
    ],
  },
  config: {
    maintainAspectRatio: false,
    scales: {
      "1st-y-axis": {
        type: "linear",
        position: "left",
        title: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Suivi : ${context.formattedValue}`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  },
};
