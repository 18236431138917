import { useState, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { MddContext } from "../../../../context/MddContext";
import MddButton from "../../../reusableElements/MddButton";
import mddStreamStoreApi from "../../../../api/mddStreamStoreApi";
import revertingToken from "../../../../utils/auth/revertingToken";

/**
 * This editor allows selecting nodes and edit scopes
 * 1. displays all nodes
 * 2. displays (high light) selected nodes for the current endpoint
 * 3. displays (high light) selected scopes
 * 4. click on a node to toggle selection
 * 5. click on a scope to toggle selection
 * 6. click 'ajouter un scope' to create a new scope for selected nodes
 */
export default function NodesModalWithScopes({
  clause,
  nodesModal,
  setNodesModal,
  allNodes,
  currentPage,
  setClauseNodes,
}) {
 
  const {
    activeVersion,
    closingSession,
    userToken,
    feedBackBuilder,
    handling401,
  } = useContext(MddContext);

  let history = useHistory();
  const [page, setPage] = useState(currentPage);
  const [hoveredNode, setHoveredNode] = useState("");

  // draws a 5x10 grid
  // displaying ordered nodes vertically first
  /**
   * 
   * @param {*} nodes list of nodes
   * @param {*} page  current page number [1, n ]
   * @returns 
   */
  const returningNodesGrid = (nodes, page) => {
    let grid = [];
    let row = [];

    if (page <= 0)
      return grid;

    for (let r = 0; r < 10; r++) {
      row = [];
      for (let c = 0; c < 5; c++) {
        let index = (page-1) * 50 + c * 10 + r;
        //console.log(index);
        let node = nodes[index];
        
        if (node) {
          let used = clause.nodes.find(nodeName => nodeName === node.name)!=undefined;
          node.used = used;
          
          row.push(
            <div
              className="col"
              onMouseEnter={(e) => {
                setHoveredNode(node.host);
              }}
              onMouseLeave={(e) => {
                setHoveredNode("");
              }}
            >
              <MddButton
                buttonInnerText={node.name}
                buttonActionFunctionOne={toggleNodeSelection}
                buttonActionPropOne={node.name}
                iconColor={`button-${node.name} modalButton-${clause.uuid} ${
                  node.used===true && node.available===true ? "" :                    // node used (nominal)
                  node.used===true && node.available===false ? "alertButtonColor" :   // node used but available (alert)
                  node.used===false && node.available===true ? "altButtonColor" :     // node not used (nominal)
                  "offButtonColor"                                                    // node not used node and not available
                }`}
              />
            </div>
          );
        } else {
          row.push(<div className="col" />);
        }
      }
      grid.push(<div className="row">{row}</div>);
    }
    return grid;
  };

  const toggleNodeSelection = (nodeName) => {
    //console.log(clause.nodes);
    //console.log(`clicked ${nodeName}`);
    

    // toggle node from the clause's list
    let select = ! clause.nodes.includes(nodeName);
    if (select) {
      //console.log("not found");
      clause.nodes.push(nodeName);
    } else {
      //console.log("found");
      let index = clause.nodes.findIndex((node) => node ===nodeName);
      clause.nodes.splice(index, 1);
    }
      
    //console.log(clause.nodes);
    setClauseNodes([...clause.nodes]);
    postNodes({ clause: clause.uuid, nodes: clause.nodes });
    /*
    let button = document.querySelector( `.button-${nodeName}` );  
    if (select) {
      button.classList.remove('altButtonColor');
    } else {
      button.classList.add('altButtonColor');
    }
    */
  }

  const postNodes = (dataInput) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(`/version/resources/${activeVersion.id}?timeout=120000`, dataInput, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let updatedClause = response.data.data.clauses.find((c)=> c.uuid === clause.uuid);
          if (updatedClause)
            clause = updatedClause;

          feedBackBuilder(
            true,
            `Nodes mis à jour`,
            "Streams Store"
          );
        })
        .catch((err) => {
          if (! err || ! err.response || ! err.response.status)
            return;

          switch(err.response.status) {
            case 401:
              handling401(
                err,
                history,
                `Vous devez être connecté`
              );
              break;
            case 409:
              //console.log(JSON.stringify(err.response.data));
              if (err.response.data.error.code==="NODES QUOTA EXCEEDED") {
                feedBackBuilder(
                  false,
                  `Vous avez atteint le nombre maximal de nodes autorisés (nodes=${err.response.data.error.count}, quota=${err.response.data.error.limit})`,
                  "Quotas"
                );  
              }
              if (err.response.data.error.code==="ENDPOINTS QUOTA EXCEEDED") {
                feedBackBuilder(
                  false,
                  `Vous avez atteint le nombre maximal d'endpoints autorisés (endpoints=${err.response.data.error.count}, quota=${err.response.data.error.limit})`,
                  "Quotas"
                );  
              }
              
              break;              
          }         
        });
    }
  };


    
  return (
    <div
      className={`nodesModalBackground${
        nodesModal ? " openedEndpointsModal" : ""
      }`}
    >
      <article
        className={`nodesModal${nodesModal ? " openedEndpointsModal" : ""}`} id={`nodesModal - ${clause.uuid}`}
      >
        <div className="nodesModalHeader">
          <p className="nodesModalTitle">{`${clause.path}... ${clause.uri}`}</p>
          <p
            className={`nodesModalCount${
              clause.nodes.length === 0
                ? " statusAlert"
                : clause.nodes.length === 1
                ? " statusWarning"
                : ""
            }`}
          >{`${clause.nodes.length} nodes sélectionnés`}</p>
        </div>
        <div className="nodesModalBody container">{returningNodesGrid(allNodes, page)}</div>
        <div className="nodesModalFooter">
          <p>{hoveredNode}</p>
          <div className="nodesModalNav">
            { page < 1 ? (
              <div className="nodesModalPlaceholder" />
            ) : (
              <MddButton
                iconName={`bi bi-chevron-left modalButton-${clause.uuid}`}
                buttonSize="small"
                smallButtonDescription="Previous page"
                iconColor={`altButtonColor modalButton-${clause.uuid}`}
                buttonActionFunctionOne={setPage}
                buttonActionPropOne={page - 1}
              />
            )}
            <p>{`page ${page}`}</p>
            <MddButton
              iconName={`bi bi-chevron-right modalButton-${clause.uuid}`}
              buttonSize="small"
              smallButtonDescription="Next page"
              iconColor={`altButtonColor modalButton-${clause.uuid}`}
              buttonActionFunctionOne={setPage}
              buttonActionPropOne={page + 1}
            />
          </div>
          <div>
            <MddButton
              buttonInnerText="retour"
              iconColor="basicButtonColor"
              buttonActionFunctionOne={setNodesModal}
              buttonActionPropOne={false}
            />
          </div>
        </div>
      </article>
    </div>
  );
}
