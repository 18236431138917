import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../../../context/MddContext";

import MddBreadCrumb from "../../../reusableElements/MddBreadCrumb";
import MddButton from "../../../reusableElements/MddButton";

export default function EditHeader({
  streamName,
  streamVersion,
  // retrievingVersion,
  editNav,
  versionPublished,
  publishingVersionAPI,
  postingPackages,
  packagesUploadFiles,
  postingCode,
  postingDocs,
  editedCode,
  docsUploadFiles,
  editedVariable,
  versionPublishable,
}) {
  const { activeStream, currentTeam, activeVersion } = useContext(MddContext);
  let history = useHistory();

  const MddBreadCrumbNameBuilder = () => {
    let stockName = ``;
    if (activeStream) {
      stockName = streamName;
    }
    if (activeVersion) {
      stockName = `[${activeVersion.number}] ${stockName}`;
    }
    if (stockName) {
      return stockName;
    } else {
      return "Création d'un stream";
    }
  };

  const handlingEditNav = (navInput) => {
    history.push(
      `/streamstore/${
        activeVersion ? activeVersion.reference : activeStream.reference
      }#${navInput}`
    );
  };

  return (
    <div className="topEditcardContainer">
      <MddBreadCrumb
        backPagesNames={[
          { name: "Home", url: "/" },
          { name: "Streams store", url: "/streamstore" },
        ]}
        currentPage={MddBreadCrumbNameBuilder()}
      />
      {activeStream && (
        <div
          className={
            activeStream.provider === currentTeam || currentTeam === "all"
              ? ""
              : "userNavContainer"
          }
        >
          <MddButton
            buttonInnerText="général"
            smallButtonDescription="Manage stream details"
            buttonActionFunctionOne={handlingEditNav}
            buttonActionPropOne="general"
            // buttonActionFunctionTwo={retrievingVersion}
            iconColor={
              editNav === "general" ? "activeAltButton" : "altButtonColor"
            }
          />
          <MddButton
            buttonInnerText={
              activeStream.provider === currentTeam || currentTeam === "all"
                ? "packages"
                : "stream"
            }
            buttonSize={
              activeStream.provider === currentTeam || currentTeam === "all"
                ? "medium"
                : "long"
            }
            smallButtonDescription="Manage stream packages"
            buttonActionFunctionOne={handlingEditNav}
            buttonActionPropOne="packages"
            iconColor={
              editNav === "packages" ? "activeAltButton" : "altButtonColor"
            }
          />
          {((activeStream.editable &&
            (activeStream.provider === currentTeam || currentTeam === "all")) ||
            (activeVersion && activeVersion.shared)) && (
            <MddButton
              buttonInnerText="code"
              smallButtonDescription="Manage stream code"
              buttonActionFunctionOne={handlingEditNav}
              buttonActionPropOne="code"
              iconColor={
                editNav === "code" ? "activeAltButton" : "altButtonColor"
              }
            />
          )}
          {/*activeStream.editable &&
            (activeStream.provider === currentTeam ||
              currentTeam === "all") && (
              <MddButton
                buttonInnerText="env"
                smallButtonDescription="Manage stream variables"
                buttonActionFunctionOne={handlingEditNav}
                buttonActionPropOne="env"
                iconColor={
                  editNav === "env" ? "activeAltButton" : "altButtonColor"
                }
              />
            )*/}
          <MddButton
            buttonInnerText="documentation"
            buttonSize="long"
            smallButtonDescription="Manage stream documentation"
            buttonActionFunctionOne={handlingEditNav}
            buttonActionPropOne="documentation"
            iconColor={
              editNav === "documentation"
                ? "activeAltButton streamDocButton"
                : "altButtonColor streamDocButton"
            }
          />
          {activeStream.editable &&
            (activeStream.provider === currentTeam ||
              currentTeam === "all") && (
              <>
                {editNav === "general" &&
                  streamVersion !== "" &&
                  versionPublished && (
                    <MddButton
                      buttonInnerText="retirer"
                      buttonActionFunctionOne={publishingVersionAPI}
                      iconColor="basicButtonColor"
                    />
                  )}
                {editNav === "general" && !versionPublished && (
                  <MddButton
                    buttonInnerText="publier"
                    buttonActionFunctionOne={publishingVersionAPI}
                    inactive={!activeVersion || !versionPublishable}
                    inactiveTitle="Il est nécessaire de créer une version avec des packages fonctionnels pour la publier"
                  />
                )}
                {editNav === "packages" && (
                  <MddButton
                    buttonInnerText="charger"
                    buttonActionFunctionOne={postingPackages}
                    inactive={
                      versionPublished || packagesUploadFiles.length < 1
                    }
                    inactiveTitle={
                      versionPublished
                        ? "Les fichiers d'une version publiée ne peuvent pas être modifiés"
                        : "Choisissez un fichier pour pouvoir l'enregistrer"
                    }
                  />
                )}
                {editNav === "code" && (
                  <MddButton
                    buttonInnerText="enregistrer"
                    buttonActionFunctionOne={postingCode}
                    inactive={versionPublished || !editedCode}
                    inactiveTitle={
                      versionPublished
                        ? "Les fichiers d'une version publiée ne peuvent pas être modifiés"
                        : "Aucun changement à enregistrer"
                    }
                  />
                )}
                {editNav === "env" && (
                  <MddButton
                    buttonInnerText="enregistrer"
                    inactive={!editedVariable}
                    inactiveTitle="Modifiez au moins une variable pour pouvoir enregistrer"
                    iconColor="basicButtonColor"
                    // buttonActionFunctionOne={handleExit}
                  />
                )}
                {editNav === "documentation" && (
                  <MddButton
                    buttonInnerText="charger"
                    buttonActionFunctionOne={postingDocs}
                    buttonActionPropOne={docsUploadFiles}
                    inactive={docsUploadFiles.length < 1}
                    inactiveTitle="Choisissez un fichier pour pouvoir l'enregistrer"
                  />
                )}
              </>
            )}
        </div>
      )}
    </div>
  );
}
