import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";
import "../../styles/export/export.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import StreamStoreSideBar from "../../components/streamStore/sideBar/StreamStoreSideBar";
import DependenciesCard from "../../components/export/DependenciesCard";
import ArchiveCard from "../../components/export/ArchiveCard";
import SynchronizeExport from "../../components/export/SynchronizeExport";
import ExportConfirmation from "../../components/export/ExportConfirmation";
import MddLoader from "../../components/reusableElements/MddLoader";
import ExportHeader from "../../components/export/ExportHeader";

export default function Export() {
  const {
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    fullScreen,
    showSideBar,
    closingSession,
    checkingRole,
    filters,
    activeFilter,
    userToken,
    feedBackBuilder,
    handling401,
  } = useContext(MddContext);
  let history = useHistory();

  const [stockAPIVersions, setStockAPIVersions] = useState([]);
  const [displayedVersions, setDisplayedVersions] = useState([]);
  const [versionsAssigned, setVersionsAssigned] = useState(false);
  const [currentlyTyping, setCurrentlyTyping] = useState("");
  const [filterElements, setFilterElements] = useState([]);
  const [selectedStreams, setSelectedStreams] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [stockAPISubscriptions, setStockAPISubscriptions] = useState([]);
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  const [selectedDataviz, setSelectedDataviz] = useState([]);
  const [exportNav, setExportNav] = useState("streams");
  const [syncUrl, setSyncUrl] = useState("");
  const [syncToken, setSyncToken] = useState("");
  const [archives, setArchives] = useState([]);

  const retrievingVersions = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setVersionsAssigned(false);
      mddStreamStoreApi
        .get(`/version`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          setDisplayedVersions(informations);
          setStockAPIVersions(informations);
          setVersionsAssigned(true);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete API GET : ", err);
          handling401(err, history);
        });
    }
  };
  const retrievingSubscriptions = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/subscription`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          setStockAPISubscriptions(informations);
          setDisplayedSubscriptions(informations);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete API GET : ", err);
          handling401(err, history);
        });
    }
  };
  const listArchives = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/archives?timeout=60000`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const archives = response.data.data;
          setArchives(archives);
          feedBackBuilder(
            true,
            `${archives.length} archives disponibles`,
            `Streams Store [équipe ${response.data.team}]`
          )
        })
        .catch((err) => {
          if (err.response) {
            switch(err.response.status) {
              case 404: feedBackBuilder(
                          false,
                          "Impossible de lister les archives",
                          "Streams Store"
                        );
                break;
              case 401: handling401(err, history);
                break;
              case 403: feedBackBuilder(
                          false,
                          "Vous ne disposez pas des droits pour consulter les archives",
                          "Streams Store"
                        );
                break;
              default:
                console.log("erreur au niveau de la requete API GET : ", err);          
            }

          }          
        });
    }
  };
  /*
  * @param payload  { versions: [], subscriptions: []}
  */    
  const postExport = (payload) => {
      mddStreamStoreApi
        .post(`/archives?timeout=60000`,
          payload,
          {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
          }      
        )
        .then((response) => {
          // show done message
          setArchives(response.data.data);
        })
        .catch((err) => {
          if (err.response) {
            switch(err.response.status) {
              case 404: feedBackBuilder(
                          false,
                          "Impossible de créer l'archive",
                          "Streams Store"
                        );
                break;
              case 401: handling401(err, history);
                break;
              case 403: feedBackBuilder(
                          false,
                          "Vous ne disposez pas des droits pour créer des archives",
                          "Streams Store"
                        );
                break;
              default:
                console.log("erreur au niveau de la requete API GET : ", err);          
            }

          }
        });
  }  
  const synchronizeArchives = (payload) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      
      mddStreamStoreApi
        .post(`/archives/sync?timeout=60000`, 
          payload,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          feedBackBuilder(true, `Archive synchronisée`, "Streams Store");
          const archives = response.data.data;
          archives.forEach((archive) => archive.i++);
          setArchives(archives);
          return response;
        })
       // .then((response) => {
          //setTimeout(setArchives(response.data.data), 500);          
       // })
        .catch((err) => {
          if (err.response) {
            switch(err.response.status) {
              case 404: feedBackBuilder(
                          false,
                          "Impossible de lister les archives",
                          "Streams Store"
                        );
                break;
              case 401: handling401(err, history);
                break;
              case 403: feedBackBuilder(
                          false,
                          "Vous ne disposez pas des droits pour consulter les archives",
                          "Streams Store"
                        );
                break;
              default:
                console.log("erreur au niveau de la requete API GET : ", err);          
            }

          } 
        });
    }
  }; 
  /**
   * 
   * @param payload  ids of archives to remove { archives: ["id", ..]}
   */
  const deleteArchives = (id) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      
      mddStreamStoreApi
        .delete(`/archives/${id}?timeout=60000`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          feedBackBuilder(true, `Archive supprimée`, "Streams Store");
          return response;
        })
        .then((response) => {
          setTimeout(setArchives(response.data.data), 500);          
        })
        .catch((err) => {
          if (err.response) {
            switch(err.response.status) {
              case 404: feedBackBuilder(
                          false,
                          "Impossible de lister les archives",
                          "Streams Store"
                        );
                break;
              case 401: handling401(err, history);
                break;
              case 403: feedBackBuilder(
                          false,
                          "Vous ne disposez pas des droits pour consulter les archives",
                          "Streams Store"
                        );
                break;
              default:
                console.log("erreur au niveau de la requete API GET : ", err);          
            }

          } 
        });
    }
  }; 
  const autoSelectingSubscriptions = (inputVersion, selecting) => {
    let stockSubscriptions = [...selectedSubscriptions];
    let tempStock = [];
    if (selecting) {
      stockSubscriptions.push(
        ...stockAPISubscriptions.filter((sub) => {
          return sub.version === inputVersion.id;
        })
      );
      setSelectedSubscriptions(stockSubscriptions);
    } else {
      tempStock = stockSubscriptions.filter((sub) => {
        return sub.version !== inputVersion.id;
      });
      setSelectedSubscriptions(tempStock);
    }
  };
  const autoSelectingVersions = (inputSub) => {
    let stockVersions = [...selectedVersions];
    stockVersions.push(
      ...stockAPIVersions.filter((version) => {
        return version.id === inputSub.version;
      })
    );
    setSelectedVersions(stockVersions);
  };

  const retrievingDataviz = () => {
    console.log("retrieving dataviz");
  };

  const filteringVersions = (list) => {
    let stockElements = [];
    let currentFilter = filters.find((f) => f.key === activeFilter);
    for (let i = 0; i < currentFilter.elements.length; i++) {
      switch (currentFilter.elements[i].field) {
        case "tout":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
        case "nom":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.name
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
        case "provider":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.provider
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
        case "status":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.status
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
        case "description":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.description
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
        default:
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedVersions(stockElements);
          }
          break;
      }
    }
  };

  const returningStreamCards = () => {
    let stockCards = [];
    for (let i = 0; i < displayedVersions.length; i++) {
      stockCards.push(
        <DependenciesCard
          inputElement={displayedVersions[i]}
          key={`stream ${displayedVersions[i].id}`}
          dependencyType="streamDependency"
          selectingElement={selectingElement}
          selectedElements={selectedVersions}
        />
      );
    }
    return stockCards;
  };
  const returningSubsriptionCards = () => {
    let stockCards = [];
    for (let i = 0; i < displayedSubscriptions.length; i++) {
      stockCards.push(
        <DependenciesCard
          inputElement={displayedSubscriptions[i]}
          key={`subscription ${displayedSubscriptions[i].id}`}
          dependencyType="subscriptionDependency"
          selectingElement={selectingElement}
          selectedElements={selectedSubscriptions}
        />
      );
    }
    return stockCards;
  };
  const returningDatavizCards = () => {
    let stockCards = [];
    for (let i = 0; i < selectedDataviz.length; i++) {
      stockCards.push(
        <DependenciesCard
          inputElement={selectedDataviz[i]}
          key={`dataviz ${selectedDataviz[i].id}`}
          dependencyType="datavizDependency"
          selectingElement={selectingElement}
          selectedElements={[selectedDataviz]}
        />
      );
    }
    return stockCards;
  };

  /*
  A modifier pour afficher une carte par export disponible
  - avec : zipname, team, nb streams
  - affichage du détail (stream, subscriptions) quand on clique sur la ligne (style abo)
  - bouton 'supprimer' pour effacer un export
  - chiffrer le champ 'key' de l'abo
  */
  const returningArchiveCards = () => {
    let stockCards = [];
    for (let i = 0; i < archives.length; i++) {
      //console.log(archives[i]);
      archives[i].opened = false;
      stockCards.push(
        <ArchiveCard
          archive={archives[i]}
          key={`archive ${archives[i].id}`}
          onSynchronizeArchive={synchronizeArchives}
          onRemoveArchive={deleteArchives}          
        />
      );
      
    }
    return stockCards;
  };

  const addingFilterElement = () => {
    const options = document.getElementById("filterOptions").childNodes;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === currentlyTyping) {
        let stockFilter = filterElements;
        if (
          filterElements.length === 0 ||
          filterElements[filterElements.length - 1].criteria
        ) {
          stockFilter.push({ field: currentlyTyping });
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        } else if (
          filterElements[filterElements.length - 1].field &&
          !filterElements[filterElements.length - 1].operator
        ) {
          stockFilter[stockFilter.length - 1].operator =
            currentlyTyping === "contient" || currentlyTyping === "="
              ? "="
              : "!=";
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        }
      } else if (
        currentlyTyping.includes("> Chercher ") &&
        filterElements[filterElements.length - 1].operator &&
        !filterElements[filterElements.length - 1].criteria
      ) {
        let stockFilter = filterElements;
        stockFilter[stockFilter.length - 1].criteria = currentlyTyping
          .slice(11, currentlyTyping.length)
          .toLowerCase();
        setFilterElements(stockFilter);
        setCurrentlyTyping("");
        break;
      }
    }
  };

  const selectingElement = (elementInput) => {
    //console.log("selecting element : ", selectedVersions);
    if (elementInput.period) {
      let stockSelected = [...selectedVersions];
      if (
        stockSelected.some((element) => {
          return element.id === elementInput.id;
        })
      ) {
        stockSelected.splice(
          stockSelected.findIndex((element) => {
            return element.id === elementInput.id;
          }),
          1
        );
        autoSelectingSubscriptions(elementInput, false);
      } else {
        stockSelected.push(elementInput);
        autoSelectingSubscriptions(elementInput, true);
      }
      setSelectedVersions(stockSelected);
    } else if (elementInput.token) {
      let stockSelected = [...selectedSubscriptions];
      if (
        stockSelected.some((element) => {
          return element.id === elementInput.id;
        })
      ) {
        stockSelected.splice(
          stockSelected.findIndex((element) => {
            return element.id === elementInput.id;
          }),
          1
        );
      } else {
        stockSelected.push(elementInput);
        autoSelectingVersions(elementInput);
      }
      setSelectedSubscriptions(stockSelected);
    }
  };

  // const openingModal = (idInput) => {
  //   mddStreamStoreApi
  //     .get(`/version?stream=${idInput}`)
  //     .then((response) => {
  //       let stock = response.data.data;
  //       setActiveVersions(stock);
  //     })
  //     .then(() => {
  //       setOpenedModal(true);
  //     })
  //     .catch((err) => {
  //       console.log(
  //         "erreur au niveau de la requete API de récupération des versions : ",
  //         err
  //       );
  //       feedBackBuilder(false, `Erreur dans la récupération des données`);
  //     });
  // };

  const buildingButtonText = () => {
    let stockElements = [
      ...selectedVersions,
      ...selectedSubscriptions,
      ...selectedDataviz,
    ];
    if (exportNav==="archive")
      return `${stockElements.length} éléments exportés`;

    if (stockElements.length === 1) {
      return `Exporter 1 élément`;
    } else {
      return `Exporter ${stockElements.length} éléments`;
    }
  };

  const stockingStreams = () => {
    let stockStreams = [];
    for (let i = 0; i < selectedVersions.length; i++) {
      if (
        !stockStreams.some((element) => {
          return element === selectedVersions[i].stream;
        })
      ) {
        stockStreams.push(selectedVersions[i].stream);
      }
    }
    setSelectedStreams(stockStreams);
  };

  const handlingSync = () => {
   //console.log("synched !");
  };
  const positioningNav = () => {
    switch(exportNav) {
      case "streams":
        return { left: "0" };
      case "abonnements":
        return { left: "7rem" };
      case "dataviz":
        return { left: "14rem" };
      case "archive":
        return { left: "21rem" };
    }
    return { opacity: "0" };
/*
    if (exportNav === "abonnements") {
      return { left: "7rem" };
    } else if (exportNav === "dataviz") {
      return { left: "14rem" };
    } else if (exportNav === "streams") {
      return { left: "0" };
    } else {
      return { opacity: "0" };
    }
    */
  };

  const displayingSelectedElements = () => {
    let stockVersions = [...selectedVersions];
    let stockSubscriptions = [...selectedSubscriptions];
    if (exportNav !== "confirmation") {
      stockVersions = [...stockAPIVersions];
      stockSubscriptions = [...stockAPISubscriptions];
    }
    setDisplayedVersions(stockVersions);
    setDisplayedSubscriptions(stockSubscriptions);
    //console.log(stockVersions);  
  };
    
  const exportStreams = () => {
      let payload = {
        versions:[],
        subscriptions: [],
      };
      for (let i = 0; i < displayedVersions.length; i++) {
          payload.versions.push( displayedVersions[i].id );
      }
      
      for (let i = 0; i < displayedSubscriptions.length; i++) {
          payload.subscriptions.push( displayedSubscriptions[i].id );
      }
      postExport(payload);
  }

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    } else if (checkingRole(1)) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(true);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("mddTheme")) {
      if (activeFilter) {
        filteringVersions(stockAPIVersions);
      } else {
        retrievingVersions();
      }
      retrievingSubscriptions();
    }
  }, [filters, activeFilter]);
    
  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);
    
  useEffect(() => {
    document.getElementById("filterOptions") && addingFilterElement();
  }, [currentlyTyping]);
    
  useEffect(() => {
    if (selectedVersions.length > 0) {
      stockingStreams();
    }
  }, [selectedVersions]);
    
  useEffect(() => {
    displayingSelectedElements();
      
    if (exportNav=="export")
      exportStreams();

    if (exportNav=="archive") {
      listArchives();      
    }
    
  }, [exportNav]);

  useEffect(() => {
    
  }, [archives]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <StreamStoreSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer">
        <div className="exportGlobalContainer">
          {versionsAssigned ? (
            <>
              <ExportHeader
                exportNav={exportNav}
                positioningNav={positioningNav}
                setExportNav={setExportNav}
                selectedStreams={selectedStreams}
                buildingButtonText={buildingButtonText}
              />
              {exportNav === "confirmation" && (
                <ExportConfirmation
                  setExportNav={setExportNav}
                  selectedStreams={selectedStreams}
                  selectedSubscriptions={selectedSubscriptions}
                  selectedDataviz={selectedDataviz}
                  selectedVersions={selectedVersions}
                />
              )}
              {(exportNav === "confirmation" ||
                exportNav === "streams" ||
                exportNav === "abonnements" ||
                exportNav === "dataviz") && (
                <article className="listHeader">
                  <p>reference</p>
                  <p>fournisseur</p>
                  <p>description</p>
                  <p>sélection</p>
                </article>
              )}
              {(exportNav === "export") && (
                <article className="listHeader">
                  <p>archive</p>
                  <p></p>
                  <p></p>
                  <p>éléments</p>
                </article>
              )}
              {(exportNav === "streams" || exportNav === "confirmation") &&
                returningStreamCards()}
              {(exportNav === "abonnements" || exportNav === "confirmation") &&
                returningSubsriptionCards()}
              {(exportNav === "dataviz" || exportNav === "confirmation") &&
                returningDatavizCards()}
              {exportNav === "export" && 
                returningArchiveCards()}
              {exportNav === "archive" && 
                returningArchiveCards()} 
              {exportNav === "synchronize" && (
                <SynchronizeExport
                  handlingSync={handlingSync}
                  syncUrl={syncUrl}
                  syncToken={syncToken}
                  setSyncToken={setSyncToken}
                  setSyncUrl={setSyncUrl}
                  setExportNav={setExportNav}
                />
              )}
            </>
          ) : (
            <MddLoader />
          )}
        </div>
      </Dashboard>
    </>
  );
}
