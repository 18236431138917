import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import "../../styles/demo/hotelsManagement.css";

import Dashboard from "../../components/Dashboard";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import HotelCard from "../../components/demo/hotelsManagement/HotelCard";
import HotelDetails from "../../components/demo/hotelsManagement/HotelDetails";

const fakeHotels = [
  {
    name: "Hôtel Régent",
    city: "Périgueux",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "304", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Palace",
    city: "Bordeaux",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: false, temperature: 20 },
      { name: "109", type: "bedroom", error: true, temperature: 20 },
      { name: "201", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: true, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "L'Ensoleillé",
    city: "Agen",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "201", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Les Flots Bleus",
    city: "Marseille",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: true, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: true, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: true, temperature: 20 },
      { name: "109", type: "bedroom", error: false, temperature: 20 },
      { name: "201", type: "bedroom", error: true, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: true, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: true, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: true, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "304", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: true, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Hôtel Ténor",
    city: "Lyon",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: false, temperature: 20 },
      { name: "109", type: "bedroom", error: true, temperature: 20 },
      { name: "201", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "304", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Hôtel Océanique",
    city: "Biarritz",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: false, temperature: 20 },
      { name: "109", type: "bedroom", error: false, temperature: 20 },
      { name: "201", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Saratoga",
    city: "Toulouse",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: true, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "108", type: "bedroom", error: false, temperature: 20 },
      { name: "109", type: "bedroom", error: true, temperature: 20 },
      { name: "201", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: true, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "304", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: true, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Breizh Hôtel",
    city: "Nantes",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "107", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "301", type: "bedroom", error: false, temperature: 20 },
      { name: "302", type: "bedroom", error: false, temperature: 20 },
      { name: "303", type: "bedroom", error: false, temperature: 20 },
      { name: "304", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
  {
    name: "Hôtel Pyrénées",
    city: "Pau",
    temperature: 23,
    weather: 1,
    rooms: [
      { name: "101", type: "bedroom", error: false, temperature: 20 },
      { name: "102", type: "bedroom", error: false, temperature: 20 },
      { name: "103", type: "bedroom", error: false, temperature: 20 },
      { name: "104", type: "bedroom", error: false, temperature: 20 },
      { name: "105", type: "bedroom", error: false, temperature: 20 },
      { name: "106", type: "bedroom", error: false, temperature: 20 },
      { name: "202", type: "bedroom", error: false, temperature: 20 },
      { name: "203", type: "bedroom", error: false, temperature: 20 },
      { name: "204", type: "bedroom", error: false, temperature: 20 },
      { name: "205", type: "bedroom", error: false, temperature: 20 },
      { name: "206", type: "bedroom", error: false, temperature: 20 },
      { name: "207", type: "bedroom", error: false, temperature: 20 },
      { name: "208", type: "bedroom", error: false, temperature: 20 },
      { name: "209", type: "bedroom", error: false, temperature: 20 },
      { name: "001", type: "livingroom", error: false, temperature: 20 },
      { name: "002", type: "livingroom", error: false, temperature: 20 },
      { name: "003", type: "diningroom", error: false, temperature: 20 },
      { name: "004", type: "diningroom", error: false, temperature: 20 },
    ],
  },
];

export default function HotelsManagement() {
  const { setShowSideBar, setFullScreen, setFullSideBar, closingSession } =
    useContext(MddContext);
  let history = useHistory();

  const [hotelsDetails, setHotelsDetails] = useState(null);
  const [hotelsDisplayed, setHotelsDisplayed] = useState([...fakeHotels]);

  const returningHotels = () => {
    let stockHotels = [];
    for (let i = 0; i < hotelsDisplayed.length; i++) {
      stockHotels.push(
        <HotelCard
          hotel={hotelsDisplayed[i]}
          setHotelsDetails={setHotelsDetails}
        />
      );
    }
    return stockHotels;
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer hotelsDashboard">
      <MddBreadCrumb
        backPagesNames={[
          { name: "Home", url: "/" },
          { name: "Demos", url: "/dataviz" },
        ]}
        currentPage="Gestion d'hôtels"
      />
      {hotelsDetails ? (
        <HotelDetails
          hotel={hotelsDetails}
          setHotelsDetails={setHotelsDetails}
        />
      ) : (
        <main className="hotelsGlobalContainer">{returningHotels()}</main>
      )}
    </Dashboard>
  );
}
