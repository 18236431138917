import { useContext } from "react";
import { MddContext } from "../../context/MddContext";

import "../../styles/reusableElements/confirmModal.css";

import MddButton from "./MddButton";

export default function ConfirmModal({
  confirmTitle,
  confirmMessage,
  confirmInfo,
  handleModalYes,
  handleModalNo,
  yesProp,
  noProp,
  noText,
  yesText,
  noLength,
  yesLength,
  handleModalAlt,
  altProp,
  altText,
  altLength,
}) {
  const { setConfirmModal } = useContext(MddContext);

  const handleNo = () => {
    handleModalNo && noProp && handleModalNo(noProp);
    handleModalNo && !noProp && handleModalNo();
    setConfirmModal(false);
  };
  const handleYes = () => {
    yesProp ? handleModalYes(yesProp) : handleModalYes();
    setConfirmModal(false);
  };
  const handleAlt = () => {
    handleModalAlt && altProp && handleModalAlt(altProp);
    handleModalAlt && !altProp && handleModalAlt();
    setConfirmModal(false);
  };

  return (
    <section className="confirmModalContainer">
      <article>
        <p>{confirmTitle}</p>
        <p>{confirmMessage}</p>
        {confirmInfo && <p>{confirmInfo}</p>}
        <MddButton
          buttonInnerText={noText}
          buttonSize={noLength ? noLength : "medium"}
          iconColor="secondaryButton"
          buttonActionFunctionOne={handleNo}
        />
        <MddButton
          buttonInnerText={yesText}
          buttonSize={yesLength ? yesLength : "medium"}
          buttonActionFunctionOne={handleYes}
        />
        {altText && (
          <MddButton
            buttonInnerText={altText}
            buttonSize={altLength ? altLength : "medium"}
            buttonActionFunctionOne={handleAlt}
          />
        )}
      </article>
    </section>
  );
}
