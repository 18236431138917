import { createContext, useState } from "react";

export const MddContext = createContext({});

export const MddProvider = ({ children }) => {
  const [appRem, setAppRem] = useState(18);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [feedBackMessage, setFeedBackMessage] = useState(null);
  const [feedBackSection, setFeedBackSection] = useState(null);
  const [feedBackState, setFeedBackState] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);
  const [fullSideBar, setFullSideBar] = useState(false);
  // const [fullCard, setFullCard] = useState(false);
  const [activeStream, setActiveStream] = useState(null);
  const [activeVersion, setActiveVersion] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [typeOfScreen, setTypeOfScreen] = useState("");
  const [logsLevels, setLogsLevels] = useState([
    "TRACE",
    "DEBUG",
    "INFO",
    "WARN",
    "ERROR",
    "FATAL",
  ]);
  const [reverseLogs, setReverseLogs] = useState(false);
  const [mergeLogs, setMergeLogs] = useState(true);
  const [clauseLogs, setClauseLogs] = useState(true);
  const [activeFilter, setActiveFilter] = useState(null);
  const [filters, setFilters] = useState([]);
  const [userToken, setUserToken] = useState("");
  const [tempToken, setTempToken] = useState("");
  const [tabId, setTabId] = useState(null);
  const [tokenLimit, setTokenLimit] = useState(null);
  const [currentSolution, setCurrentSolution] = useState("Mdd Dynamics Link");
  const [logsModalTitle, setLogsModalTitle] = useState("");
  const [userTeams, setUserTeams] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [challengeModal, setChallengeModal] = useState(false);
  const [streamstoreRefresh, setStreamstoreRefresh] = useState(30000);
  const [streamDetailsRefresh, setStreamDetailsRefresh] = useState(5000);
  const [streamPackagesRefresh, setStreamPackagesRefresh] = useState(5000);
  const [subscriptionsRefresh, setSubscriptionsRefresh] = useState(5000);
  const [okLevel, setOkLevel] = useState(95);
  const [warningLevel, setWarningLevel] = useState(75);
  const [activeClause, setActiveClause] = useState(null);
  const [challengedCall, setChallengedCall] = useState(null);
  const [filterElements, setFilterElements] = useState([]);
  const [succesfullChallenge, setSuccessfullChallenge] = useState(false);
  const [bedRoomsTemperature, setBedRoomsTemperature] = useState(22);
  const [diningRoomsTemperature, setDiningRoomsTemperature] = useState(20);
  const [livingRoomsTemperature, setLivingRoomsTemperature] = useState(20);
  const [activeLinked, setActiveLinked] = useState([]);
  const [reverseActive, setReverseActive] = useState(null);
  const [demoToken, setDemoToken] = useState(null);
  const [lastVersions, setLastVersions] = useState({});

  const feedBackBuilder = (success, message, section, loading) => {
    setFeedBackMessage(message);
    setFeedBackSection(section);
    success ? setFeedBackState("success") : setFeedBackState("error");
    loading && setFeedBackState("loading");
  };

  const cleaningContext = () => {
    localStorage.removeItem("mddTheme");
    // setFullCard(false);
    setActiveStream(null);
    setCurrentUser(null);
    setConfirmModal(false);
    setTypeOfScreen("");
    setLogsLevels([]);
    setActiveFilter(null);
    setFilters([]);
    setUserToken("");
    setTempToken("");
    setTokenLimit(null);
    setFullScreen(null);
    setFullSideBar(false);
    setLogsModalTitle("");
    setUserTeams([]);
    setUserRole("");
    setCurrentTeam("");
    setChallengeModal(false);
    setStreamstoreRefresh(30000);
    setStreamDetailsRefresh(5000);
    setStreamPackagesRefresh(5000);
    setSubscriptionsRefresh(5000);
    setOkLevel(95);
    setWarningLevel(75);
    setActiveClause(null);
    setReverseLogs(false);
    setMergeLogs(true);
    setClauseLogs(true);
    setActiveVersion(null);
    setChallengedCall(null);
    setFilterElements([]);
    setSuccessfullChallenge(false);
    setBedRoomsTemperature(22);
    setDiningRoomsTemperature(20);
    setLivingRoomsTemperature(20);
    setActiveLinked([]);
    setReverseActive(null);
    setDemoToken(null);
    setLastVersions({});
  };

  const closingSession = () => {
    const tokenCheckFeedBackBuilder = () => {
      let stockMessage = "";
      if (tokenLimit && stockDate.getTime() > tokenLimit) {
        stockMessage = stockMessage + "Votre session a expiré.";
      }
      if (!currentUser || !userToken || userToken.length) {
        stockMessage = stockMessage + "Vous n'êtes pas connecté.";
      }
      feedBackBuilder(false, stockMessage, "Authentification");
    };
    let stockDate = new Date();
    if (
      stockDate.getTime() > tokenLimit ||
      !currentUser ||
      !userToken ||
      userToken.length === 0
    ) {
      cleaningContext();
      tokenCheckFeedBackBuilder();
      return true;
    } else {
      return false;
    }
  };

  const checkingRole = (roleInput) => {
    if (userRole === "") {
      feedBackBuilder(false, "Accès non autorisé", "Authentification");
      return true;
    } else if (roleInput === 2) {
      return false;
    } else if (roleInput === 1) {
      if (userRole === "admin" || userRole === "provider") {
        return false;
      } else {
        feedBackBuilder(false, "Accès non autorisé", "Authentification");
        return true;
      }
    } else if (roleInput === 0) {
      if (userRole === "admin") {
        return false;
      } else {
        feedBackBuilder(false, "Accès non autorisé", "Authentification");
        return true;
      }
    } else {
      return false;
    }
  };

  const handling401 = (err, history, text) => {
    if (
      err &&
      err.response &&
      err.response.status === 401 &&
      err.response.headers &&
      err.response.headers["www-authenticate"] &&
      err.response.headers["www-authenticate"] === "Basic realm=confirm"
    ) {
      setChallengeModal(true);
    } else if (err && err.response && err.response.status === 401) {
      feedBackBuilder(false, `Vous devez être connecté`, "Authentification");
      history.push(`/login`);
      setTokenLimit(null);
      setCurrentUser(null);
      setUserToken("");
    } else {
      feedBackBuilder(
        false,
        text ? text : `Echec dans la récupération des données`,
        "Authentification"
      );
    }
  };

  return (
    <MddContext.Provider
      value={{
        activeStream,
        setActiveStream,
        // fullCard,
        // setFullCard,
        fullSideBar,
        setFullSideBar,
        showSideBar,
        setShowSideBar,
        fullScreen,
        setFullScreen,
        feedBackMessage,
        setFeedBackMessage,
        feedBackSection,
        setFeedBackSection,
        feedBackState,
        setFeedBackState,
        currentUser,
        setCurrentUser,
        confirmModal,
        setConfirmModal,
        typeOfScreen,
        setTypeOfScreen,
        logsLevels,
        setLogsLevels,
        activeFilter,
        setActiveFilter,
        filters,
        setFilters,
        userToken,
        setUserToken,
        tempToken,
        setTempToken,
        tabId,
        setTabId,
        tokenLimit,
        setTokenLimit,
        cleaningContext,
        closingSession,
        handling401,
        currentSolution,
        setCurrentSolution,
        logsModalTitle,
        setLogsModalTitle,
        userTeams,
        setUserTeams,
        userRole,
        setUserRole,
        currentTeam,
        setCurrentTeam,
        checkingRole,
        challengeModal,
        setChallengeModal,
        feedBackBuilder,
        streamstoreRefresh,
        setStreamstoreRefresh,
        streamDetailsRefresh,
        setStreamDetailsRefresh,
        streamPackagesRefresh,
        setStreamPackagesRefresh,
        subscriptionsRefresh,
        setSubscriptionsRefresh,
        okLevel,
        setOkLevel,
        warningLevel,
        setWarningLevel,
        activeClause,
        setActiveClause,
        reverseLogs,
        setReverseLogs,
        mergeLogs,
        setMergeLogs,
        clauseLogs,
        setClauseLogs,
        activeVersion,
        setActiveVersion,
        challengedCall,
        setChallengedCall,
        filterElements,
        setFilterElements,
        succesfullChallenge,
        setSuccessfullChallenge,
        bedRoomsTemperature,
        setBedRoomsTemperature,
        livingRoomsTemperature,
        setLivingRoomsTemperature,
        diningRoomsTemperature,
        setDiningRoomsTemperature,
        activeLinked,
        setActiveLinked,
        reverseActive,
        setReverseActive,
        demoToken,
        setDemoToken,
        lastVersions,
        setLastVersions,
        appRem,
        setAppRem,
        windowWidth,
        setWindowWidth,
      }}
    >
      {children}
    </MddContext.Provider>
  );
};
