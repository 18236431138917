import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import Dashboard from "../../components/Dashboard";


import "../../styles/home/dashboard.css";
import "../../styles/account/manager.css";

export default function Home() {
  const {
    setShowSideBar,
    setFullSideBar,
    setFullScreen,
    tokenLimit,
    currentUser,
    userToken,
    cleaningContext,
  } = useContext(MddContext);
  let history = useHistory();

  useEffect(() => {
    let stockDate = new Date();
    if (
      stockDate.getTime() > tokenLimit ||
      !currentUser ||
      !userToken ||
      userToken.length === 0
    ) {
      cleaningContext();
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);
  return (
    <>
      <Dashboard containerClass="dashboardContainer">
        <div className="managerCardContainer">
          <div className="topDashboardContainer">
            <MddBreadCrumb
              backPagesNames={[{ name: "Home", url: "/" }]}
              currentPage="Espace client"
            />            
          </div>
          
          <form>
            <div class="form-group row">
              <label for="firstname" class="col col-form-label">Prénom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control-plaintext" id="firstname" value=""/>
              </div>
            </div>
            <div class="form-group row">
              <label for="lastname" class="col col-form-label">Nom</label>
              <div class="col-sm-10">
                <input type="text" class="form-control-plaintext" id="lastname" value=""/>
              </div>
            </div>
            <div class="form-group row">
              <label for="email" class="col col-form-label">Email</label>
              <div class="col-sm-10">
                <input type="text" class="form-control" id="email" placeholder="prenom.nom@email.com"/>
              </div>
            </div>
            <div class="form-group row">
              <label for="organization" class="col col-form-label">Société</label>
              <div class="col-sm-10">
                <input type="text" class="form-control-plaintext" id="organization" value=""/>
              </div>
            </div>
          </form>
        </div>
      </Dashboard>
    </>
  );
}
