import { useState, useContext, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";

import "../../styles/profileMenu.css";
import { MddContext } from "../../context/MddContext";
import mddUsers from "../../api/mddUsers";
import mddAuth from "../../api/mddAuth";
import { profileRegex } from "../../utils/profile/profileRegex";
import classingIncorrectInput from "../../utils/classingIncorrectInput";
import revertingToken from "../../utils/auth/revertingToken";

import MddButton from "../reusableElements/MddButton";
import ConfirmModal from "../reusableElements/ConfirmModal";

export default function ProfileMenu() {
  const {
    currentUser,
    setCurrentUser,
    userToken,
    setUserToken,
    setTempToken,
    tokenLimit,
    setTokenLimit,
    cleaningContext,
    closingSession,
    handling401,
    userTeams,
    setUserTeams,
    currentTeam,
    setCurrentTeam,
    userRole,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();
  let { pathname } = useLocation();

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [openedWidget, setOpenedWidget] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userEmail, setUserEmail] = useState(currentUser || "");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userTitle, setUserTitle] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [userTeam, setUserTeam] = useState(currentTeam || "");
  const [profileValidInputs, setProfileValidInputs] = useState([
    "email",
    "firstname",
    "lastname",
    "title",
    "organization",
  ]);
  const [profileModal, setProfileModal] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);
  const [version, setVersion] = useState("");

  const retrievingUser = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .get(`/user`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          setUserEmail(currentUser);
          const informations = response.data.data;
          setUserProfile(informations);
          setUserFirstName(informations.firstname);
          setUserLastName(informations.lastname);
          setUserTitle(informations.title);
          setUserOrganization(informations.organization);
          setCurrentTeam(informations.team);
          setVersion(informations.version);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const retrievingScopes = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddAuth
        .get(
          userRole === "admin" ? `/scopes?email=${currentUser}` : `/scopes`,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          const informations = response.data.data;
          let stockScopes = informations.scopes.split(",");
          let stockTeams = [];
          for (let i = 0; i < stockScopes.length; i++) {
            stockTeams.push(stockScopes[i].trim());
          }
          setUserTeams([...stockTeams]);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const buildingUser = () => {
    let stockUser = {};
    if (
      userFirstName &&
      (!userProfile || userProfile.firstname !== userFirstName)
    ) {
      stockUser.firstname = userFirstName;
    }
    if (
      userLastName &&
      (!userProfile || userProfile.lastname !== userLastName)
    ) {
      stockUser.lastname = userLastName;
    }
    if (userTitle && (!userProfile || userProfile.title !== userTitle)) {
      stockUser.title = userTitle;
    }
    if (
      userOrganization &&
      (!userProfile || userProfile.organization !== userOrganization)
    ) {
      stockUser.organization = userOrganization;
    }
    if (userTeam && (!userProfile || userProfile.team !== userTeam)) {
      stockUser.team = userTeam;
    }
    return stockUser;
  };

  const modifyingUser = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .post(`/user`, buildingUser(), {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          setUserProfile(informations);
          setUserFirstName(informations.firstname);
          setUserLastName(informations.lastname);
          setUserTitle(informations.title);
          setUserOrganization(informations.organization);
          setUserEmail(currentUser);
          setCurrentTeam(informations.team);
          feedBackBuilder(true, `modification enregistrée`, "Profil");
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const checkingInputs = () => {
    let stockInputs = profileValidInputs;
    const addValidInput = (inputName) => {
      if (
        !stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs.push(inputName);
        setProfileValidInputs(stockInputs);
      }
    };
    const removeInvalidInput = (inputName) => {
      if (
        stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs = stockInputs.filter((element) => {
          return element !== inputName;
        });
        setProfileValidInputs(stockInputs);
      }
    };
    if (profileRegex.email.test(userEmail)) {
      addValidInput("email");
    } else {
      removeInvalidInput("email");
    }
    if (
      profileRegex.firstname.test(userFirstName) ||
      userFirstName.length === 0
    ) {
      addValidInput("firstname");
    } else {
      removeInvalidInput("firstname");
    }
    if (profileRegex.lastname.test(userLastName) || userLastName.length === 0) {
      addValidInput("lastname");
    } else {
      removeInvalidInput("lastname");
    }
    if (profileRegex.title.test(userTitle) || userTitle.length === 0) {
      addValidInput("title");
    } else {
      removeInvalidInput("title");
    }
    if (
      profileRegex.organization.test(userOrganization) ||
      userOrganization.length === 0
    ) {
      addValidInput("organization");
    } else {
      removeInvalidInput("organization");
    }
    forceUpdate();
  };

  const handlingModify = () => {
    if (profileValidInputs.length === 5) {
      const stockCheck =
        !userProfile ||
        userFirstName !== userProfile.firstname ||
        userLastName !== userProfile.lastname ||
        userTitle !== userProfile.title ||
        userOrganization !== userProfile.organization;
      if (userEmail !== currentUser) {
        modifyingLogin();
      }
      if (stockCheck) {
        modifyingUser();
      }
    } else {
      let stockMessage = "";
      if (
        !profileValidInputs.some((element) => {
          return element === "email";
        })
      ) {
        stockMessage = stockMessage + " L'adresse email n'est pas valide.";
      }
      if (
        !profileValidInputs.some((element) => {
          return element === "firstname";
        })
      ) {
        stockMessage = stockMessage + " Le prénom n'est pas valide.";
      }
      if (
        !profileValidInputs.some((element) => {
          return element === "lastname";
        })
      ) {
        stockMessage = stockMessage + " Le nom n'est pas valide.";
      }
      if (
        !profileValidInputs.some((element) => {
          return element === "title";
        })
      ) {
        stockMessage = stockMessage + " Le titre n'est pas valide.";
      }
      if (
        !profileValidInputs.some((element) => {
          return element === "team";
        })
      ) {
        stockMessage = stockMessage + " Le nom d'équipe n'est pas valide.";
      }
      if (
        !profileValidInputs.some((element) => {
          return element === "organization";
        })
      ) {
        stockMessage =
          stockMessage + " Le nom de l'organisation n'est pas valide.";
      }
      feedBackBuilder(false, stockMessage, "Profil");
    }
  };

  const cleaningStates = () => {
    cleaningContext();
    setUserProfile(null);
    setOpenedWidget(false);
    setOpenedMenu(false);
    setUserEmail("");
    setUserFirstName("");
    setUserLastName("");
    setUserTitle("");
    setUserOrganization("");
    setUserTeam("");
    setProfileValidInputs([
      "email",
      "firstname",
      "lastname",
      "title",
      "organization",
    ]);
    setProfileModal(false);
    setIncorrectLogin(false);
  };

  const loggingOut = () => {
    mddAuth
      .post(
        `/oauth2/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        }
      )
      .then(() => {
        history.push(`/login`);
        feedBackBuilder(true, "Vous êtes déconnecté", "Profil");
        cleaningStates();
        history.push(`/login`);
      })
      .catch((err) => {
        console.log("erreur au niveau de la requête : ", err);
        history.push(`/login`);
        feedBackBuilder(true, "Vous êtes déconnecté", "Profil");
        cleaningStates();
        history.push(`/login`);
      });
  };

  const handlingRedirect = () => {
    history.push(`/account/change-password`);
    setOpenedMenu(false);
  };

  const openingModal = () => {
    setProfileModal(true);
    setOpenedMenu(false);
  };

  const handleModalYes = () => {
    let stockDate = new Date();
    if (
      stockDate.getTime() > tokenLimit ||
      !currentUser ||
      !userToken ||
      userToken.length === 0
    ) {
      history.push(`/login`);
      setTokenLimit(null);
      setCurrentUser(null);
      setUserToken("");
    } else {
      mddAuth
        .post(
          `/unsubscribe`,
          {},
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then(() => {
          feedBackBuilder(true, "Votre compte est supprimé", "Profil");
          cleaningStates();
          // setCurrentUser(null);
          setProfileModal(false);
          history.push(`/account/unsubscribed`);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requête : ", err);
          feedBackBuilder(false, `La suppression a echoué`, "Profil");
        });
    }
  };

  const handleModalNo = () => {
    setProfileModal(false);
    setOpenedMenu(true);
  };

  const modifyingLogin = () => {
    let stockDate = new Date();
    if (
      stockDate.getTime() > tokenLimit ||
      !currentUser ||
      !userToken ||
      userToken.length === 0
    ) {
      history.push(`/login`);
      setTokenLimit(null);
      setCurrentUser(null);
      setUserToken("");
    } else {
      setIncorrectLogin(true);
      mddAuth
        .post(
          `/change-login`,
          { email: userEmail },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          feedBackBuilder(
            true,
            "Un email de confirmation vous a été envoyé. Veuillez cliquer sur le lien pour activer votre nouveau login.",
            "Profil"
          );
          setTempToken(response.data.data.temp);
          // history.push(`/account/change-login`);
          // setTokenLimit(null);
          // setCurrentUser(null);
          // setUserToken("");
          // setOpenedMenu(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIncorrectLogin(true);
            feedBackBuilder(
              false,
              `Votre login doit être un email valide`,
              "Profil"
            );
          } else if (err.response.status === 401) {
            history.push(`/login`);
            setTokenLimit(null);
            setCurrentUser(null);
            setUserToken("");
          } else {
            feedBackBuilder(
              false,
              `Le login n'a pas pu être modifié`,
              "Profil"
            );
          }
          console.log("erreur au niveau de la requête : ", err);
        });
    }
  };

  useEffect(() => {
    currentUser &&
      pathname &&
      pathname !== "/login" &&
      !userProfile &&
      retrievingUser();
    currentUser &&
      pathname &&
      pathname !== "/login" &&
      userTeams.length === 0 &&
      retrievingScopes();
    !currentUser &&
      pathname &&
      pathname !== "/login" &&
      userProfile &&
      cleaningStates();
  }, [currentUser, pathname]);

  useEffect(() => {
    openedMenu && setOpenedMenu(false);
  }, [pathname]);

  useEffect(() => {
    userEmail && checkingInputs();
  }, [
    userEmail,
    userFirstName,
    userLastName,
    userTitle,
    userTeams,
    userOrganization,
  ]);

  useEffect(() => {
    currentTeam !== userTeam && setUserTeam(currentTeam);
  }, [currentTeam]);

  useEffect(() => {
    currentTeam !== userTeam && modifyingUser();
  }, [userTeam]);

  return (
    <>
      {currentUser && (
        <article
          className="profileWidget"
          onMouseEnter={() => {
            setOpenedWidget(true);
          }}
          onMouseLeave={() => {
            setOpenedWidget(false);
          }}
        >
          <div>
            <MddButton
              buttonInnerText={
                userFirstName && userLastName
                  ? userFirstName.slice(0, 1) + userLastName.slice(0, 1)
                  : currentUser.slice(0, 2).toUpperCase()
              }
              buttonSize="small"
              smallButtonDescription="Open user menu"
              iconColor="basicButtonColor"
              buttonActionFunctionOne={setOpenedMenu}
              buttonActionPropOne={true}
            />
            <MddButton
              buttonInnerText="déconnexion"
              iconColor="basicButtonColor"
              buttonActionFunctionOne={loggingOut}
            />
          </div>
          {openedWidget && (
            <div>
              {userTeams.length > 0 ? (
                userTeams.map((team) => {
                  return (
                    <MddButton
                      buttonInnerText={
                        team === "all"
                          ? "admin"
                          : team.length > 10
                          ? `${team.slice(0, 9)}...`
                          : team.slice(0, 10)
                      }
                      iconColor={
                        currentTeam === team
                          ? "altButtonColor activeTeamButton"
                          : "altButtonColor"
                      }
                      smallIcon
                      iconName="bi bi-circle-fill"
                      buttonActionFunctionOne={setUserTeam}
                      buttonActionPropOne={team}
                    />
                  );
                })
              ) : (
                <p>pas d'équipe</p>
              )}
            </div>
          )}
        </article>
      )}
      {openedMenu && (
        <div className="profileMenuContainer">
          <section>          
            <article className="profileLabels">
              <div class="version">Version { version }</div>
              <label htmlFor="emailForm">* email</label>
              <label htmlFor="firstNameForm">prénom</label>
              <label htmlFor="lastNameForm">nom</label>
              <label htmlFor="roleForm">rôle</label>
              <label htmlFor="titleForm">titre</label>
              <label htmlFor="organizationForm">entité</label>
              <i className="bi bi-person-circle" />
              <div>
                <MddButton
                  buttonInnerText="modifier le mot de passe"
                  buttonSize="long"
                  buttonActionFunctionOne={handlingRedirect}
                  iconColor="secondaryButton"
                />
                <MddButton
                  buttonInnerText="Fermer mon compte"
                  buttonSize="long"
                  buttonActionFunctionOne={openingModal}
                  iconColor="altButtonColor"
                />
              </div>
            </article>
            <article className="profileInputs">
              <h5>Mes informations</h5>
              <input
                type="email"
                id="emailForm"
                className={classingIncorrectInput("email", profileValidInputs)}
                placeholder="un email est obligatoire"
                required
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <input
                type="text"
                id="firstNameForm"
                className={
                  userFirstName.length > 0
                    ? classingIncorrectInput("firstname", profileValidInputs)
                    : ""
                }
                placeholder="prénom"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
              />
              <input
                type="text"
                id="lastNameForm"
                className={
                  userLastName.length > 0
                    ? classingIncorrectInput("lastname", profileValidInputs)
                    : ""
                }
                placeholder="nom"
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
              />
              <input
                type="text"
                id="roleForm"
                className="disabledInput"
                placeholder="role"
                value={userRole}
                disabled
              />
              <input
                type="text"
                id="titleForm"
                className={
                  userTitle.length > 0
                    ? classingIncorrectInput("title", profileValidInputs)
                    : ""
                }
                placeholder="titre"
                value={userTitle}
                onChange={(e) => setUserTitle(e.target.value)}
              />
              <input
                type="text"
                id="organizationForm"
                className={
                  userOrganization.length > 0
                    ? classingIncorrectInput("organization", profileValidInputs)
                    : ""
                }
                placeholder="organisation"
                value={userOrganization}
                onChange={(e) => setUserOrganization(e.target.value)}
              />
              <div>
                <MddButton
                  buttonInnerText="retour"
                  buttonActionFunctionOne={setOpenedMenu}
                  buttonActionPropOne={false}
                  iconColor="secondaryButton"
                />
                <MddButton
                  buttonInnerText="enregistrer"
                  buttonActionFunctionOne={handlingModify}
                />
              </div>
            </article>
          </section>
        </div>
      )}
      {profileModal && (
        <ConfirmModal
          noText="annuler"
          yesText="fermer mon compte"
          handleModalYes={handleModalYes}
          handleModalNo={handleModalNo}
          yesLength="long"
          confirmTitle="confirmation"
          confirmMessage="Confirmez-vous la fermeture de votre compte ?"
          confirmInfo="Attention : une fois votre compte fermé, vous ne pourrez plus accéder à l'application."
        />
      )}
    </>
  );
}
