import { useState } from "react";

import MddButton from "../reusableElements/MddButton";

export default function SingleVariable({
  variable,
  editedVariable,
  setEditedVariable,
}) {
  const [variableName, setVariableName] = useState(
    variable ? variable.name : ""
  );
  const [variableValue, setVariableValue] = useState(
    variable ? variable.value : ""
  );
  const [variableFormat, setVariableFormat] = useState(
    variable ? variable.format : ""
  );
  const [variableScope, setVariableScope] = useState(
    variable ? variable.scope : ""
  );
  const [variableDescription, setVariableDescription] = useState(
    variable ? variable.description : ""
  );

  const checkingEditedStatus = (edited, base) => {
    if (
      (variableName !== variable.name ||
        variableValue !== variable.value ||
        variableFormat !== variable.format ||
        variableScope !== variable.scope ||
        variableDescription !== variable.description ||
        edited !== base) &&
      !editedVariable
    ) {
      setEditedVariable(true);
    }
  };

  return (
    <article
      className={`variableContainer row${
        !variable.used ? " inactiveVariable" : ""
      }`}
    >
      <input
        className="col"
        list="scopeOptions"
        value={variableScope}
        onChange={(e) => {
          setVariableScope(e.target.value);
          checkingEditedStatus(e.target.value, variableScope);
        }}
        disabled={!variable.used}
      />
      <datalist id="scopeOptions">
        <option value="app" />
        <option value="team" />
        <option value="business" />
      </datalist>
      <input
        className="col"
        value={variableName}
        onChange={(e) => {
          setVariableName(e.target.value);
          checkingEditedStatus(e.target.value, variableName);
        }}
        disabled={!variable.used}
      />
      <input
        className="col"
        value={variableValue}
        onChange={(e) => {
          setVariableValue(e.target.value);
          checkingEditedStatus(e.target.value, variableValue);
        }}
        disabled={!variable.used}
      />
      <input
        className="col"
        value={variableFormat}
        onChange={(e) => {
          setVariableFormat(e.target.value);
          checkingEditedStatus(e.target.value, variableFormat);
        }}
        disabled={!variable.used}
      />
      <input
        className="col"
        value={variableDescription}
        onChange={(e) => {
          setVariableDescription(e.target.value);
          checkingEditedStatus(e.target.value, variableDescription);
        }}
        disabled={!variable.used}
        title={variableDescription}
      />
      {variable.used ? (
        <div className="variablePlaceholder" />
      ) : (
        <MddButton
          iconName="bi bi-x"
          buttonSize="small"
          smallButtonDescription="Delete variable"
          //   buttonActionFunctionOne={deletingVersion}
          //   buttonActionPropOne={allVersions[i].id}
          iconColor="altButtonColor"
        />
      )}
    </article>
  );
}
