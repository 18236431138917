import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import revertingToken from "../../utils/auth/revertingToken";

export default function TokenHandler() {
  const {
    setCurrentUser,
    userToken,
    setUserToken,
    setTokenLimit,
    setUserRole,
    setActiveFilter,
    activeFilter,
    setWindowWidth,
    setAppRem,
  } = useContext(MddContext);
  let { pathname } = useLocation();

  const decodingToken = (tokenInput) => {
    let base64Url = tokenInput.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const retrievingToken = () => {
    let stockDate = new Date();
    if (localStorage.getItem("mddTheme") && !userToken) {
      const stockToken = decodingToken(
        revertingToken(localStorage.getItem("mddTheme"))
      );
      if (stockDate.getTime() < stockToken.exp) {
        setUserToken(localStorage.getItem("mddTheme"));
        setCurrentUser(stockToken.sub);
        setTokenLimit(stockToken.exp);
        setUserRole(stockToken.aud);
      } else {
        localStorage.removeItem("mddTheme");
      }
    }
    // console.log(
    //   "retrieving token : ",
    //   userToken,
    //   localStorage.getItem("mddTheme")
    // );
  };

  const stockingToken = () => {
    if (
      userToken &&
      (!localStorage.getItem("mddTheme") ||
        localStorage.getItem("mddTheme") !== userToken)
    ) {
      localStorage.setItem("mddTheme", userToken);
    }
    // console.log(
    //   "stocking token : ",
    //   userToken,
    //   localStorage.getItem("mddTheme")
    // );
  };

  const handlingWindowResize = () => {
    setWindowWidth(window.innerWidth);
    setAppRem(window.innerWidth > 800 ? 18 : 16);
  };

  useEffect(() => {
    // console.log(
    //   "token handler : ",
    //   userToken,
    //   localStorage.getItem("mddTheme")
    // );
    // if (userToken !== "") {
    stockingToken();
    // } else {
    retrievingToken();
    // }
  }, [userToken]);

  useEffect(() => {
    window.onresize = handlingWindowResize;
  }, []);

  useEffect(() => {
    if (
      pathname !== "/streamstore" &&
      pathname !== "/subscriptions" &&
      pathname !== "export" &&
      activeFilter
    ) {
      setActiveFilter(null);
    }
  }, [pathname]);

  return <></>;
}
