import { useContext, useEffect, useState, useRef } from "react";

import OptionsCategory from "../OptionsCategory";
import DatavizColorPicker from "../inputs/DatavizColorPicker";
import DatavizList from "../inputs/DatavizList";
import DatavizNumberInput from "../inputs/DatavizNumberInput";
import DatavizSwitch from "../inputs/DatavizSwitch";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizIconsSwitch from "../inputs/DatavizIconsSwitch";

export default function RadarChart({
  datavizSidebar,
  updatingWidget,
  set,
  index,
}) {
  return (
    <OptionsCategory name={`Dataset : ${set.label}`}>
      <p>Ligne</p>
      <DatavizColorPicker
        label="couleur"
        input={set.borderColor}
        path={["component", "settings", "datasets", index, "borderColor"]}
        updater={updatingWidget}
      />
      <p>Points</p>
      <DatavizColorPicker
        label="couleur"
        input={set.pointBackgroundColor}
        path={[
          "component",
          "settings",
          "datasets",
          index,
          "pointBackgroundColor",
        ]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label="taille"
        displayValue={5}
        outputValue={["multiply", 1]}
        input={set.pointRadius}
        path={["component", "settings", "datasets", index, "pointRadius"]}
        updater={updatingWidget}
      />
    </OptionsCategory>
  );
}
