export default function returningRandomColors(numberInput) {
  const returningDigit = () => {
    let stockDigit = Math.floor(Math.random() * (16 - 0)) + 0;
    return stockDigit.toString(16);
  };
  if (numberInput > 1) {
    let stockColors = [];
    for (let i = 0; i < numberInput; i++) {
      stockColors.push(
        `#${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}`
      );
    }
    return stockColors;
  } else {
    return `#${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}${returningDigit()}`;
  }
}
