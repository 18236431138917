export default function statusLogoPicker(value, subscription) {
  if (subscription && subscription.state && subscription.state === "PAUSED") {
    return <i className="bi bi-pause-circle-fill statusOff" />;
  }
  if (subscription && subscription.published === false) {
    return <i className="bi bi-code-slash statusOff" />;
  }
  switch (value) {
    case "OFF":
      return <i className="bi bi-power statusOff" />;
    case "OK":
      return <i className="bi bi-check-circle-fill statusOk" />;
    case "WARNING":
      return <i className="bi bi-exclamation-circle-fill statusWarning" />;
    case "DEGRADED":
      return <i className="bi bi-exclamation-circle-fill statusAlert" />;
    case "FATAL":
      return <i className="bi bi-x-circle-fill statusFatal" />;
    case "UNKNOWN":
      return <i className="bi bi-question-circle-fill statusWarning" />;

    default:
      return <i className="bi bi-question-circle-fill statusWarning" />;
  }
}
