import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddAuth from "../../api/mddAuth";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function Subscribe() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    setTempToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [userEmail, setUserEmail] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [incorrectInputs, setIncorrectInputs] = useState(false);

  const handlingSubmit = () => {
    setIncorrectInputs(false);
    if (userEmail.length > 3) {
      mddAuth
        .post(`/subscribe`, { email: userEmail })
        .then((response) => {
          const informations = response.data.data;
          console.log("handling subcribe : ", informations.temp);
          setTempToken(informations.temp);
          feedBackBuilder(
            true,
            "Un email de confirmation vous a été envoyé",
            "Authentification"
          );
          setSentEmail(true);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requête : ", err);
          if (
            err &&
            err.response &&
            err.response.status === 409 &&
            err.code &&
            err.code === "LOGIN ALREADY EXISTS"
          ) {
            setIncorrectInputs(true);
            feedBackBuilder(false, `Ce login existe déjà`, "Profil");
          } else {
            feedBackBuilder(
              false,
              `Echec dans la requête, merci de réessayer`,
              "Authentification"
            );
          }
        });
    } else {
      setIncorrectInputs(true);
      feedBackBuilder(
        false,
        `L'adresse email n'est pas au bon format`,
        "Authentification"
      );
    }
  };

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className={"subscribeGlobalContainer"}>
        {sentEmail ? (
          <article>
            <h5>Un email vous a été envoyé</h5>
            <p>Veuillez cliquer sur le lien pour créer votre mot de passe.</p>
            <MddButton
              buttonInnerText="retour"
              buttonActionFunctionOne={history.push}
              buttonActionPropOne={"/login"}
              iconColor="secondaryButton"
            />
          </article>
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <h5>Entrez votre email</h5>
            <input
              type="email"
              id="emailForm"
              className={incorrectInputs ? "incorrectInput" : ""}
              title={incorrectInputs ? "L'adresse email est incorrecte." : ""}
              placeholder="email"
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <MddButton
              buttonInnerText="valider"
              buttonActionFunctionOne={handlingSubmit}
            />
          </form>
        )}
      </div>
    </Dashboard>
  );
}
