import { useContext, useEffect, useState, useRef } from "react";

import OptionsCategory from "../OptionsCategory";
import DatavizColorPicker from "../inputs/DatavizColorPicker";
import DatavizList from "../inputs/DatavizList";
import DatavizNumberInput from "../inputs/DatavizNumberInput";
import DatavizSwitch from "../inputs/DatavizSwitch";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizIconsSwitch from "../inputs/DatavizIconsSwitch";

export default function BubbleChart({
  datavizSidebar,
  updatingWidget,
  set,
  index,
}) {
  const returningColorInputs = () => {
    let stockInputs = [];
    for (let i = 0; i < set.data.length; i++) {
      stockInputs.push(
        <DatavizColorPicker
          label={`couleur ${i + 1}`}
          input={set.pointBackgroundColor[i]}
          path={[
            "component",
            "settings",
            "datasets",
            index,
            "pointBackgroundColor",
            `${i}`,
          ]}
          updater={updatingWidget}
        />
      );
    }
    return stockInputs;
  };
  return (
    <OptionsCategory name={`Dataset : ${set.label}`}>
      <p>Points</p>
      {returningColorInputs()}
    </OptionsCategory>
  );
}
