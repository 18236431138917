import { Link } from "react-router-dom";
import { useContext } from "react";
import { MddContext } from "../../context/MddContext";

export default function MddBreadCrumb({ backPagesNames, currentPage }) {
  const { setActiveStream } = useContext(MddContext);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {backPagesNames.map((p) => (
          <li className="breadcrumb-item" key={p.name}>
            <Link
              to={p.url}
              onClick={() => {
                setActiveStream(null);
              }}
            >
              {p.name}
            </Link>
          </li>
        ))}
        <li className="breadcrumb-item active" aria-current="page">
          {currentPage}
        </li>
      </ol>
    </nav>
  );
}
