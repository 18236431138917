import { useContext } from "react";

import { MddContext } from "../../../../context/MddContext";
import dateParser from "../../../../utils/dateParser";
import statusLogoPicker from "../../../../utils/statusLogoPicker";

import MddButton from "../../../reusableElements/MddButton";

export default function SingleVersion({
  version,
  changingActiveVersion,
  setModifiedVersion,
  deletingVersion,
  streamValidInputs,
  versionDescription,
  setVersionDescription,
  checkingEditedStatus,
}) {
  const { activeStream, currentTeam, feedBackBuilder, activeVersion } =
    useContext(MddContext);

  const findingPeriod = (inputVersion) => {
    switch (inputVersion.period) {
      case "development":
        if (inputVersion.preopsAt) {
          return inputVersion.preopsAt;
        } else if (inputVersion.releasedAt) {
          return inputVersion.releasedAt;
        } else if (inputVersion.endOfSupportAt) {
          return inputVersion.endOfSupportAt;
        } else if (inputVersion.endOfLifeAt) {
          return inputVersion.endOfLifeAt;
        } else {
          return null;
        }
      case "pre-production":
        return inputVersion.preopsAt;
      case "production":
        return inputVersion.releasedAt;
      case "end-of-support":
        return inputVersion.endOfSupportAt;
      case "end-of-life":
        return inputVersion.endOfLifeAt;
      default:
        return null;
    }
  };

  const translatingPeriod = (inputVersion) => {
    switch (inputVersion.period) {
      case "development":
        if (inputVersion.preopsAt) {
          return "pré-production";
        } else if (inputVersion.releasedAt) {
          return "production";
        } else if (inputVersion.endOfSupportAt) {
          return "end-of-support";
        } else if (inputVersion.endOfLifeAt) {
          return "fin de vie";
        } else {
          return "développement";
        }
      case "pre-production":
        return "pré-production";
      case "production":
        return "production";
      case "end-of-support":
        return "fin de support";
      case "end-of-life":
        return "fin de vie";
      default:
        return "développement";
    }
  };

  return (
    <div
      // key={version.number}
      className={
        activeVersion && activeVersion.number === version.number
          ? "activeVersion"
          : ""
      }
      onClick={() => {
        if (!activeVersion || version.id !== activeVersion.id) {
          changingActiveVersion(version);
          setModifiedVersion(true);
          feedBackBuilder(
            true,
            `Version ${version.number} sélectionnée`,
            "Streamstore"
          );
        }
      }}
    >
      {activeStream.provider === currentTeam || currentTeam === "all" ? (
        <MddButton
          iconName="bi-x-lg"
          buttonSize="small"
          smallButtonDescription="Delete version"
          buttonActionFunctionOne={deletingVersion}
          buttonActionPropOne={version.id}
          iconColor="altButtonColor"
          noPropagation
          inactive={version.subscribed || version.published}
          inactiveTitle="Il est nécessaire de supprimer les abonnements avant de supprimer une version"
        />
      ) : (
        <div className="versionPlaceHolder" />
      )}
      {version.published ? (
        <i className="bi bi-calendar-check" />
      ) : (
        <div className="versionPlaceHolder" />
      )}
      {version.subscribed ? (
        <div>
          <i className="bi bi-heart" style={{ fontSize: "0.9rem" }} />
          <p>{version.subscriptions > 999 ? "999+" : version.subscriptions}</p>
        </div>
      ) : (
        <div className="versionPlaceHolder" style={{ width: "4.5rem" }} />
      )}
      {statusLogoPicker(version.status)}
      <p className="versionTimeDetails">
        {findingPeriod(version)
          ? `${translatingPeriod(version)} à partir du ${dateParser(
              findingPeriod(version)
            )}`
          : "Aucune date n'est rentrée pour l'instant"}
      </p>
      {activeVersion && activeVersion.number === version.number ? (
        <p className="versionPickerItem">{version.number}</p>
      ) : (
        <button
          type="button"
          className="versionPickerItem"
          onClick={() => {
            changingActiveVersion(version);
            setModifiedVersion(true);
          }}
        >
          {version.number}
        </button>
      )}
      {activeStream.provider === currentTeam || currentTeam === "all" ? (
        <textarea
          type="text"
          className={`versionDescriptionForm${
            !streamValidInputs.some((element) => {
              return element === "versionDescription";
            }) &&
            activeVersion &&
            activeVersion.number === version.number
              ? " incorrectInput"
              : ""
          }`}
          required
          placeholder="description"
          disabled={activeStream.provider !== currentTeam}
          value={
            activeVersion && activeVersion.number === version.number
              ? versionDescription
              : version.description
          }
          onChange={(e) => setVersionDescription(e.target.value)}
          onBlur={() => {
            checkingEditedStatus &&
              checkingEditedStatus(
                activeVersion.description,
                versionDescription,
                "version"
              );
            setModifiedVersion(true);
          }}
        />
      ) : (
        <p className="versionDescriptionForm">
          {activeVersion && activeVersion.number === version.number
            ? versionDescription
            : version.description}
        </p>
      )}
    </div>
  );
}
