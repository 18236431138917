import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddAuth from "../../api/mddAuth";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function ForgottenPassword() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    tempToken,
    setTempToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();
  let location = useLocation();

  const [userEmail, setUserEmail] = useState("");
  const [userValue, setUserValue] = useState("");
  const [secondUserValue, setSecondUserValue] = useState("");
  const [sentEmail, setSentEmail] = useState(false);

  const askingNewPassword = () => {
    mddAuth
      .post(`/forgot-password`, { email: userEmail })
      .then((response) => {
        console.log("asking password : ", response);
        if (response.data.data) {
          feedBackBuilder(
            true,
            "Un email vous a été envoyé",
            "Authentification"
          );
          setTempToken(response.data.data.temp);
        }
      })
      .catch((err) => {
        console.log("erreur au niveau de la requête : ", err);
        feedBackBuilder(
          false,
          `L'email n'a pas pu être envoyé`,
          "Authentification"
        );
      });
  };

  const parsingToken = () => {
    if (location.search) {
      return location.search.slice(7);
    }
  };

  const handlingSettingPassword = () => {
    if (userValue === secondUserValue) {
      mddAuth
        .post(
          `/change-password`,
          { password: userValue },
          {
            headers: {
              Authorization: `Bearer ${parsingToken(tempToken)}`,
            },
          }
        )
        .then(() => {
          feedBackBuilder(true, "Mot de passe modifié", "Authentification");
          history.push(`/login`);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requête : ", err);
          feedBackBuilder(
            false,
            `Le mot de passe n'a pas pu être modifié`,
            "Authentification"
          );
        });
    } else {
      feedBackBuilder(
        false,
        `Les deux mots de passe doivent être identiques`,
        "Authentification"
      );
    }
  };

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  useEffect(() => {
    tempToken && history.push(`/account/forgotten-password?token=${tempToken}`);
  }, [tempToken]);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className="subscribeGlobalContainer">
        {sentEmail ? (
          <article>
            <h5>Un email vous a été envoyé</h5>
            <p>
              Veuillez cliquer sur le lien pour modifier votre mot de passe.
            </p>
            <MddButton
              buttonInnerText="retour"
              buttonActionFunctionOne={history.push}
              buttonActionPropOne={"/login"}
              iconColor="secondaryButton"
            />
          </article>
        ) : location && location.search.length > 0 ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <h5>Choisissez votre nouveau mot de passe</h5>
            <input style={{ opacity: 0, position: "absolute" }} />
            <input
              type="password"
              style={{ opacity: 0, position: "absolute" }}
            />

            <input
              type="password"
              id="valueForm"
              placeholder="mot de passe"
              required
              value={userValue}
              onChange={(e) => setUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) &&
                  handlingSettingPassword();
              }}
            />
            <input
              type="password"
              id="value2Form"
              placeholder="confirmation du mot de passe"
              required
              value={secondUserValue}
              onChange={(e) => setSecondUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) &&
                  handlingSettingPassword();
              }}
            />
            <MddButton
              buttonInnerText="valider"
              buttonActionFunctionOne={handlingSettingPassword}
            />
          </form>
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <h5>Restaurez votre mot de passe</h5>
            <input
              type="email"
              id="emailForm"
              required
              placeholder="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <MddButton
              buttonInnerText="valider"
              buttonActionFunctionOne={askingNewPassword}
            />
          </form>
        )}
      </div>
    </Dashboard>
  );
}
