import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import solarPanels from "../../api/demo/solarPanels.js";
import "../../styles/demo/solarPanels.css";

import Dashboard from "../../components/Dashboard";
import SolarHeader from "../../components/demo/solarPanels/SolarHeader";
import SolarIndicators from "../../components/demo/solarPanels/SolarIndicators";
import SolarProduction from "../../components/demo/solarPanels/SolarProduction";
import SolarBatteries from "../../components/demo/solarPanels/SolarBatteries";
import TokenForm from "../../components/reusableElements/TokenForm";
import MddButton from "../../components/reusableElements/MddButton";

export default function SolarPanels() {
  const {
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    closingSession,
    setDemoToken,
    demoToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [solarNav, setSolarNav] = useState("indicators");
  const [panelsMaxProduction, setPanelsMaxProduction] = useState(null);
  const [panelsCurrentProduction, setPanelsCurrentProduction] = useState([
    // { x: "10h32 00'", y: 810 },
    // { x: "10h32 01'", y: 820 },
    // { x: "10h32 02'", y: 890 },
    // { x: "10h32 03'", y: 800 },
    // { x: "10h32 04'", y: 800 },
    // { x: "10h32 05'", y: 860 },
    // { x: "10h32 06'", y: 720 },
    // { x: "10h32 07'", y: 800 },
    // { x: "10h32 08'", y: 880 },
    // { x: "10h32 09'", y: 800 },
    // { x: "10h32 10'", y: 900 },
    // { x: "10h32 11'", y: 730 },
    // { x: "10h32 12'", y: 800 },
    // { x: "10h32 13'", y: 870 },
    // { x: "10h32 14'", y: 930 },
    // { x: "10h32 15'", y: 800 },
    // { x: "10h32 16'", y: 800 },
    // { x: "10h32 17'", y: 760 },
    // { x: "10h32 18'", y: 800 },
    // { x: "10h32 19'", y: 840 },
    // { x: "10h32 20'", y: 800 },
    // { x: "10h32 21'", y: 910 },
    // { x: "10h32 22'", y: 870 },
    // { x: "10h32 23'", y: 800 },
    // { x: "10h32 24'", y: 800 },
    // { x: "10h32 25'", y: 960 },
    // { x: "10h32 26'", y: 800 },
    // { x: "10h32 27'", y: 800 },
    // { x: "10h32 28'", y: 750 },
    // { x: "10h32 29'", y: 840 },
    // { x: "10h32 30'", y: 800 },
    // { x: "10h32 31'", y: 800 },
    // { x: "10h32 32'", y: 890 },
    // { x: "10h32 33'", y: 820 },
    // { x: "10h32 34'", y: 800 },
    // { x: "10h32 35'", y: 960 },
    // { x: "10h32 36'", y: 800 },
    // { x: "10h32 37'", y: 920 },
    // { x: "10h32 38'", y: 730 },
    // { x: "10h32 39'", y: 800 },
    // { x: "10h32 40'", y: 800 },
    // { x: "10h32 41'", y: 620 },
    // { x: "10h32 42'", y: 800 },
    // { x: "10h32 43'", y: 800 },
    // { x: "10h32 44'", y: 880 },
    // { x: "10h32 45'", y: 730 },
    // { x: "10h32 46'", y: 810 },
    // { x: "10h32 47'", y: 800 },
    // { x: "10h32 48'", y: 800 },
    // { x: "10h32 49'", y: 970 },
    // { x: "10h32 50'", y: 800 },
    // { x: "10h32 51'", y: 800 },
    // { x: "10h32 52'", y: 890 },
    // { x: "10h32 53'", y: 870 },
    // { x: "10h32 54'", y: 800 },
    // { x: "10h32 55'", y: 900 },
    // { x: "10h32 56'", y: 800 },
    // { x: "10h32 57'", y: 830 },
    // { x: "10h32 58'", y: 830 },
    // { x: "10h32 59'", y: 800 },
  ]);
  const [panelsLongProduction, setPanelsLongProduction] = useState([
    // { x: "01/05", y: 81 },
    // { x: "02/05", y: 82 },
    // { x: "03/05", y: 89 },
    // { x: "04/05", y: 80 },
    // { x: "05/05", y: 80 },
    // { x: "06/05", y: 86 },
    // { x: "07/05", y: 72 },
    // { x: "08/05", y: 80 },
    // { x: "09/05", y: 88 },
    // { x: "10/05", y: 80 },
    // { x: "11/05", y: 73 },
    // { x: "12/05", y: 80 },
    // { x: "13/05", y: 87 },
    // { x: "14/05", y: 93 },
    // { x: "15/05", y: 80 },
    // { x: "16/05", y: 80 },
    // { x: "17/05", y: 76 },
    // { x: "18/05", y: 80 },
    // { x: "19/05", y: 84 },
    // { x: "20/05", y: 80 },
    // { x: "21/05", y: 91 },
    // { x: "22/05", y: 87 },
    // { x: "23/05", y: 80 },
    // { x: "24/05", y: 80 },
    // { x: "25/05", y: 96 },
    // { x: "26/05", y: 80 },
    // { x: "27/05", y: 80 },
    // { x: "28/05", y: 75 },
    // { x: "29/05", y: 84 },
    // { x: "30/05", y: 90 },
  ]);
  const [panelsMaxPower, setPanelsMaxPower] = useState(null);
  const [panelsCurrentPower, setPanelsCurrentPower] = useState([
    // { x: "10h32 00'", y: 80 },
    // { x: "10h32 01'", y: 82 },
    // { x: "10h32 02'", y: 80 },
    // { x: "10h32 03'", y: 80 },
    // { x: "10h32 04'", y: 80 },
    // { x: "10h32 05'", y: 86 },
    // { x: "10h32 06'", y: 80 },
    // { x: "10h32 07'", y: 80 },
    // { x: "10h32 08'", y: 88 },
    // { x: "10h32 09'", y: 80 },
    // { x: "10h32 10'", y: 80 },
    // { x: "10h32 11'", y: 73 },
    // { x: "10h32 12'", y: 80 },
    // { x: "10h32 13'", y: 80 },
    // { x: "10h32 14'", y: 93 },
    // { x: "10h32 15'", y: 80 },
    // { x: "10h32 16'", y: 80 },
    // { x: "10h32 17'", y: 78 },
    // { x: "10h32 18'", y: 80 },
    // { x: "10h32 19'", y: 84 },
    // { x: "10h32 20'", y: 80 },
    // { x: "10h32 21'", y: 80 },
    // { x: "10h32 22'", y: 87 },
    // { x: "10h32 23'", y: 80 },
    // { x: "10h32 24'", y: 80 },
    // { x: "10h32 25'", y: 97 },
    // { x: "10h32 26'", y: 80 },
    // { x: "10h32 27'", y: 80 },
    // { x: "10h32 28'", y: 75 },
    // { x: "10h32 29'", y: 80 },
    // { x: "10h32 30'", y: 80 },
    // { x: "10h32 31'", y: 80 },
    // { x: "10h32 32'", y: 89 },
    // { x: "10h32 33'", y: 80 },
    // { x: "10h32 34'", y: 80 },
    // { x: "10h32 35'", y: 96 },
    // { x: "10h32 36'", y: 80 },
    // { x: "10h32 37'", y: 80 },
    // { x: "10h32 38'", y: 73 },
    // { x: "10h32 39'", y: 80 },
    // { x: "10h32 40'", y: 80 },
    // { x: "10h32 41'", y: 74 },
    // { x: "10h32 42'", y: 80 },
    // { x: "10h32 43'", y: 80 },
    // { x: "10h32 44'", y: 88 },
    // { x: "10h32 45'", y: 80 },
    // { x: "10h32 46'", y: 81 },
    // { x: "10h32 47'", y: 80 },
    // { x: "10h32 48'", y: 80 },
    // { x: "10h32 49'", y: 86 },
    // { x: "10h32 50'", y: 80 },
    // { x: "10h32 51'", y: 80 },
    // { x: "10h32 52'", y: 89 },
    // { x: "10h32 53'", y: 80 },
    // { x: "10h32 54'", y: 80 },
    // { x: "10h32 55'", y: 90 },
    // { x: "10h32 56'", y: 80 },
    // { x: "10h32 57'", y: 80 },
    // { x: "10h32 58'", y: 83 },
    // { x: "10h32 59'", y: 80 },
  ]);
  const [panelsLongPower, setPanelsLongPower] = useState([
    // { x: "01/05", y: 81 },
    // { x: "02/05", y: 82 },
    // { x: "03/05", y: 89 },
    // { x: "04/05", y: 80 },
    // { x: "05/05", y: 93 },
    // { x: "06/05", y: 86 },
    // { x: "07/05", y: 72 },
    // { x: "08/05", y: 80 },
    // { x: "09/05", y: 75 },
    // { x: "10/05", y: 80 },
    // { x: "11/05", y: 93 },
    // { x: "12/05", y: 80 },
    // { x: "13/05", y: 87 },
    // { x: "14/05", y: 93 },
    // { x: "15/05", y: 82 },
    // { x: "16/05", y: 80 },
    // { x: "17/05", y: 76 },
    // { x: "18/05", y: 80 },
    // { x: "19/05", y: 88 },
    // { x: "20/05", y: 80 },
    // { x: "21/05", y: 91 },
    // { x: "22/05", y: 87 },
    // { x: "23/05", y: 65 },
    // { x: "24/05", y: 80 },
    // { x: "25/05", y: 96 },
    // { x: "26/05", y: 80 },
    // { x: "27/05", y: 72 },
    // { x: "28/05", y: 75 },
    // { x: "29/05", y: 84 },
    // { x: "30/05", y: 90 },
  ]);
  const [panelsCurrentTension, setPanelsCurrentTension] = useState([
    // { x: "10h32 00'", y: 86 },
    // { x: "10h32 01'", y: 82 },
    // { x: "10h32 02'", y: 80 },
    // { x: "10h32 03'", y: 80 },
    // { x: "10h32 04'", y: 89 },
    // { x: "10h32 05'", y: 86 },
    // { x: "10h32 06'", y: 80 },
    // { x: "10h32 07'", y: 72 },
    // { x: "10h32 08'", y: 88 },
    // { x: "10h32 09'", y: 80 },
    // { x: "10h32 10'", y: 68 },
    // { x: "10h32 11'", y: 73 },
    // { x: "10h32 12'", y: 80 },
    // { x: "10h32 13'", y: 92 },
    // { x: "10h32 14'", y: 93 },
    // { x: "10h32 15'", y: 80 },
    // { x: "10h32 16'", y: 91 },
    // { x: "10h32 17'", y: 78 },
    // { x: "10h32 18'", y: 80 },
    // { x: "10h32 19'", y: 73 },
    // { x: "10h32 20'", y: 80 },
    // { x: "10h32 21'", y: 80 },
    // { x: "10h32 22'", y: 87 },
    // { x: "10h32 23'", y: 88 },
    // { x: "10h32 24'", y: 80 },
    // { x: "10h32 25'", y: 97 },
    // { x: "10h32 26'", y: 85 },
    // { x: "10h32 27'", y: 80 },
    // { x: "10h32 28'", y: 75 },
    // { x: "10h32 29'", y: 82 },
    // { x: "10h32 30'", y: 80 },
    // { x: "10h32 31'", y: 80 },
    // { x: "10h32 32'", y: 89 },
    // { x: "10h32 33'", y: 80 },
    // { x: "10h32 34'", y: 80 },
    // { x: "10h32 35'", y: 96 },
    // { x: "10h32 36'", y: 80 },
    // { x: "10h32 37'", y: 80 },
    // { x: "10h32 38'", y: 73 },
    // { x: "10h32 39'", y: 80 },
    // { x: "10h32 40'", y: 80 },
    // { x: "10h32 41'", y: 74 },
    // { x: "10h32 42'", y: 84 },
    // { x: "10h32 43'", y: 80 },
    // { x: "10h32 44'", y: 88 },
    // { x: "10h32 45'", y: 80 },
    // { x: "10h32 46'", y: 81 },
    // { x: "10h32 47'", y: 80 },
    // { x: "10h32 48'", y: 80 },
    // { x: "10h32 49'", y: 86 },
    // { x: "10h32 50'", y: 80 },
    // { x: "10h32 51'", y: 80 },
    // { x: "10h32 52'", y: 89 },
    // { x: "10h32 53'", y: 80 },
    // { x: "10h32 54'", y: 80 },
    // { x: "10h32 55'", y: 90 },
    // { x: "10h32 56'", y: 80 },
    // { x: "10h32 57'", y: 80 },
    // { x: "10h32 58'", y: 83 },
    // { x: "10h32 59'", y: 80 },
  ]);
  const [panelsLongTension, setPanelsLongTension] = useState([
    // { x: "01/05", y: 81 },
    // { x: "02/05", y: 82 },
    // { x: "03/05", y: 89 },
    // { x: "04/05", y: 80 },
    // { x: "05/05", y: 80 },
    // { x: "06/05", y: 86 },
    // { x: "07/05", y: 78 },
    // { x: "08/05", y: 80 },
    // { x: "09/05", y: 88 },
    // { x: "10/05", y: 81 },
    // { x: "11/05", y: 73 },
    // { x: "12/05", y: 80 },
    // { x: "13/05", y: 62 },
    // { x: "14/05", y: 93 },
    // { x: "15/05", y: 80 },
    // { x: "16/05", y: 97 },
    // { x: "17/05", y: 76 },
    // { x: "18/05", y: 80 },
    // { x: "19/05", y: 92 },
    // { x: "20/05", y: 80 },
    // { x: "21/05", y: 91 },
    // { x: "22/05", y: 71 },
    // { x: "23/05", y: 80 },
    // { x: "24/05", y: 80 },
    // { x: "25/05", y: 97 },
    // { x: "26/05", y: 80 },
    // { x: "27/05", y: 80 },
    // { x: "28/05", y: 72 },
    // { x: "29/05", y: 84 },
    // { x: "30/05", y: 90 },
  ]);
  const [batteriesAutonomy, setBatteriesAutonomy] = useState(null);
  const [batteriesCurrentCharge, setbatteriesCurrentCharge] = useState([
    // { x: "10h32 00'", y: 81 },
    // { x: "10h32 01'", y: 82 },
    // { x: "10h32 02'", y: 89 },
    // { x: "10h32 03'", y: 80 },
    // { x: "10h32 04'", y: 80 },
    // { x: "10h32 05'", y: 86 },
    // { x: "10h32 06'", y: 72 },
    // { x: "10h32 07'", y: 80 },
    // { x: "10h32 08'", y: 88 },
    // { x: "10h32 09'", y: 80 },
    // { x: "10h32 10'", y: 90 },
    // { x: "10h32 11'", y: 73 },
    // { x: "10h32 12'", y: 80 },
    // { x: "10h32 13'", y: 87 },
    // { x: "10h32 14'", y: 93 },
    // { x: "10h32 15'", y: 80 },
    // { x: "10h32 16'", y: 80 },
    // { x: "10h32 17'", y: 76 },
    // { x: "10h32 18'", y: 80 },
    // { x: "10h32 19'", y: 84 },
    // { x: "10h32 20'", y: 80 },
    // { x: "10h32 21'", y: 91 },
    // { x: "10h32 22'", y: 87 },
    // { x: "10h32 23'", y: 80 },
    // { x: "10h32 24'", y: 80 },
    // { x: "10h32 25'", y: 96 },
    // { x: "10h32 26'", y: 80 },
    // { x: "10h32 27'", y: 80 },
    // { x: "10h32 28'", y: 75 },
    // { x: "10h32 29'", y: 84 },
    // { x: "10h32 30'", y: 80 },
    // { x: "10h32 31'", y: 80 },
    // { x: "10h32 32'", y: 89 },
    // { x: "10h32 33'", y: 82 },
    // { x: "10h32 34'", y: 80 },
    // { x: "10h32 35'", y: 96 },
    // { x: "10h32 36'", y: 80 },
    // { x: "10h32 37'", y: 92 },
    // { x: "10h32 38'", y: 73 },
    // { x: "10h32 39'", y: 80 },
    // { x: "10h32 40'", y: 80 },
    // { x: "10h32 41'", y: 62 },
    // { x: "10h32 42'", y: 80 },
    // { x: "10h32 43'", y: 80 },
    // { x: "10h32 44'", y: 88 },
    // { x: "10h32 45'", y: 73 },
    // { x: "10h32 46'", y: 81 },
    // { x: "10h32 47'", y: 80 },
    // { x: "10h32 48'", y: 80 },
    // { x: "10h32 49'", y: 97 },
    // { x: "10h32 50'", y: 80 },
    // { x: "10h32 51'", y: 80 },
    // { x: "10h32 52'", y: 89 },
    // { x: "10h32 53'", y: 87 },
    // { x: "10h32 54'", y: 80 },
    // { x: "10h32 55'", y: 90 },
    // { x: "10h32 56'", y: 80 },
    // { x: "10h32 57'", y: 83 },
    // { x: "10h32 58'", y: 83 },
    // { x: "10h32 59'", y: 80 },
  ]);
  const [batteriesLongCharge, setbatteriesLongCharge] = useState([
    // { x: "01/05", y: 81 },
    // { x: "02/05", y: 82 },
    // { x: "03/05", y: 89 },
    // { x: "04/05", y: 80 },
    // { x: "05/05", y: 80 },
    // { x: "06/05", y: 86 },
    // { x: "07/05", y: 72 },
    // { x: "08/05", y: 80 },
    // { x: "09/05", y: 88 },
    // { x: "10/05", y: 80 },
    // { x: "11/05", y: 73 },
    // { x: "12/05", y: 80 },
    // { x: "13/05", y: 87 },
    // { x: "14/05", y: 93 },
    // { x: "15/05", y: 80 },
    // { x: "16/05", y: 80 },
    // { x: "17/05", y: 76 },
    // { x: "18/05", y: 80 },
    // { x: "19/05", y: 84 },
    // { x: "20/05", y: 80 },
    // { x: "21/05", y: 91 },
    // { x: "22/05", y: 87 },
    // { x: "23/05", y: 80 },
    // { x: "24/05", y: 80 },
    // { x: "25/05", y: 96 },
    // { x: "26/05", y: 80 },
    // { x: "27/05", y: 80 },
    // { x: "28/05", y: 75 },
    // { x: "29/05", y: 84 },
    // { x: "30/05", y: 90 },
  ]);
  const [batteriesCurrentAutonomy, setbatteriesCurrentAutonomy] = useState([
    // { x: "10h32 00'", y: 35000 },
    // { x: "10h32 01'", y: 37000 },
    // { x: "10h32 02'", y: 39000 },
    // { x: "10h32 03'", y: 33000 },
    // { x: "10h32 04'", y: 34000 },
    // { x: "10h32 05'", y: 36000 },
    // { x: "10h32 06'", y: 48000 },
    // { x: "10h32 07'", y: 33000 },
    // { x: "10h32 08'", y: 39000 },
    // { x: "10h32 09'", y: 30000 },
    // { x: "10h32 10'", y: 30000 },
    // { x: "10h32 11'", y: 30000 },
    // { x: "10h32 12'", y: 33000 },
    // { x: "10h32 13'", y: 30000 },
    // { x: "10h32 14'", y: 30000 },
    // { x: "10h32 15'", y: 37000 },
    // { x: "10h32 16'", y: 30000 },
    // { x: "10h32 17'", y: 30000 },
    // { x: "10h32 18'", y: 36000 },
    // { x: "10h32 19'", y: 30000 },
    // { x: "10h32 20'", y: 30000 },
    // { x: "10h32 21'", y: 22000 },
    // { x: "10h32 22'", y: 30000 },
    // { x: "10h32 23'", y: 30000 },
    // { x: "10h32 24'", y: 47000 },
    // { x: "10h32 25'", y: 30000 },
    // { x: "10h32 26'", y: 30000 },
    // { x: "10h32 27'", y: 36000 },
    // { x: "10h32 28'", y: 30000 },
    // { x: "10h32 29'", y: 30000 },
    // { x: "10h32 30'", y: 39000 },
    // { x: "10h32 31'", y: 30000 },
    // { x: "10h32 32'", y: 30000 },
    // { x: "10h32 33'", y: 49000 },
    // { x: "10h32 34'", y: 30000 },
    // { x: "10h32 35'", y: 30000 },
    // { x: "10h32 36'", y: 20000 },
    // { x: "10h32 37'", y: 30000 },
    // { x: "10h32 38'", y: 30000 },
    // { x: "10h32 39'", y: 30000 },
    // { x: "10h32 40'", y: 45000 },
    // { x: "10h32 41'", y: 30000 },
    // { x: "10h32 42'", y: 30000 },
    // { x: "10h32 43'", y: 38000 },
    // { x: "10h32 44'", y: 30000 },
    // { x: "10h32 45'", y: 30000 },
    // { x: "10h32 46'", y: 35000 },
    // { x: "10h32 47'", y: 30000 },
    // { x: "10h32 48'", y: 30000 },
    // { x: "10h32 49'", y: 33000 },
    // { x: "10h32 50'", y: 30000 },
    // { x: "10h32 51'", y: 30000 },
    // { x: "10h32 52'", y: 30000 },
    // { x: "10h32 53'", y: 39000 },
    // { x: "10h32 54'", y: 30000 },
    // { x: "10h32 55'", y: 30000 },
    // { x: "10h32 56'", y: 30000 },
    // { x: "10h32 57'", y: 33000 },
    // { x: "10h32 58'", y: 30000 },
    // { x: "10h32 59'", y: 30000 },
  ]);
  const [batteriesLongAutonomy, setbatteriesLongAutonomy] = useState([
    // { x: "01/05", y: 34000 },
    // { x: "02/05", y: 30000 },
    // { x: "03/05", y: 30000 },
    // { x: "04/05", y: 30000 },
    // { x: "05/05", y: 37000 },
    // { x: "06/05", y: 30000 },
    // { x: "07/05", y: 30000 },
    // { x: "08/05", y: 30000 },
    // { x: "09/05", y: 22000 },
    // { x: "10/05", y: 30000 },
    // { x: "11/05", y: 30000 },
    // { x: "12/05", y: 30000 },
    // { x: "13/05", y: 38000 },
    // { x: "14/05", y: 30000 },
    // { x: "15/05", y: 30000 },
    // { x: "16/05", y: 30000 },
    // { x: "17/05", y: 38000 },
    // { x: "18/05", y: 30000 },
    // { x: "19/05", y: 30000 },
    // { x: "20/05", y: 30000 },
    // { x: "21/05", y: 48000 },
    // { x: "22/05", y: 30000 },
    // { x: "23/05", y: 30000 },
    // { x: "24/05", y: 30000 },
    // { x: "25/05", y: 26000 },
    // { x: "26/05", y: 30000 },
    // { x: "27/05", y: 30000 },
    // { x: "28/05", y: 30000 },
    // { x: "29/05", y: 38000 },
    // { x: "30/05", y: 30000 },
  ]);
  const [batteriesCurrentTension, setbatteriesCurrentTension] = useState([
    // { x: "10h32 00'", y: 86 },
    // { x: "10h32 01'", y: 82 },
    // { x: "10h32 02'", y: 80 },
    // { x: "10h32 03'", y: 80 },
    // { x: "10h32 04'", y: 89 },
    // { x: "10h32 05'", y: 86 },
    // { x: "10h32 06'", y: 80 },
    // { x: "10h32 07'", y: 72 },
    // { x: "10h32 08'", y: 88 },
    // { x: "10h32 09'", y: 80 },
    // { x: "10h32 10'", y: 68 },
    // { x: "10h32 11'", y: 73 },
    // { x: "10h32 12'", y: 80 },
    // { x: "10h32 13'", y: 92 },
    // { x: "10h32 14'", y: 93 },
    // { x: "10h32 15'", y: 80 },
    // { x: "10h32 16'", y: 91 },
    // { x: "10h32 17'", y: 78 },
    // { x: "10h32 18'", y: 80 },
    // { x: "10h32 19'", y: 73 },
    // { x: "10h32 20'", y: 80 },
    // { x: "10h32 21'", y: 80 },
    // { x: "10h32 22'", y: 87 },
    // { x: "10h32 23'", y: 88 },
    // { x: "10h32 24'", y: 80 },
    // { x: "10h32 25'", y: 97 },
    // { x: "10h32 26'", y: 85 },
    // { x: "10h32 27'", y: 80 },
    // { x: "10h32 28'", y: 75 },
    // { x: "10h32 29'", y: 82 },
    // { x: "10h32 30'", y: 80 },
    // { x: "10h32 31'", y: 80 },
    // { x: "10h32 32'", y: 89 },
    // { x: "10h32 33'", y: 80 },
    // { x: "10h32 34'", y: 80 },
    // { x: "10h32 35'", y: 96 },
    // { x: "10h32 36'", y: 80 },
    // { x: "10h32 37'", y: 80 },
    // { x: "10h32 38'", y: 73 },
    // { x: "10h32 39'", y: 80 },
    // { x: "10h32 40'", y: 80 },
    // { x: "10h32 41'", y: 74 },
    // { x: "10h32 42'", y: 84 },
    // { x: "10h32 43'", y: 80 },
    // { x: "10h32 44'", y: 88 },
    // { x: "10h32 45'", y: 80 },
    // { x: "10h32 46'", y: 81 },
    // { x: "10h32 47'", y: 80 },
    // { x: "10h32 48'", y: 80 },
    // { x: "10h32 49'", y: 86 },
    // { x: "10h32 50'", y: 80 },
    // { x: "10h32 51'", y: 80 },
    // { x: "10h32 52'", y: 89 },
    // { x: "10h32 53'", y: 80 },
    // { x: "10h32 54'", y: 80 },
    // { x: "10h32 55'", y: 90 },
    // { x: "10h32 56'", y: 80 },
    // { x: "10h32 57'", y: 80 },
    // { x: "10h32 58'", y: 83 },
    // { x: "10h32 59'", y: 80 },
  ]);
  const [batteriesLongTension, setbatteriesLongTension] = useState([
    // { x: "01/05", y: 81 },
    // { x: "02/05", y: 82 },
    // { x: "03/05", y: 89 },
    // { x: "04/05", y: 80 },
    // { x: "05/05", y: 80 },
    // { x: "06/05", y: 86 },
    // { x: "07/05", y: 78 },
    // { x: "08/05", y: 80 },
    // { x: "09/05", y: 88 },
    // { x: "10/05", y: 81 },
    // { x: "11/05", y: 73 },
    // { x: "12/05", y: 80 },
    // { x: "13/05", y: 62 },
    // { x: "14/05", y: 93 },
    // { x: "15/05", y: 80 },
    // { x: "16/05", y: 97 },
    // { x: "17/05", y: 76 },
    // { x: "18/05", y: 80 },
    // { x: "19/05", y: 92 },
    // { x: "20/05", y: 80 },
    // { x: "21/05", y: 91 },
    // { x: "22/05", y: 71 },
    // { x: "23/05", y: 80 },
    // { x: "24/05", y: 80 },
    // { x: "25/05", y: 97 },
    // { x: "26/05", y: 80 },
    // { x: "27/05", y: 80 },
    // { x: "28/05", y: 72 },
    // { x: "29/05", y: 84 },
    // { x: "30/05", y: 90 },
  ]);
  const [failedRequest, setFailedRequest] = useState(false);

  const parsingDate = (timeInput) => {
    let stockTime = null;
    stockTime = new Date(timeInput);
    return `${stockTime.toLocaleTimeString().slice(0, 8)}`;
  };

  const retrievingProbeData = (probeType) => {
    const buildingRequest = () => {
      switch (probeType) {
        case "panels":
          return "panel-power,panel-voltage,panel-yield";
        case "batteries":
          return "battery-voltage,battery-state-of-charge,battery-time-to-go";
        default:
          return "panel-power,panel-voltage,panel-yield";
      }
    };
    if (localStorage.getItem("mddTheme")) {
      if (closingSession()) {
        history.push("/login");
      } else {
        solarPanels
          .get(`/api?metric=${buildingRequest()}&timeout=10000`, {
            headers: {
              Authorization: `Bearer ${demoToken}`,
            },
          })
          .then((response) => {
            const informations = response.data.data;
            console.log("retrieving probe data : ", informations);
            for (let i = 0; i < informations.length; i++) {
              if (informations[i].metric === "panel-power") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setPanelsCurrentPower([...stockData]);
              } else if (informations[i].metric === "panel-voltage") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setPanelsCurrentTension([...stockData]);
              } else if (informations[i].metric === "panel-yield") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setPanelsCurrentProduction([...stockData]);
              } else if (informations[i].metric === "battery-voltage") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setbatteriesCurrentTension([...stockData]);
              } else if (informations[i].metric === "battery-state-of-charge") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setbatteriesCurrentCharge([...stockData]);
              } else if (informations[i].metric === "battery-time-to-go") {
                let stockData = [];
                for (let j = 0; j < informations[i].datapoints.length; j++) {
                  let stockDate = new Date(informations[i].datapoints[j][0]);
                  stockDate.setHours(stockDate.getHours() + 2);
                  stockData.push({
                    x: parsingDate(stockDate),
                    y: informations[i].datapoints[j][1],
                  });
                }
                setbatteriesCurrentAutonomy([...stockData]);
              }
            }
          })
          .catch((err) => {
            console.log("erreur au niveau de la requete API : ", err);
            if (err && err.response && err.response.status === 401) {
              feedBackBuilder(false, "Le token n'est pas valide", "Demos");
            } else if (err && err.response && err.response.status === 500) {
              feedBackBuilder(
                false,
                "La requête n'a pas pu être traitée. Réessayez ultérieurement.",
                "Demos"
              );
            } else {
              feedBackBuilder(
                false,
                "Les vols n'ont pas été récupérés",
                "Demos"
              );
            }
            setFailedRequest(true);
          });
      }
    }
  };

  const parsingTime = (timeInput) => {
    let stockString = "";
    let stockTime = timeInput;
    if (stockTime > 86400) {
      stockString = parseInt(stockTime / 86400) + "j ";
      stockTime = stockTime % 86400;
    }
    if (stockTime > 3600) {
      stockString = stockString + parseInt(stockTime / 3600) + "h";
      stockTime = stockTime % 3600;
    }
    if (stockTime > 60) {
      stockString = stockString + parseInt(stockTime / 60) + "m";
    }
    return stockString;
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
    return function exitSolar() {
      setDemoToken(null);
    };
  }, []);

  useEffect(() => {
    demoToken && retrievingProbeData();
  }, [demoToken]);

  return (
    <Dashboard containerClass="dashboardContainer solarGlobalContainer">
      {!demoToken && <TokenForm />}
      <SolarHeader
        solarNav={solarNav}
        setSolarNav={setSolarNav}
        retrievingProbeData={retrievingProbeData}
      />
      {solarNav === "indicators" && (
        <SolarIndicators
          panelsMaxPower={panelsMaxPower}
          panelsMaxProduction={panelsMaxProduction}
          batteriesAutonomy={batteriesAutonomy}
          panelsCurrentPower={panelsCurrentPower}
          setSolarNav={setSolarNav}
          parsingTime={parsingTime}
          failedRequest={failedRequest}
          setFailedRequest={setFailedRequest}
          setDemoToken={setDemoToken}
        />
      )}
      {solarNav === "production" && (
        <SolarProduction
          panelsCurrentPower={panelsCurrentPower}
          panelsCurrentProduction={panelsCurrentProduction}
          panelsCurrentTension={panelsCurrentTension}
          panelsLongPower={panelsLongPower}
          panelsLongProduction={panelsLongProduction}
          panelsLongTension={panelsLongTension}
          failedRequest={failedRequest}
          setFailedRequest={setFailedRequest}
          setDemoToken={setDemoToken}
        />
      )}
      {solarNav === "batteries" && (
        <SolarBatteries
          batteriesCurrentCharge={batteriesCurrentCharge}
          batteriesLongCharge={batteriesLongCharge}
          batteriesCurrentAutonomy={batteriesCurrentAutonomy}
          batteriesLongAutonomy={batteriesLongAutonomy}
          batteriesCurrentTension={batteriesCurrentTension}
          batteriesLongTension={batteriesLongTension}
          parsingTime={parsingTime}
          failedRequest={failedRequest}
          setFailedRequest={setFailedRequest}
          setDemoToken={setDemoToken}
        />
      )}
    </Dashboard>
  );
}
