export const usersFilters = {
  fields: [
    "tout",
    "email",
    "prénom",
    "nom",
    "rôle",
    "équipe",
    "état",
    "titre",
    "organisation",
  ],
};
