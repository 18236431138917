import { useContext } from "react";

import { MddContext } from "../../../context/MddContext";

import "../../../styles/sideBar.css";
import SingleFilter from "./SingleFilter";

export default function StreamStoreSideBar() {
  const { filters, activeFilter, setFilters, setActiveFilter } =
    useContext(MddContext);
  return (
    <section className="streamStoreSideBarContainer">
      <h5>Vos filtres</h5>
      {filters.map((f) => {
        return (
          <SingleFilter
            key={f.key}
            filter={f}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            setFilters={setFilters}
            filters={filters}
          />
        );
      })}
    </section>
  );
}
