import { useState } from "react";

import RoomTypes from "./RoomTypes";
import MddButton from "../../reusableElements/MddButton";
import RoomsContainer from "./RoomsContainer";

export default function HotelDetails({ hotel, setHotelsDetails }) {
  const [roomsFilter, setRoomsFilter] = useState("");

  return (
    <div className="hotelDetailsContainer">
      <section className="hotelTitleContainer">
        <MddButton
          buttonInnerText="Retour"
          iconColor="altButtonColor"
          buttonActionFunctionOne={setHotelsDetails}
          buttonActionPropOne={null}
          iconName="bi bi-chevron-left"
        />
        <h1>{hotel.name}</h1>
        <div style={{ width: "7rem", height: "2rem" }} />
      </section>
      <section className="hotelLocalInfos">
        <p>{hotel.city}</p>
        <div>
          <div>
            <i class="bi bi-sun-fill" />
            <p>07h32</p>
          </div>
          <div>
            <i class="bi bi-moon-fill" />
            <p>20h04</p>
          </div>
        </div>
        <div>
          <p>{hotel.temperature}°</p>
          <i class="bi bi-sun-fill" />
        </div>
      </section>
      <RoomsContainer rooms={hotel.rooms} roomsFilter={roomsFilter} />
      <RoomTypes
        allRooms={hotel.rooms}
        roomsFilter={roomsFilter}
        setRoomsFilter={setRoomsFilter}
      />
    </div>
  );
}
