import { useContext, useEffect, useState } from "react";

import { MddContext } from "../../../context/MddContext";

import SingleRoom from "./SingleRoom";

export default function RoomsContainer({ rooms, roomsFilter }) {
  const {
    bedRoomsTemperature,
    livingRoomsTemperature,
    diningRoomsTemperature,
  } = useContext(MddContext);

  const [displayedRooms, setDisplayedRooms] = useState([]);

  const returningRooms = () => {
    let stockRooms = [];
    let stockRefs = [];
    const findingType = (typeInput) => {
      switch (typeInput) {
        case "bedroom":
          return bedRoomsTemperature;
        case "diningroom":
          return diningRoomsTemperature;
        case "livingroom":
          return livingRoomsTemperature;
        default:
          return bedRoomsTemperature;
      }
    };
    if (roomsFilter !== "") {
      stockRefs = rooms.filter((room) => {
        return room.type === roomsFilter;
      });
    } else {
      stockRefs = [...rooms];
    }
    for (let i = 0; i < stockRefs.length; i++) {
      stockRooms.push(
        <SingleRoom
          room={stockRefs[i]}
          key={`room - ${i}`}
          typeTemperature={findingType(stockRefs[i].type)}
        />
      );
    }
    setDisplayedRooms(stockRooms);
  };

  useEffect(() => {
    rooms.length > 0 && returningRooms();
  }, [rooms, roomsFilter]);

  return (
    <section className="hotelRoomsContainer">
      {displayedRooms.length > 0 && displayedRooms}
    </section>
  );
}
