import { useContext, useState, useEffect } from "react";

import { MddContext } from "../../../../context/MddContext";
import classingIncorrectInput from "../../../../utils/classingIncorrectInput";

import LifeCycle from "../elements/LifeCycle";
import ReactCalendar from "react-calendar";
import MddCustomInput from "../../../reusableElements/MddCustomInput";
import MddButton from "../../../reusableElements/MddButton";

export default function EditDetails({
  streamDescription,
  streamEndOfLife,
  streamEndOfSupport,
  streamName,
  streamVersion,
  streamProvider,
  registerDate,
  setStreamDescription,
  setStreamName,
  setDateSelector,
  dateSelector,
  streamPreOps,
  streamProd,
  calendarPositioning,
  setOpenedModal,
  versionDescription,
  versionPeriod,
  versionDays,
  checkingEditedStatus,
  creatingStream,
  postingImage,
  streamValidInputs,
  allVersions,
  deletingImage,
  invalidTime,
  streamTags,
  setStreamTags,
  calendarVerticalPosition,
  setCalendarVerticalPosition,
  setStreamProvider,
  retrievingAllVersions,
}) {
  const {
    activeStream,
    currentTeam,
    userTeams,
    setCurrentTeam,
    activeVersion,
    feedBackBuilder,
  } = useContext(MddContext);

  const [uploadFile, setUploadFile] = useState(null);
  const [displayedTags, setDisplayedTags] = useState([]);
  const [typingTag, setTypingTag] = useState("");

  const handleModalOpening = (e) => {
    e.preventDefault();
    retrievingAllVersions();
    setOpenedModal(true);
  };

  const buildingVersionButtonText = () => {
    let stockText = ``;
    if (activeVersion) {
      if (versionDescription === "") {
        stockText = activeVersion.number;
      } else {
        stockText = `${activeVersion.number}, ${versionDescription}`;
      }
    } else if (allVersions.length > 0) {
      stockText = "choisir une version";
    } else {
      stockText = "créer la première version";
    }
    return stockText;
  };
  const returningTags = () => {
    if (streamTags.length > 0) {
      let stockTags = [];
      for (let i = 0; i < streamTags.length; i++) {
        stockTags.push(
          <p className="singleTag">
            {streamTags[i]}{" "}
            <i
              className="bi bi-x-lg"
              onClick={() => {
                deletingTags(i);
              }}
            />
          </p>
        );
      }
      setDisplayedTags(stockTags);
    }
  };
  const handlingTags = () => {
    let stockTags = [...streamTags];
    typingTag !== "" && stockTags.push(typingTag);
    setStreamTags(stockTags);
    setTypingTag("");
  };
  const deletingTags = (inputIndex) => {
    let stockTags = [...streamTags];
    stockTags.splice(inputIndex, 1);
    setStreamTags(stockTags);
  };

  const startDateFinder = () => {
    switch (dateSelector) {
      case "preOpsDate":
        return streamPreOps ? new Date(streamPreOps) : new Date();
      case "releaseDate":
        return streamProd ? new Date(streamProd) : new Date();

      case "endOfSupportDate":
        return streamEndOfSupport ? new Date(streamEndOfSupport) : new Date();

      case "endOfLifeDate":
        return streamEndOfLife ? new Date(streamEndOfLife) : new Date();

      default:
    }
  };

  const returningSelectElements = () => {
      
    let stockTeams = userTeams.filter((team) => {
      return team !== "all" && (activeStream ? team != activeStream.provider : true);
    });
      
    if (activeStream)
        stockTeams.push(activeStream.provider);
      
    let stockSelect = [];
    for (let i = 0; i < stockTeams.length; i++) {
      stockSelect.push(
        <option
          value={stockTeams[i]}
          key={i}
          selected={activeStream && stockTeams[i] === activeStream.provider}
        >
          {stockTeams[i]}
        </option>
      );
    }
    return stockSelect;
  };
  const handlingSelectChange = (e) => {
    if (e.target.value !== "addProvider") {
      setStreamProvider(e.target.value);
      setCurrentTeam(e.target.value);
    }
  };
  const handlingProviderChange = () => {
    if (activeStream) {
      checkingEditedStatus(activeStream.provider, streamProvider, "stream");
    } else if (
      streamProvider &&
      streamName &&
      streamValidInputs.includes("name")
    ) {
      creatingStream();
    }
  };

  useEffect(() => {
    uploadFile && postingImage(uploadFile);
  }, [uploadFile]);
  useEffect(() => {
    returningTags();
  }, [streamTags]);

  useEffect(() => {
    handlingProviderChange();
  }, [streamProvider]);

  return (
    <form
      name="modifyStream"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <article className="formInnerContainer">
        <div className="labelsContainer">
          <label htmlFor="nameForm">Nom</label>
          <label htmlFor="providerForm">Fournisseur</label>
          {activeStream && (
            <>
              {/* <label htmlFor="tagsForm">Tags</label> */}
              <label htmlFor="descriptionForm">Description</label>
              <p>Image</p>
              <label htmlFor="versionForm">Version</label>
              <p>Référence</p>
            </>
          )}
        </div>
        <div className="inputsContainer">
          <input
            type="text"
            id="nameForm"
            className={classingIncorrectInput("name", streamValidInputs)}
            title={
              streamValidInputs.some((element) => {
                return element === "name";
              })
                ? ""
                : "Le nom doit comporter uniquement des lettres, des chiffres et des espaces. Il doit faire au moins trois caractères de long."
            }
            placeholder={activeStream ? "nom" : "commencez par entrer un nom"}
            required
            value={streamName}
            onChange={(e) => setStreamName(e.target.value)}
            onBlur={() => {
              activeStream
                ? checkingEditedStatus(activeStream.name, streamName, "stream")
                : streamProvider &&
                  streamValidInputs.includes("name") &&
                  creatingStream();
            }}
            onKeyUp={(e) => {
              (e.key === "Enter" || e.keyCode === 13) &&
                (activeStream
                  ? checkingEditedStatus(
                      activeStream.name,
                      streamName,
                      "stream"
                    )
                  : streamProvider &&
                    streamValidInputs.includes("name") &&
                    creatingStream());
            }}
            disabled={
              activeStream &&
              !(
                activeStream.editable &&
                (activeStream.provider === currentTeam || currentTeam === "all")
              )
            }
            style={
              activeStream &&
              !(
                activeStream.editable &&
                (activeStream.provider === currentTeam || currentTeam === "all")
              )
                ? {
                    border: "none",
                    padding: "0.05rem 0.80rem",
                  }
                : {}
            }
          />
          {!activeStream ||
          activeStream.provider === currentTeam ||
          currentTeam === "all" ? (
            <select
              name="selectProviderForm"
              id="selectProviderForm"
              onChange={(e) => handlingSelectChange(e)}
            >
              {!activeStream && (
                <option value="addProvider">choisir un fournisseur</option>
              )}
              {returningSelectElements()}
            </select>
          ) : (
            <input
              type="text"
              id="providerForm"
              className={classingIncorrectInput("provider", streamValidInputs)}
              title={
                streamValidInputs.some((element) => {
                  return element === "provider";
                })
                  ? ""
                  : "Le nom du fournisseur doit faire moins de trente caractères de long."
              }
              placeholder="provider"
              required
              disabled
              value={streamProvider}
              style={
                activeStream &&
                !(
                  activeStream.editable &&
                  (activeStream.provider === currentTeam ||
                    currentTeam === "all")
                )
                  ? {
                      border: "none",
                      padding: "0.05rem 0.80rem",
                    }
                  : {}
              }
            />
          )}
          {activeStream && (
            <>
              {/* <div
                className="tagsContainer"
                style={
                  activeStream &&
                  !(
                    activeStream.editable &&
                    (activeStream.provider === currentTeam ||
                      currentTeam === "all")
                  )
                    ? {
                        border: "none",
                        padding: "0.05rem 0.55rem",
                      }
                    : {}
                }
              >
                {displayedTags}
                <input
                  type="text"
                  id="tagsForm"
                  placeholder={
                    activeStream.editable &&
                    (activeStream.provider === currentTeam ||
                      currentTeam === "all")
                      ? "tags"
                      : "aucun tag"
                  }
                  disabled={
                    activeStream &&
                    !(
                      activeStream.editable &&
                      (activeStream.provider === currentTeam ||
                        currentTeam === "all")
                    )
                  }
                  value={typingTag}
                  onChange={(e) => setTypingTag(e.target.value)}
                  onBlur={() => {
                    handlingTags();
                  }}
                  onKeyUp={(e) => {
                    (e.key === "Enter" || e.keyCode === 13) && handlingTags();
                  }}
                />
              </div> */}
              <textarea
                type="text"
                id="descriptionForm"
                className={classingIncorrectInput(
                  "description",
                  streamValidInputs
                )}
                placeholder={
                  activeStream.editable &&
                  (activeStream.provider === currentTeam ||
                    currentTeam === "all")
                    ? "description"
                    : "aucune description"
                }
                value={streamDescription}
                disabled={
                  activeStream &&
                  !(
                    activeStream.editable &&
                    (activeStream.provider === currentTeam ||
                      currentTeam === "all")
                  )
                }
                onChange={(e) => setStreamDescription(e.target.value)}
                onBlur={() => {
                  checkingEditedStatus(
                    activeStream.description,
                    streamDescription,
                    "stream"
                  );
                }}
                style={
                  activeStream &&
                  !(
                    activeStream.editable &&
                    (activeStream.provider === currentTeam ||
                      currentTeam === "all")
                  )
                    ? {
                        border: "none",
                        padding: "0.55rem 0.80rem",
                      }
                    : {}
                }
              />
              {activeStream && (
                <div>
                  <MddCustomInput
                    disabler={
                      activeStream &&
                      !(
                        activeStream.editable &&
                        (activeStream.provider === currentTeam ||
                          currentTeam === "all")
                      )
                    }
                    setUpperLevelFile={setUploadFile}
                    previousName={activeStream.image}
                    typeOfFiles=".jpg, .png"
                  />
                  {activeStream.image &&
                    activeStream.editable &&
                    (activeStream.provider === currentTeam ||
                      currentTeam === "all") && (
                      <MddButton
                        iconName="bi-x"
                        buttonSize="small"
                        smallButtonDescription="Delete picture"
                        buttonActionFunctionOne={deletingImage}
                        iconColor="altButtonColor"
                        inactive={!activeStream}
                      />
                    )}
                </div>
              )}
              <button
                type="button"
                id="versionForm"
                disabled={!activeStream}
                onClick={(e) => handleModalOpening(e)}
              >
                {buildingVersionButtonText()}
              </button>
              <div>
                {activeVersion ? (
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(activeVersion.reference);
                      feedBackBuilder(
                        true,
                        `référence copiée`,
                        "Streams Store"
                      );
                    }}
                  >
                    {activeVersion.reference}
                  </p>
                ) : (
                  <p>&nbsp;</p>
                )}
                {/* <MddButton
                  iconName="bi bi-files"
                  buttonSize="small"
                  smallButtonDescription="Copy stream and version"
                  buttonActionFunctionOne={setConfirmModal}
                  buttonActionPropOne={true}
                  buttonActionFunctionTwo={setTypeOfModal}
                  buttonActionPropTwo="copy"
                  iconColor="altButtonColor"
                /> */}
              </div>
            </>
          )}
        </div>
      </article>
      {activeVersion && (
        <LifeCycle
          setDateSelector={setDateSelector}
          dateSelector={dateSelector}
          streamPreOps={streamPreOps}
          streamProd={streamProd}
          streamEndOfSupport={streamEndOfSupport}
          streamEndOfLife={streamEndOfLife}
          versionPeriod={versionPeriod}
          versionDays={versionDays}
          activeVersion={activeVersion}
          invalidTime={invalidTime}
          calendarVerticalPosition={calendarVerticalPosition}
          setCalendarVerticalPosition={setCalendarVerticalPosition}
        />
      )}
      {activeVersion && dateSelector && (
        <article className="calendarContainer" style={calendarPositioning()}>
          <ReactCalendar onChange={registerDate} value={startDateFinder()} />
        </article>
      )}
    </form>
  );
}
