import { useContext, useEffect, useState, useRef, useCallback } from "react";

import { ChromePicker } from "react-color";

export default function DatavizColorPicker({
  label,
  displayValue,
  input,
  path,
  updater,
}) {
  const colorPickerRef = useRef(null);

  const [activeColor, setActiveColor] = useState(
    input ? input : displayValue ? displayValue : "#fff"
  );
  const [openedPicker, setOpenedPicker] = useState(false);

  const handlingClick = (e) => {
    if (
      // e.target.getAttribute("class") &&
      // e.target.getAttribute("class") === "chrome-picker" &&
      e.target.parentNode === colorPickerRef.current &&
      !openedPicker
    ) {
      setOpenedPicker(true);
    } else {
      setOpenedPicker(false);
    }
  };

  useEffect(() => {
    let stockPicker = document.getElementsByClassName("chrome-picker");
    if (openedPicker && stockPicker.length > 0 && colorPickerRef.current) {
      stockPicker[0].style.top = colorPickerRef.current.offsetTop + "px";
      stockPicker[0].style.display = "initial";
    }
    // else if (stockPicker.length > 0) {
    //   stockPicker[0].style.display = "none";
    // }
  }, [openedPicker]);

  useEffect(() => {
    // let stockPicker = document.getElementsByClassName("datavizColorPicker");
    document.addEventListener("click", handlingClick);
    return function exitInput() {
      document.removeEventListener("click", handlingClick);
    };
  }, []);

  return (
    <div className="datavizColorPicker" ref={colorPickerRef}>
      <label>{label}</label>
      <input
        type="text"
        title=""
        placeholder=""
        value={activeColor ? activeColor : ""}
        onChange={(e) => {
          setActiveColor(e.target.value);
        }}
        onBlur={() => {
          activeColor !== input && updater(path, activeColor);
          // setOpenedPicker(false);
        }}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            activeColor !== input &&
            updater(path, activeColor);
        }}
      />
      <button
        type="button"
        className="colorDisplay"
        // onClick={() => {
        //   setOpenedPicker(!openedPicker);
        // }}
        style={{ backgroundColor: activeColor ? activeColor : "#fff" }}
      />
      {openedPicker && (
        <ChromePicker
          color={activeColor}
          onChangeComplete={(color) => {
            setActiveColor(color.hex);
            color.hex !== input && updater(path, color.hex);
          }}
        />
      )}
    </div>
  );
}
