export default function dateParser(unparsedDate) {
  if (unparsedDate === "") {
    return unparsedDate;
  } else if (typeof unparsedDate === "string" && unparsedDate.length > 10) {
    const day = unparsedDate.slice(5, 7);
    const month = unparsedDate.slice(8, 10);
    const year = unparsedDate.slice(0, 4);
    return `${day}/${month}/${year}`;
  } else {
    const month = unparsedDate.slice(0, 2);
    const day = unparsedDate.slice(3, 5);
    const year = unparsedDate.slice(6, 10);
    return `${year}-${month}-${day}T00:00:00+00:00`;
  }
}
