import { useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  LineController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import MddButton from "../../reusableElements/MddButton";

export default function SolarBatteries({
  batteriesCurrentCharge,
  batteriesLongCharge,
  batteriesCurrentAutonomy,
  batteriesLongAutonomy,
  batteriesCurrentTension,
  batteriesLongTension,
  parsingTime,
  failedRequest,
  setFailedRequest,
  setDemoToken,
}) {
  const [solarNav, setSolarNav] = useState("live");

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend
  );

  const positioningNav = () => {
    if (solarNav === "live") {
      return { left: "0" };
    } else if (solarNav === "history") {
      return { left: "7rem" };
    } else {
      return { opacity: "0" };
    }
  };
  const returningColors = (dataInput) => {
    let stockColors = [];
    for (let i = 0; i < dataInput.length; i++) {
      if (dataInput[i].y === -1) {
        // -1 data not available (stream OFFLINE)
        stockColors.push("#f4f4f4");
      } else if (dataInput[i].y === -2) {
        // -2 healthcheck monitoring has timed out
        stockColors.push("#c9c9c9");
      } else if (dataInput[i].y > 85) {
        // OK thresold
        stockColors.push("#c0e657");
      } else if (dataInput[i].y > 70) {
        // WARNING thresold
        stockColors.push("#ffcb00");
      } else {
        stockColors.push("#fd7668"); // CRITICAL thresold
      }
    }
    return stockColors;
  };

  const consommationSettings = {
    datasets: [
      {
        label: "autonomie",
        type: "line",
        backgroundColor: "#2bb0fc",
        borderColor: "#2bb0fc",
        pointBackgroundColor: "#2bb0fc",
        pointBorderColor: "#2bb0fc",
        pointRadius: 0,
        data:
          solarNav === "live"
            ? batteriesCurrentAutonomy
            : batteriesLongAutonomy,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        yAxisID: "left-y-axis",
      },
      {
        label: "taux de charge",
        type: "bar",
        backgroundColor: returningColors(batteriesCurrentCharge),
        borderColor: returningColors(batteriesCurrentCharge),
        pointBackgroundColor: "#c0e657",
        pointBorderColor: "#c0e657",
        pointRadius: 0,
        data:
          solarNav === "live" ? batteriesCurrentCharge : batteriesLongCharge,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
        yAxisID: "right-y-axis",
      },
    ],
  };

  const consommationConfig = {
    maintainAspectRatio: false,
    scales: {
      "left-y-axis": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "autonomie",
        },
      },
      "right-y-axis": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "% (taux de charge)",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label} : ${
              context.dataset.label === "taux de charge"
                ? `${context.formattedValue}%`
                : parsingTime(context.parsed.y)
            }`;
          },
        },
      },
    },
  };

  const tensionSettings = {
    datasets: [
      {
        label: "Tension",
        type: "line",
        backgroundColor: "#fd7668",
        borderColor: "#fd7668",
        pointBackgroundColor: "#fd7668",
        pointBorderColor: "#fd7668",
        pointRadius: 0,
        data:
          solarNav === "live" ? batteriesCurrentTension : batteriesLongTension,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  const tensionConfig = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "V (tension)",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label} : ${context.formattedValue} V`;
          },
        },
      },
    },
  };

  return (
    <section className="solarBody productionBody">
      <div className="navigationToggle">
        <div
          className={`solarNavBackground ${solarNav}`}
          style={positioningNav()}
        />
        <MddButton
          buttonInnerText="temps réel"
          buttonActionFunctionOne={setSolarNav}
          buttonActionPropOne="live"
          iconColor={solarNav === "live" ? "activeAltButton" : "altButtonColor"}
        />
        <MddButton
          buttonInnerText="historique"
          buttonActionFunctionOne={setSolarNav}
          buttonActionPropOne="history"
          iconColor={
            solarNav === "history" ? "activeAltButton" : "altButtonColor"
          }
        />
      </div>
      {failedRequest ? (
        <MddButton
          buttonInnerText="réessayer"
          iconColor="basicButtonColor"
          buttonActionFunctionOne={setDemoToken}
          buttonActionPropOne={null}
          buttonActionFunctionTwo={setFailedRequest}
          buttonActionPropTwo={false}
        />
      ) : (
        batteriesCurrentAutonomy.length > 0 && (
          <article className="solarGraph">
            <Line options={consommationConfig} data={consommationSettings} />
          </article>
        )
      )}
      {batteriesCurrentTension.length > 0 && (
        <article className="solarGraph">
          <Line options={tensionConfig} data={tensionSettings} />
        </article>
      )}
    </section>
  );
}
