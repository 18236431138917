import { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../../context/MddContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import mddMetrics from "../../../../api/mddMetrics";
// import revertingToken from "../../../../utils/auth/revertingToken";
import "../../../styles/streamStore/detailedStream/qosModal.css";

import MddButton from "../../../components/reusableElements/MddButton";
import MddCalendar from "../../../components/reusableElements/MddCalendar";

const fakeHours = [
  {
    x: `00h`,
    y: 18,
  },
  {
    x: `01h`,
    y: 17,
  },
  {
    x: `02h`,
    y: 17,
  },
  {
    x: `03h`,
    y: 18,
  },
  {
    x: `04h`,
    y: 18,
  },
  {
    x: `05h`,
    y: 18,
  },
  {
    x: `06h`,
    y: 18,
  },
  {
    x: `07h`,
    y: 18,
  },
  {
    x: `08h`,
    y: 20,
  },
  {
    x: `09h`,
    y: 20,
  },
  {
    x: `10h`,
    y: 20,
  },
  {
    x: `11h`,
    y: 20,
  },
  {
    x: `12h`,
    y: 20,
  },
  {
    x: `13h`,
    y: 20,
  },
  {
    x: `14h`,
    y: 20,
  },
  {
    x: `15h`,
    y: 20,
  },
  {
    x: `16h`,
    y: 20,
  },
  {
    x: `17h`,
    y: 20,
  },
  {
    x: `18h`,
    y: 20,
  },
  {
    x: `19h`,
    y: 20,
  },
  {
    x: `20h`,
    y: 18,
  },
  {
    x: `21h`,
    y: 18,
  },
  {
    x: `22h`,
    y: 18,
  },
  {
    x: `23h`,
    y: 18,
  },
];
// const fakeHours = [18, 18, 18, 18, 20, 20, 20, 20, 20, 18, 18, 18];
const fakeDays = [
  {
    x: `30/04`,
    y: 18,
  },
  {
    x: `01/05`,
    y: 18,
  },
  {
    x: `02/05`,
    y: 18,
  },
  {
    x: `03/05`,
    y: 18,
  },
  {
    x: `04/05`,
    y: 18,
  },
  {
    x: `05/05`,
    y: 18,
  },
  {
    x: `06/05`,
    y: 18,
  },
  {
    x: `07/05`,
    y: 18,
  },
  {
    x: `08/05`,
    y: 20,
  },
  {
    x: `09/05`,
    y: 20,
  },
  {
    x: `10/05`,
    y: 20,
  },
  {
    x: `11/05`,
    y: 20,
  },
  {
    x: `12/05`,
    y: 20,
  },
  {
    x: `13/05`,
    y: 20,
  },
  {
    x: `14/05`,
    y: 20,
  },
  {
    x: `15/05`,
    y: 20,
  },
  {
    x: `16/05`,
    y: 20,
  },
  {
    x: `17/05`,
    y: 20,
  },
  {
    x: `18/05`,
    y: 20,
  },
  {
    x: `19/05`,
    y: 20,
  },
  {
    x: `20/05`,
    y: 18,
  },
  {
    x: `21/05`,
    y: 18,
  },
  {
    x: `22/05`,
    y: 18,
  },
  {
    x: `23/05`,
    y: 18,
  },
  {
    x: `24/05`,
    y: 18,
  },
  {
    x: `25/05`,
    y: 18,
  },
  {
    x: `26/05`,
    y: 18,
  },
  {
    x: `27/05`,
    y: 18,
  },
  {
    x: `28/05`,
    y: 18,
  },
];
// const fakeDays = [18, 18, 18, 18, 20, 20, 20, 20, 20, 18, 18, 18];

export default function TemperatureModal({ setTempModal, room }) {
  const { userToken, closingSession, handling401, activeVersion } =
    useContext(MddContext);
  let history = useHistory();
  const dailyChartRef = useRef(null);
  const monthlyChartRef = useRef(null);

  const [tempNav, setTempNav] = useState("quotidienne");
  const [monthData, setMonthData] = useState([...fakeDays]);
  const [dayData, setDayData] = useState([...fakeHours]);
  const [monthReference, setMonthReference] = useState([...fakeDays]);
  const [dayReference, setDayReference] = useState([...fakeHours]);
  const [dayStartingPoint, setDayStartingPoint] = useState(null);
  const [dayParsed, setDayParsed] = useState(null);
  const [dayEndingPoint, setDayEndingPoint] = useState(null);
  const [monthStartingPoint, setMonthStartingPoint] = useState(null);
  const [monthParsed, setMonthParsed] = useState(null);
  const [monthEndingPoint, setMonthEndingPoint] = useState(null);
  const [initialDayStartingPoint, setInitialDayStartingPoint] = useState(null);
  const [initialMonthStartingPoint, setInitialMonthStartingPoint] =
    useState(null);
  const [initialDayEndingPoint, setInitialDayEndingPoint] = useState(null);
  const [initialMonthEndingPoint, setInitialMonthEndingPoint] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [endDate, setEndDate] = useState(false);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend
  );

  const positioningNav = () => {
    if (tempNav === "quotidienne") {
      return { left: "0" };
    } else if (tempNav === "mensuelle") {
      return { left: "7rem" };
    } else {
      return { opacity: "0" };
    }
  };

  const returningColors = (dataInput) => {
    let stockColors = [];
    for (let i = 0; i < dataInput.length; i++) {
      if (dataInput[i].y === -1) {
        stockColors.push("#f4f4f4");
      } else if (dataInput[i].y > 22) {
        stockColors.push("#c8e47d");
      } else if (dataInput[i].y > 17) {
        stockColors.push("#fcde68");
      } else {
        stockColors.push("#6F9AE6");
      }
    }
    return stockColors;
  };

  const dailySettings = {
    datasets: [
      {
        label: "Temperature",
        type: "line",
        backgroundColor: "#203b53",
        borderColor: "#203b53",
        pointBackgroundColor: returningColors(dayReference),
        pointBorderColor: returningColors(dayReference),
        data: dayData,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };
  const monthlySettings = {
    datasets: [
      {
        label: "Temperature",
        type: "line",
        backgroundColor: "#203b53",
        borderColor: "#203b53",
        pointBackgroundColor: returningColors(monthReference),
        pointBorderColor: returningColors(monthReference),
        data: monthData,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  const dailyConfig = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Temperature : ${context.formattedValue}°`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  };
  const monthlyConfig = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Temperature : ${context.formattedValue}°`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  };

  // const retrievingDayMetrics = () => {
  //   if (closingSession()) {
  //     history.push("/login");
  //   } else {
  //     mddMetrics
  //       .get(
  //         `?reference=${activeVersion.reference}${
  //           dayStartingPoint ? `&from=${dayStartingPoint.toISOString()}` : ""
  //         }${dayEndingPoint ? `&to=${dayEndingPoint.toISOString()}` : ""}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${revertingToken(userToken)}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const informations = response.data.data.datapoints;
  //         !initialDayStartingPoint &&
  //           setInitialDayStartingPoint(new Date(response.data.data.from));
  //         !initialDayEndingPoint &&
  //           setInitialDayEndingPoint(new Date(response.data.data.to));
  //         let stockData = [];
  //         let stockReference = [];
  //         let stockDate = null;
  //         let stockHour = null;
  //         for (let i = 0; i < informations.length; i++) {
  //           stockDate = new Date(informations[i][0]);
  //           stockHour = stockDate.getHours();
  //           stockData.push({
  //             x: `${stockHour}h`,
  //             y: informations[i][1] === -1 ? 100 : informations[i][1],
  //           });
  //           stockReference.push({
  //             x: `${stockHour}h`,
  //             y: informations[i][1],
  //           });
  //         }
  //         setDayData([...stockData]);
  //         setDayReference([...stockReference]);
  //       })
  //       .catch((err) => {
  //         console.log("erreur au niveau de la requete : ", err);
  //         handling401(err, history);
  //       });
  //   }
  // };

  // const retrievingMonthMetrics = () => {
  //   if (closingSession()) {
  //     history.push("/login");
  //   } else {
  //     mddMetrics
  //       .get(
  //         `?reference=${activeVersion.reference}${
  //           monthStartingPoint
  //             ? `&from=${monthStartingPoint.toISOString()}`
  //             : ""
  //         }${
  //           monthEndingPoint ? `&to=${monthEndingPoint.toISOString()}` : ""
  //         }&timing=day`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${revertingToken(userToken)}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         const informations = response.data.data.datapoints;
  //         !initialMonthStartingPoint &&
  //           setInitialMonthStartingPoint(new Date(response.data.data.from));
  //         !initialMonthEndingPoint &&
  //           setInitialMonthEndingPoint(new Date(response.data.data.to));
  //         let stockData = [];
  //         let stockReference = [];
  //         let stockDate = null;
  //         let stockDay = null;
  //         for (let i = 0; i < informations.length; i++) {
  //           stockDate = new Date(informations[i][0]);
  //           stockDay = `${stockDate.getDate()}/${stockDate.getMonth() + 1}`;
  //           stockData.push({
  //             x: stockDay,
  //             y: informations[i][1] === -1 ? 100 : informations[i][1],
  //           });
  //           stockReference.push({
  //             x: stockDay,
  //             y: informations[i][1],
  //           });
  //         }
  //         setMonthData([...stockData]);
  //         setMonthReference([...stockReference]);
  //       })
  //       .catch((err) => {
  //         console.log("erreur au niveau de la requete : ", err);
  //         handling401(err, history);
  //       });
  //   }
  // };

  const updatingStartingPoint = (newStart) => {
    dailyChartRef.current = null;
    monthlyChartRef.current = null;
    if (tempNav === "quotidienne") {
      let stockStart = new Date(
        dayStartingPoint ? dayStartingPoint : initialDayStartingPoint
      );
      switch (newStart) {
        case -2:
          stockStart.setDate(stockStart.getDate() - 1);
          setDayStartingPoint(stockStart);
          break;
        case -1:
          stockStart.setHours(stockStart.getHours() - 1);
          setDayStartingPoint(stockStart);
          break;
        case 1:
          stockStart.setHours(stockStart.getHours() + 1);
          setDayStartingPoint(stockStart);
          break;
        case 2:
          stockStart.setDate(stockStart.getDate() + 1);
          setDayStartingPoint(stockStart);
          break;
        default:
          break;
      }
    } else if (tempNav === "mensuelle") {
      let stockStart = new Date(
        monthStartingPoint ? monthStartingPoint : initialMonthStartingPoint
      );
      switch (newStart) {
        case -2:
          stockStart.setDate(stockStart.getDate() - 30);
          setMonthStartingPoint(stockStart);
          break;
        case -1:
          stockStart.setDate(stockStart.getDate() - 1);
          setMonthStartingPoint(stockStart);
          break;
        case 1:
          stockStart.setDate(stockStart.getDate() + 1);
          setMonthStartingPoint(stockStart);
          break;
        case 2:
          stockStart.setDate(stockStart.getDate() + 30);
          setMonthStartingPoint(stockStart);
          break;
        default:
          break;
      }
    }
  };

  const registeringDate = (newDate) => {
    if (tempNav === "quotidienne" && !endDate) {
      setDayStartingPoint(newDate);
    } else if (tempNav === "quotidienne" && endDate) {
      let stockDate = new Date(newDate);
      stockDate && stockDate.setHours(stockDate.getHours() - 23);
      setDayStartingPoint(stockDate);
    } else if (tempNav === "mensuelle" && !endDate) {
      setMonthStartingPoint(newDate);
    } else if (tempNav === "mensuelle" && endDate) {
      let stockDate = new Date(newDate);
      stockDate && stockDate.setDate(stockDate.getDate() - 29);
      setMonthStartingPoint(stockDate);
    }
  };

  const findingStartDate = () => {
    if (tempNav === "quotidienne" && !endDate) {
      return dayStartingPoint ? dayStartingPoint : new Date();
    } else if (tempNav === "quotidienne" && endDate) {
      return dayEndingPoint ? dayEndingPoint : new Date();
    } else if (tempNav === "mensuelle" && !endDate) {
      return monthStartingPoint ? monthStartingPoint : new Date();
    } else if (tempNav === "mensuelle" && endDate) {
      return monthEndingPoint ? monthEndingPoint : new Date();
    }
  };

  const unparsingDate = (dateInput) => {
    const buldingLocaleDate = (dateInput) => {
      const stockYear = dateInput.slice(6, 10);
      const stockMonth = dateInput.slice(3, 5);
      const stockDay = dateInput.slice(0, 2);
      const stockHours = dateInput.slice(11, 13);
      const stockMinutes = dateInput.slice(14, 16);
      const stockSeconds = dateInput.slice(17, 19);
      const stockMilliSeconds = dateInput.slice(20, 23);
      return `${stockYear}-${stockMonth}-${stockDay}T${stockHours}:${stockMinutes}:${stockSeconds}.${stockMilliSeconds}+02:00`;
    };

    let stockDate = buldingLocaleDate(dateInput);
    switch (tempNav) {
      case "quotidienne":
        setDayStartingPoint(new Date(stockDate));
        setShowCalendar(false);
        break;
      case "mensuelle":
        setMonthStartingPoint(new Date(stockDate));
        setShowCalendar(false);
        break;
      default:
        setShowCalendar(false);
    }
  };

  const parsingDate = () => {
    const returningMilliSeconds = (dateInput) => {
      let stockMilliSeconds = dateInput.getMilliseconds();
      if (stockMilliSeconds < 10) {
        stockMilliSeconds = "00" + stockMilliSeconds;
      } else if (stockMilliSeconds < 100) {
        stockMilliSeconds = "0" + stockMilliSeconds;
      }
      return stockMilliSeconds;
    };

    function returningDate(dateInput) {
      const stockDate = `${
        dateInput.getDate() > 9
          ? dateInput.getDate()
          : "0" + dateInput.getDate()
      }/${
        dateInput.getMonth() + 1 > 9
          ? dateInput.getMonth() + 1
          : "0" + (dateInput.getMonth() + 1)
      }/${dateInput.getFullYear()} ${
        dateInput.getHours() > 9
          ? dateInput.getHours()
          : "0" + dateInput.getHours()
      }:${
        dateInput.getMinutes() > 9
          ? dateInput.getMinutes()
          : "0" + dateInput.getMinutes()
      }:${
        dateInput.getSeconds() > 9
          ? dateInput.getSeconds()
          : "0" + dateInput.getSeconds()
      }.${returningMilliSeconds(dateInput)}`;
      return stockDate;
    }
    tempNav === "quotidienne" && setDayParsed(returningDate(dayStartingPoint));
    tempNav === "mensuelle" &&
      setMonthParsed(returningDate(monthStartingPoint));
  };

  function positioningCalendar() {
    let stockCalendar = document.getElementsByClassName("logsDatesModal");
    console.log("positioning calendar : ", stockCalendar);
    for (let i = 0; i < stockCalendar.length; i++) {
      if (endDate) {
        stockCalendar[i].style.right = "1rem";
        stockCalendar[i].style.left = "";
      } else {
        stockCalendar[i].style.left = "1rem";
      }
    }
  }

  const translatingType = () => {
    switch (room.type) {
      case "bedroom":
        return "chambre";
      case "diningroom":
        return "restaurant";
      case "livingroom":
        return "salon";
      default:
        return "pièce";
    }
  };

  // useEffect(() => {
  //   retrievingDayMetrics();
  //   retrievingMonthMetrics();
  // }, []);

  useEffect(() => {
    // dayStartingPoint && retrievingDayMetrics();
    dayStartingPoint && parsingDate(dayStartingPoint);
    let stockDate = new Date(dayStartingPoint);
    stockDate && stockDate.setHours(stockDate.getHours() + 23);
    dayStartingPoint && setDayEndingPoint(stockDate);
  }, [dayStartingPoint]);

  useEffect(() => {
    // monthStartingPoint && retrievingMonthMetrics();
    monthStartingPoint && parsingDate(monthStartingPoint);
    let stockDate = new Date(monthStartingPoint);
    stockDate && stockDate.setDate(stockDate.getDate() + 29);
    monthStartingPoint && setMonthEndingPoint(stockDate);
  }, [monthStartingPoint]);

  useEffect(() => {
    // dayEndingPoint && retrievingDayMetrics();
  }, [dayEndingPoint]);

  useEffect(() => {
    // monthEndingPoint && retrievingMonthMetrics();
  }, [monthEndingPoint]);

  // useEffect(() => {
  //   !dayStartingPoint && setDayStartingPoint(initialDayStartingPoint);
  // }, [initialDayStartingPoint]);

  // useEffect(() => {
  //   !monthStartingPoint && setMonthStartingPoint(initialMonthStartingPoint);
  // }, [initialMonthStartingPoint]);

  useEffect(() => {
    positioningCalendar();
  }, [endDate]);

  return (
    <div className="qosModalContainer">
      <section>
        <article className="qosHeader">
          <h4>
            Suivi de la température : {room.name} ({room && translatingType()})
          </h4>
          <div className="qosNavigationContainer">
            <div className="qosSideNavigation">
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-double-left"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={-2}
              />
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-left"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={-1}
              />
            </div>
            <MddButton
              buttonInnerText={
                tempNav === "quotidienne"
                  ? // ? dayStartingPoint
                    //   ? `${dayStartingPoint.toLocaleDateString()} ${dayStartingPoint
                    //       .toLocaleTimeString()
                    //       .slice(0, 5)}`
                    //   : initialDayStartingPoint
                    //   ? `${initialDayStartingPoint.toLocaleDateString()} ${initialDayStartingPoint
                    //       .toLocaleTimeString()
                    //       .slice(0, 5)}`
                    //   : ""
                    // : monthStartingPoint
                    // ? monthStartingPoint.toLocaleDateString()
                    // : initialMonthStartingPoint
                    // ? initialMonthStartingPoint.toLocaleDateString()
                    // : ""
                    "24/05/2022 00h"
                  : "30/04/2022"
              }
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setShowCalendar}
              buttonActionPropOne={endDate ? true : !showCalendar}
              buttonActionFunctionTwo={setEndDate}
              buttonActionPropTwo={false}
              iconName="bi bi-calendar-range"
            />
            <div className="navigationToggle">
              <div
                className={`qosNavBackground ${tempNav}`}
                style={positioningNav()}
              />
              <MddButton
                buttonInnerText="quotidienne"
                buttonActionFunctionOne={setTempNav}
                buttonActionPropOne="quotidienne"
                iconColor={
                  tempNav === "quotidienne"
                    ? "activeAltButton"
                    : "altButtonColor"
                }
              />
              <MddButton
                buttonInnerText="mensuelle"
                buttonActionFunctionOne={setTempNav}
                buttonActionPropOne="mensuelle"
                iconColor={
                  tempNav === "mensuelle" ? "activeAltButton" : "altButtonColor"
                }
              />
            </div>
            <MddButton
              buttonInnerText={
                tempNav === "quotidienne"
                  ? // ? dayEndingPoint
                    //   ? `${dayEndingPoint.toLocaleDateString()} ${dayEndingPoint
                    //       .toLocaleTimeString()
                    //       .slice(0, 5)}`
                    //   : initialDayEndingPoint
                    //   ? `${initialDayEndingPoint.toLocaleDateString()} ${initialDayEndingPoint
                    //       .toLocaleTimeString()
                    //       .slice(0, 5)}`
                    //   : ""
                    // : monthEndingPoint
                    // ? monthEndingPoint.toLocaleDateString()
                    // : initialMonthEndingPoint
                    // ? `${initialMonthEndingPoint.toLocaleDateString()} ${initialMonthEndingPoint
                    //     .toLocaleTimeString()
                    //     .slice(0, 5)}`
                    // : ""
                    "24/05/2022 23h"
                  : "28/05/2022"
              }
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setShowCalendar}
              buttonActionPropOne={!endDate ? true : !showCalendar}
              buttonActionFunctionTwo={setEndDate}
              buttonActionPropTwo={true}
              iconName="bi bi-calendar-range"
            />
            <MddCalendar
              showModal={showCalendar}
              registeringDate={registeringDate}
              findingStartDate={findingStartDate}
              unparsingDate={unparsingDate}
              dateParsed={tempNav === "quotidienne" ? dayParsed : monthParsed}
              setDateParsed={
                tempNav === "quotidienne" ? setDayParsed : setMonthParsed
              }
              endDate={endDate}
            />
            <div className="qosSideNavigation">
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-right"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={1}
              />
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-double-right"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={2}
              />
            </div>
          </div>
        </article>
        <article className="chartContainer">
          {tempNav === "quotidienne" && dayData.length > 0 && (
            <div>
              <Bar options={dailyConfig} data={dailySettings} />
            </div>
          )}
          {tempNav === "mensuelle" && monthData.length > 0 && (
            <div>
              <Bar options={monthlyConfig} data={monthlySettings} />
            </div>
          )}
        </article>
        <article>
          <p>
            {activeVersion && activeVersion.reason ? activeVersion.reason : ""}
          </p>
          <MddButton
            buttonInnerText="retour"
            buttonActionFunctionOne={setTempModal}
            buttonActionPropOne={false}
          />
        </article>
      </section>
    </div>
  );
}
