import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";

import MddButton from "../reusableElements/MddButton";

export default function DependenciesCard({
  inputElement,
  dependencyType,
  selectingElement,
  selectedElements,
}) {
  const { setActiveStream, userToken, closingSession, handling401 } =
    useContext(MddContext);

  let history = useHistory();

  const retrievingParentStream = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/stream/${inputElement.stream}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setActiveStream(stock);
          history.push(`/streamstore/${inputElement.reference}`);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          handling401(err, history);
        });
    }
  };

  return (
    <article
      className={
        inputElement.editable
          ? `dependenciesCard ${dependencyType}`
          : `dependenciesCard ${dependencyType} nonEditableDependency`
      }
      onClick={() => {
        inputElement.editable && selectingElement(inputElement);
      }}
    >
      <MddButton
        buttonInnerText={inputElement.reference}
        buttonSize="long"
        iconColor="altButtonColor"
        buttonActionFunctionOne={retrievingParentStream}
      />
      <p>{inputElement.provider}</p>
      <p>{inputElement.description!=="" ? inputElement.description : inputElement.name}</p>
      {selectedElements.some((element) => {
        return element.id === inputElement.id;
      }) ? (
        <i className="bi bi-check2-square" />
      ) : (
        <i className="bi bi-square" />
      )}
    </article>
  );
}
