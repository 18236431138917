import { useContext, useEffect, useState, useRef } from "react";

import MddButton from "../../../../../../components/reusableElements/MddButton";

export default function DatavizIconsSwitch({
  displayValue,
  label,
  input,
  path,
  updater,
  outputValue,
}) {
  const findingInitialPosition = () => {
    if (!input || outputValue.length === 0) {
      return 0;
    }
    let stockIndex = outputValue.findIndex((value) => {
      return value === input;
    });
    if (stockIndex === -1) {
      return 0;
    } else {
      return stockIndex;
    }
  };

  const [option, setOption] = useState(findingInitialPosition());

  const handlingUpdate = (indexInput) => {
    outputValue[indexInput] !== input && updater(path, outputValue[indexInput]);
  };

  return (
    <div className="datavizSwitch iconsSwitch">
      <label>{label}</label>
      <div style={{ width: `${displayValue.length * 2}rem` }}>
        <div
          className={`datavizSwitchBackground position${option}`}
          style={{ left: `${option * 2}rem` }}
        />
        {displayValue.map((element, index) => {
          return (
            <MddButton
              buttonSize="small"
              iconName={element}
              buttonActionFunctionOne={setOption}
              buttonActionPropOne={index}
              buttonActionFunctionTwo={handlingUpdate}
              buttonActionPropTwo={index}
              iconColor={
                option === index ? "activeAltButton" : "altButtonColor"
              }
            />
          );
        })}
      </div>
    </div>
  );
}
