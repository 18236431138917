import "../../styles/subscriptions/card.css";
import { useState, useEffect, useContext } from "react";
import { MddContext } from "../../context/MddContext";
import { useHistory } from "react-router-dom";

import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import dateParser from "../../utils/dateParser";
import statusLogoPicker from "../../utils/statusLogoPicker";
import { streamRegex } from "../../utils/streamStore/streamRegex";
import revertingToken from "../../utils/auth/revertingToken";

import MddButton from "../reusableElements/MddButton";
import ConfirmModal from "../reusableElements/ConfirmModal";
import SubscriptionInterfaces from "./SubscriptionInterfaces";

export default function SubscriptionCard({
  subscription,
  openedSubscriptions,
  handlingOpenedSubscriptions,
  retrievingSubscriptionsAPI,
  setDeletedSubscription,
}) {
  const {
    setActiveStream,
    confirmModal,
    setConfirmModal,
    userToken,
    closingSession,
    handling401,
    currentTeam,
    feedBackBuilder,
  } = useContext(MddContext);

  let history = useHistory();

  const [openedCard, setOpenedCard] = useState(false);
  const [subscriptionName, setSubscriptionName] = useState(subscription.name);
  const [subscriptionKey, setSubscriptionKey] = useState(subscription.key);    
  const [subscriptionDescription, setSubscriptionDescription] = useState(
    subscription.description
  );
  const [innerSubscription, setInnerSubscription] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subscriptionValidInputs, setSubscriptionValidInputs] = useState([
    "name",
    "description",
  ]);
  const [hoveredToken, setHoveredToken] = useState(false);

  const retrievingSingleSubscriptionAPI = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/subscription/${subscription.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          setInnerSubscription(informations);
          setSubscriptionName(informations.name);
          setSubscriptionKey(informations.key);
          setSubscriptionDescription(informations.description);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération de l'abonnement : ",
            err
          );
          handling401(err, history);
        });
    }
  };

  const retrievingParentStream = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/stream/${subscription.stream}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setActiveStream(stock);
          history.push(`/streamstore/${subscription.reference}`);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          handling401(err, history);
        });
    }
  };

  const classNameBuilder = () => {
    let builtClass = "card subscriptionCardContainer";
    if (openedSubscriptions.includes(subscription.id)) {
      builtClass = builtClass + " openedCard";
    }
    return builtClass;
  };

  const cleaningOnCollapse = () => {
    innerSubscription && setSubscriptionName(innerSubscription.name);
    innerSubscription &&
      setSubscriptionDescription(innerSubscription.description);
  };

  const handlingCopy = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(
          `/subscription`,
          {
            name: `copie de ${subscription.name}`,
            version: subscription.version,
            description: `copie de ${subscription.description}`,
            consumer: currentTeam,
          },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            setDeletedSubscription(true);
          } else {
            console.log(
              "erreur au niveau de la requete API de copie de version : ",
              response.data.error.message
            );
          }
          return response;
        })
        .then((response) => {
          if (response.data.data) {
            setDeletedSubscription(false);
            handlingOpenedSubscriptions(response.data.data.id);
            feedBackBuilder(true, `abonnement copié`, "Abonnements");
          } else {
            feedBackBuilder(
              false,
              `Un stream doit être publié pour pouvoir copier un abonnement associé`,
              "Abonnements"
            );
          }
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de création de l'abonnement : ",
            err
          );
          handling401(err, history, "Abonnement non copié");
        });
    }
  };

  const handlingEdit = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      if (subscriptionValidInputs.length === 2) {
        mddStreamStoreApi
          .put(
            `/subscription/${subscription.id}`,
            {
              name: subscriptionName,
              key: subscriptionKey,  
              description: subscriptionDescription,
            },
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            const informations = response.data.data;
            setInnerSubscription(informations);
            setSubscriptionName(informations.name);
            setSubscriptionKey(informations.key);
            setSubscriptionDescription(informations.description);
            feedBackBuilder(true, `abonnement modifié`, "Abonnements");
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de mise à jour de l'abonnement : ",
              err
            );
            handling401(err, history, "Abonnement non modifié");
          });
      } else {
        feedBackBuilder(
          false,
          `Le nom de l'abonnement doit comporter au moins 3 caractères`,
          "Abonnements"
        );
      }
    }
  };

  const handlingPause = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      if (innerSubscription && innerSubscription.state === "ACTIVE") {
        mddStreamStoreApi
          .post(
            `/subscription/pause/${subscription.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            const informations = response.data.data;
            setInnerSubscription(informations);
            setSubscriptionName(informations.name);
            setSubscriptionDescription(informations.description);
            feedBackBuilder(true, `abonnement mis en pause`, "Abonnements");
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de mise en pause de l'abonnement : ",
              err
            );
            handling401(err, history, "Echec dans la mise en pause");
          });
      } else {
        mddStreamStoreApi
          .post(
            `/subscription/resume/${subscription.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            const informations = response.data.data;
            setInnerSubscription(informations);
            setSubscriptionName(informations.name);
            setSubscriptionDescription(informations.description);
            feedBackBuilder(true, `abonnement relancé`, "Abonnements");
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de réactivation de l'abonnement : ",
              err
            );
            handling401(err, history, "Abonnement non relancé");
          });
      }
    }
  };

  const handlingUnsubscribe = () => {
    innerSubscription && setSubscriptionId(innerSubscription.id);
    innerSubscription && setConfirmModal(true);
  };
  const handleModalYes = (subId) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/subscription/${subId}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then(() => {
          setDeletedSubscription(true);
        })
        .then(() => {
          setDeletedSubscription(false);
          feedBackBuilder(true, `abonnement supprimé`, "Abonnements");
          retrievingSubscriptionsAPI();
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression de l'abonnement : ",
            err
          );
          handling401(err, history, "Abonnement non supprimé");
        });
    }
  };

  const handlingRenew = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(
          `/subscription/renew/${subscription.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          const informations = response.data.data;
          setInnerSubscription(informations);
          setSubscriptionName(informations.name);
          setSubscriptionDescription(informations.description);
          feedBackBuilder(true, `token renouvelé`, "Abonnements");
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de renouvellement du token de l'abonnement : ",
            err
          );
          handling401(err, history, "Token non renouvelé");
        });
    }
  };

  const checkingInputs = () => {
    let stockInputs = subscriptionValidInputs;
    const addValidInput = (inputName) => {
      if (
        !stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs.push(inputName);
        setSubscriptionValidInputs(stockInputs);
      }
    };
    const removeInvalidInput = (inputName) => {
      if (
        stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs = stockInputs.filter((element) => {
          return element !== inputName;
        });
        setSubscriptionValidInputs(stockInputs);
      }
    };
    if (
      streamRegex.name.test(subscriptionName) &&
      subscriptionName.length > 2 &&
      subscriptionName.length <= 100
    ) {
      addValidInput("name");
    } else {
      removeInvalidInput("name");
    }
    if (
      streamRegex.description.test(subscriptionDescription) &&
      subscriptionDescription.length <= 500
    ) {
      addValidInput("description");
    } else {
      removeInvalidInput("description");
    }
  };

  const parsingDisplayDates = (dateInput) => {
    if (dateInput) {
      let day = dateInput.slice(3, 5);
      let month = dateInput.slice(0, 2);
      let year = dateInput.slice(6, 10);
      return `${day}/${month}/${year}`;
    }
  };

  useEffect(() => {
    openedSubscriptions.includes(subscription.id) && setOpenedCard(true);
    !openedSubscriptions.includes(subscription.id) && setOpenedCard(false);
  }, [openedSubscriptions]);
  useEffect(() => {
    openedCard && retrievingSingleSubscriptionAPI();
    !openedCard && cleaningOnCollapse();
  }, [openedCard, subscription]);
  useEffect(() => {
    subscriptionName && checkingInputs();
  }, [subscriptionName, subscriptionKey, subscriptionDescription]);

  return (
    <div
      className={classNameBuilder()}
      onClick={() => {
        handlingOpenedSubscriptions(subscription.id);
      }}
    >
      <div>
        {innerSubscription
          ? statusLogoPicker(innerSubscription.status, innerSubscription)
          : statusLogoPicker(subscription.status, subscription)}
        <div>
          <h5>
            {innerSubscription ? innerSubscription.name : subscription.name}
          </h5>
          <p>
            {innerSubscription
              ? innerSubscription.reference
              : subscription.reference}
          </p>
          <p>
            {innerSubscription
              ? innerSubscription.consumer
              : subscription.consumer}
          </p>
          <p>
            {innerSubscription
              ? parsingDisplayDates(dateParser(innerSubscription.startAt))
              : parsingDisplayDates(dateParser(subscription.startAt))}
          </p>
          <p>
            {innerSubscription
              ? parsingDisplayDates(dateParser(innerSubscription.endAt))
              : parsingDisplayDates(dateParser(subscription.endAt))}
          </p>
        </div>
      </div>
      {openedSubscriptions.includes(subscription.id) && (
        <>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="labelsContainer">
              <label htmlFor="editSubscriptionName">Nom*</label>
              <label htmlFor="editSubscriptionKey">Clés associées</label>
              <label htmlFor="editSubscriptionDescription">Description</label>
              <label htmlFor="subscriptionToken">Token</label>
            </div>
            <div className="inputsContainer">
              <input
                type="text"
                name="editSubscriptionName"
                id="editSubscriptionName"
                className={
                  subscriptionValidInputs.some((element) => {
                    return element === "name";
                  })
                    ? ""
                    : "incorrectInput"
                }
                title={
                  subscriptionValidInputs.some((element) => {
                    return element === "name";
                  })
                    ? ""
                    : "Le nom doit comporter uniquement des lettres, des chiffres et des espaces. Il doit faire au moins trois caractères de long."
                }
                required
                value={subscriptionName}
                onChange={(e) => setSubscriptionName(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onBlur={() => {
                  handlingEdit();
                }}
                onKeyUp={(e) => {
                  (e.key === "Enter" || e.keyCode === 13) && handlingEdit();
                }}
              />
              <input
                type="text"
                name="editSubscriptionKey"
                id="editSubscriptionKey"
                className={
                  subscriptionValidInputs.some((element) => {
                    return element === "name";
                  })
                    ? ""
                    : "incorrectInput"
                }
                title={
                  subscriptionValidInputs.some((element) => {
                    return element === "name";
                  })
                    ? ""
                    : "Une liste clé=valeur,.. est attendue"
                }
                required
                value={subscriptionKey}
                onChange={(e) => setSubscriptionKey(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onBlur={() => {
                  handlingEdit();
                }}
                onKeyUp={(e) => {
                  (e.key === "Enter" || e.keyCode === 13) && handlingEdit();
                }}
              />
              <textarea
                type="text"
                name="editSubscriptionDescription"
                id="editSubscriptionDescription"
                className={
                  subscriptionValidInputs.some((element) => {
                    return element === "description";
                  })
                    ? ""
                    : "incorrectInput"
                }
                required
                value={subscriptionDescription}
                placeholder="description"
                onChange={(e) => setSubscriptionDescription(e.target.value)}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onBlur={() => {
                  handlingEdit();
                }}
              />
              <div>
                <input
                  type="text"
                  name="subscriptionToken"
                  id="subscriptionToken"
                  readOnly
                  value={
                    hoveredToken
                      ? innerSubscription
                        ? innerSubscription.token
                        : subscription.token
                      : "copier le token"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.target.select();
                    navigator.clipboard.writeText(
                      innerSubscription
                        ? innerSubscription.token
                        : subscription.token
                    );
                    feedBackBuilder(true, `token copié`, "Abonnements");
                  }}
                  onMouseEnter={() => {
                    setHoveredToken(true);
                  }}
                  onMouseLeave={() => {
                    setHoveredToken(false);
                  }}
                />
                <MddButton
                  iconName="bi bi-arrow-clockwise"
                  buttonSize="small"
                  smallButtonDescription="Renouveler le token"
                  iconColor="altButtonColor"
                  buttonActionFunctionOne={handlingRenew}
                />
              </div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MddButton
              buttonInnerText={
                innerSubscription
                  ? innerSubscription.reference
                  : subscription.reference
              }
              buttonSize="long"
              iconColor="altButtonColor"
              iconName="bi bi-wind"
              buttonActionFunctionOne={retrievingParentStream}
            />
            <div>
              {/* <MddButton
                iconColor="altButtonColor"
                buttonInnerText="Interfaces"
              /> */}
              <MddButton
                iconName="bi bi-files"
                iconColor="altButtonColor"
                buttonInnerText="Copier"
                buttonActionFunctionOne={handlingCopy}
                inactive={
                  innerSubscription
                    ? innerSubscription.status === "OFF"
                    : subscription.status === "OFF"
                }
                inactiveTitle="Un stream doit être publié pour pouvoir copier un abonnement associé"
              />
              <MddButton
                buttonInnerText={
                  innerSubscription
                    ? innerSubscription.state === "ACTIVE"
                      ? "Pause"
                      : "Réactiver"
                    : subscription.state === "ACTIVE"
                    ? "Pause"
                    : "Réactiver"
                }
                iconColor="altButtonColor"
                iconName={
                  innerSubscription
                    ? innerSubscription.state === "ACTIVE"
                      ? "bi bi-pause-circle"
                      : "bi bi-play-circle"
                    : subscription.state === "ACTIVE"
                    ? "bi bi-pause-circle"
                    : "bi bi-play-circle"
                }
                buttonActionFunctionOne={handlingPause}
              />
              <MddButton
                buttonInnerText="Résilier"
                iconColor="secondaryButton"
                buttonActionFunctionOne={handlingUnsubscribe}
              />
            </div>
          </div>
        </>
      )}
      {confirmModal && subscriptionId && (
        <ConfirmModal
          handleModalYes={handleModalYes}
          yesProp={subscriptionId}
          confirmMessage="Voulez-vous résilier cet abonnement ?"
          noText="annuler"
          yesText="résilier"
          confirmTitle="confirmation"
          confirmInfo={`L'abonnement ${subscriptionName} sera résilié définitivement`}
        />
      )}
      {/* {openedCard && <SubscriptionInterfaces />} */}
    </div>
  );
}
