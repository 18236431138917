import { useContext, useEffect, useState, useRef } from "react";

import "../../../style/sidebar/sidebar.css";

import MddButton from "../../../../components/reusableElements/MddButton";
import WidgetStyle from "./widgets/WidgetStyle";
import WidgetData from "./widgets/WidgetData";

export default function DatavizSidebar({
  datavizSidebar,
  setDatavizSidebar,
  dashboardData,
  deletingDashboard,
  updatingDashboard,
}) {
  const [widgetSidebarNav, setWidgetSidebarNav] = useState("style");

  const positioningToggle = () => {
    let stockFirst = document.getElementsByClassName(
      `switchButton-title-style`
    );
    let stockSecond = document.getElementsByClassName(
      `switchButton-title-data`
    );
    if (stockFirst.length > 0 && stockSecond.length > 0) {
      if (widgetSidebarNav === "data") {
        return {
          left: stockFirst[0].offsetWidth + "px",
          width: stockSecond[0].offsetWidth + "px",
        };
      } else if (widgetSidebarNav === "style") {
        return { left: "0", width: stockFirst[0].offsetWidth + "px" };
      } else {
        return { opacity: "0" };
      }
    }
  };

  const updatingWidget = (path, newValue) => {
    // let stockInfo = datavizSidebar.widget.map((item) =>
    //   Object.assign({}, item, { selected: false })
    // );
    let stockInfo = { ...datavizSidebar.widget, selected: false };
    if (path[8]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]] = {};
      }
      if (
        !stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ]
      ) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ] = {};
      }
      if (
        !stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ][path[7]]
      ) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ][path[7]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][path[6]][
        path[7]
      ][path[8]] = newValue;
    } else if (path[7]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]] = {};
      }
      if (
        !stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ]
      ) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][
          path[6]
        ] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][path[6]][
        path[7]
      ] = newValue;
    } else if (path[6]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]][path[6]] =
        newValue;
    } else if (path[5]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]][path[5]] =
        newValue;
    } else if (path[4]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]][path[3]]) {
        stockInfo[path[0]][path[1]][path[2]][path[3]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]][path[4]] = newValue;
    } else if (path[3]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      if (!stockInfo[path[0]][path[1]][path[2]]) {
        stockInfo[path[0]][path[1]][path[2]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]][path[3]] = newValue;
    } else if (path[2]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      if (!stockInfo[path[0]][path[1]]) {
        stockInfo[path[0]][path[1]] = {};
      }
      stockInfo[path[0]][path[1]][path[2]] = newValue;
    } else if (path[1]) {
      if (!stockInfo[path[0]]) {
        stockInfo[path[0]] = {};
      }
      stockInfo[path[0]][path[1]] = newValue;
    }
    updatingDashboard("widget", stockInfo);
  };

  return (
    <aside
      className={`datavizSidebar${
        datavizSidebar ? " openedDatavizSidebar" : ""
      }`}
      style={
        datavizSidebar && widgetSidebarNav === "data"
          ? { maxWidth: "50rem" }
          : {}
      }
    >
      {datavizSidebar && (
        <>
          <article>
            {datavizSidebar.type === "widget" ? (
              <div className="sidebarTitleToggle">
                <div
                  className={`titleToggleBackground ${
                    widgetSidebarNav === "style" ? "style" : "data"
                  }`}
                  style={positioningToggle()}
                />
                <MddButton
                  buttonInnerText="style"
                  buttonActionFunctionOne={setWidgetSidebarNav}
                  buttonActionPropOne="style"
                  iconColor={`${
                    widgetSidebarNav === "style"
                      ? "activeAltButton"
                      : "altButtonColor"
                  } switchButton-title-style`}
                />
                <MddButton
                  buttonInnerText="data"
                  buttonActionFunctionOne={setWidgetSidebarNav}
                  buttonActionPropOne="data"
                  iconColor={`${
                    widgetSidebarNav === "data"
                      ? "activeAltButton"
                      : "altButtonColor"
                  } switchButton-title-data`}
                />
              </div>
            ) : (
              <h4>{dashboardData.title}</h4>
            )}
            <MddButton
              iconName="bi bi-arrow-left"
              buttonSize="small"
              smallButtonDescription="Close sidebar"
              buttonActionFunctionOne={setDatavizSidebar}
              buttonActionPropOne={null}
              iconColor="basicButtonColor"
            />
          </article>
          {datavizSidebar.type === "widget" && widgetSidebarNav === "style" && (
            <WidgetStyle
              datavizSidebar={datavizSidebar}
              updatingWidget={updatingWidget}
            />
          )}
          {datavizSidebar.type === "widget" && widgetSidebarNav === "data" && (
            <WidgetData
              datavizSidebar={datavizSidebar}
              updatingWidget={updatingWidget}
            />
          )}
          {datavizSidebar.type === "dashboard" && (
            <>
              <article className="deleteDashboardContainer">
                <MddButton
                  buttonInnerText="supprimer"
                  buttonActionFunctionOne={deletingDashboard}
                  iconColor="basicButtonColor"
                />
              </article>
            </>
          )}
        </>
      )}
    </aside>
  );
}
