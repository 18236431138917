import { useState, useEffect, useRef, useContext } from "react";

import { MddContext } from "../../../../context/MddContext";
import "../../../../styles/streamStore/detailedStream/timelines.css";

import MddButton from "../../../reusableElements/MddButton";
import SingleVersion from "./SingleVersion";

export default function VersionPicker({
  streamName,
  allVersions,
  setStreamVersion,
  existingVersion,
  streamVersion,
  setOpenedModal,
  setVersionDescription,
  changingActiveVersion,
  versionDescription,
  checkingEditedStatus,
  deletingVersion,
  streamValidInputs,
  creatingVersion,
}) {
  const inputRef = useRef(null);
  const descriptionRef = useRef(null);
  const { activeStream, currentTeam, activeVersion } = useContext(MddContext);

  const [modifiedVersion, setModifiedVersion] = useState(false);

  function setHeight() {
    let stockList = document.getElementsByClassName("versionDescriptionForm");
    for (let i = 0; i < stockList.length; i++) {
      stockList[i].style.height = stockList[i].scrollHeight + "px";
    }
  }

  const returningVersions = () => {
    let stockVersions = [];
    for (let i = 0; i < allVersions.length; i++) {
      stockVersions.push(
        <SingleVersion
          key={`version ${allVersions[i].id}`}
          version={allVersions[i]}
          changingActiveVersion={changingActiveVersion}
          setModifiedVersion={setModifiedVersion}
          deletingVersion={deletingVersion}
          streamValidInputs={streamValidInputs}
          versionDescription={versionDescription}
          setVersionDescription={setVersionDescription}
          checkingEditedStatus={checkingEditedStatus}
        />
      );
    }
    return stockVersions;
  };

  useEffect(() => {
    setHeight();
  });

  useEffect(() => {
    allVersions.length === 0 && inputRef.current && inputRef.current.focus();
  }, [allVersions, inputRef.current]);

  return (
    <section className="versionPickerContainer">
      <div>
        <article
          className={
            activeStream.provider === currentTeam || currentTeam === "all"
              ? "topVersionPickerContainer"
              : "topVersionPickerContainer nonEditingTopContainer"
          }
        >
          <p>
            {activeStream.provider === currentTeam || currentTeam === "all"
              ? `${streamName}: Sélectionner ou créer une version`
              : `${streamName}: Sélectionner une version`}
          </p>
          {(activeStream.provider === currentTeam || currentTeam === "all") && (
            <form
              name="modifyVersion"
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              onClick={() => {
                changingActiveVersion();
              }}
            >
              <input
                type="text"
                id="versionNumber"
                ref={inputRef}
                className={
                  !streamValidInputs.some((element) => {
                    return element === "versionNumber";
                  }) &&
                  inputRef.current &&
                  inputRef.current.value.length > 0
                    ? "incorrectInput"
                    : ""
                }
                title={
                  streamValidInputs.some((element) => {
                    return element === "versionNumber";
                  }) || "Le numéro de version doit être au format 'X.Y.Z'"
                }
                placeholder="X.Y.Z"
                required
                defaultValue={!existingVersion ? streamVersion : ""}
                onChange={(e) => {
                  setStreamVersion(e.target.value);
                  existingVersion && changingActiveVersion();
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    creatingVersion();
                    setModifiedVersion(true);
                    e.target.value = "";
                    if (descriptionRef.current) {
                      descriptionRef.current.value = "";
                    }
                  }
                }}
              />
              <input
                type="text"
                id="versionDescription"
                className="versionDescription"
                ref={descriptionRef}
                placeholder="description"
                required
                value={!existingVersion ? versionDescription : ""}
                onChange={(e) => {
                  setVersionDescription(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    creatingVersion();
                    setModifiedVersion(true);
                    e.target.value = "";
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  creatingVersion();
                  setModifiedVersion(true);
                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                  if (descriptionRef.current) {
                    descriptionRef.current.value = "";
                  }
                }}
              >
                <i className="bi bi-plus-lg" style={{ fontSize: "1rem" }} />
              </button>
            </form>
          )}
        </article>
        {allVersions && allVersions.length > 0 && (
          <div>
            <article className="versionPickerTimeLine">
              {returningVersions()}
            </article>
          </div>
        )}
        <article>
          <MddButton
            buttonInnerText={modifiedVersion ? "ok" : "retour"}
            buttonActionFunctionOne={setOpenedModal}
            buttonActionPropOne={false}
          />
        </article>
      </div>
    </section>
  );
}
