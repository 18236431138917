import { useEffect, useState, useContext, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";

import { MddContext } from "../../../../context/MddContext";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function LinkedStreamCard({ stream, status }) {
  const { activeLinked, setReverseActive, setActiveStream, setActiveVersion } =
    useContext(MddContext);
  let history = useHistory();

  const [selectedLink, setSelectedLink] = useState(false);

  const redirectingToLinked = () => {
    setActiveStream(null);
    setActiveVersion(null);
    history.push(`/streamstore/${stream.reference}#general`);
  };

  const returningStreamNumber = () => {
    if (stream.reference) {
        let stockRef = stream.reference.split("-");
        return stockRef[stockRef.length - 1];
    }
    return "";
  };

  useEffect(() => {
    let ref = activeLinked.filter(link => link.reference===stream.reference );
   
    if (ref.length > 0) {
      setSelectedLink(true);
    } else {
      setSelectedLink(false);
    }
      
  }, [activeLinked, stream]);

  //console.log("linked stream card : ", stream);

  return (
    <div
      className={`card streamCardContainer${
        selectedLink ? " activeStreamCard" : ""
      }`}
      onClick={() => {
        redirectingToLinked();
      }}
      onMouseHover={() => {
        setReverseActive(stream);
      }}
      onMouseLeave={() => {
        setReverseActive(null);
      }}
    >
      <div className="topCardContainer">
        {stream.image ? (
          <img
            src={`${REACT_APP_BASE_URL}/assets${stream.image}`}
            alt="Illustration du stream"
          />
        ) : (
          <i className="bi bi-wind" />
        )}
      </div>
      <p className="card-title">{stream.name}</p>
      <div>
        <div className={`PlaceHolder`} />
        <p>{returningStreamNumber()}</p>
        <i
          className={
            status === "linked" ? "bi bi-diagram-3-fill" : "bi bi-server"
          }
        />
        <div className={`PlaceHolder`} />
      </div>
    </div>
  );
}
