import MddButton from "../reusableElements/MddButton";

export default function SynchronizeExport({
  handlingSync,
  syncUrl,
  syncToken,
  setSyncToken,
  setSyncUrl,
  setExportNav,
}) {
  return (
    <form
      className="syncContainer"
      onSubmit={(e) => {
        e.preventDefault();
        handlingSync();
      }}
    >
      <h5>Synchroniser</h5>
      <input
        type="text"
        id="urlForm"
        placeholder="URL de la plateforme cible"
        required
        value={syncUrl}
        onChange={(e) => setSyncUrl(e.target.value)}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            syncUrl !== "" &&
            syncToken !== "" &&
            handlingSync();
        }}
      />
      <input
        type="text"
        id="tokenForm"
        placeholder="token"
        required
        value={syncToken}
        onChange={(e) => setSyncToken(e.target.value)}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            syncUrl !== "" &&
            syncToken !== "" &&
            handlingSync();
        }}
      />
      <div>
        <MddButton
          buttonInnerText="retour"
          buttonActionFunctionOne={setExportNav}
          buttonActionPropOne={"confirmation"}
          iconColor="secondaryButton"
        />
        <MddButton
          buttonInnerText="Synchroniser"
          buttonActionFunctionOne={handlingSync}
        />
      </div>
    </form>
  );
}
