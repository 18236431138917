export default function HotelCard({ hotel, setHotelsDetails }) {
  const buildingRooms = () => {
    let stockRooms = hotel.rooms.filter((room) => {
      return room.type === "bedroom";
    });
    return (
      <p>{`${stockRooms.length} chambre${stockRooms.length > 1 ? "s" : ""}`}</p>
    );
  };

  const buildingErrors = () => {
    let stockRooms = hotel.rooms.filter((room) => {
      return room.error === true;
    });
    return stockRooms.length > 0 ? <p>{stockRooms.length}</p> : <></>;
  };

  return (
    <article
      className="hotelCard"
      onClick={() => {
        setHotelsDetails(hotel);
      }}
    >
      <h3>{hotel.name.toUpperCase()}</h3>
      <div>
        <div>{buildingErrors()}</div>
        <p>{hotel.city}</p>
        <div>
          <p>{hotel.temperature}°</p>
          <i class="bi bi-sun-fill" />
        </div>
      </div>
      {buildingRooms()}
    </article>
  );
}
