import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";

import HomeCard from "../../components/home/HomeCard";
import Dashboard from "../../components/Dashboard";

import "../../styles/home/dashboard.css";

export default function Home() {
  const {
    setShowSideBar,
    setFullSideBar,
    setFullScreen,
    tokenLimit,
    currentUser,
    userToken,
    cleaningContext,
  } = useContext(MddContext);
  let history = useHistory();

  useEffect(() => {
    let stockDate = new Date();
    if (
      stockDate.getTime() > tokenLimit ||
      !currentUser ||
      !userToken ||
      userToken.length === 0
    ) {
      cleaningContext();
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);
  return (
    <Dashboard containerClass="homeCardsContainer">
      <HomeCard
        adress="streamstore"
        name="Streams store"
        text={[
          "Créez des apis, microservices, data streams, analytics...",
          "Gérez les versions et supervisez vos streams.",
        ]}
        iconName="bi bi-wind"
        access={1}
      />
      <HomeCard
        adress="subscriptions"
        name="Abonnements"
        text={[
          "Gérez vos abonnements.",
          "Activez, renouvelez ou révoquez un token d'accès, associez un contexte d'exécution client.",
        ]}
        iconName="bi bi-bag-check"
        access={1}
      />
      {
      <HomeCard
        adress="dataviz"
        name="Dataviz"
        text={[
          "Préparez efficacement vos visualisations de data.",
          "Améliorez la communication avec vos partenaires.",
        ]}
        iconName="bi bi-clipboard-data"
        access={1}
      />
       }
      <HomeCard
        adress="export"
        name="Exports"
        text={[
          "Transférez vos flux vers un autre espace de travail.",
          "Synchronisez vos différents espaces.",
        ]}
        iconName="bi bi-share"
        access={2}
      />
      <HomeCard
        adress="users"
        name="Utilisateurs"
        text={[
          "Gérez les utilisateurs ayant accès à la plateforme.",
          "Invitez vos nouveaux collaborateurs.",
        ]}
        iconName="bi bi-people"
        access={3}
      />
      {/*
      <HomeCard
        adress="account/manager"
        name="Espace client"
        text={[
          "Gérez votre compte client.",
          "Accédez aux distributions, à vos licences et demandes de support",
        ]}
        iconName="bi bi-people"
        access={3}
      />
      
      <HomeCard
        adress="variables"
        name="Variables"
        text={[
          "Paramétrez vos variables d'environnement.",
          "Gérez vos variables de niveaux app et stream.",
        ]}
        iconName="bi bi-braces"
        access={3}
      />
      */}
    </Dashboard>
  );
}
