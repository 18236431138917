import { useState, useEffect } from "react";

import MddButton from "../reusableElements/MddButton";
export default function TeamsDropdown({
  userTeams,
  setUserTeams,
  usersTeams,
  setCreatingTeam,
}) {
  const [openedTeams, setOpenedTeams] = useState(false);

  const handlingTeamsChange = (inputTeam) => {
    if (userTeams.includes(inputTeam)) {
      let stockTeams = [...userTeams];
      stockTeams.splice(
        stockTeams.findIndex((team) => {
          return team === inputTeam;
        }, 1)
      );
      setUserTeams([...stockTeams]);
    } else {
      let stockTeams = [...userTeams, inputTeam];
      setUserTeams([...stockTeams]);
    }
  };

  const removeDropdown = (e) => {
    if (
      e.target.getAttribute("class") &&
      e.target.getAttribute("class") === "selectTeamsContainer"
    ) {
      return;
    } else {
      setOpenedTeams(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", removeDropdown);
    return function exitEdit() {
      document.removeEventListener("click", removeDropdown);
    };
  }, []);

  return (
    <div
      className={
        openedTeams
          ? "selectTeamsContainer openedSelect"
          : "selectTeamsContainer"
      }
    >
      <MddButton
        buttonInnerText={
          userTeams && userTeams.length > 0
            ? userTeams.join(", ").length > 17
              ? `${userTeams.join(", ").slice(0, 17)}...`
              : userTeams.join(", ")
            : "choisir les équipes"
        }
        iconName="bi bi-caret-down-fill"
        buttonActionFunctionOne={setOpenedTeams}
        buttonActionPropOne={!openedTeams}
        iconColor="altButtonColor"
        noPropagation
        smallIcon
      />
      {openedTeams && (
        <div>
          <MddButton
            buttonInnerText="ajouter une équipe"
            buttonActionFunctionOne={setCreatingTeam}
            buttonActionPropOne={true}
            iconColor="basicButtonColor"
            noPropagation
          />
          {usersTeams.map((team, index) => {
            return (
              <div key={index}>
                <MddButton
                  buttonInnerText={team}
                  iconName={userTeams.includes(team) ? "bi bi-check" : null}
                  buttonActionFunctionOne={handlingTeamsChange}
                  buttonActionPropOne={team}
                  iconColor={userTeams.includes(team) ? "" : "altButtonColor"}
                  noPropagation
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
