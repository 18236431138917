import { useContext, useEffect, useState, useRef } from "react";

import { MddContext } from "../../../context/MddContext";
import "../../style/dashboard/widget.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BubbleController,
  PieController,
  RadarController,
  DoughnutController,
  PolarAreaController,
  ArcElement,
  RadialLinearScale,
  Filler,
} from "chart.js";
import {
  Line,
  Bar,
  Bubble,
  Pie,
  Doughnut,
  Radar,
  PolarArea,
} from "react-chartjs-2";
import returningInputWidth from "../../utils/returningInputWIdth";

import MddButton from "../../../components/reusableElements/MddButton";
import MddLoader from "../../../components/reusableElements/MddLoader";

// let pos = {
//   x0: 1,
//   x1: 11,
//   y0: 1,
//   y1: 8,
// };

export default function DataWidget({
  number,
  widget,
  // datavizSidebar,
  setDatavizSidebar,
  updatingDashboard,
  editedWidget,
  // setEditedWidget,
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    BarElement,
    BubbleController,
    PieController,
    RadarController,
    DoughnutController,
    PolarAreaController,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler
  );

  const { appRem } = useContext(MddContext);

  const widgetRef = useRef(null);
  const posRef = useRef({ x0: 1, x1: 11, y0: 1, y1: 8 });

  const [widgetPosition, setWidgetPosition] = useState(
    widget.position
      ? { ...widget.position }
      : {
          x0: 1,
          x1: 11,
          y0: 1,
          y1: 8,
        }
  );
  const [dragStart, setDragStart] = useState({ x: null, y: null });
  const [isSafari, setIsSafari] = useState(false);
  const [widgetData, setWidgetData] = useState(widget ? widget : {});
  const [widgetTitle, setWidgetTitle] = useState(
    widget.title ? widget.title : "Entrer un titre"
  );
  const [fullScreenWidget, setFullScreenWidget] = useState(false);
  const [widgetSettings, setWidgetSettings] = useState(
    widget.component.settings ? widget.component.settings : {}
  );
  const [widgetConfig, setWidgetConfig] = useState(
    widget.component.config ? widget.component.config : {}
  );
  const [updateCount, setUpdateCount] = useState(0);
  const [deletingWidget, setDeletingWidget] = useState(false);

  const updatingPosition = (positionInput) => {
    let stockData = { ...widgetData };
    stockData.position = positionInput;
    return stockData;
  };

  const handlingDragStart = (e) => {
    setDragStart({ x: e.pageX, y: e.pageY });
  };
  const handlingDragOn = (e) => {
    let deltaX = e.pageX - dragStart.x;
    let deltaY = e.pageY - dragStart.y - 0;
    deltaX = Math.round(deltaX / (appRem * 2));
    deltaY = Math.round(deltaY / (appRem * 2));

    let x = widgetPosition.x0 + deltaX;
    let y = widgetPosition.y0 + deltaY;
    // console.log(`onDrag mouse ${e.pageX} ${e.pageY}`);
    if (e.pageX !== 0) {
      setIsSafari(true);
    }
    posRef.current.x0 = x;
    posRef.current.y0 = y;
    posRef.current.x1 =
      x +
      Math.round(
        getComputedStyle(widgetRef.current, null)
          .getPropertyValue("width")
          .replace("px", "") /
          (appRem * 2)
      );
    posRef.current.y1 =
      y +
      Math.round(
        getComputedStyle(widgetRef.current, null)
          .getPropertyValue("height")
          .replace("px", "") /
          (appRem * 2)
      );
  };

  const handlingDragEnd = (e) => {
    if (isSafari) {
      setWidgetPosition({
        x0: posRef.current.x0,
        x1: posRef.current.x1,
        y0: posRef.current.y0,
        y1: posRef.current.y1,
        // width: [pos.x0, pos.x1],
        // height: [pos.y0, pos.y1],
      });
      updatingDashboard(
        "widget",
        updatingPosition({
          x0: posRef.current.x0,
          x1: posRef.current.x1,
          y0: posRef.current.y0,
          y1: posRef.current.y1,
        })
      );
    } else {
      let deltaX = e.pageX - dragStart.x;
      let deltaY = e.pageY - dragStart.y;
      deltaX = Math.round(deltaX / (appRem * 2));
      deltaY = Math.round(deltaY / (appRem * 2));
      const x0 = widgetPosition.x0 + deltaX;
      const y0 = widgetPosition.y0 + deltaY;
      const x1 = widgetPosition.x1 + deltaX;
      const y1 = widgetPosition.y1 + deltaY;
      setWidgetPosition({
        x0: x0,
        x1: x1,
        y0: y0,
        y1: y1,
        // width: [x0, x1],
        // height: [y0, y1],
      });
      updatingDashboard(
        "widget",
        updatingPosition({
          x0: x0,
          x1: x1,
          y0: y0,
          y1: y1,
        })
      );
      setDragStart({ x: null, y: null });
    }
  };

  const handlingResize = (e) => {
    e.preventDefault();
    if (widgetRef.current) {
      const minimum_size = 100;
      let original_width = parseFloat(
        getComputedStyle(widgetRef.current, null)
          .getPropertyValue("width")
          .replace("px", "")
      );
      let original_height = parseFloat(
        getComputedStyle(widgetRef.current, null)
          .getPropertyValue("height")
          .replace("px", "")
      );
      let original_mouse_x = e.pageX;
      let original_mouse_y = e.pageY;
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", stopResize);

      function resize(e) {
        const width = original_width + (e.pageX - original_mouse_x);
        const height = original_height + (e.pageY - original_mouse_y);
        if (width > minimum_size) {
          widgetRef.current.style.width = width + "px";
          widgetRef.current.style.gridColumn = `${widgetPosition.x0} / ${
            widgetPosition.x0 + Math.round(width / (appRem * 2))
          }`;
        }
        if (height > minimum_size) {
          widgetRef.current.style.height = height + "px";
          widgetRef.current.style.gridRow = `${widgetPosition.y0} / ${
            widgetPosition.y0 + Math.round(height / (appRem * 2))
          }`;
        }
      }

      function stopResize() {
        widgetRef.current.style.width =
          Math.round(
            getComputedStyle(widgetRef.current, null)
              .getPropertyValue("width")
              .replace("px", "") /
              (appRem * 2)
          ) *
            (appRem * 2) -
          appRem +
          "px";
        widgetRef.current.style.gridColumn = `${widgetPosition.x0} / ${
          widgetPosition.x0 +
          Math.round(
            getComputedStyle(widgetRef.current, null)
              .getPropertyValue("width")
              .replace("px", "") /
              (appRem * 2)
          )
        }`;
        widgetRef.current.style.height =
          Math.round(
            getComputedStyle(widgetRef.current, null)
              .getPropertyValue("height")
              .replace("px", "") /
              (appRem * 2)
          ) *
            (appRem * 2) -
          appRem +
          "px";
        widgetRef.current.style.gridRow = `${widgetPosition.y0} / ${
          widgetPosition.y0 +
          Math.round(
            getComputedStyle(widgetRef.current, null)
              .getPropertyValue("height")
              .replace("px", "") /
              (appRem * 2)
          )
        }`;
        setWidgetPosition({
          x0: widgetPosition.x0,
          x1:
            widgetPosition.x0 +
            Math.round(
              getComputedStyle(widgetRef.current, null)
                .getPropertyValue("width")
                .replace("px", "") /
                (appRem * 2)
            ),
          y0: widgetPosition.y0,
          y1:
            widgetPosition.y0 +
            Math.round(
              getComputedStyle(widgetRef.current, null)
                .getPropertyValue("height")
                .replace("px", "") /
                (appRem * 2)
            ),
        });
        updatingDashboard(
          "widget",
          updatingPosition({
            x0: widgetPosition.x0,
            x1:
              widgetPosition.x0 +
              Math.round(
                getComputedStyle(widgetRef.current, null)
                  .getPropertyValue("width")
                  .replace("px", "") /
                  (appRem * 2)
              ),
            y0: widgetPosition.y0,
            y1:
              widgetPosition.y0 +
              Math.round(
                getComputedStyle(widgetRef.current, null)
                  .getPropertyValue("height")
                  .replace("px", "") /
                  (appRem * 2)
              ),
          })
        );
        window.removeEventListener("mousemove", resize);
        window.removeEventListener("mouseup", stopResize);
      }
    }
  };

  const buildingStyle = () => {
    let stockStyle = {
      gridColumn: `${widgetPosition.x0} / ${widgetPosition.x1}`,
      gridRow: `${widgetPosition.y0} / ${widgetPosition.y1}`,
      width: `${(widgetPosition.x1 - widgetPosition.x0) * 2 - 1}rem`,
      height: `${(widgetPosition.y1 - widgetPosition.y0) * 2 - 1}rem`,
    };
    if (fullScreenWidget) {
      stockStyle.width = "100vw";
      stockStyle.height = "calc(100vh - 4rem)";
    }
    if (deletingWidget) {
      stockStyle.opacity = "0";
    }
    return stockStyle;
  };

  const updatingTitle = () => {
    let stockData = { ...widgetData };
    stockData.title = widgetTitle;
    return stockData;
  };

  const handlingDelete = () => {
    setDeletingWidget(true);
    updatingDashboard("deletingWidget", widgetData);
  };

  useEffect(() => {
    if (widget && editedWidget !== widget.index) {
      setWidgetData(widget);
      widget.title && setWidgetTitle(widget.title);
      widget.component.settings && setWidgetSettings(widget.component.settings);
      widget.component.config && setWidgetConfig(widget.component.config);
    }
  }, [editedWidget]);

  return (
    <article
      className={`dataWidget${fullScreenWidget ? " fullScreenDataWidget" : ""}`}
      id={`datawidget - ${number}`}
      style={buildingStyle()}
      ref={widgetRef}
      draggable
      onDragStart={(e) => {
        handlingDragStart(e);
      }}
      onDragEnd={(e) => {
        handlingDragEnd(e);
      }}
      onDrag={(e) => {
        handlingDragOn(e);
      }}
    >
      {editedWidget !== widget.index && (
        <>
          <div>
            <input
              type="text"
              placeholder="titre"
              value={widgetTitle}
              onChange={(e) => {
                setWidgetTitle(e.target.value);
              }}
              onBlur={() => {
                updatingDashboard("widget", updatingTitle());
              }}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) &&
                  updatingDashboard("widget", updatingTitle());
              }}
              style={{
                width: returningInputWidth(widgetTitle, true) + "rem",
                // marginLeft: `calc(50% - ${
                //   returningInputWidth(widgetTitle, true) / 2
                // }rem)`,
              }}
            />
            <h6 style={widget.style.title}>{widgetTitle}</h6>
            <div>
              <MddButton
                iconName="bi bi-tools"
                buttonSize="small"
                smallButtonDescription="widget options"
                iconColor="altButtonColor"
                noPropagation
                buttonActionFunctionOne={setDatavizSidebar}
                buttonActionPropOne={{
                  type: "widget",
                  widget: widget,
                }}
              />
              <MddButton
                iconName="bi bi-aspect-ratio"
                buttonSize="small"
                smallButtonDescription="fullscreen mode"
                iconColor="altButtonColor"
                noPropagation
                buttonActionFunctionOne={setFullScreenWidget}
                buttonActionPropOne={!fullScreenWidget}
              />
              <MddButton
                iconName="bi bi-x-lg"
                buttonSize="small"
                smallButtonDescription="delete widget"
                iconColor="altButtonColor"
                noPropagation
                buttonActionFunctionOne={handlingDelete}
              />
            </div>
          </div>
          {widget.type === "line" && (
            <Line options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "bar" && (
            <Bar options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "bubble" && (
            <Bubble options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "pie" && (
            <Pie options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "radar" && (
            <Radar options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "doughnut" && (
            <Doughnut options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "polarArea" && (
            <PolarArea options={widgetConfig} data={widgetSettings} />
          )}
          {widget.type === "text" && <input type="text" />}
          <MddButton
            iconName="bi bi-filter"
            buttonSize="small"
            smallButtonDescription="resize widget"
            iconColor="altButtonColor"
            smallIcon
            mousedown
            noPropagation
            buttonActionFunctionTwo={handlingResize}
          />
        </>
      )}
    </article>
  );
}
