import { useEffect, useState, useContext } from "react";

import { MddContext } from "../../../../context/MddContext";
import "../../../../styles/streamStore/detailedStream/editDocs.css";

import MddButton from "../../../reusableElements/MddButton";
import MddCustomInput from "../../../reusableElements/MddCustomInput";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function EditDocs({
  versionDocs,
  deletingDocs,
  docsUploadFiles,
  setDocsUploadFiles,
  docsFilesInputs,
  setDocsFilesInputs,
  docsFilesCounter,
  setDocsFilesCounter,
}) {
  const { activeStream, currentTeam } = useContext(MddContext);

  const [existingDocs, setExistingDocs] = useState([]);
  const [sizesSum, setSizesSum] = useState(0);

  const returningExistingDocs = () => {
    let stockDocs = [];
    for (let i = 0; i < versionDocs.length; i++) {
      stockDocs.push(
        <div key={`existing document ${versionDocs[i].id}`}>
          <a
            href={`${REACT_APP_BASE_URL}/assets${versionDocs[i].url}`}
            download
            title={`télécharger ${versionDocs[i].filename}`}
            target="_blank"
            rel="noreferrer"
          >
            {versionDocs[i].filename}
          </a>
          {activeStream.editable &&
            (activeStream.provider === currentTeam ||
              currentTeam === "all") && (
              <MddButton
                iconName="bi-x"
                buttonSize="small"
                smallButtonDescription="Delete document"
                buttonActionFunctionOne={deletingDocs}
                buttonActionPropOne={versionDocs[i].id}
                iconColor="altButtonColor"
              />
            )}
        </div>
      );
    }
    setExistingDocs(stockDocs);
  };

  const returningInputs = () => {
    let stockInputs = [];
    for (let i = 0; i < docsFilesCounter; i++) {
      stockInputs.push(
        <MddCustomInput
          key={`custom input ${i}`}
          setUpperLevelFile={handlingInputChange}
          lastInput={i === docsFilesCounter - 1}
        />
      );
    }
    setDocsFilesInputs(stockInputs);
  };
  const handlingInputChange = (inputValue, lastInput) => {
    if (inputValue !== null) {
      lastInput && setDocsFilesCounter(docsFilesCounter + 1);
      let stockFiles = docsUploadFiles;
      stockFiles.push(inputValue);
      setDocsUploadFiles(stockFiles);
      setSizesSum(sizesSum + inputValue.size);
    }
  };

  function setLabelsHeight() {
    let stockInputs = document.getElementsByClassName("inputsContainer");
    let stockLabels = document.getElementsByClassName("labelsContainer");
    stockLabels[0].style.height = stockInputs[0].scrollHeight + "px";
  }

  useEffect(() => {
    if (docsFilesCounter === 0) {
      setDocsFilesCounter(1);
    }
    returningInputs();
  }, [docsFilesCounter]);

  useEffect(() => {
    versionDocs.length > 0 && returningExistingDocs();
  }, [versionDocs]);

  useEffect(() => {
    setLabelsHeight();
  });

  return (
    <div className="docsGlobalContainer">
      <section className="docsImportContainer">
        <div className="labelsContainer">
          <p>Documentations</p>
        </div>
        <div className="inputsContainer" id="inputsContainer">
          {versionDocs.length > 0 && existingDocs}
          {versionDocs.length === 0 && <p>Aucun document enregistré</p>}
          {activeStream.editable &&
            (activeStream.provider === currentTeam || currentTeam === "all") &&
            docsFilesInputs}
        </div>
      </section>
    </div>
  );
}
