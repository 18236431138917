export default function sumParser(sizesSum) {
  if (sizesSum === 0) {
    return "Aucun fichier sélectionné";
  } else if (sizesSum < 1000) {
    return `${sizesSum} octets`;
  } else if (sizesSum < 1000000) {
    return `${Math.round(sizesSum / 1000)} Ko`;
  } else if (sizesSum < 1000000000) {
    return `${Math.round(sizesSum / 1000000)} Mo`;
  } else {
    return `${Math.round(sizesSum / 1000000000)} Go`;
  }
}
