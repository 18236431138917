export default function returningRandomData(threeDimensions, type) {
  let stockData = [];
  if (threeDimensions) {
    for (let i = 1; i < 51; i++) {
      stockData.push({
        x: i,
        y: Math.floor(Math.random() * (100 + 100)) - 100,
        r: Math.floor(Math.random() * (10 - 1)) + 1,
      });
    }
  } else if (!threeDimensions && type === "pie") {
    for (let i = 1; i < 11; i++) {
      stockData.push({
        id: i,
        nested: { value: Math.floor(Math.random() * (100 - 1)) + 1 },
      });
    }
  } else if (!threeDimensions && type === "radar") {
    for (let i = 1; i < 11; i++) {
      stockData.push(Math.floor(Math.random() * (100 - 1)) + 1);
    }
  } else {
    for (let i = 1; i < 51; i++) {
      stockData.push({
        x: `${i}`,
        y: Math.floor(Math.random() * (100 + 100)) - 100,
      });
    }
  }
  return stockData;
}
