import MddButton from "../../../../../components/reusableElements/MddButton";
import RequestData from "./categories/RequestData";

import "../../../../style/sidebar/dataSidebar.css";

export default function WidgetData({ datavizSidebar, updatingWidget }) {
  return (
    <>
      <MddButton
        buttonInnerText="nouvelle requête"
        buttonSize="long"
        //   buttonActionFunctionOne={deletingDashboard}
        iconColor="basicButtonColor"
      />
      <RequestData
        name="requête"
        datavizSidebar={datavizSidebar}
        updatingWidget={updatingWidget}
      />
    </>
  );
}
