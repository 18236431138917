import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import "../../styles/challengeModal.css";
import mddAuth from "../../api/mddAuth";
import changingToken from "../../utils/auth/changingToken";

import MddButton from "../reusableElements/MddButton";

export default function ChallengeModal() {
  const {
    challengeModal,
    setChallengeModal,
    setCurrentUser,
    setUserToken,
    setTokenLimit,
    setCurrentSolution,
    setUserRole,
    cleaningContext,
    feedBackBuilder,
    succesfullChallenge,
    setSuccessfullChallenge,
    // challengedCall,
    // setChallengedCall,
  } = useContext(MddContext);
  let history = useHistory();
  let { pathname } = useLocation();

  const [userOtherValue, setUserOtherValue] = useState("");
  const [userValue, setUserValue] = useState("");
  const [incorrectInputs, setIncorrectInputs] = useState(false);
  const [challengeTimeout, setChallengeTimeout] = useState(null);
  // const [succesfullChallenge, setSuccessfullChallenge] = useState(false);

  const encodingToBase64 = (str) => {
    if (str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
    return window.btoa(unescape(encodeURIComponent("")));
  };

  const decodingToken = (tokenInput) => {
    let base64Url = tokenInput.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const handlingLogin = () => {
    setIncorrectInputs(false);
    mddAuth
      .post(
        `/oauth2/login`,
        {},
        {
          headers: {
            Authorization: `Basic ${encodingToBase64(
              userOtherValue + ":" + userValue
            )}`,
          },
        }
      )
      .then((response) => {
        setCurrentSolution(response.data.solution);
        setUserToken(changingToken(response.data.data.token));
        let stockUser = decodingToken(response.data.data.token);
        setTokenLimit(stockUser.exp);
        setCurrentUser(stockUser.sub);
        setUserRole(stockUser.aud);
        feedBackBuilder(true, "Vous êtes connecté", "Profil");
        setSuccessfullChallenge(true);
        // setChallengeModal(false);
        // setChallengeTimeout(null);
        // setIncorrectInputs(false);
        // setUserOtherValue("");
        // setUserValue("");
        // history.push(pathname);
        // history.go(0);
      })
      .catch((err) => {
        console.log("erreur au niveau de la requête : ", err);
        if (err && err.response && err.response.status === 401) {
          setIncorrectInputs(true);
          feedBackBuilder(
            false,
            `Identifiant ou mot de passe erroné`,
            "Profil"
          );
        } else {
          feedBackBuilder(false, `La connexion a echoué`, "Profil");
        }
      });
  };
  const loggingOut = () => {
    feedBackBuilder(true, "Vous êtes déconnecté", "Profil");
    cleaningContext();
    setChallengeTimeout(null);
    setIncorrectInputs(false);
    setUserOtherValue("");
    setUserValue("");
    history.push(`/login`);
  };

  const timeoutCheck = () => {
    let stockDate = null;
    const checker = () => {
      stockDate = new Date();
      if (stockDate && challengeTimeout && stockDate >= challengeTimeout) {
        clearInterval(timer);
        loggingOut();
      } else if (!challengeTimeout) {
        clearInterval(timer);
      }
    };
    let timer = setInterval(checker, 5000);
  };

  useEffect(() => {
    if (succesfullChallenge) {
      setChallengeModal(false);
      setChallengeTimeout(null);
      setIncorrectInputs(false);
      setUserOtherValue("");
      setUserValue("");
      setSuccessfullChallenge(false);
      // console.log("challenge modal : ", challengedCall);
      // challengedCall && challengedCall();
      // history.push(pathname);
    }
  }, [succesfullChallenge]);

  // useEffect(() => {
  //   if (challengeModal && !challengeTimeout) {
  //     let stockDate = new Date();
  //     setChallengeTimeout(new Date(stockDate.getTime() + 3 * 60000));
  //   } else if (challengeModal && challengeTimeout) {
  //     timeoutCheck();
  //   }
  // }, [challengeModal, challengeTimeout]);

  return (
    <>
      {challengeModal && (
        <section className="challengeModalContainer">
          <article>
            <h5>Votre session a expiré</h5>
            <p>Veuillez confirmer vos identifiants pour continuer</p>
            <form>
              <div>
                <label for="loginForm">login</label>
                <input
                  type="text"
                  id="loginForm"
                  placeholder="login"
                  required
                  value={userOtherValue}
                  onChange={(e) => setUserOtherValue(e.target.value)}
                />
              </div>
              <div>
                <label for="valueForm">mot de passe</label>
                <input
                  type="password"
                  id="valueForm"
                  placeholder="mot de passe"
                  required
                  value={userValue}
                  onChange={(e) => setUserValue(e.target.value)}
                />
              </div>
              <div>
                <MddButton
                  buttonInnerText="fermer"
                  iconColor="secondaryButton"
                  buttonActionFunctionOne={loggingOut}
                />
                <MddButton
                  buttonInnerText="connexion"
                  buttonActionFunctionOne={handlingLogin}
                />
              </div>
            </form>
          </article>
        </section>
      )}
    </>
  );
}
