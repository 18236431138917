export default function UsersCounts({ usersObjects }) {
  const buildingText = (textInput) => {
    let stockUsers = [...usersObjects];
    if (textInput === "actif") {
      stockUsers = usersObjects.filter((user) => {
        return user.state === "active";
      });
    }
    if (textInput === "suspendu") {
      stockUsers = usersObjects.filter((user) => {
        return user.state === "suspended";
      });
    }
    if (textInput === "non confirmé") {
      stockUsers = usersObjects.filter((user) => {
        return user.state === "inactive";
      });
    }
    if (stockUsers.length === 1) {
      return `1 utilisateur ${textInput}`;
    } else if (stockUsers.length > 1) {
      return `${stockUsers.length} utilisateurs ${textInput}s`;
    } else {
      return `0 utilisateur ${textInput}`;
    }
  };

  return (
    <article className="usersCountsContainer">
      <div>
        <p>{buildingText("actif")}</p>
      </div>
      <div>
        <p>{buildingText("suspendu")}</p>
      </div>
      <div>
        <p>{buildingText("non confirmé")}</p>
      </div>
    </article>
  );
}
