import { useState, useEffect, useContext } from "react";
import { MddContext } from "../../context/MddContext";

import "../../styles/feedBackToast.css";

export default function FeedBackToast() {
  const {
    feedBackMessage,
    setFeedBackMessage,
    feedBackSection,
    setFeedBackSection,
    feedBackState,
    setFeedBackState,
  } = useContext(MddContext);

  const [allFeedBacks, setAllFeedBacks] = useState([]);

  const updatingFeedBacks = () => {
    let stockAllFeedBacks = [...allFeedBacks];
    let stockFeedback = `{`;
    feedBackMessage &&
      (stockFeedback = stockFeedback + `"message": "${feedBackMessage}"`);
    feedBackSection &&
      (stockFeedback = stockFeedback + `, "appSection": "${feedBackSection}"`);
    feedBackState &&
      (stockFeedback = stockFeedback + `, "state": "${feedBackState}"`);
    stockFeedback = stockFeedback + `}`;
    if (stockFeedback !== "{}") {
      stockAllFeedBacks.push(JSON.parse(stockFeedback));
    }
    setAllFeedBacks(stockAllFeedBacks);
    setFeedBackMessage(null);
    setFeedBackSection(null);
    setFeedBackState(null);
  };

  const classBuilder = (inputState) => {
    switch (inputState) {
      case "success":
        return "me-auto successToast";
      case "error":
        return "me-auto errorToast";
      case "loading":
        return "me-auto loadingToast";
      default:
        return "me-auto";
    }
  };

  const returningToasts = () => {
    let stockToasts = [];
    if (allFeedBacks.length > 0) {
      for (let j = 0; j < allFeedBacks.length; j++) {
        stockToasts.push(
          <article
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            key={j}
            animation
          >
            <div className="toast-header">
              {allFeedBacks[j].state === "success" && (
                <i className="bi bi-bookmark-check-fill successToast" />
              )}
              {allFeedBacks[j].state === "error" && (
                <i className="bi bi-bookmark-x-fill errorToast" />
              )}
              {allFeedBacks[j].state === "loading" && (
                <i className="bi bi-bookmark-plus-fill loadingToast" />
              )}
              <strong className={classBuilder(allFeedBacks[j].state)}>
                {allFeedBacks[j].state === "success" && "succès"}
                {allFeedBacks[j].state === "error" && "erreur"}
                {allFeedBacks[j].state === "loading" && "chargement"}
              </strong>
              <small className="text-muted">{allFeedBacks[j].appSection}</small>
            </div>
            <div className="toast-body">{allFeedBacks[j].message}</div>
          </article>
        );
      }
    }
    return stockToasts;
  };

  // const removingToast = (e) => {
  //   e.target.remove();
  // };

  useEffect(() => {
    feedBackState && updatingFeedBacks();
    // let allToasts = document.getElementsByClassName("toast");
    // for (let i = 0; i < allToasts.length; i++) {
    //   allToasts[i].addEventListener("hidden.bs.toast", function () {
    //     console.log("hidden toast");
    //   });
    // }
    // return function exitCleaning() {
    //   allToasts = document.getElementsByClassName("toast");
    // for (let i = 0; i<allToasts.length;i++){
    //   allToasts[i].removeEventListener('hidden.bs.toast', removingToast)
    // }
    // };
  }, [feedBackState]);

  return (
    <section className="toast-container">
      {allFeedBacks && returningToasts()}
    </section>
  );
}
