import { useContext } from "react";
import { Link } from "react-router-dom";

import { MddContext } from "../../context/MddContext";

export default function HomeCard({ adress, name, text, iconName, access }) {
  const { userRole } = useContext(MddContext);

  const sortingAccess = () => {
    if (access) {
      if (access === 3 && userRole === "admin") {
        return true;
      } else if (
        access === 2 &&
        (userRole === "admin" || userRole === "provider")
      ) {
        return true;
      } else if (access === 1 && userRole) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {sortingAccess() && (
        <button className="homeCardOuterLink">
          <Link to={`/${adress}`}>
            <article className="homeCard">
              <h4>{name}</h4>
              <p>{text[0]}</p>
              <p>{text[1]}</p>
              <i className={iconName} style={{ fontSize: "5rem" }} />
            </article>
          </Link>
        </button>
      )}
    </>
  );
}
