import ReactCalendar from "react-calendar";
import "../../styles/reusableElements/mddCalendar.css";

import MddButton from "./MddButton";

export default function MddCalendar({
  showModal,
  registeringDate,
  findingStartDate,
  dateParsed,
  setDateParsed,
  unparsingDate,
  endDate,
}) {
  return (
    <div
      className={
        showModal ? "logsDatesModal openedLogsModal" : "logsDatesModal"
      }
    >
      <article className="calendarContainer">
        <ReactCalendar onChange={registeringDate} value={findingStartDate()} />
      </article>
      <article className="timePickerContainer">
        <label>
          {" "}
          {endDate ? "Jusqu'à" : "À partir de"}
          <input
            value={dateParsed}
            onChange={(e) => {
              setDateParsed(e.target.value);
            }}
          />
        </label>
        <MddButton
          buttonInnerText="valider"
          buttonActionFunctionOne={unparsingDate}
          buttonActionPropOne={dateParsed}
          iconColor="basicButtonColor"
        />
      </article>
    </div>
  );
}
