import { useContext, useEffect, useState, useRef } from "react";

import OptionsCategory from "../OptionsCategory";
import DatavizColorPicker from "../inputs/DatavizColorPicker";
import DatavizList from "../inputs/DatavizList";
import DatavizNumberInput from "../inputs/DatavizNumberInput";
import DatavizSwitch from "../inputs/DatavizSwitch";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizIconsSwitch from "../inputs/DatavizIconsSwitch";

export default function DefaultText({ datavizSidebar, updatingWidget }) {
  return (
    <OptionsCategory name="Texte">
      <DatavizTextInput
        label={"police"}
        displayValue={"Open sans MS"}
        input={datavizSidebar.widget.style.body.fontFamily}
        path={["style", "body", "fontFamily"]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label={"taille"}
        displayValue={5}
        outputValue={["multiply", 3]}
        input={datavizSidebar.widget.style.body.fontSize}
        path={["style", "body", "fontSize"]}
        updater={updatingWidget}
      />
      <DatavizColorPicker
        label={"couleur"}
        displayValue="#444"
        input={datavizSidebar.widget.style.body.color}
        path={["style", "body", "color"]}
        updater={updatingWidget}
      />
      <DatavizIconsSwitch
        label={"alignement"}
        displayValue={[
          "bi bi-text-left",
          "bi bi-text-center",
          "bi bi-text-right",
          "bi bi-justify",
        ]}
        outputValue={["left", "center", "right", "justify"]}
        input={datavizSidebar.widget.style.body.textAlign}
        path={["style", "body", "textAlign"]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label={"épaisseur"}
        displayValue={5}
        outputValue={["multiply", 100]}
        min={"1"}
        input={datavizSidebar.widget.style.body.fontWeight}
        path={["style", "body", "fontWeight"]}
        updater={updatingWidget}
      />
      <DatavizIconsSwitch
        label={"style"}
        displayValue={["bi bi-fonts", "bi bi-type-italic"]}
        outputValue={["normal", "italic"]}
        input={datavizSidebar.widget.style.body.fontStyle}
        path={["style", "body", "fontStyle"]}
        updater={updatingWidget}
      />
    </OptionsCategory>
  );
}
