import { useContext, useEffect, useState } from "react";
import { MddContext } from "../../../context/MddContext";
import bedroom_white from "../../../utils/pictures/bedroom_white.png";
import diningroom_white from "../../../utils/pictures/diningroom_white.png";
import livingroom_white from "../../../utils/pictures/livingroom_white.png";
import TemperatureModal from "./TemperatureModal";

export default function SingleRoom({ room, typeTemperature }) {
  const {
    bedRoomsTemperature,
    livingRoomsTemperature,
    diningRoomsTemperature,
  } = useContext(MddContext);

  const [roomTemperature, setRoomTemperature] = useState(
    room.temperature ? room.temperature : 18
  );
  const [tempModal, setTempModal] = useState(false);

  const logoFinder = () => {
    switch (room.type) {
      case "bedroom":
        return <img src={bedroom_white} alt="bedrooms" />;
      case "diningroom":
        return <img src={diningroom_white} alt="diningrooms" />;
      case "livingroom":
        return <img src={livingroom_white} alt="livingrooms" />;
      default:
        return <img src={bedroom_white} alt="bedrooms" />;
    }
  };

  // useEffect(() => {
  //   if (room.type === "bedroom") {
  //     setRoomTemperature(bedRoomsTemperature);
  //   } else if (room.type === "diningroom") {
  //     setRoomTemperature(diningRoomsTemperature);
  //   } else if (room.type === "livingroom") {
  //     setRoomTemperature(livingRoomsTemperature);
  //   }
  // }, [bedRoomsTemperature, livingRoomsTemperature, diningRoomsTemperature]);

  return (
    <>
      <article
        className="hotelSingleRoom"
        onClick={() => {
          setTempModal(true);
        }}
      >
        {logoFinder()}
        <p>{room.name}</p>
        {room.error ? (
          <div>
            <i className="bi bi-thermometer-high statusAlert" />
          </div>
        ) : (
          <div>
            <input
              type="number"
              min="0"
              max="30"
              value={roomTemperature}
              onChange={(e) => {
                setRoomTemperature(Number(e.target.value));
              }}
              disabled
            />
            <p>°</p>
          </div>
        )}
      </article>
      {tempModal && (
        <TemperatureModal setTempModal={setTempModal} room={room} />
      )}
    </>
  );
}
