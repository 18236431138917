import { useState, useEffect } from "react";
import "../../styles/reusableElements/mddCustomInput.css";
import sumParser from "../../utils/streamStore/sumParser";

import MddButton from "./MddButton";

export default function MddCustomInput({
  disabler,
  setUpperLevelFile,
  previousName,
  typeOfFiles,
  lastInput,
}) {
  const [fileUpload, setFileUpload] = useState(null);

  useEffect(() => {
    if (lastInput) {
      setUpperLevelFile && setUpperLevelFile(fileUpload, lastInput);
    } else {
      setUpperLevelFile && setUpperLevelFile(fileUpload);
    }
  }, [fileUpload]);

  return (
    <label className="customInputLabel">
      <MddButton
        buttonInnerText="parcourir"
        iconColor="basicButtonColor"
        fakeButton
        inactive={disabler && disabler}
        inactiveTitle=""
      />
      <p>
        {(fileUpload && `${fileUpload.name} (${sumParser(fileUpload.size)})`) ||
          (previousName && previousName) ||
          (disabler ? "aucun fichier" : "choisissez un fichier")}
      </p>
      <input
        type="file"
        name="realInput"
        className="innerFileInput"
        accept={typeOfFiles && typeOfFiles}
        disabled={disabler && disabler}
        style={{ display: "none" }}
        onChange={(e) => {
          setFileUpload(e.target.files[0]);
        }}
      />
    </label>
  );
}
