import "../../../styles/streamStore/dashboard/streamCard.css";
import { useEffect, useContext } from "react";
import { MddContext } from "../../../context/MddContext";
import { useHistory } from "react-router-dom";

import statusLogoPicker from "../../../utils/statusLogoPicker";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function StreamCard({ stream }) {
  const { setActiveStream, currentTeam } = useContext(MddContext);
  let history = useHistory();

  function setWidth() {
    let stockCount = document.getElementsByClassName(`${stream.reference}Count`);
    let stockPlaceHolder = document.getElementsByClassName(
      `${stream.name}PlaceHolder`
    );
    let stockPencil = document.getElementsByClassName(`${stream.reference}Pencil`);
    if (stockPencil.length > 0 && stockPlaceHolder.length > 0) {
      for (let i = 0; i < stockPencil.length; i++) {
        stockPlaceHolder[i].style.width =
          stockPencil[i].scrollWidth + 0.5 + "px";
        stockPencil[i].style.width = stockPencil[i].scrollWidth + 0.5 + "px";
      }
    } else if (stockPencil.length > 0 && stockCount.length > 0) {
      for (let i = 0; i < stockPencil.length; i++) {
        stockPencil[i].style.width = stockCount[i].scrollWidth + 0.5 + "px";
        stockCount[i].style.width = stockCount[i].scrollWidth + 0.5 + "px";
      }
    } else if (stockCount.length > 0 && stockPlaceHolder.length > 0) {
      for (let i = 0; i < stockCount.length; i++) {
        stockPlaceHolder[i].style.width =
          stockCount[i].scrollWidth + 0.5 + "px";
        stockCount[i].style.width = stockCount[i].scrollWidth + 0.5 + "px";
      }
    }
  }

  const handlingNavigation = () => {
    setActiveStream(stream);
    history.push(`/streamstore/${stream.reference}`);
  };

  useEffect(() => {
    setWidth();
  });

  return (
    <div
      className="card streamCardContainer"
      onClick={() => {
        handlingNavigation();
      }}
    >
      <div className="topCardContainer">
        {stream.imageURL ? (
          <img
            src={`${REACT_APP_BASE_URL}/assets${stream.imageURL}`}
            alt="Illustration du stream"
          />
        ) : (
          <i className="bi bi-wind" />
        )}
      </div>
      <p className="card-title">{stream.name}</p>
      <div className="streamCardVersion">{stream.number}</div>
      <div>
        {stream.editable &&
        (stream.provider === currentTeam || currentTeam === "all") ? (
          <div className={`${stream.reference}Pencil`}>
            <i className="bi bi-pencil-square" />
          </div>
        ) : (
          <div className={`${stream.reference}PlaceHolder`} />
        )}
        {statusLogoPicker(stream.status, stream)}
        {stream.subscriptions > 0 ? (
          <div className={`${stream.reference}Count`}>
            <i className="bi bi-heart" />
            {stream.subscriptions > 9 ? (
              <p
                style={{
                  width: `${stream.subscriptions.toString().length * 0.75}rem`,
                }}
              >
                {stream.subscriptions}
              </p>
            ) : (
              <p>{stream.subscriptions}</p>
            )}
          </div>
        ) : (
          <div className={`${stream.reference}PlaceHolder`} />
        )}
      </div>
    </div>
  );
}
