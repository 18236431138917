import { useState } from "react";
import "../../../styles/sideBar.css";
import MddButton from "../../reusableElements/MddButton";

export default function SingleFilter({
  filter,
  setFilters,
  activeFilter,
  setActiveFilter,
  filters,
}) {
  const [editing, setEditing] = useState(false);
  const [editedName, setEditedName] = useState(filter.name);

  const removingFilter = () => {
    let stockFilters = filters.filter((f) => {
      return f.key !== filter.key;
    });
    setFilters(stockFilters);
  };

  const changingFilterName = (index) => {
    let stockFilters = filters;
    stockFilters.forEach((f) => {
      if (f.key === index) {
        f.name = editedName;
      }
    });
    setFilters(stockFilters);
  };

  return (
    <div className="filterContainer">
      {editing ? (
        <input
          type="text"
          className="modifyFilter"
          id={`modifyFilter${filter.key}`}
          name={`modifyFilter${filter.key}`}
          value={editedName}
          onChange={(e) => {
            setEditedName(e.target.value);
          }}
          onKeyUp={(e) => {
            if (
              (e.key === "Enter" || e.keyCode === 13) &&
              editedName !== filter.name
            ) {
              changingFilterName(filter.key);
              setEditing(false);
            }
          }}
        />
      ) : (
        <MddButton
          buttonInnerText={filter.name}
          buttonSize="long"
          iconColor={
            filter.key === activeFilter ? "basicButtonColor" : "secondaryButton"
          }
          buttonActionFunctionOne={setActiveFilter}
          buttonActionPropOne={filter.key === activeFilter ? null : filter.key}
        />
      )}
      <MddButton
        iconName="bi-pencil-fill"
        buttonSize="small"
        smallButtonDescription="Edit filter"
        iconColor="altButtonColor"
        buttonActionFunctionOne={setEditing}
        buttonActionPropOne={!editing}
        buttonActionFunctionTwo={
          editedName !== filter.name && changingFilterName
        }
        buttonActionPropTwo={editedName !== filter.name && filter.key}
        smallIcon
      />
      <MddButton
        iconName="bi-x-lg"
        buttonSize="small"
        smallButtonDescription="Return to dashboard"
        iconColor="altButtonColor"
        buttonActionFunctionOne={setActiveFilter}
        buttonActionPropOne={null}
        buttonActionFunctionTwo={removingFilter}
        smallIcon
      />
    </div>
  );
}
