import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddAuth from "../../api/mddAuth";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function CreatePassword() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    tempToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [userValue, setUserValue] = useState("");
  const [secondUserValue, setSecondUserValue] = useState("");
  const [createdAccount, setCreatedAccount] = useState(false);

  const decodingToken = (tokenInput) => {
    let base64Url = tokenInput.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const handlingSubmit = () => {
    if (userValue === secondUserValue) {
      mddAuth
        .post(
          `/credentials`,
          { password: userValue },
          {
            headers: {
              Authorization: `Bearer ${tempToken}`,
            },
          }
        )
        .then(() => {
          feedBackBuilder(true, "Mot de passe créé", "Authentification");
          setCreatedAccount(true);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requête : ", err);
          feedBackBuilder(
            false,
            `Le mot de passe n'a pas pu être créé`,
            "Authentification"
          );
        });
    } else {
      feedBackBuilder(
        false,
        `Les deux mots de passe doivent être identiques`,
        "Authentification"
      );
    }
  };

  const handlingRedirect = () => {
    if (createdAccount) {
      history.push(`/login`);
    }
  };

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
    console.log("create password use effect : ", tempToken);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className="subscribeGlobalContainer">
        {!createdAccount ? (
          <form onSubmit={(e) => e.preventDefault()}>
            <h5>Créez votre mot de passe</h5>
            <input
              type="email"
              id="emailForm"
              required
              disabled
              value={tempToken ? decodingToken(tempToken).sub : ""}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <input
              type="password"
              id="valueForm"
              placeholder="mot de passe"
              required
              value={userValue}
              onChange={(e) => setUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <input
              type="password"
              id="value2Form"
              placeholder="confirmation du mot de passe"
              required
              value={secondUserValue}
              onChange={(e) => setSecondUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <MddButton
              buttonInnerText="valider"
              buttonActionFunctionOne={handlingSubmit}
            />
          </form>
        ) : (
          <article>
            <h5>Votre compte est créé</h5>
            <p>
              Vous pouvez désormais vous connecter avec votre email et votre mot
              de passe.
            </p>
            <MddButton
              buttonInnerText="se connecter"
              buttonActionFunctionOne={handlingRedirect}
            />
          </article>
        )}
      </div>
    </Dashboard>
  );
}
