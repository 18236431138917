import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddUsers from "../../api/mddUsers";
import mddAuth from "../../api/mddAuth";
import revertingToken from "../../utils/auth/revertingToken";
import { usersFilters } from "../../utils/users/usersFilters";
import "../../styles/users/users.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import StreamStoreSideBar from "../../components/streamStore/sideBar/StreamStoreSideBar";
import UserForm from "../../components/users/UserForm";
import UsersActions from "../../components/users/UserActions";
import UsersCounts from "../../components/users/UsersCounts";
import MddLoader from "../../components/reusableElements/MddLoader";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import MddSearchBar from "../../components/reusableElements/MddSearchBar";
import ConfirmModal from "../../components/reusableElements/ConfirmModal";

export default function Users() {
  const {
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    fullScreen,
    showSideBar,
    closingSession,
    checkingRole,
    confirmModal,
    filters,
    activeFilter,
    userToken,
    handling401,
    feedBackBuilder,
    succesfullChallenge,
  } = useContext(MddContext);
  let history = useHistory();

  const [usersObjects, setUsersObjects] = useState([]);
  const [usersDisplayed, setUsersDisplayed] = useState([]);
  const [usersSelected, setUsersSelected] = useState([]);
  const [filterElements, setFilterElements] = useState([]);
  const [currentlyTyping, setCurrentlyTyping] = useState("");
  const [creatingUser, setCreatingUser] = useState(false);
  const [userAction, setUserAction] = useState("");
  const [usersAssigned, setUsersAssigned] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [openedParameters, setOpenedParameters] = useState(false);
  const [usersTeams, setUsersTeams] = useState([]);
  const [emailAdresses, setEmailAdresses] = useState([]);
  const [emailDomains, setEmailDomains] = useState("");

  const retrievingScopes = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setUsersAssigned(false);
      mddAuth
        .get(`/scopes`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          retrievingUsers(informations);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const retrievingUsers = (usersInput) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .get(`/user?all=true`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data;
          let stockUsers = [...usersInput];
          for (let i = 0; i < stockUsers.length; i++) {
            let stockSame = informations.find((user) => {
              return user.account === stockUsers[i].account;
            });
            stockSame && (stockUsers[i].firstname = stockSame.firstname);
            stockSame && (stockUsers[i].lastname = stockSame.lastname);
            stockSame
              ? (stockUsers[i].state = stockSame.state)
              : (stockUsers[i].state = "inactive");
          }
          setUsersObjects([...stockUsers]);
          setUsersAssigned(true);
          retrievingReferences();
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const retrievingReferences = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddAuth
        .get(`/references`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = response.data.data.scopes;
          setUsersTeams([...informations]);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const buildingAccountsList = () => {
    let stockList = [];
    for (let i = 0; i < usersSelected.length; i++) {
      stockList.push(usersSelected[i].account);
    }
    return stockList;
  };

  const buldingUsersList = () => {
    let stockList = [];
    let stockUser = {};
    for (let i = 0; i < usersSelected.length; i++) {
      stockUser = {
        email: usersSelected[i].login,
        role: usersSelected[i].role,
        scopes: usersSelected[i].scopes,
      };
      stockList.push(stockUser);
    }
    return stockList;
  };

  const deletingUser = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddAuth
        .post(
          `/unsubscribe`,
          { accounts: buildingAccountsList() },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          setUserAction("");
          feedBackBuilder(true, `Compte(s) supprimé(s)`, "Utilisateurs");
        })
        .then(() => {
          retrievingScopes();
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history, "Compte(s) non supprimé(s)");
        });
    }
  };

  const modifyingUser = (userInput, userEmail, callbackCleaner) => {
    if (closingSession()) {
      history.push("/login");
    } else if (userEmail && userEmail !== "") {
      mddAuth
        .post(`/scopes?email=${userEmail}`, userInput, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          if (response.data.data) {
            let stockUsers = [...usersObjects];
            let stockIndex = stockUsers.findIndex((user) => {
              return user.account === response.data.data.account;
            });
            if (stockIndex === -1) {
              stockUsers.push(response.data.data);
            } else {
              stockUsers.splice(stockIndex, 1, response.data.data);
            }
            setUsersObjects([...stockUsers]);
            feedBackBuilder(true, `Modifications enregistrés`, "Utilisateurs");
            callbackCleaner && callbackCleaner();
            callbackCleaner && setCreatingUser(false);
          } else {
            console.log(
              "erreur au niveau de la requete d'édition de l'utilisateur : ",
              response.data.error.message
            );
            feedBackBuilder(
              false,
              `Un compte suspendu ne peut être modifié`,
              "Utilisateurs"
            );
          }
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          if (
            err.response.data.error &&
            err.response.data.error.code &&
            err.response.data.error.code === "INVALID ROLE"
          ) {
            feedBackBuilder(
              false,
              `Le rôle saisi n'est pas valide`,
              "Utilisateurs"
            );
          } else if (
            err.response.data.error &&
            err.response.data.error.code &&
            err.response.data.error.code === "INVALID SCOPE"
          ) {
            feedBackBuilder(
              false,
              `Les équipes saisies ne sont pas valides, sont autorisés : les lettres, les chiffres, espaces et caractères @ -`,
              "Utilisateurs"
            );
          } else if (
            err.response.data.error &&
            err.response.data.error.code &&
            err.response.data.error.code === "INVALID EMAIL"
          ) {
            feedBackBuilder(false, `L'email est invalide`, "Utilisateurs");
          } else if (
            err.response.data.error &&
            err.response.data.error.code &&
            err.response.data.error.code === "BAD REQUEST"
          ) {
            feedBackBuilder(
              false,
              `Vous ne pouvez pas modifier le rôle`,
              "Utilisateurs"
            );
          } else {
            handling401(err, history, "Utilisateur non modifié");
          }
        });
    }
  };

  const modifyingEmail = (newEmail, oldEmail) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddAuth
        .post(
          `/change-login?email=${oldEmail}`,
          { email: newEmail },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          feedBackBuilder(
            true,
            "Un email de confirmation a été envoyé à l'utilisateur",
            "Utilisateurs"
          );
        })
        .catch((err) => {
          if (err.response.status === 404) {
            feedBackBuilder(
              false,
              `Le login doit être un email valide`,
              "Utilisateurs"
            );
          } else {
            handling401(err, history, "Login non modifié");
          }
          console.log("erreur au niveau de la requête : ", err);
        });
    }
  };

  const invitingUsers = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddAuth
        .post(
          `/invite`,
          { accounts: buldingUsersList() },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data.length==1)
            feedBackBuilder(true, `1 utilisateur invité ${response.data.data[0].email}. Vous pouvez lui transmettre cet OTP pour valider son compte:    ${response.data.data[0].otp.replace(/(\d{3})(\d{3})/, "$1 $2")}`, "Utilisateurs");         
          else
            feedBackBuilder(true, `${response.data.data.length} utilisateurs invités`, "Utilisateurs");         
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history, "Utilisateur(s) non invité(s)");
        });
    }
  };

  const activatingUsers = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .post(
          `/active`,
          { accounts: buildingAccountsList() },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            feedBackBuilder(true, `Compte(s) activé(s)`, "Utilisateurs");
            setUserAction("");
          } else {
            console.log(
              "erreur au niveau de la requete d'activation de l'utilisateur : ",
              response.data.error.message
            );
            feedBackBuilder(false, `Le compte est déjà actif`, "Utilisateurs");
          }
        })
        .then(() => {
          retrievingScopes();
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          if (err && err.response && err.response.status === 401) {
            feedBackBuilder(
              false,
              `Le compte est en attente de validation`,
              "Utilisateurs"
            );
          } else {
            handling401(err, history, "Compte(s) non activé(s)");
          }
        });
    }
  };

  const suspendingUsers = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .post(
          `/suspend`,
          { accounts: buildingAccountsList() },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            feedBackBuilder(true, `Compte(s) suspendu(s)`, "Utilisateurs");
            setUserAction("");
          } else {
            console.log(
              "erreur au niveau de la requete de suspension de l'utilisateur : ",
              response.data.error.message
            );
            feedBackBuilder(
              false,
              `Le compte est déjà suspendu`,
              "Utilisateurs"
            );
          }
        })
        .then(() => {
          retrievingScopes();
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          if (err && err.response && err.response.status === 401) {
            feedBackBuilder(
              false,
              `Le compte est en attente de validation`,
              "Utilisateurs"
            );
          } else {
            handling401(err, history, "Compte(s) non suspendu(s)");
          }
        });
    }
  };

  const returningUsers = () => {
    let stockUsers = [];
    for (let i = 0; i < usersDisplayed.length; i++) {
      stockUsers.push(
        <UserForm
          user={usersDisplayed[i]}
          selectingUser={selectingUser}
          key={`user ${usersDisplayed[i].account}`}
          modifyingUser={modifyingUser}
          usersSelected={usersSelected}
          editingUser={editingUser}
          setEditingUser={setEditingUser}
          usersTeams={usersTeams}
          setUsersTeams={setUsersTeams}
          modifyingEmail={modifyingEmail}
        />
      );
    }
    return stockUsers;
  };

  const handlingDelete = () => {
    deletingUser();
    setUserAction("");
  };

  const selectingUser = (userInput) => {
    let stockUsers = [...usersSelected];
    let selected = stockUsers.some((user) => {
      return user.account === userInput.account;
    });
    if (selected) {
      let stockIndex = stockUsers.findIndex((user) => {
        return user === userInput;
      });
      stockUsers.splice(stockIndex, 1);
    } else {
      stockUsers.push(userInput);
    }
    setUsersSelected(stockUsers);
  };

  const addingFilterElement = () => {
    const options = document.getElementById("filterOptions").childNodes;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === currentlyTyping) {
        let stockFilter = filterElements;
        if (
          filterElements.length === 0 ||
          filterElements[filterElements.length - 1].criteria
        ) {
          stockFilter.push({ field: currentlyTyping });
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        } else if (
          filterElements[filterElements.length - 1].field &&
          !filterElements[filterElements.length - 1].operator
        ) {
          stockFilter[stockFilter.length - 1].operator =
            currentlyTyping === "contient" || currentlyTyping === "="
              ? "="
              : "!=";
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        }
      } else if (
        currentlyTyping.includes("> Chercher ") &&
        filterElements[filterElements.length - 1].operator &&
        !filterElements[filterElements.length - 1].criteria
      ) {
        let stockFilter = filterElements;
        stockFilter[stockFilter.length - 1].criteria = currentlyTyping
          .slice(11, currentlyTyping.length)
          .toLowerCase();
        setFilterElements(stockFilter);
        setCurrentlyTyping("");
        break;
      }
    }
  };

  const sortingActivateSuspend = () => {
    if (userAction === "activate") {
      let activeUsers = usersSelected.some((user) => {
        return user.state === "active";
      });
      let suspendedUsers = usersSelected.some((user) => {
        return user.state === "suspended";
      });
      if (activeUsers && !suspendedUsers) {
        return (
          <ConfirmModal
            handleModalYes={suspendingUsers}
            confirmMessage={`Voulez-vous suspendre ${
              usersSelected.length === 1
                ? "ce compte"
                : `ces ${usersSelected.length} comptes`
            } ?`}
            noText="annuler"
            yesText="suspendre"
            confirmTitle="confirmation"
            confirmInfo="Un utilisateur dont le compte est suspendu ne peut plus se loguer ni gérer l'application. Les ressources liées son compte seront accessibles à l'administrateur et les informations sur l'utilisateur seront conservées."
          />
        );
      } else if (suspendedUsers && !activeUsers) {
        return (
          <ConfirmModal
            handleModalYes={activatingUsers}
            confirmMessage={`Voulez-vous activer ${
              usersSelected.length === 1
                ? "ce compte"
                : `ces ${usersSelected.length} comptes`
            } ?`}
            noText="annuler"
            yesText="activer"
            confirmTitle="confirmation"
            confirmInfo="Un utilisateur dont le compte est actif peut se loguer et gérer l'application selon ses droits."
          />
        );
      } else if (suspendedUsers && activeUsers) {
        return (
          <ConfirmModal
            handleModalYes={activatingUsers}
            handleModalAlt={suspendingUsers}
            confirmMessage={`Voulez-vous activer ou suspendre ${
              usersSelected.length === 1
                ? "ce compte"
                : `ces ${usersSelected.length} comptes`
            } ?`}
            noText="annuler"
            yesText="activer"
            altText="suspendre"
            confirmTitle="confirmation"
            confirmInfo="Vous avez sélectionné des utilisateurs actifs et d'autres suspendus. L'action choisie s'appliquera à tous ces comptes."
          />
        );
      }
    } else if (userAction === "delete") {
      return (
        <ConfirmModal
          handleModalYes={handlingDelete}
          confirmMessage={`Voulez-vous supprimer ${
            usersSelected.length === 1
              ? "ce compte"
              : `ces ${usersSelected.length} comptes`
          } ?`}
          noText="annuler"
          yesText="supprimer"
          confirmTitle="confirmation"
          confirmInfo="Les ressources liées à ces comptes (streams, abonnements, dataviz) seront accessibles à l'administrateur mais les informations sur ces utilisateurs seront effacées."
        />
      );
    }
  };

  const selectingAll = () => {
    let stockUsers = [...usersDisplayed];
    setUsersSelected(stockUsers);
  };

  const retrievingFilteredUsers = (filterInput, currentInput, exclude) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .get(`/user`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = exclude
            ? response.data.data.filter(
                (item) =>
                  !item[filterInput]
                    .toLowerCase()
                    .includes(currentInput.criteria)
              )
            : response.data.data.filter((item) =>
                item[filterInput].toLowerCase().includes(currentInput.criteria)
              );
          setUsersObjects(informations);
          setUsersDisplayed(informations);
          setUsersAssigned(true);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };
  const retrievingAllFilteredUsers = (currentInput, exclude) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddUsers
        .get(`/user`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const informations = exclude
            ? response.data.data.filter(
                (item) =>
                  !item.email.toLowerCase().includes(currentInput.criteria) ||
                  !item.role.toLowerCase().includes(currentInput.criteria) ||
                  !item.firstname
                    .toLowerCase()
                    .includes(currentInput.criteria) ||
                  !item.lastname
                    .toLowerCase()
                    .includes(currentInput.criteria) ||
                  !item.title.toLowerCase().includes(currentInput.criteria) ||
                  !item.team.toLowerCase().includes(currentInput.criteria) ||
                  !item.organization
                    .toLowerCase()
                    .includes(currentInput.criteria) ||
                  !item.state.toLowerCase().includes(currentInput.criteria)
              )
            : response.data.data.filter(
                (item) =>
                  item.email.toLowerCase().includes(currentInput.criteria) ||
                  item.role.toLowerCase().includes(currentInput.criteria) ||
                  item.firstname
                    .toLowerCase()
                    .includes(currentInput.criteria) ||
                  item.lastname.toLowerCase().includes(currentInput.criteria) ||
                  item.title.toLowerCase().includes(currentInput.criteria) ||
                  item.team.toLowerCase().includes(currentInput.criteria) ||
                  item.organization
                    .toLowerCase()
                    .includes(currentInput.criteria) ||
                  item.state.toLowerCase().includes(currentInput.criteria)
              );
          setUsersObjects(informations);
          setUsersDisplayed(informations);
          setUsersAssigned(true);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const filteringUsers = () => {
    setUsersAssigned(false);
    let currentFilter = filters.find((f) => f.key === activeFilter);
    for (let i = 0; i < currentFilter.elements.length; i++) {
      switch (currentFilter.elements[i].field) {
        case "tout":
          if (currentFilter.elements[i].operator === "=") {
            retrievingAllFilteredUsers(currentFilter.elements[i]);
          } else {
            retrievingAllFilteredUsers(currentFilter.elements[i], true);
          }
          break;
        case "email":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("email", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("email", currentFilter.elements[i], true);
          }
          break;
        case "prénom":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("prénom", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("prénom", currentFilter.elements[i], true);
          }
          break;
        case "nom":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("nom", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("nom", currentFilter.elements[i], true);
          }
          break;
        case "rôle":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("rôle", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("rôle", currentFilter.elements[i], true);
          }
          break;
        case "team":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("team", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("team", currentFilter.elements[i], true);
          }
          break;
        case "état":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("état", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("état", currentFilter.elements[i], true);
          }
          break;
        case "titre":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("titre", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers("titre", currentFilter.elements[i], true);
          }
          break;
        case "organisation":
          if (currentFilter.elements[i].operator === "=") {
            retrievingFilteredUsers("organisation", currentFilter.elements[i]);
          } else {
            retrievingFilteredUsers(
              "organisation",
              currentFilter.elements[i],
              true
            );
          }
          break;
        default:
          if (currentFilter.elements[i].operator === "=") {
            retrievingAllFilteredUsers(currentFilter.elements[i]);
          } else {
            retrievingAllFilteredUsers(currentFilter.elements[i], true);
          }
          break;
      }
    }
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    } else if (checkingRole(0)) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(true);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("mddTheme")) {
      if (activeFilter) {
        filteringUsers();
      } else {
        retrievingScopes();
      }
    }
  }, [filters, activeFilter]);

  useEffect(() => {
    if (localStorage.getItem("mddTheme") && succesfullChallenge) {
      if (activeFilter) {
        filteringUsers();
      } else {
        retrievingScopes();
      }
    }
  }, [succesfullChallenge]);

  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);

  useEffect(() => {
    document.getElementById("filterOptions") && addingFilterElement();
  }, [currentlyTyping]);

  useEffect(() => {
    usersObjects.length > 0 && setUsersDisplayed([...usersObjects]);
  }, [usersObjects]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <StreamStoreSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer">
        <section className="usersGlobalContainer">
          <article className="topUsersContainer">
            <MddBreadCrumb
              backPagesNames={[{ name: "Home", url: "/" }]}
              currentPage="Gestion des utilisateurs"
            />
            {openedParameters || <UsersCounts usersObjects={usersObjects} />}
            {/* {openedParameters ? (
              <div>
                <MddButton
                  iconColor="secondaryButton"
                  buttonInnerText="retour"
                  buttonActionFunctionOne={setOpenedParameters}
                  buttonActionPropOne={false}
                />
                <MddButton buttonInnerText="enregistrer" />
              </div>
            ) : (
              <MddButton
                iconColor="basicButtonColor"
                buttonInnerText="réglages"
                buttonActionFunctionOne={setOpenedParameters}
                buttonActionPropOne={true}
              />
            )} */}
          </article>
          {/* {openedParameters ? (
            <UsersSettings
              setOpenedParameters={setOpenedParameters}
              emailAdresses={emailAdresses}
              setEmailAdresses={setEmailAdresses}
              emailDomains={emailDomains}
              setEmailDomains={setEmailDomains}
            />
          ) : (
            <> */}
          <MddSearchBar
            filterElements={filterElements}
            setFilterElements={setFilterElements}
            currentlyTyping={currentlyTyping}
            setCurrentlyTyping={setCurrentlyTyping}
            filtersDatalist={usersFilters}
          />
          <UsersActions
            usersSelected={usersSelected}
            setCreatingUser={setCreatingUser}
            usersObjects={usersObjects}
            handlingDelete={handlingDelete}
            setUserAction={setUserAction}
            selectingAll={selectingAll}
            invitingUsers={invitingUsers}
          />
          <article className="usersLabelsContainer">
            <p>email</p>
            <p>prénom</p>
            <p>nom</p>
            <p>rôle</p>
            <p>équipes</p>
            <p>organisation</p>
          </article>
          {usersAssigned ? (
            <div className="usersListContainer">
              {creatingUser && (
                <UserForm
                  creatingUser={creatingUser}
                  setCreatingUser={setCreatingUser}
                  modifyingUser={modifyingUser}
                  usersTeams={usersTeams}
                  setUsersTeams={setUsersTeams}
                />
              )}
              {returningUsers()}
            </div>
          ) : (
            <MddLoader />
          )}
          {confirmModal && userAction && sortingActivateSuspend()}
          {/* </>
          )} */}
        </section>
      </Dashboard>
    </>
  );
}
