import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";
import { streamStoreFilters } from "../../utils/streamStore/streamStoreFilters";
import "../../styles/streamStore/dashboard/dashboard.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import StreamStoreSideBar from "../../components/streamStore/sideBar/StreamStoreSideBar";
import MddButton from "../../components/reusableElements/MddButton";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import MddSearchBar from "../../components/reusableElements/MddSearchBar";
import MddLoader from "../../components/reusableElements/MddLoader";
import StreamCard from "../../components/streamStore/dashboard/StreamCard";

export default function StreamStore() {
  const {
    showSideBar,
    fullScreen,
    closingSession,
    setShowSideBar,
    setFullSideBar,
    setActiveStream,
    filters,
    activeFilter,
    setFullScreen,
    userToken,
    handling401,
    userRole,
    // streamstoreRefresh,
    // setChallengedCall,
    setActiveVersion,
    setFilterElements,
    succesfullChallenge,
  } = useContext(MddContext);
  let history = useHistory();
  const { pathname } = useLocation();

  const [stockAPIStreams, setStockAPIStreams] = useState([]);
  const [displayedStreams, setDisplayedStreams] = useState([]);
  const [streamsAssigned, setStreamsAssigned] = useState(false);

  const retrievingStreamsAPI = (defaultfilter) => {
    if (localStorage.getItem("mddTheme")) {
      if (closingSession()) {
        history.push("/login");
      } else {
        mddStreamStoreApi
          .get(`/stream`, {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            const informations = response.data.data;
            setDisplayedStreams(informations);
            setStockAPIStreams(informations);
            setStreamsAssigned(true);
            defaultfilter && filteringStreams(informations);
          })
          .catch((err) => {
            console.log("erreur au niveau de la requete API GET : ", err);
            handling401(err, history);
            // setChallengedCall(retrievingStreamsAPI);
          });
      }
    }
  };

  const filteringStreams = (list) => {
    let stockElements = [];
    let currentFilter = filters.find((f) => f.key === activeFilter);
    for (let i = 0; i < currentFilter.elements.length; i++) {
      switch (currentFilter.elements[i].field) {
        case "tout":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        case "nom":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.name
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        case "fournisseur":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.provider
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        case "statut":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.status
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        case "description":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.description
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        case "référence":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.reference
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
        default:
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedStreams(stockElements);
          }
          break;
      }
    }
  };

  const returningCards = () => {
    let stockCards = [];
    for (let i = 0; i < displayedStreams.length; i++) {
      stockCards.push(
        <StreamCard
          stream={displayedStreams[i]}
          key={`stream ${displayedStreams[i].id}`}
        />
      );
    }
    return stockCards;
  };

  const handlingCreateNav = () => {
    history.push(`/streamstore/create`);
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    // setShowSideBar(true);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);
  useEffect(() => {
    // let refreshingStreams = null;
    // if (activeFilter && streamsAssigned) {
    //   !fullCard && retrievingStreamsAPI(true);
    //   refreshingStreams = setInterval(() => {
    //     !fullCard && retrievingStreamsAPI(true);
    //   }, streamstoreRefresh);
    // } else if (activeFilter) {
    //   !fullCard && retrievingStreamsAPI(true);
    //   refreshingStreams = setInterval(() => {
    //     !fullCard && retrievingStreamsAPI(true);
    //   }, streamstoreRefresh);
    // } else {
    //   !fullCard && retrievingStreamsAPI();
    //   refreshingStreams = setInterval(() => {
    //     !fullCard && retrievingStreamsAPI();
    //   }, streamstoreRefresh);
    // }
    // return function exitSubscriptions() {
    //   clearInterval(refreshingStreams);
    // };
    if (activeFilter) {
      retrievingStreamsAPI(true);
    } else {
      retrievingStreamsAPI();
    }
    if (filters.length === 0 && showSideBar) {
      setShowSideBar(false);
    } else if (filters.length > 0 && !showSideBar) {
      setShowSideBar(true);
    }
  }, [filters, activeFilter]);

  useEffect(() => {
    if (succesfullChallenge) {
      if (activeFilter) {
        retrievingStreamsAPI(true);
      } else {
        retrievingStreamsAPI();
      }
    }
  }, [succesfullChallenge]);

  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);

  useEffect(() => {
    pathname && pathname === "/streamstore" && setActiveStream(null);
    pathname && pathname === "/streamstore" && setActiveVersion(null);
  }, [pathname]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <StreamStoreSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer">
        <div className="allCardsContainer">
          <div className="topDashboardContainer">
            <MddBreadCrumb
              backPagesNames={[{ name: "Home", url: "/" }]}
              currentPage="Streams store"
            />
            {(userRole === "admin" || userRole === "provider") && (
              <MddButton
                buttonInnerText="Créer un stream"
                buttonSize="long"
                iconColor="altButtonColor"
                buttonActionFunctionOne={handlingCreateNav}
                iconName="bi bi-plus-circle"
              />
            )}
          </div>
          {streamsAssigned ? (
            stockAPIStreams.length > 0 ? (
              <>
                <MddSearchBar filtersDatalist={streamStoreFilters} />

                {returningCards()}
              </>
            ) : (
              <article className="noElementDisclaimer">
                <h4>Aucun stream n'est disponible</h4>
                <p>
                  {userRole === "user"
                    ? "Aucun stream n'a été publié par les fournisseurs"
                    : "Commencez par créer votre premier stream"}
                </p>
                <MddButton
                  buttonInnerText="Créer un stream"
                  buttonSize="long"
                  buttonActionFunctionOne={handlingCreateNav}
                />
              </article>
            )
          ) : (
            <MddLoader />
          )}
        </div>
      </Dashboard>
    </>
  );
}
