import { useContext } from "react";

import { MddContext } from "../../../context/MddContext";

import SingleType from "./SingleType";

export default function RoomTypes({ allRooms, roomsFilter, setRoomsFilter }) {
  const {
    bedRoomsTemperature,
    setBedRoomsTemperature,
    livingRoomsTemperature,
    setLivingRoomsTemperature,
    diningRoomsTemperature,
    setDiningRoomsTemperature,
  } = useContext(MddContext);

  return (
    <section className="hotelTypesContainer">
      <SingleType
        rooms={allRooms.filter((room) => {
          return room.type === "bedroom";
        })}
        type="bedroom"
        roomsFilter={roomsFilter}
        setRoomsFilter={setRoomsFilter}
        roomsTemperature={bedRoomsTemperature}
        setRoomsTemperature={setBedRoomsTemperature}
      />
      <SingleType
        rooms={allRooms.filter((room) => {
          return room.type === "diningroom";
        })}
        type="diningroom"
        roomsFilter={roomsFilter}
        setRoomsFilter={setRoomsFilter}
        roomsTemperature={diningRoomsTemperature}
        setRoomsTemperature={setDiningRoomsTemperature}
      />
      <SingleType
        rooms={allRooms.filter((room) => {
          return room.type === "livingroom";
        })}
        type="livingroom"
        roomsFilter={roomsFilter}
        setRoomsFilter={setRoomsFilter}
        roomsTemperature={livingRoomsTemperature}
        setRoomsTemperature={setLivingRoomsTemperature}
      />
    </section>
  );
}
