import { useEffect, useContext, useState } from "react";

import { MddContext } from "../../context/MddContext";

import SingleVariable from "./SingleVariable";
import MddLoader from "../reusableElements/MddLoader";

export default function VariablesBody({
  allVariables,
  currentSort,
  editedVariable,
  setEditedVariable,
}) {
  const { filters, activeFilter, appRem } = useContext(MddContext);

  const [displayedVariables, setDisplayedVariables] = useState([]);

  const returningVariables = () => {
    setDisplayedVariables([]);
    let stockData = filteringVariables([...allVariables]);
    let stockVariables = [];
    if (currentSort === "default") {
      for (let i = 0; i < stockData.length; i++) {
        stockVariables.push(
          <SingleVariable
            editedVariable={editedVariable}
            setEditedVariable={setEditedVariable}
            variable={stockData[i]}
            key={`variable-${stockData[i].reference}-${i}`}
          />
        );
      }
    } else if (currentSort === "scope") {
      stockVariables.push(
        [...stockData]
          .sort((a, b) => {
            const cleanedA = a.scope.toLowerCase();
            const cleanedB = b.scope.toLowerCase();
            const diff = cleanedA.localeCompare(cleanedB);
            return diff;
          })
          .map((singleVariable) => {
            return (
              <SingleVariable
                editedVariable={editedVariable}
                setEditedVariable={setEditedVariable}
                variable={singleVariable}
                key={`variable-${singleVariable.id}`}
              />
            );
          })
      );
    } else if (currentSort === "scope-reverse") {
      stockVariables.push(
        [...stockData]
          .sort((a, b) => {
            const cleanedA = a.scope.toLowerCase();
            const cleanedB = b.scope.toLowerCase();
            const diff = cleanedB.localeCompare(cleanedA);
            return diff;
          })
          .map((singleVariable) => {
            return (
              <SingleVariable
                editedVariable={editedVariable}
                setEditedVariable={setEditedVariable}
                variable={singleVariable}
                key={`variable-${singleVariable.id}`}
              />
            );
          })
      );
    } else if (currentSort === "name") {
      stockVariables.push(
        [...stockData]
          .sort((a, b) => {
            const cleanedA = a.name.toLowerCase();
            const cleanedB = b.name.toLowerCase();
            const diff = cleanedA.localeCompare(cleanedB);
            return diff;
          })
          .map((singleVariable) => {
            return (
              <SingleVariable
                editedVariable={editedVariable}
                setEditedVariable={setEditedVariable}
                variable={singleVariable}
                key={`variable-${singleVariable.id}`}
              />
            );
          })
      );
    } else if (currentSort === "name-reverse") {
      stockVariables.push(
        [...stockData]
          .sort((a, b) => {
            const cleanedA = a.name.toLowerCase();
            const cleanedB = b.name.toLowerCase();
            const diff = cleanedB.localeCompare(cleanedA);
            return diff;
          })
          .map((singleVariable) => {
            return (
              <SingleVariable
                editedVariable={editedVariable}
                setEditedVariable={setEditedVariable}
                variable={singleVariable}
                key={`variable-${singleVariable.id}`}
              />
            );
          })
      );
    }
    // let stockRem = window.innerWidth > 800 ? 18 : 16;
    let stockHeightLeft =
      window.innerHeight -
      (2 * stockVariables.length * appRem + 2 * 11.5 * appRem);
    while (stockHeightLeft >= appRem) {
      stockVariables.push(
        <div className="variableContainer fullVariablePlaceHolder" />
      );
      stockHeightLeft -= appRem;
    }
    setDisplayedVariables([...stockVariables]);
  };

  const filteringVariables = (list) => {
    if (!activeFilter) return list;
    let stockElements = [];
    let currentFilter = filters.find((f) => f.key === activeFilter);
    for (let i = 0; i < currentFilter.elements.length; i++) {
      switch (currentFilter.elements[i].field) {
        case "tout":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.scope
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.value
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.scope
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.value
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          }
          break;
        case "scope":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.scope
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
          } else {
            stockElements = list.filter(
              (item) =>
                !item.scope
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          }
          break;
        case "nom":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.name
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          }
          break;
        case "valeur":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.value
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
          } else {
            stockElements = list.filter(
              (item) =>
                !item.value
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          }
          break;
        default:
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.scope
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.value
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.scope
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.value
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
          }
          break;
      }
    }
    return stockElements;
  };

  useEffect(() => {
    returningVariables();
    window.addEventListener("resize", returningVariables);
    return function exitEdit() {
      window.removeEventListener("resize", returningVariables);
    };
  }, [currentSort, activeFilter]);

  return (
    <section>
      {displayedVariables.length > 0 ? displayedVariables : <MddLoader />}
    </section>
  );
}
