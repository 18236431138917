import { useContext } from "react";

import { MddContext } from "../../../context/MddContext";

export default function WidgetPosition({ setNewWidget, newWidget, position }) {
  const { appRem } = useContext(MddContext);

  const returningPosition = () => {
    const stockX = document.getElementsByClassName(
      `widgetPosition${newWidget ? " lockedPosition" : ""}`
    )[0].offsetLeft;
    const stockY = document.getElementsByClassName(
      `widgetPosition${newWidget ? " lockedPosition" : ""}`
    )[0].offsetTop;

    return {
      x0: Math.round(stockX / (appRem * 2)),
      x1: Math.round(stockX / (appRem * 2)) + 11,
      y0: Math.round(stockY / (appRem * 2)),
      y1: Math.round(stockY / (appRem * 2)) + 8,
    };
  };

  // const buildingStyle = () => {
  //   return {
  //     gridColumn: `${position.x0} / ${position.x1}`,
  //     gridRow: `${position.y0} / ${position.y1}`,
  //     width: `${(position.x1 - position.x0) * 2 - 1}rem`,
  //     height: `${(position.y1 - position.y0) * 2 - 1}rem`,
  //   };
  // };

  return (
    <button
      className={`widgetPosition${newWidget ? " lockedPosition" : ""}`}
      type="button"
      aria-label=""
      title=""
      style={{
        gridColumn: `${position.x0} / ${position.x1}`,
        gridRow: `${position.y0} / ${position.y1}`,
        width: `${(position.x1 - position.x0) * 2 - 1}rem`,
        height: `${(position.y1 - position.y0) * 2 - 1}rem`,
      }}
      onClick={(e) => {
        e.preventDefault();
        setNewWidget(returningPosition());
      }}
    >
      <i className="bi bi-plus-lg" />
    </button>
  );
}
