import { useContext } from "react";

import { MddContext } from "../../../context/MddContext";

import MddButton from "../../reusableElements/MddButton";

export default function ArrivalsSidebar({
  flightsSidebar,
  setFlightsSidebar,
  flightsResponse,
  flightsRequest,
}) {
  const { demoToken } = useContext(MddContext);
/*
  console.log(
    "arrivals sidebar : ",
    flightsRequest,
    flightsResponse,
    demoToken
  );
*/
  return (
    <section
      className={`flightsSidebar${
        flightsSidebar ? " fullSideBarContainer" : ""
      }`}
    >
      {flightsSidebar && (
        <>
          <p>Requête</p>
          <pre className="requestContainer">
            <code>
              {`{
                  method: 'get',
                  url: 'https://localhost:4455/app/flights-arrivals/1.0/api${flightsRequest}',
                  headers: { 
                    'Authorization': 'Bearer ${demoToken}'
                  }
                };`
              }
            </code>
          </pre>
          <p>Réponse</p>
          <pre className="responseContainer">
            <code>{JSON.stringify(flightsResponse, null, 2)}</code>
          </pre>
        </>
      )}
      <MddButton
        iconName={`bi bi-arrow-${flightsSidebar ? "left" : "right"}-square`}
        buttonSize="small"
        smallButtonDescription="Open sidebar"
        buttonActionFunctionOne={setFlightsSidebar}
        buttonActionPropOne={!flightsSidebar}
        iconColor="altButtonColor"
      />
    </section>
  );
}
