export default function classingIncorrectInput(nameInput, listToCheck) {
  if (
    listToCheck.some((element) => {
      return element === nameInput;
    })
  ) {
    return "";
  } else {
    return "incorrectInput";
  }
}
