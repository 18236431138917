import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import flightsArrivals from "../../api/demo/flightsArrivals";
import "../../styles/demo/flightsArrivals.css";

import Dashboard from "../../components/Dashboard";
import MddLoader from "../../components/reusableElements/MddLoader";
import FlightCard from "../../components/demo/flightsArrivals/FlightCard";
import ArrivalsHeader from "../../components/demo/flightsArrivals/ArrivalsHeader";
import ArrivalsSidebar from "../../components/demo/flightsArrivals/ArrivalsSidebar";
import TokenForm from "../../components/reusableElements/TokenForm";
import MddButton from "../../components/reusableElements/MddButton";

export default function FlightsArrivals() {
  const {
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    closingSession,
    setActiveFilter,
    activeFilter,
    filters,
    handling401,
    succesfullChallenge,
    demoToken,
    setDemoToken,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [currentlyTyping, setCurrentlyTyping] = useState("");
  const [filterElements, setFilterElements] = useState([]);
  const [flightsNav, setFlightsNav] = useState("estimations");
  const [flightsSidebar, setFlightsSidebar] = useState(false);
  const [flightsObjects, setFlightsObjects] = useState([]);
  const [displayedFlights, setDisplayedFlights] = useState([]);
  const [liveRefresh, setLiveRefresh] = useState(false);
  const [flightsRefresh, setFlightsRefresh] = useState(10000);
  const [failedRequest, setFailedRequest] = useState(false);
  const [flightsResponse, setFlightsResponse] = useState(
    "Le résultat de la requête s'affichera ici"
  );
  const [flightsRequest, setFlightsRequest] = useState("");

  const positioningNav = () => {
    if (flightsNav === "previsions") {
      return { left: "13rem" };
    } else if (flightsNav === "estimations") {
      return { left: "0" };
    } else {
      return { opacity: "0" };
    }
  };
  const positioningToggle = () => {
    if (liveRefresh) {
      return { left: "4rem" };
    } else if (!liveRefresh) {
      return { left: "0" };
    } else {
      return { opacity: "0" };
    }
  };

  const addingFilterElement = () => {
    const options = document.getElementById("filterOptions").childNodes;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === currentlyTyping) {
        let stockFilter = filterElements;
        if (
          filterElements.length === 0 ||
          filterElements[filterElements.length - 1].criteria
        ) {
          stockFilter.push({ field: currentlyTyping });
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        } else if (
          filterElements[filterElements.length - 1].field &&
          !filterElements[filterElements.length - 1].operator
        ) {
          stockFilter[stockFilter.length - 1].operator =
            currentlyTyping === "contient" || currentlyTyping === "="
              ? "="
              : "!=";
          setFilterElements(stockFilter);
          setCurrentlyTyping("");
          break;
        }
      } else if (
        currentlyTyping.includes("> Chercher ") &&
        filterElements[filterElements.length - 1].operator &&
        !filterElements[filterElements.length - 1].criteria
      ) {
        let stockFilter = filterElements;
        stockFilter[stockFilter.length - 1].criteria = currentlyTyping.slice(
          11,
          currentlyTyping.length
        );
        // .toLowerCase();
        setFilterElements(stockFilter);
        setCurrentlyTyping("");
        break;
      }
    }
  };

  const retrievingFlights = (filtering) => {
    if (localStorage.getItem("mddTheme")) {
      if (closingSession()) {
        history.push("/login");
      } else {
        flightsArrivals
          .get(`/api${buildingParams()}`, {
            headers: {
              Authorization: `Bearer ${demoToken}`,
            },
          })
          .then((response) => {
            const informations = response.data.data[0].flights;
            setFlightsObjects([...informations]);
            setDisplayedFlights([...informations]);
            setFlightsResponse(informations);
          })
          .catch((err) => {
            console.log("erreur au niveau de la requete API : ", err);
            if (err && err.response && err.response.status === 401) {
              feedBackBuilder(false, "Le token n'est pas valide", "Demos");
            } else if (err && err.response && err.response.status === 500) {
              feedBackBuilder(
                false,
                "La requête n'a pas pu être traitée. Réessayez ultérieurement.",
                "Demos"
              );
            } else {
              feedBackBuilder(
                false,
                "Les vols n'ont pas été récupérés",
                "Demos"
              );
            }
            setFailedRequest(true);
          });
      }
    }
  };

  const buildingParams = () => {
    const translatingField = (inputField) => {
      switch (inputField) {
        case "destination":
          return "destination";
        case "provenance":
          return "departure";
        case "arrivée estimée":
          return "estimatedTime";
        case "arrivée prévue":
          return "targetedTime";
        case "compagnie":
          return "company";
        case "vol":
          return "flight";
        case "numéro d'appareil":
          return "aircraftID";
        case "piste":
          return "runway";
        case "départ":
          return "departureTime";
        default:
          return "destination";
      }
    };
    if (activeFilter) {
      let stockFilter = filters.find((singleFilter) => {
        return singleFilter.key === activeFilter;
      });
      let stockParams = `?timeout=10000&`;
      for (let i = 0; i < stockFilter.elements.length; i++) {
        stockParams =
          stockParams +
          `${translatingField(
            stockFilter.elements[i].field
          )}=${stockFilter.elements[i].operator.replace(
            "=",
            ""
          )}${stockFilter.elements[i].criteria.toUpperCase()}&`;
      }
      if (stockParams === `?`) {
        setFlightsRequest("");
        return "";
      } else {
        setFlightsRequest(stockParams.slice(0, -1));
        return stockParams.slice(0, -1);
      }
    } else {
      setFlightsRequest("");
      return "?timeout=10000";
    }
  };

  useEffect(() => {
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
    setActiveFilter(null);
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    let refreshingStreams = null;
    if (liveRefresh) {
      refreshingStreams = setInterval(() => {
        retrievingFlights();
      }, flightsRefresh);
    }
    return function exitFlights() {
      clearInterval(refreshingStreams);
      setDemoToken(null);
    };
  }, [liveRefresh, flightsRefresh]);

  useEffect(() => {
    if (activeFilter) {
      demoToken && retrievingFlights(true);
    } else {
      demoToken && retrievingFlights();
    }
  }, [filters, activeFilter, demoToken]);

  useEffect(() => {
    if (succesfullChallenge) {
      if (activeFilter) {
        demoToken && retrievingFlights(true);
      } else {
        demoToken && retrievingFlights();
      }
    }
  }, [succesfullChallenge]);

  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);

  useEffect(() => {
    document.getElementById("filterOptions") && addingFilterElement();
  }, [currentlyTyping]);

  return (
    <Dashboard containerClass="dashboardContainer">
      <div className="exportGlobalContainer flightsGlobalContainer">
        {!demoToken && <TokenForm />}
        <ArrivalsHeader
          flightsNav={flightsNav}
          positioningNav={positioningNav}
          setFlightsNav={setFlightsNav}
          liveRefresh={liveRefresh}
          positioningToggle={positioningToggle}
          setLiveRefresh={setLiveRefresh}
          retrievingFlights={retrievingFlights}
        />
        <article className="listHeader">
          <p>arrivée</p>
          <p>destination</p>
          <p>provenance</p>
          <p>compagnie</p>
          <p>vol</p>
          <p>piste</p>
        </article>
        {failedRequest ? (
          <MddButton
            buttonInnerText="réessayer"
            iconColor="basicButtonColor"
            buttonActionFunctionOne={setDemoToken}
            buttonActionPropOne={null}
            buttonActionFunctionTwo={setFailedRequest}
            buttonActionPropTwo={false}
          />
        ) : displayedFlights ? (
          displayedFlights.map((flight) => {
            return <FlightCard flight={flight} flightsNav={flightsNav} />;
          })
        ) : (
          <MddLoader />
        )}
        <ArrivalsSidebar
          flightsSidebar={flightsSidebar}
          setFlightsSidebar={setFlightsSidebar}
          flightsResponse={flightsResponse}
          flightsRequest={flightsRequest}
        />
        {flightsSidebar && <div className="flightsShadow" />}
      </div>
    </Dashboard>
  );
}
