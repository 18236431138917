import { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import "../style/dashboard/dashboard.css";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";
import { bar } from "../utils/templates/bar";
import { line } from "../utils/templates/line";
import { bubble } from "../utils/templates/bubble";
import { doughnut } from "../utils/templates/doughnut";
import { pie } from "../utils/templates/pie";
import { polar } from "../utils/templates/polar";
import { radar } from "../utils/templates/radar";

import Dashboard from "../../components/Dashboard";
import DataWidget from "../components/dashboard/DataWidget";
import DatavizHeader from "../components/dashboard/DatavizHeader";
import WidgetPosition from "../components/dashboard/WidgetPosition";
import WidgetTypesModal from "../components/dashboard/WidgetTypesModal";
import DatavizSidebar from "../components/dashboard/sidebar/DatavizSidebar";
import MddLoader from "../../components/reusableElements/MddLoader";

export default function DatavizDashboard() {
  const {
    handling401,
    setShowSideBar,
    setFullScreen,
    setFullSideBar,
    closingSession,
    userToken,
    feedBackBuilder,
    appRem,
  } = useContext(MddContext);
  let history = useHistory();
  const { dashboard_id } = useParams();

  const [dashboardData, setDashboardData] = useState(null);
  const [widgetsList, setWidgetsList] = useState([]);
  const [newWidget, setNewWidget] = useState(null);
  const [datavizSidebar, setDatavizSidebar] = useState(null);
  const [dashboardAssigned, setDashboardAssigned] = useState(false);
  // const [widgetsAssigned, setWidgetsAssigned] = useState(false);
  const [editedWidget, setEditedWidget] = useState(null);
  const [widgetPositions, setWidgetPositions] = useState([]);

  const parsingWidgets = (widgetsInput) => {
    let stockWidgets = [...widgetsInput];
    for (let i = 0; i < stockWidgets.length; i++) {
      stockWidgets[i].index = i;
    }
    console.log("parsing widgets : ", stockWidgets);
    // widgetsList.current = stockWidgets;
    setWidgetsList(stockWidgets);
    // setWidgetsAssigned(true);
  };

  const retrievingDashboard = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setDashboardAssigned(false);
      mddStreamStoreApi
        .get(`/dataviz/${dashboard_id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          console.log("retrieving dashboard : ", stock);
          setDashboardData(stock);
          // setWidgetsList(stock.widgets);
          parsingWidgets(stock.widgets);
          setDashboardAssigned(true);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          // setChallengedCall(() => retrievingStream());
          handling401(err, history);
        });
    }
  };

  const deletingDashboard = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setDashboardAssigned(false);
      mddStreamStoreApi
        .delete(`/dataviz/${dashboard_id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          console.log("retrieving dashboard : ", stock);
          feedBackBuilder(true, "Dashboard supprimé", "Dataviz");
          history.push("/dataviz");
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          // setChallengedCall(() => retrievingStream());
          handling401(err, history, "Le dashboard n'a pas été supprimé");
        });
    }
  };

  const updatingDashboard = (inputName, inputValue) => {
    // setWidgetsAssigned(false);
    let stockDashboard = { ...dashboardData };
    if (inputName === "title") {
      stockDashboard.title = inputValue;
    } else if (inputName === "addingWidget") {
      const returningComponent = () => {
        switch (inputValue.type) {
          case "line":
            return line;
          case "bar":
            return bar;
          case "bubble":
            return bubble;
          case "doughnut":
            return doughnut;
          case "pie":
            return pie;
          case "polarArea":
            return polar;
          case "radar":
            return radar;
          default:
            return {
              settings: {
                datasets: [
                  {
                    label: "",
                    backgroundColor: "",
                    borderColor: "",
                    pointBackgroundColor: "",
                    pointBorderColor: "",
                    pointRadius: 0,
                    data: [],
                    cubicInterpolationMode: "",
                    tension: 0.4,
                  },
                ],
              },
              config: {
                maintainAspectRatio: false,
                scales: {
                  left: {
                    type: "linear",
                    display: true,
                    position: "left",
                  },
                  center: {
                    type: "linear",
                    display: true,
                    position: "center",
                  },
                  right: {
                    type: "linear",
                    display: true,
                    position: "right",
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    label: "",
                  },
                },
              },
            };
        }
      };
      const stockComponent = returningComponent();
      const stockWidgets = [
        ...widgetsList,
        {
          title: "",
          position: inputValue.position,
          type: inputValue.type,
          style: {
            header: {
              backgroundColor: "",
            },
            title: {
              color: "",
              fontSize: "",
              fontFamily: "",
              fontWeight: "",
              fontType: "",
              textAlign: "",
            },
            body: {
              backgroundColor: "",
            },
            card: {
              border: "",
              boxShadow: "",
            },
          },
          component: stockComponent,
          mapping: {
            timeserie: "",
            json: "",
            keys: "",
            values: "",
            table: "",
          },
        },
      ];
      stockDashboard.widgets = stockWidgets;
    } else if (inputName === "widget") {
      setEditedWidget(inputValue.index);
      let stockWidgets = [...widgetsList];
      stockWidgets.splice(inputValue.index, 1, inputValue);
      stockDashboard.widgets = stockWidgets;
    } else if (inputName === "deletingWidget") {
      let stockWidgets = [...widgetsList];
      stockWidgets.splice(inputValue.index, 1);
      stockDashboard.widgets = stockWidgets;
    }
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .put(`/dataviz/${dashboardData.id}`, stockDashboard, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setDashboardData(stock);
          // setDashboardTitle(stock.title);
          parsingWidgets(stock.widgets);
          feedBackBuilder(true, "Dashboard modifié", "Dataviz");
          setTimeout(() => {
            setEditedWidget(null);
          }, 100);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          // setChallengedCall(() => retrievingStream());
          handling401(err, history, "Le dashboard n'a pas été créé");
        });
    }
  };

  const returningWidgets = () => {
    let stockWidgets = [];
    for (let i = 0; i < widgetsList.length; i++) {
      try {
        stockWidgets.push(
          <DataWidget
            number={i}
            widget={widgetsList[i]}
            // datavizSidebar={datavizSidebar}
            setDatavizSidebar={setDatavizSidebar}
            updatingDashboard={updatingDashboard}
            editedWidget={editedWidget}
            // setEditedWidget={setEditedWidget}
          />
        );
      } catch (err) {
        console.log("returning widgets : ", err);
      }
    }
    return stockWidgets;
  };

  const returningWidgetPositions = () => {
    let stockPositions = [];
    let stockGrid = document.getElementsByClassName("datavizDashboard");
    if (stockGrid.length > 0) {
      // let x = getComputedStyle(stockGrid[0])
      //   .getPropertyValue("grid-template-columns")
      //   .split(" ").length;
      // let y = getComputedStyle(stockGrid[0])
      //   .getPropertyValue("grid-template-rows")
      //   .split(" ").length;
      let x = Math.round(stockGrid[0].clientWidth / (appRem * 2));
      let y = Math.round(stockGrid[0].clientHeight / (appRem * 2));
      let stockColumns = `${appRem}px`;
      for (let i = 1; i < x; i++) {
        stockColumns = stockColumns + ` ${appRem}px`;
      }
      let stockRows = `${appRem}px`;
      for (let i = 1; i < y; i++) {
        stockRows = stockRows + ` ${appRem}px`;
      }
      stockGrid[0].style.gridTemplateColumns = stockColumns;
      stockGrid[0].style.gridTemplateRows = stockRows;
      for (let i = 1; i < x + 1; i++) {
        // console.log("returning widget positions x : ", x, i);
        for (let j = 1; j < y + 1; j++) {
          // console.log("returning widget positions y : ", y, j);
          if (
            stockPositions.some((position) => {
              return (
                position.x0 <= i &&
                position.x1 >= i &&
                position.y0 <= j &&
                position.y1 >= j
              );
            }) ||
            widgetsList.some((widget) => {
              return (
                widget.position.x0 <= i &&
                widget.position.x1 >= i &&
                widget.position.y0 <= j &&
                widget.position.y1 >= j
              );
            })
          ) {
            continue;
          } else {
            stockPositions.push({ x0: i, x1: i + 11, y0: j, y1: j + 8 });
          }
        }
      }
      let stockWidgetPositions = [];
      for (let k = 0; k < stockPositions.length; k++) {
        stockWidgetPositions.push(
          <WidgetPosition
            setNewWidget={setNewWidget}
            newWidget={newWidget}
            position={stockPositions[k]}
          />
        );
      }
      setWidgetPositions(stockWidgetPositions);
    }
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
    setShowSideBar(false);
    setFullSideBar(false);
    setFullScreen(false);
    retrievingDashboard();
    // returningWidgetPositions();
  }, []);

  useEffect(() => {
    returningWidgetPositions();
  }, [dashboardAssigned, dashboardData]);

  return (
    <Dashboard containerClass="dashboardContainer datavizGlobalContainer">
      {dashboardAssigned ? (
        <>
          <DatavizHeader
            datavizSidebar={datavizSidebar}
            setDatavizSidebar={setDatavizSidebar}
            dashboard={dashboardData}
            updatingDashboard={updatingDashboard}
          />
          <DatavizSidebar
            datavizSidebar={datavizSidebar}
            setDatavizSidebar={setDatavizSidebar}
            dashboardData={dashboardData}
            deletingDashboard={deletingDashboard}
            updatingDashboard={updatingDashboard}
          />
          <section
            className="datavizDashboard"
            onDragOver={(e) => {
              e.preventDefault();
            }}
          >
            {widgetsList.length > 0
              ? returningWidgets()
              : !newWidget && (
                  <button
                    type="button"
                    aria-label=""
                    title=""
                    className="firstWidget"
                    onClick={(e) => {
                      e.preventDefault();
                      setNewWidget({
                        x0: 1,
                        x1: 12,
                        y0: 1,
                        y1: 9,
                      });
                    }}
                  >
                    Pour commencer, ajoutez un widget
                  </button>
                )}
            {widgetPositions.length > 0 && widgetPositions}
            {newWidget && (
              <WidgetTypesModal
                setNewWidget={setNewWidget}
                // setWidgetsList={setWidgetsList}
                // widgetsList={widgetsList}
                newWidget={newWidget}
                updatingDashboard={updatingDashboard}
              />
            )}
          </section>{" "}
        </>
      ) : (
        <MddLoader />
      )}
    </Dashboard>
  );
}
