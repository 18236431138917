import { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import returningInputWidth from "../../utils/returningInputWIdth";
import "../../style/dashboard/header.css";

import MddButton from "../../../components/reusableElements/MddButton";

export default function DatavizHeader({
  datavizSidebar,
  setDatavizSidebar,
  dashboard,
  updatingDashboard,
}) {
  let history = useHistory();

  const [dashboardTitle, setDashboardTitle] = useState(
    dashboard ? dashboard.title : "Titre"
  );

  return (
    <section className="datavizHeader">
      <article>
        <MddButton
          buttonInnerText="retour"
          iconColor="altButtonColor"
          buttonActionFunctionOne={history.push}
          buttonActionPropOne="/dataviz"
        />
      </article>
      <input
        type="text"
        placeholder="titre"
        value={dashboardTitle}
        onChange={(e) => {
          setDashboardTitle(e.target.value);
        }}
        onBlur={() => {
          updatingDashboard("title", dashboardTitle);
        }}
        onKeyUp={(e) => {
          (e.key === "Enter" || e.keyCode === 13) &&
            updatingDashboard("title", dashboardTitle);
        }}
        style={{ width: returningInputWidth(dashboardTitle) + "rem" }}
      />
      <article>
        {/* <MddButton
          iconName="bi bi-pencil"
          buttonSize="small"
          smallButtonDescription="edit dashboard"
          iconColor="altButtonColor"
        /> */}
        <MddButton
          iconName="bi bi-tools"
          buttonSize="small"
          smallButtonDescription="dashboard options"
          iconColor="altButtonColor"
          buttonActionFunctionOne={setDatavizSidebar}
          buttonActionPropOne={{ type: "dashboard" }}
        />
        <MddButton
          iconName="bi bi-share"
          buttonSize="small"
          smallButtonDescription="share dashboard"
          iconColor="altButtonColor"
        />
        {/* <MddButton
          iconName="bi bi-x-lg"
          buttonSize="small"
          smallButtonDescription="close dashboard"
          iconColor="altButtonColor"
          buttonActionFunctionOne={history.push}
          buttonActionPropOne="/dataviz"
        /> */}
      </article>
    </section>
  );
}
