import { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { MddContext } from "../../../../context/MddContext";
import "../../../../styles/streamStore/detailedStream/packagesModals.css";
import mddStreamStoreApi from "../../../../api/mddStreamStoreApi";
import revertingToken from "../../../../utils/auth/revertingToken";

import MddButton from "../../../reusableElements/MddButton";
//import NodesModal from "./NodesModal";
import NodesModalWithScopes from "./NodesModalWithScopes";
import EndpointsModal from "./EndpointsModal";

export default function PackageClause({
  clause,
  activeVersion,
  postingClause,
  updatingClauses,
}) {
  const {
    activeStream,
    currentTeam,
    setActiveClause,
    closingSession,
    userToken,
    handling401,
    setActiveStream,
    setActiveVersion,
    setActiveLinked,
    reverseActive,
  } = useContext(MddContext);
  let history = useHistory();

  const [logsLevel, setLogsLevel] = useState(
    clause.logLevel ? clause.logLevel : "info"
  );
  const [nodesModal, setNodesModal] = useState(false);
  const [endpointsModal, setEndpointsModal] = useState(false);
  const [clauseEndpoints, setClauseEndpoints] = useState(
    clause.endpoints ? clause.endpoints : 0
  );
  const [clauseNodes, setClauseNodes] = useState(
    clause.nodes ? [...clause.nodes] : []
  );
  const [allNodes, setAllNodes] = useState([]);

  const retrievingAllNodes = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/version/nodes/${activeVersion.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          const executedNodes = response.data.data;
          let nodes = [];
          
          executedNodes.forEach(node => {
            let used = clause.nodes.find(nodeName => nodeName === node.name)!=undefined;
            nodes.push({ name: node.name, host: node.host, available: node.status==='ONLINE', used: used });   
          });
          
          /*
          clause.nodes.forEach(nodeName => {
            let found = executedNodes.find(node => node.name === nodeName)!=undefined;
            if (! found)
                nodes.push({ name: nodeName, host: '', available: false, used: true });   
          });
          */
          
          setAllNodes([...nodes]);
        })
        .catch((err) => {
          console.log(
            "Listing des nodes impossible : ",
            err
          );
          handling401(
            err,
            history,
            `Accès non autorisé`
          );
        });
    }
  };

  const returningClausesPlaceHolders = (depth) => {
    let stockPlaceholders = [];
    if (depth > 1) {
      for (let j = 1; j < depth; j++) {
        stockPlaceholders.push(<div className="treePlaceHolder" />);
      }
      return stockPlaceholders;
    }
  };

  const statusPicker = (inputValue) => {
    switch (inputValue) {
      case "OFF":
        return <div className="treeStatus statusOff" />;
      case "OK":
        return <div className="treeStatus statusOk" />;
      case "WARNING":
        return <div className="treeStatus statusWarning" />;
      case "DEGRADED":
        return <div className="treeStatus statusAlert" />;
      case "FATAL":
        return <div className="treeStatus statusFatal" />;
      case "UNKNOWN":
        return (
          <i className="bi bi-question-circle-fill treeStatus statusWarning" />
        );

      default:
        return (
          <i className="bi bi-question-circle-fill treeStatus statusWarning" />
        );
    }
  };

  const removeModals = (e) => {
    if (
      (e.target.getAttribute("class") &&
        e.target.getAttribute("class").includes("endpointsModal")) ||
      (e.target.getAttribute("id") &&
        e.target.getAttribute("id") === `clauseUri - ${clause.uuid}`) ||
      (e.target.getAttribute("class") &&
        e.target
          .getAttribute("class")
          .includes(`endpointsButton-${clause.uuid}`))
    ) {
    } else {
      setEndpointsModal(false);
    }
    if (
      (e.target.getAttribute("class") &&
        e.target.getAttribute("class").includes("nodesModal")) ||
      (e.target.getAttribute("class") &&
        e.target.getAttribute("class").includes(`modalButton-${clause.uuid}`))
    ) {
    } else {
      setNodesModal(false);
    }
  };

  const redirectingToSource = () => {
    setActiveStream(null);
    setActiveVersion(null);
    history.push(`/streamstore/${clause.source.reference}#general`);
  };
    
  const redirectingToLinkedStream = () => {
  };    
    
  const distributionModeAction = () => {    
  };
  
  const buildingLinkedStreams = () => {
    let links = [];
    if (clause.source) {
        links.push(clause.source);
    }
    if (clause.linked) {
        clause.linked.forEach(link => links.push(link));
    }
    
    if (links.length > 0)
        setActiveLinked([...links]);
  };

  const buildingClassName = () => {
    let stockName = "singleClause";
    if (
      reverseActive &&
      ((clause.linked && clause.linked.includes(reverseActive)) ||
        (clause.source && clause.source === reverseActive))
    ) {
      stockName += " activeClause";
    }
    return stockName;
  };

  useEffect(() => {
    document.addEventListener("click", removeModals);
    return function exitEdit() {
      document.removeEventListener("click", removeModals);
    };
  }, []);

  useEffect(() => {
    /*nodesModal &&*/ activeVersion && retrievingAllNodes();
  }, [nodesModal]);

  useEffect(() => {
    let clauses = [];
    clauses.push(clause);
    updatingClauses(clauses);
    //console.log(`clauseNodes=${clauseNodes}`);
  }, [clauseNodes])

  return (
    <article
      className={buildingClassName()}
      id={`clause-${clause.uuid}`}
      onMouseEnter={() => {
        buildingLinkedStreams();
      }}
      onMouseLeave={() => {
        setActiveLinked([]);        
      }}
    >
      <div
        style={
          activeStream &&
          activeStream.editable &&
          (activeStream.provider === currentTeam || currentTeam === "all")
            ? null
            : { width: "calc(100% - 42rem)" }
        }
      >
        <div>
          {statusPicker(clause.status)}
          {returningClausesPlaceHolders(clause.deep)}
          {
            clause.name.match("from|when|otherwise") ?
            (<p>{clause.name} <div className="clauseFlowLabel">({clause.label})</div></p>) :
            (<p>{clause.name} <div className="clauseLabel"> {clause.label}</div></p>) 
          }
          
        </div>
        {activeStream &&
          activeStream.editable &&
          activeStream.provider === currentTeam && (
            <select
              name="selectLogLevelForm"
              id="selectLogLevelForm"
              className="logsLevelSelect"
              onChange={(e) => {
                setLogsLevel(e.target.value);
                postingClause({
                  clause: clause.uuid,
                  logLevel: e.target.value,
                });
              }}
            >
              <option value="TRACE" selected={logsLevel === "TRACE"}>
                TRACE
              </option>
              <option value="DEBUG" selected={logsLevel === "DEBUG"}>
                DEBUG
              </option>
              <option value="INFO" selected={logsLevel === "INFO"}>
                INFO
              </option>
              <option value="WARNING" selected={logsLevel === "WARNING"}>
                WARN
              </option>
              <option value="ERROR" selected={logsLevel === "ERROR"}>
                ERROR
              </option>
              <option value="FATAL" selected={logsLevel === "FATAL"}>
                FATAL
              </option>
            </select>
          )}
      </div>
      <div>
        {clause.name.match('(from|to|broadcast|map|reduce|parallel|store|read)') && clause.url.match('.*(mddynamics.auto=true|mddynamics.auto(&|$)).*') && ( 
          <MddButton
            iconName="bi bi-shuffle"
            buttonSize="small"
            smallButtonDescription={`Load-balancing [${ clause.params.filter(param => param.startsWith('mddynamics.auto')) }]`}
            iconColor="altButtonColor"
            inactiveTitle={`Load-balancing [${ clause.params.filter(param => param.startsWith('mddynamics.auto')) }]`}
            buttonActionFunctionOne={distributionModeAction}
          />
        ) || 
        clause.name.match('(from|to|broadcast|map|reduce|parallel|store|read)') && clause.url.match('.*mddynamics.id=.*') && ( 
          <MddButton
            iconName="bi bi-alt"
            buttonSize="small"
            smallButtonDescription={`Routing [${ clause.params.filter(param => param.startsWith('mddynamics.id')) }]`}
            iconColor="altButtonColor"
            inactiveTitle={`Routing [${ clause.params.filter(param => param.startsWith('mddynamics.id')) }]`}
            buttonActionFunctionOne={distributionModeAction}
          />
        ) || 
        clause.name.match('(to|broadcast|map|reduce|parallel|store|read)') && ( 
          <MddButton
            iconName="bi bi-wind"
            buttonSize="small"
            smallButtonDescription={`Broadcasting message to all endpoints`}
            iconColor="altButtonColor"
            inactiveTitle={`Broadcasting message to all endpoints`}
            buttonActionFunctionOne={distributionModeAction}
          />
        ) ||   
        (
            <div style={{ width: "2rem", height: "0.5rem" }} />
        )}
        <p
          id={`clauseUri - ${clause.uuid}`}
          style={
            { width: "calc(100% - 2rem)" }
          }
        >
          { !clause.local ? clause.uri : '' }
        </p>        
        {clause.source && (
          <MddButton
            iconName="bi bi-file-earmark-arrow-up"
            buttonSize="small"
            smallButtonDescription="See sources"
            iconColor="altButtonColor"
            inactiveTitle="voir la source"
            buttonActionFunctionOne={redirectingToSource}
          />
        )}
        {clause.linked && (
          <MddButton
            iconName="bi bi-file-earmark-arrow-down"
            buttonSize="small"
            smallButtonDescription="See linked stream"
            iconColor="altButtonColor"
            inactiveTitle="voir le stream lié"
            buttonActionFunctionOne={redirectingToLinkedStream}
          />
        )}
        {!clause.source && ! clause.linked && (
            <div style={{ width: "2rem", height: "0.5rem" }} />
          )              
        }
      </div>      
      {activeStream &&
        activeStream.editable &&
        (activeStream.provider === currentTeam || currentTeam === "all") && (
          <MddButton
            buttonInnerText={ !clause.local ? (clauseNodes ? clauseNodes.length : 0) : ''}
            buttonSize="small"
            smallButtonDescription="Select nodes"
            iconColor={`altButtonColor modalButton-${clause.uuid}${
              clauseNodes.length === 0
                ? " statusAlert"
                : allNodes.filter(node => node.used===true && node.available===false).length > 0
                ? " statusWarning"
                : ""
            }`}
            buttonActionFunctionOne={setNodesModal}
            buttonActionPropOne={!nodesModal}
            inactive={clause.source}
            inactiveTitle={
              clause.source
                ? "Les nodes doivent être sélectionnés sur le stream source"
                : null
            }
          />
        )}
      {activeStream &&
        activeStream.editable &&
        (activeStream.provider === currentTeam || currentTeam === "all") && (
          <MddButton
            buttonInnerText={
              !clause.local ?          
                (clauseNodes && clauseEndpoints ?
                    (clauseNodes.length==0 ? clauseEndpoints : clauseNodes.length * clauseEndpoints) : 0
                  )
                : ''
            }
            buttonSize="small"
            smallButtonDescription="Select endpoints"
            iconColor={`altButtonColor endpointsButton-${clause.uuid}`}
            buttonActionFunctionOne={setEndpointsModal}
            buttonActionPropOne={!endpointsModal}
            inactive={clause.source}
            inactiveTitle={
              clause.source
                ? "Les endpoints doivent être sélectionnés sur le stream source"
                : null
            }
          />
        )}
      {activeStream &&
        activeStream.editable &&
        activeStream.provider === currentTeam && (
          <Link
            to={{
              pathname: `/streamstore/${activeVersion.reference}/logs`,
            }}
          >
            <MddButton
              iconName="bi bi-file-earmark-text"
              buttonSize="small"
              smallButtonDescription="See clause logs"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setActiveClause}
              buttonActionPropOne={clause}
            />
          </Link>
        )}
      <EndpointsModal
        endpointsModal={endpointsModal}
        clause={clause}
        clauseEndpoints={clauseEndpoints}
        setClauseEndpoints={setClauseEndpoints}
        setEndpointsModal={setEndpointsModal}
        postingClause={postingClause}
      />
      {/*
      <NodesModal
        clause={clause}
        nodesModal={nodesModal}
        setNodesModal={setNodesModal}
        allNodes={allNodes}
        clauseNodes={clauseNodes}
        setClauseNodes={setClauseNodes}
        postingClause={postingClause}
      />
      */} 
      <NodesModalWithScopes
        clause={clause}
        nodesModal={nodesModal}
        setNodesModal={setNodesModal}
        allNodes={allNodes} 
        currentPage={1}    
        setClauseNodes={setClauseNodes} 
      />
    </article>
  );
}
