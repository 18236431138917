import { useState } from "react";

import "../../../../styles/variables/variablesManagement.css";

import VariablesBody from "../../../variables/VariablesBody";
import MddSearchBar from "../../../reusableElements/MddSearchBar";
import MddButton from "../../../reusableElements/MddButton";

const fakeVariables = [
  {
    id: "YtezyjJHGgee4244e1e12e12e1",
    name: "APP_TEST",
    value: 12,
    format: "number",
    scope: "app",
    used: true,
    description: "texte de description",
  },
  {
    id: "Ytezyejdnfugi4244e1e12e12e1",
    name: "APP_TEST2",
    value: 56,
    format: "number",
    scope: "app",
    used: true,
    description: "texte de description 2",
  },
  {
    id: "YtezyjJHGgee42fkgndjqie12e1",
    name: "APP_TEST3",
    value: "test",
    format: "string",
    scope: "app",
    used: true,
    description: "texte de description 3",
  },
  {
    id: "fjsnhqyjJHGgee4244e1e12e12e1",
    name: "APP_TEST4",
    value: 105,
    format: "number",
    scope: "app",
    used: false,
    description: "texte de description 4",
  },
  {
    id: "YtezyjJHGgee4244e1e1lzokfe1",
    name: "ENV_TEST",
    value: 12,
    format: "number",
    scope: "business",
    used: true,
    description: "texte de description",
  },
  {
    id: "Ytezpaoqldj4e1e12e12e1",
    name: "ENV_TEST2",
    value: 56,
    format: "number",
    scope: "business",
    used: true,
    description: "texte de description 2",
  },
  {
    id: "paofldjJHGgee4244e1e12e12e1",
    name: "ENV_TEST3",
    value: "test",
    format: "string",
    scope: "business",
    used: true,
    description: "texte de description 3",
  },
  {
    id: "nsjfhcJHGgee4244e1e12e12e1",
    name: "ENV_TEST4",
    value: 105,
    format: "number",
    scope: "business",
    used: false,
    description: "texte de description 4",
  },
  {
    id: "uhdnsjgee4244e1e12e12e1",
    name: "ENV_TEST",
    value: 12,
    format: "number",
    scope: "team",
    used: true,
    description: "texte de description",
  },
  {
    id: "Yqkaidgee4244e1e12e12e1",
    name: "ENV_TEST2",
    value: 56,
    format: "number",
    scope: "team",
    used: true,
    description: "texte de description 2",
  },
  {
    id: "iqoaldkcJHGgee4244e1e12e12e1",
    name: "ENV_TEST3",
    value: "test",
    format: "string",
    scope: "team",
    used: true,
    description: "texte de description 3",
  },
  {
    id: "tnejkdjHGgee4244e1e12e12e1",
    name: "ENV_TEST4",
    value: 105,
    format: "number",
    scope: "team",
    used: false,
    description: "texte de description 4",
  },
];

const variablesFilters = { fields: ["tout", "scope", "nom", "valeur"] };

export default function EditVariables({ editedVariable, setEditedVariable }) {
  const [allVariables, setAllVariables] = useState([...fakeVariables]);
  const [currentSort, setCurrentSort] = useState("default");

  return (
    <div className="streamVariablesContainer">
      <MddSearchBar filtersDatalist={variablesFilters} />
      <article className="variablesHeader row">
        <MddButton
          buttonInnerText="scope"
          buttonSize="long"
          iconColor="altButtonColor col"
          buttonActionFunctionOne={setCurrentSort}
          buttonActionPropOne={
            currentSort === "scope" ? "scope-reverse" : "scope"
          }
          iconName={
            currentSort === "scope"
              ? "bi bi-sort-alpha-down-alt"
              : "bi bi-sort-alpha-down"
          }
        />
        <MddButton
          buttonInnerText="nom"
          buttonSize="long"
          iconColor="altButtonColor col"
          buttonActionFunctionOne={setCurrentSort}
          buttonActionPropOne={currentSort === "name" ? "name-reverse" : "name"}
          iconName={
            currentSort === "name"
              ? "bi bi-sort-alpha-down-alt"
              : "bi bi-sort-alpha-down"
          }
        />
        <p className="col">valeur</p>
        <p className="col">format</p>
        <p className="col">description</p>
        <div className="variablePlaceholder" />
      </article>
      <VariablesBody
        allVariables={allVariables}
        currentSort={currentSort}
        setCurrentSort={setCurrentSort}
        editedVariable={editedVariable}
        setEditedVariable={setEditedVariable}
      />
    </div>
  );
}
