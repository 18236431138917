import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { MddProvider } from "./context/MddContext";

import "./App.css";

import Home from "./pages/home/Home";
import Footer from "./components/Footer";
import StreamStore from "./pages/streamstore/StreamStore";
import Subscriptions from "./pages/subscriptions/Subscriptions";
import FeedBackToast from "./components/widgets/FeedBackToast";
import DetailedStream from "./pages/streamstore/DetailedStream";
import Logs from "./pages/streamstore/Logs";
import Login from "./pages/auth/Login";
import Export from "./pages/export/Export";
import Users from "./pages/users/Users";
import NoPage from "./pages/NoPage";
import ProfileMenu from "./components/widgets/ProfileMenu";
import AccountManager from "./pages/account/Manager";
import Subscribe from "./pages/auth/Subscribe";
import CreatePassword from "./pages/auth/CreatePassword";
import ForgottenPassword from "./pages/auth/ForgottenPassword";
import ChangePassword from "./pages/auth/ChangePassword";
import Unsubscribed from "./pages/auth/Unsubscribed";
import TokenHandler from "./components/widgets/TokenHandler";
import ChangeLogin from "./pages/auth/ChangeLogin";
import ChallengeModal from "./components/widgets/ChallengeModal";
import FlightsArrivals from "./pages/demo/FlightsArrivals";
import DatavizHome from "./dataviz/page/DatavizHome";
import HotelsManagement from "./pages/demo/HotelsManagement";
// import VariablesManagement from "./pages/streamstore/VariablesManagement";
import VariablesManagement from "./pages/variables/VariablesManagement";
import SolarPanels from "./pages/demo/SolarPanels";
import DatavizDashboard from "./dataviz/page/DatavizDashboard";
// import TestDashboard from "./dataviz/page/TestDashboard";

function App() {
  return (
    <MddProvider>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/account/subscribe">
              <Subscribe />
            </Route>
            <Route exact path="/account/create-password">
              <CreatePassword />
            </Route>
            <Route exact path="/account/forgotten-password">
              <ForgottenPassword />
            </Route>
            <Route exact path="/account/change-password">
              <ChangePassword />
            </Route>
            <Route exact path="/account/unsubscribed">
              <Unsubscribed />
            </Route>
            {/* <Route exact path="/account/change-login:token_slug">
              <ChangeLogin/>
            </Route> */}
            <Route exact path="/account/change-login">
              <ChangeLogin />
            </Route>
            <Route exact path="/account/manager">
              <AccountManager />
            </Route>
            <Route exact path="/streamstore">
              <StreamStore />
            </Route>
            <Route exact path={`/streamstore/create`}>
              <DetailedStream />
            </Route>
            <Route path={`/streamstore/:version_reference/logs`}>
              <Logs />
            </Route>
            <Route path={`/streamstore/:version_reference`}>
              <DetailedStream />
            </Route>
            <Route exact path="/subscriptions">
              <Subscriptions />
            </Route>
            <Route exact path={`/export`}>
              <Export />
            </Route>
            <Route exact path={`/users`}>
              <Users />
            </Route>
            <Route exact path={`/variables`}>
              <VariablesManagement />
            </Route>
            <Route exact path={`/dataviz`}>
              <DatavizHome />
            </Route>
            <Route exact path={`/dataviz/flights-arrivals`}>
              <FlightsArrivals />
            </Route>
            <Route exact path={`/dataviz/hotels-management`}>
              <HotelsManagement />
            </Route>
            <Route exact path={`/dataviz/solar-panels`}>
              <SolarPanels />
            </Route>
            <Route exact path={`/dataviz/dashboard/:dashboard_id`}>
              <DatavizDashboard />
            </Route>
            {/* <Route exact path={`/dataviz/test`}>
              <TestDashboard />
            </Route> */}
            <Route path="*">
              <NoPage />
            </Route>
          </Switch>
          <ProfileMenu />
          <FeedBackToast />
          <Footer />
          <TokenHandler />
          <ChallengeModal />
        </Router>
      </div>
    </MddProvider>
  );
}

export default App;
