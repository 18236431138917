import { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import revertingToken from "../../utils/auth/revertingToken";
import mddAuth from "../../api/mddAuth";
import "../../styles/auth/subscribe.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function ChangePassword() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    showSideBar,
    closingSession,
    userToken,
    handling401,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [oldUserValue, setOldUserValue] = useState("");
  const [userValue, setUserValue] = useState("");
  const [secondUserValue, setSecondUserValue] = useState("");
  const [changedValue, setChangedValue] = useState(false);
  const [incorrectOld, setIncorrectOld] = useState(false);
  const [incorrectNew, setIncorrectNew] = useState(false);

  const handlingSubmit = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setIncorrectNew(false);
      setIncorrectOld(false);
      if (userValue === secondUserValue) {
        mddAuth
          .post(
            `/change-password`,
            { passwordOld: oldUserValue, passwordNew: userValue },
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then(() => {
            feedBackBuilder(true, "Mot de passe modifié", "Authentification");
            setChangedValue(true);
          })
          .catch((err) => {
            handling401(
              err,
              history,
              "Le mot de passe n'a pas pu être modifié"
            );
            console.log("erreur au niveau de la requête : ", err);
          });
      } else {
        feedBackBuilder(
          false,
          `Les deux mots de passe doivent être identiques`,
          "Authentification"
        );
        setIncorrectNew(true);
      }
    }
  };

  const handlingRedirect = () => {
    history.goBack();
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    fullScreen && setFullScreen(false);
    showSideBar && setShowSideBar(false);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div className="subscribeGlobalContainer">
        {changedValue ? (
          <article>
            <h5>Votre mot de passe a été modifié</h5>
            <MddButton
              buttonInnerText="retour"
              buttonActionFunctionOne={handlingRedirect}
              iconColor="secondaryButton"
            />
          </article>
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <h5>Modifiez votre mot de passe</h5>
            <input style={{ opacity: 0, position: "absolute", top: "0" }} />
            <input
              type="password"
              style={{ opacity: 0, position: "absolute", top: "0" }}
            />

            <input
              type="password"
              id="oldValueForm"
              className={incorrectOld ? "incorrectInput" : ""}
              placeholder="mot de passe actuel"
              required
              value={oldUserValue}
              onChange={(e) => setOldUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <input
              type="password"
              id="valueForm"
              className={incorrectNew ? "incorrectInput" : ""}
              placeholder="nouveau mot de passe"
              required
              value={userValue}
              onChange={(e) => setUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <input
              type="password"
              id="value2Form"
              className={incorrectNew ? "incorrectInput" : ""}
              placeholder="confirmation du nouveau mot de passe"
              required
              value={secondUserValue}
              onChange={(e) => setSecondUserValue(e.target.value)}
              onKeyUp={(e) => {
                (e.key === "Enter" || e.keyCode === 13) && handlingSubmit();
              }}
            />
            <div>
              <MddButton
                buttonInnerText="retour"
                buttonActionFunctionOne={handlingRedirect}
                iconColor="secondaryButton"
              />
              <MddButton
                buttonInnerText="valider"
                buttonActionFunctionOne={handlingSubmit}
              />
            </div>
          </form>
        )}
      </div>
    </Dashboard>
  );
}
