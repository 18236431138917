import WidgetTypeButton from "./WidgetTypeButton";
import MddButton from "../../../components/reusableElements/MddButton";

const widgetTypes = [
  "line",
  "bar",
  "pie",
  "radar",
  "doughnut",
  "polarArea",
  "bubble",
  "text",
  "gauge",
  "grid",
  "kpi",
  "single-value",
  "button",
  "text-area",
];

export default function WidgetTypesModal({
  setNewWidget,
  // setWidgetsList,
  // widgetsList,
  newWidget,
  updatingDashboard,
}) {
  const returningButtons = () => {
    let stockButtons = [];
    for (let i = 0; i < widgetTypes.length; i++) {
      stockButtons.push(
        <WidgetTypeButton
          setNewWidget={setNewWidget}
          newWidget={newWidget}
          // setWidgetsList={setWidgetsList}
          // widgetsList={widgetsList}
          updatingDashboard={updatingDashboard}
          type={widgetTypes[i]}
        />
      );
    }
    return stockButtons;
  };

  return (
    <section className="widgetTypesModal">
      <article>
        {returningButtons()}
        <MddButton
          iconName="bi bi-x-lg"
          buttonSize="small"
          smallButtonDescription="close modal"
          iconColor="altButtonColor"
          buttonActionFunctionOne={setNewWidget}
          buttonActionPropOne={null}
        />
      </article>
    </section>
  );
}
