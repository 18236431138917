import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";
import { subscriptionsFilters } from "../../utils/subscriptions/subscriptionsFilters";
import "../../styles/subscriptions/dashboard.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import StreamStoreSideBar from "../../components/streamStore/sideBar/StreamStoreSideBar";
import SubscriptionCard from "../../components/subscriptions/SubscriptionCard";
import MddButton from "../../components/reusableElements/MddButton";
import MddBreadCrumb from "../../components/reusableElements/MddBreadCrumb";
import MddLoader from "../../components/reusableElements/MddLoader";
import MddSearchBar from "../../components/reusableElements/MddSearchBar";

export default function Subscriptions() {
  const {
    showSideBar,
    setShowSideBar,
    fullScreen,
    setFullSideBar,
    setFullScreen,
    closingSession,
    activeStream,
    setActiveStream,
    filters,
    activeFilter,
    userToken,
    handling401,
    setFilterElements,
    succesfullChallenge,
  } = useContext(MddContext);
  let history = useHistory();
  let location = useLocation();

  const [subscriptionsAssigned, setSubscriptionsAssigned] = useState(false);
  const [stockAPISubscriptions, setStockAPISubscriptions] = useState([]);
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState([]);
  const [openedSubscriptions, setOpenedSubscriptions] = useState([]);
  const [deletedSubscription, setDeletedSubscription] = useState(false);

  const retrievingSubscriptionsAPI = (defaultfilter) => {
    if (localStorage.getItem("mddTheme")) {
      if (closingSession()) {
        history.push("/login");
      } else {
        mddStreamStoreApi
          .get(`/subscription`, {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            const informations = response.data.data;
            let stockStates = {};
            let stockAllStates = [];
            for (let i = 0; i < informations.length; i++) {
              stockStates = {
                state: informations[i].state,
                status: informations[i].status,
                id: informations[i].id,
              };
              stockAllStates.push(stockStates);
            }
            setStockAPISubscriptions(informations);
            setDisplayedSubscriptions(informations);
            setSubscriptionsAssigned(true);
            defaultfilter && filteringStreams(informations);
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de récupération des abonnements : ",
              err
            );
            handling401(err, history);
          });
      }
    }
  };

  const returningCards = () => {
    let stockCards = [];
    for (let j = 0; j < displayedSubscriptions.length; j++) {
      stockCards.push(
        <SubscriptionCard
          subscription={displayedSubscriptions[j]}
          key={`subscription ${displayedSubscriptions[j].id}`}
          openedSubscriptions={openedSubscriptions}
          handlingOpenedSubscriptions={handlingOpenedSubscriptions}
          retrievingSubscriptionsAPI={retrievingSubscriptionsAPI}
          setDeletedSubscription={setDeletedSubscription}
        />
      );
    }
    return stockCards;
  };
  const filteringStreams = (list) => {
    let stockElements = [...list];
    let currentFilter = filters.find((f) => f.key === activeFilter);
    for (let i = 0; i < currentFilter.elements.length; i++) {
      switch (currentFilter.elements[i].field) {
        case "tout":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.streamName
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.state
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.consumer
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.token
                  .toLowerCase()
                  .includes(currentFilter.elements[i].token) 
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.streamName
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.state
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.consumer
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                item.token
                  .toLowerCase()
                  .includes(currentFilter.elements[i].token) 
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "nom":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.name
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "nom du stream":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.streamName
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.streamName
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "état":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.state
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.state
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "status":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.status
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "description":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.description
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "reference":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.reference
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "abonné":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.consumer
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.consumer
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "fournisseur":
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter((item) =>
              item.provider
                .toLowerCase()
                .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
        case "token":
            if (currentFilter.elements[i].operator === "=") {
              stockElements = list.filter((item) =>
                item.token
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
              );
              setDisplayedSubscriptions(stockElements);
            } else {
              stockElements = list.filter(
                (item) =>
                  !item.token
                    .toLowerCase()
                    .includes(currentFilter.elements[i].criteria)
              );
              setDisplayedSubscriptions(stockElements);
            }
            break;
        default:
          if (currentFilter.elements[i].operator === "=") {
            stockElements = list.filter(
              (item) =>
                item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.streamName
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.state
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.consumer
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) ||
                item.token
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          } else {
            stockElements = list.filter(
              (item) =>
                !item.name
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.streamName
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.state
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.description
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.status
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.reference
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.consumer
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                !item.provider
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria) &&
                item.token
                  .toLowerCase()
                  .includes(currentFilter.elements[i].criteria)
            );
            setDisplayedSubscriptions(stockElements);
          }
          break;
      }
    }
  };
  const handlingOpenedSubscriptions = (inputId) => {
    let stockSubscriptions = [...openedSubscriptions];
    let stockIndex = null;
    if (activeStream) {
      stockSubscriptions.push(inputId);
      setOpenedSubscriptions(stockSubscriptions);
      setActiveStream(null);
    } else {
      if (stockSubscriptions.includes(inputId)) {
        stockIndex = stockSubscriptions.indexOf(inputId);
        stockSubscriptions.splice(stockIndex, 1);
      } else {
        stockSubscriptions.push(inputId);
      }
      setOpenedSubscriptions(stockSubscriptions);
      handlingSubscriptionsNav(inputId);
    }
  };

  const handlingSubscriptionsNav = (navInput) => {
    let stockHash = ``;
    if (location.hash !== "") {
      let stockArray = location.hash.slice(1).split(",");
      if (stockArray.includes(navInput)) {
        let stockIndex = stockArray.indexOf(navInput);
        stockArray.splice(stockIndex, 1);
        stockHash = `${stockArray.join(",")}`;
      } else {
        stockHash = `${stockArray.join(",")},${navInput}`;
      }
    } else {
      stockHash = navInput;
    }
    history.push(`/subscriptions#${stockHash}`);
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    // setShowSideBar(true);
    setFullSideBar(false);
    setFullScreen(false);
  }, []);

  useEffect(() => {
    // let refreshingSubscriptions = null;
    // if (activeFilter && subscriptionsAssigned) {
    //   !fullCard && retrievingSubscriptionsAPI(true);
    //   refreshingSubscriptions = setInterval(() => {
    //     !fullCard && retrievingSubscriptionsAPI(true);
    //   }, subscriptionsRefresh);
    // } else if (activeFilter) {
    //   !fullCard && retrievingSubscriptionsAPI(true);
    //   refreshingSubscriptions = setInterval(() => {
    //     !fullCard && retrievingSubscriptionsAPI(true);
    //   }, subscriptionsRefresh);
    // } else {
    //   !fullCard && retrievingSubscriptionsAPI();
    //   refreshingSubscriptions = setInterval(() => {
    //     !fullCard && retrievingSubscriptionsAPI();
    //   }, subscriptionsRefresh);
    // }
    // return function exitSubscriptions() {
    //   clearInterval(refreshingSubscriptions);
    // };
    if (activeFilter) {
      !deletedSubscription && retrievingSubscriptionsAPI(true);
    } else {
      !deletedSubscription && retrievingSubscriptionsAPI();
    }
    if (filters.length === 0 && showSideBar) {
      setShowSideBar(false);
    } else if (filters.length > 0 && !showSideBar) {
      setShowSideBar(true);
    }
  }, [filters, activeFilter, deletedSubscription]);

  useEffect(() => {
    if (succesfullChallenge) {
      if (activeFilter) {
        !deletedSubscription && retrievingSubscriptionsAPI(true);
      } else {
        !deletedSubscription && retrievingSubscriptionsAPI();
      }
    }
  }, [succesfullChallenge]);

  useEffect(() => {
    if (activeFilter) {
      setFilterElements(filters.find((f) => f.key === activeFilter).elements);
    } else {
      setFilterElements([]);
    }
  }, [activeFilter]);

  useEffect(() => {
    activeStream &&
      subscriptionsAssigned &&
      handlingOpenedSubscriptions(location.hash.slice(1));
  }, [subscriptionsAssigned]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <StreamStoreSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer">
        <div className="subscriptionDashboardContainer">
          <MddBreadCrumb
            backPagesNames={[{ name: "Home", url: "/" }]}
            currentPage="Abonnements"
          />
          {subscriptionsAssigned ? (
            stockAPISubscriptions.length > 0 ? (
              <>
                <MddSearchBar filtersDatalist={subscriptionsFilters} />
                <article className="subscriptionsLabels">
                  <p>nom</p>
                  <p>stream</p>
                  <p>abonné</p>
                  <p>production</p>
                  <p>fin de support</p>
                </article>
                {returningCards()}
              </>
            ) : (
              <article className="noElementDisclaimer">
                <h4>Vous n'avez créé aucun abonnement</h4>
                <p>
                  Sélectionnez un stream pour créer votre premier abonnement
                </p>
                <MddButton
                  buttonInnerText="Voir les streams"
                  buttonSize="long"
                  buttonActionFunctionOne={history.push}
                  buttonActionPropOne="/streamstore"
                />
              </article>
            )
          ) : (
            <MddLoader />
          )}
        </div>
      </Dashboard>
    </>
  );
}
