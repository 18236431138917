import { useState } from "react";

export default function LogField({ name, value }) {
  
  

  return (
    <tr>
        <td>{name}</td><td>{value}</td>
    </tr>
  );
}
