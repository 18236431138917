export default function changingToken(tokenInput) {
  const changingCharacters = (arrayInput) => {
    let stockArray = [];
    for (let i = 0; i < arrayInput.length; i++) {
      if (arrayInput[i] === "a") {
        stockArray.push("O");
      } else if (arrayInput[i] === "A") {
        stockArray.push("o");
      } else if (arrayInput[i] === "o") {
        stockArray.push("A");
      } else if (arrayInput[i] === "O") {
        stockArray.push("a");
      } else if (arrayInput[i] === "e") {
        stockArray.push("I");
      } else if (arrayInput[i] === "E") {
        stockArray.push("i");
      } else if (arrayInput[i] === "i") {
        stockArray.push("E");
      } else if (arrayInput[i] === "I") {
        stockArray.push("e");
      } else if (arrayInput[i] === "u") {
        stockArray.push("Y");
      } else if (arrayInput[i] === "U") {
        stockArray.push("y");
      } else if (arrayInput[i] === "y") {
        stockArray.push("U");
      } else if (arrayInput[i] === "Y") {
        stockArray.push("u");
      } else if (arrayInput[i] === "b") {
        stockArray.push("P");
      } else if (arrayInput[i] === "B") {
        stockArray.push("p");
      } else if (arrayInput[i] === "p") {
        stockArray.push("B");
      } else if (arrayInput[i] === "P") {
        stockArray.push("b");
      } else if (arrayInput[i] === "f") {
        stockArray.push("V");
      } else if (arrayInput[i] === "F") {
        stockArray.push("v");
      } else if (arrayInput[i] === "v") {
        stockArray.push("F");
      } else if (arrayInput[i] === "V") {
        stockArray.push("f");
      } else if (arrayInput[i] === "1") {
        stockArray.push("4");
      } else if (arrayInput[i] === "4") {
        stockArray.push("1");
      } else if (arrayInput[i] === "2") {
        stockArray.push("5");
      } else if (arrayInput[i] === "5") {
        stockArray.push("2");
      } else {
        stockArray.push(arrayInput[i]);
      }
    }
    return stockArray;
  };
  const splitToken = tokenInput.split("");
  let changedToken = changingCharacters(splitToken);
  changedToken.reverse();
  let extractedToken = changedToken.join("").slice(0, 5);
  let cutToken = changedToken.join("").slice(5);
  return cutToken + extractedToken;
}
