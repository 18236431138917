import { useContext, useEffect, useState, useRef } from "react";

import "../../../../style/sidebar/sidebar.css";

import OptionsCategory from "./OptionsCategory";
import DatavizColorPicker from "./inputs/DatavizColorPicker";
import DatavizList from "./inputs/DatavizList";
import DatavizNumberInput from "./inputs/DatavizNumberInput";
import DatavizSwitch from "./inputs/DatavizSwitch";
import DatavizTextInput from "./inputs/DatavizTextInput";
import DatavizIconsSwitch from "./inputs/DatavizIconsSwitch";
import LineChart from "./categories/LineChart";
import DefaultTitle from "./categories/DefaultTitle";
import DefaultText from "./categories/DefaultText";
import BarChart from "./categories/BarChart";
import PieChart from "./categories/PieChart";
import RadarChart from "./categories/RadarChart";
import BubbleChart from "./categories/BubbleChart";

export default function WidgetStyle({ datavizSidebar, updatingWidget }) {
  return (
    <>
      {datavizSidebar.widget.component.settings.datasets.map((set, index) => {
        switch (set.type) {
          case "line":
            return (
              <LineChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "bar":
            return (
              <BarChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "pie":
            return (
              <PieChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "radar":
            return (
              <RadarChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "doughnut":
            return (
              <PieChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "polarArea":
            return (
              <PieChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          case "bubble":
            return (
              <BubbleChart
                datavizSidebar={datavizSidebar}
                updatingWidget={updatingWidget}
                set={set}
                index={index}
              />
            );
          default:
            return null;
        }
      })}
      <DefaultTitle
        datavizSidebar={datavizSidebar}
        updatingWidget={updatingWidget}
      />
      <DefaultText
        datavizSidebar={datavizSidebar}
        updatingWidget={updatingWidget}
      />
    </>
  );
}
