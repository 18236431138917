export default function IndicatorCard({
  setSolarNav,
  category,
  value,
  unit,
  name,
}) {
  return (
    <button
      className="indicatorCard"
      type="button"
      onClick={() => {
        setSolarNav(category);
      }}
    >
      <p>
        {value}
        <span> {unit}</span>
      </p>
      <p>{name}</p>
    </button>
  );
}
