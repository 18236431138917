import { streamStoreFilters } from "../../utils/streamStore/streamStoreFilters";
import { subscriptionsFilters } from "../../utils/subscriptions/subscriptionsFilters";
import MddBreadCrumb from "../reusableElements/MddBreadCrumb";
import MddButton from "../reusableElements/MddButton";
import MddSearchBar from "../reusableElements/MddSearchBar";

export default function ExportHeader({
  exportNav,
  positioningNav,
  setExportNav,
  selectedStreams,
  buildingButtonText,
}) {
  return (
    <>
      <div className="topDashboardContainer">
        <MddBreadCrumb
          backPagesNames={[{ name: "Home", url: "/" }]}
          currentPage="Export"
        />
        {(exportNav === "streams" ||
          exportNav === "abonnements" ||
          exportNav === "dataviz" ||
          exportNav === "archive" ||
          exportNav === "export" ) && (
          <div>
            <div
              className={`exportNavBackground ${exportNav}`}
              style={positioningNav()}
            />
            <MddButton
              buttonInnerText="streams"
              smallButtonDescription="Manage export streams"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne="streams"
              iconColor={
                exportNav === "streams" ? "activeAltButton" : "altButtonColor"
              }
            />
            <MddButton
              buttonInnerText="abonnements"
              smallButtonDescription="Manage export abonnements"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne="abonnements"
              iconColor={
                exportNav === "abonnements"
                  ? "activeAltButton"
                  : "altButtonColor"
              }
            />
            <MddButton
              buttonInnerText="dataviz"
              smallButtonDescription="Manage export dataviz"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne="dataviz"
              iconColor={
                exportNav === "dataviz" ? "activeAltButton" : "altButtonColor"
              }
            />
            <MddButton
              buttonInnerText="archives"
              smallButtonDescription="Manage export archive"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne="archive"
              iconColor={
                exportNav === "archive" ? "activeAltButton" : "altButtonColor"
              }
            />
          </div>
        )}
        { (exportNav === "confirmation") && (
          <div className="confirmationButtonsContainer">
            <MddButton
              buttonInnerText="Exporter"
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne={"export"}
              iconName="bi bi-download"
            />
            {/* <MddButton
                  buttonInnerText="Synchroniser"
                  buttonSize="long"
                  iconColor="altButtonColor"
                  buttonActionFunctionOne={setExportNav}
                  buttonActionPropOne={"synchronize"}
                  iconName="bi bi-arrow-left-right"
                /> */}
          </div>
        )}
        {(exportNav === "streams" ||
          exportNav === "abonnements" ||
          exportNav === "dataviz" ||
          exportNav === "export" ||
          exportNav === "archive") &&
          selectedStreams.length > 0 && (
            <MddButton
              buttonInnerText={buildingButtonText()}
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setExportNav}
              buttonActionPropOne={"confirmation"}
              iconName="bi bi-download"
            />
          )}
        {exportNav !== "confirmation" && selectedStreams.length === 0 && (
          <div className="topDashboardPlaceholder" />
        )}
      </div>
      {(exportNav === "streams" ||
        exportNav === "dataviz") && (
        <MddSearchBar filtersDatalist={streamStoreFilters} />
      )}
      {(exportNav === "abonnements") && (
        <MddSearchBar filtersDatalist={subscriptionsFilters} />
      )}
    </>
  );
}
