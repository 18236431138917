import { useState, useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../../../context/MddContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import mddMetrics from "../../../../api/mddMetrics";
import revertingToken from "../../../../utils/auth/revertingToken";
import "../../../../styles/streamStore/detailedStream/qosModal.css";

import MddButton from "../../../reusableElements/MddButton";
import MddCalendar from "../../../reusableElements/MddCalendar";

export default function QosModal({ setQosModal, qosNav, setQosNav }) {
  const { userToken, closingSession, handling401, activeVersion } =
    useContext(MddContext);
  let history = useHistory();
  const dailyChartRef = useRef(null);
  const monthlyChartRef = useRef(null);

  const [monthData, setMonthData] = useState([]);
  const [dayData, setDayData] = useState([]);
  const [monthReference, setMonthReference] = useState([]);
  const [dayReference, setDayReference] = useState([]);
  const [dayStartingPoint, setDayStartingPoint] = useState(null);
  const [dayParsed, setDayParsed] = useState(null);
  const [dayEndingPoint, setDayEndingPoint] = useState(null);
  const [monthStartingPoint, setMonthStartingPoint] = useState(null);
  const [monthParsed, setMonthParsed] = useState(null);
  const [monthEndingPoint, setMonthEndingPoint] = useState(null);
  const [initialDayStartingPoint, setInitialDayStartingPoint] = useState(null);
  const [initialMonthStartingPoint, setInitialMonthStartingPoint] =
    useState(null);
  const [initialDayEndingPoint, setInitialDayEndingPoint] = useState(null);
  const [initialMonthEndingPoint, setInitialMonthEndingPoint] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [endDate, setEndDate] = useState(false);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const positioningNav = () => {
    if (qosNav === "quotidienne") {
      return { left: "0" };
    } else if (qosNav === "mensuelle") {
      return { left: "7rem" };
    } else {
      return { opacity: "0" };
    }
  };

  const returningColors = (dataInput) => {
    let stockColors = [];
    for (let i = 0; i < dataInput.length; i++) {
      if (dataInput[i].y === -1) {
        // -1 data not available (stream OFFLINE)
        stockColors.push("#f4f4f4");
      } else if (dataInput[i].y === -2) {
        // -2 healthcheck monitoring has timed out
        stockColors.push("#c9c9c9");
      } else if (dataInput[i].y > 95) {
        // OK thresold
        stockColors.push("#c8e47d");
      } else if (dataInput[i].y > 75) {
        // WARNING thresold
        stockColors.push("#ffc542");
      } else {
        stockColors.push("#f89185"); // CRITICAL thresold
      }
    }
    return stockColors;
  };

  const dailySettings = {
    datasets: [
      {
        label: "QoS",
        type: "bar",
        backgroundColor: returningColors(dayReference),
        borderColor: returningColors(dayReference),
        data: dayData,
      },
    ],
  };
  const monthlySettings = {
    datasets: [
      {
        label: "Qos",
        type: "bar",
        backgroundColor: returningColors(monthReference),
        borderColor: returningColors(monthReference),
        data: monthData,
      },
    ],
  };

  const dailyConfig = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (context.dataset.data[context.dataIndex].value >= 0) {
              label = `QoS : ${context.formattedValue}`;
            } else if (context.dataset.data[context.dataIndex].value === -1) {
              label = "Stream arrêté";
            } else if (context.dataset.data[context.dataIndex].value === -2) {
              label = "Statut indisponible !";
            }
            return label;
          },
        },
      },
    },
  };
  const monthlyConfig = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (context.dataset.data[context.dataIndex].value >= 0) {
              label = `QoS : ${context.formattedValue}`;
            } else if (context.dataset.data[context.dataIndex].value === -1) {
              label = "Stream arrêté";
            } else if (context.dataset.data[context.dataIndex].value === -2) {
              label = "Statut indisponible !";
            }
            return label;
          },
        },
      },
    },
  };

  const retrievingDayMetrics = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddMetrics
        .get(
          `?reference=${activeVersion.reference}${
            dayStartingPoint ? `&from=${dayStartingPoint.toISOString()}` : ""
          }${dayEndingPoint ? `&to=${dayEndingPoint.toISOString()}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          const informations = response.data.data.datapoints;
          !initialDayStartingPoint &&
            setInitialDayStartingPoint(new Date(response.data.data.from));
          !initialDayEndingPoint &&
            setInitialDayEndingPoint(new Date(response.data.data.to));
          let stockData = [];
          let stockReference = [];
          let stockDate = null;
          let stockHour = null;
          for (let i = 0; i < informations.length; i++) {
            stockDate = new Date(informations[i][0]);
            stockHour = stockDate.getHours();
            stockData.push({
              x: `${stockHour}h`,
              y:
                informations[i][1] === -1
                  ? 100 // data not available (stream OFFLINE)
                  : informations[i][1] === -2
                  ? 100 // healthcheck monitoring has timed out
                  : informations[i][1], // nominal
              value: informations[i][1], // original data (no formatting)
            });
            stockReference.push({
              x: `${stockHour}h`,
              y: informations[i][1],
            });
          }
          setDayData([...stockData]);
          setDayReference([...stockReference]);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const retrievingMonthMetrics = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddMetrics
        .get(
          `?reference=${activeVersion.reference}${
            monthStartingPoint
              ? `&from=${monthStartingPoint.toISOString()}`
              : ""
          }${
            monthEndingPoint ? `&to=${monthEndingPoint.toISOString()}` : ""
          }&timing=day`,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          const informations = response.data.data.datapoints;
          !initialMonthStartingPoint &&
            setInitialMonthStartingPoint(new Date(response.data.data.from));
          !initialMonthEndingPoint &&
            setInitialMonthEndingPoint(new Date(response.data.data.to));
          let stockData = [];
          let stockReference = [];
          let stockDate = null;
          let stockDay = null;
          for (let i = 0; i < informations.length; i++) {
            stockDate = new Date(informations[i][0]);
            stockDay = `${stockDate.getDate()}/${stockDate.getMonth() + 1}`;
            stockData.push({
              x: stockDay,
              y:
                informations[i][1] === -1
                  ? 100 // data not available (stream OFFLINE)
                  : informations[i][1] === -2
                  ? 100 // healthcheck monitoring has timed out
                  : informations[i][1], // nominal
              value: informations[i][1], // original data (no formatting)
            });
            stockReference.push({
              x: stockDay,
              y: informations[i][1],
            });
          }
          setMonthData([...stockData]);
          setMonthReference([...stockReference]);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requete : ", err);
          handling401(err, history);
        });
    }
  };

  const updatingStartingPoint = (newStart) => {
    dailyChartRef.current = null;
    monthlyChartRef.current = null;
    if (qosNav === "quotidienne") {
      let stockStart = new Date(
        dayStartingPoint ? dayStartingPoint : initialDayStartingPoint
      );
      switch (newStart) {
        case -2:
          stockStart.setDate(stockStart.getDate() - 1);
          setDayStartingPoint(stockStart);
          break;
        case -1:
          stockStart.setHours(stockStart.getHours() - 1);
          setDayStartingPoint(stockStart);
          break;
        case 1:
          stockStart.setHours(stockStart.getHours() + 1);
          setDayStartingPoint(stockStart);
          break;
        case 2:
          stockStart.setDate(stockStart.getDate() + 1);
          setDayStartingPoint(stockStart);
          break;
        default:
          break;
      }
    } else if (qosNav === "mensuelle") {
      let stockStart = new Date(
        monthStartingPoint ? monthStartingPoint : initialMonthStartingPoint
      );
      switch (newStart) {
        case -2:
          stockStart.setDate(stockStart.getDate() - 30);
          setMonthStartingPoint(stockStart);
          break;
        case -1:
          stockStart.setDate(stockStart.getDate() - 1);
          setMonthStartingPoint(stockStart);
          break;
        case 1:
          stockStart.setDate(stockStart.getDate() + 1);
          setMonthStartingPoint(stockStart);
          break;
        case 2:
          stockStart.setDate(stockStart.getDate() + 30);
          setMonthStartingPoint(stockStart);
          break;
        default:
          break;
      }
    }
  };

  const registeringDate = (newDate) => {
    if (qosNav === "quotidienne" && !endDate) {
      setDayStartingPoint(newDate);
    } else if (qosNav === "quotidienne" && endDate) {
      let stockDate = new Date(newDate);
      stockDate && stockDate.setHours(stockDate.getHours() - 23);
      setDayStartingPoint(stockDate);
    } else if (qosNav === "mensuelle" && !endDate) {
      setMonthStartingPoint(newDate);
    } else if (qosNav === "mensuelle" && endDate) {
      let stockDate = new Date(newDate);
      stockDate && stockDate.setDate(stockDate.getDate() - 29);
      setMonthStartingPoint(stockDate);
    }
  };

  const findingStartDate = () => {
    if (qosNav === "quotidienne" && !endDate) {
      return dayStartingPoint ? dayStartingPoint : new Date();
    } else if (qosNav === "quotidienne" && endDate) {
      return dayEndingPoint ? dayEndingPoint : new Date();
    } else if (qosNav === "mensuelle" && !endDate) {
      return monthStartingPoint ? monthStartingPoint : new Date();
    } else if (qosNav === "mensuelle" && endDate) {
      return monthEndingPoint ? monthEndingPoint : new Date();
    }
  };

  const unparsingDate = (dateInput) => {
    const buldingLocaleDate = (dateInput) => {
      const stockYear = dateInput.slice(6, 10);
      const stockMonth = dateInput.slice(3, 5);
      const stockDay = dateInput.slice(0, 2);
      const stockHours = dateInput.slice(11, 13);
      const stockMinutes = dateInput.slice(14, 16);
      const stockSeconds = dateInput.slice(17, 19);
      const stockMilliSeconds = dateInput.slice(20, 23);
      return `${stockYear}-${stockMonth}-${stockDay}T${stockHours}:${stockMinutes}:${stockSeconds}.${stockMilliSeconds}+02:00`;
    };

    let stockDate = buldingLocaleDate(dateInput);
    switch (qosNav) {
      case "quotidienne":
        setDayStartingPoint(new Date(stockDate));
        setShowCalendar(false);
        break;
      case "mensuelle":
        setMonthStartingPoint(new Date(stockDate));
        setShowCalendar(false);
        break;
      default:
        setShowCalendar(false);
    }
  };

  const parsingDate = () => {
    const returningMilliSeconds = (dateInput) => {
      let stockMilliSeconds = dateInput.getMilliseconds();
      if (stockMilliSeconds < 10) {
        stockMilliSeconds = "00" + stockMilliSeconds;
      } else if (stockMilliSeconds < 100) {
        stockMilliSeconds = "0" + stockMilliSeconds;
      }
      return stockMilliSeconds;
    };

    function returningDate(dateInput) {
      const stockDate = `${
        dateInput.getDate() > 9
          ? dateInput.getDate()
          : "0" + dateInput.getDate()
      }/${
        dateInput.getMonth() + 1 > 9
          ? dateInput.getMonth() + 1
          : "0" + (dateInput.getMonth() + 1)
      }/${dateInput.getFullYear()} ${
        dateInput.getHours() > 9
          ? dateInput.getHours()
          : "0" + dateInput.getHours()
      }:${
        dateInput.getMinutes() > 9
          ? dateInput.getMinutes()
          : "0" + dateInput.getMinutes()
      }:${
        dateInput.getSeconds() > 9
          ? dateInput.getSeconds()
          : "0" + dateInput.getSeconds()
      }.${returningMilliSeconds(dateInput)}`;
      return stockDate;
    }
    qosNav === "quotidienne" && setDayParsed(returningDate(dayStartingPoint));
    qosNav === "mensuelle" && setMonthParsed(returningDate(monthStartingPoint));
  };

  function positioningCalendar() {
    let stockCalendar = document.getElementsByClassName("logsDatesModal");
    console.log("positioning calendar : ", stockCalendar);
    for (let i = 0; i < stockCalendar.length; i++) {
      if (endDate) {
        stockCalendar[i].style.right = "1rem";
        stockCalendar[i].style.left = "";
      } else {
        stockCalendar[i].style.left = "1rem";
      }
    }
  }

  useEffect(() => {
    retrievingDayMetrics();
    retrievingMonthMetrics();
  }, []);

  useEffect(() => {
    // dayStartingPoint && retrievingDayMetrics();
    dayStartingPoint && parsingDate(dayStartingPoint);
    let stockDate = new Date(dayStartingPoint);
    stockDate && stockDate.setHours(stockDate.getHours() + 23);
    dayStartingPoint && setDayEndingPoint(stockDate);
  }, [dayStartingPoint]);

  useEffect(() => {
    monthStartingPoint && retrievingMonthMetrics();
    monthStartingPoint && parsingDate(monthStartingPoint);
    let stockDate = new Date(monthStartingPoint);
    stockDate && stockDate.setDate(stockDate.getDate() + 29);
    monthStartingPoint && setMonthEndingPoint(stockDate);
  }, [monthStartingPoint]);

  useEffect(() => {
    dayEndingPoint && retrievingDayMetrics();
  }, [dayEndingPoint]);

  useEffect(() => {
    monthEndingPoint && retrievingMonthMetrics();
  }, [monthEndingPoint]);

  // useEffect(() => {
  //   !dayStartingPoint && setDayStartingPoint(initialDayStartingPoint);
  // }, [initialDayStartingPoint]);

  // useEffect(() => {
  //   !monthStartingPoint && setMonthStartingPoint(initialMonthStartingPoint);
  // }, [initialMonthStartingPoint]);

  useEffect(() => {
    positioningCalendar();
  }, [endDate]);

  return (
    <div className="qosModalContainer">
      <section>
        <article className="qosHeader">
          <h4>Qualité de service</h4>
          <div className="qosNavigationContainer">
            <div className="qosSideNavigation">
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-double-left"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={-2}
              />
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-left"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={-1}
              />
            </div>
            <MddButton
              buttonInnerText={
                qosNav === "quotidienne"
                  ? dayStartingPoint
                    ? `${dayStartingPoint.toLocaleDateString()} ${dayStartingPoint
                        .toLocaleTimeString()
                        .slice(0, 5)}`
                    : initialDayStartingPoint
                    ? `${initialDayStartingPoint.toLocaleDateString()} ${initialDayStartingPoint
                        .toLocaleTimeString()
                        .slice(0, 5)}`
                    : ""
                  : monthStartingPoint
                  ? monthStartingPoint.toLocaleDateString()
                  : initialMonthStartingPoint
                  ? initialMonthStartingPoint.toLocaleDateString()
                  : ""
              }
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setShowCalendar}
              buttonActionPropOne={endDate ? true : !showCalendar}
              buttonActionFunctionTwo={setEndDate}
              buttonActionPropTwo={false}
              iconName="bi bi-calendar-range"
            />
            <div className="navigationToggle">
              <div
                className={`qosNavBackground ${qosNav}`}
                style={positioningNav()}
              />
              <MddButton
                buttonInnerText="quotidienne"
                buttonActionFunctionOne={setQosNav}
                buttonActionPropOne="quotidienne"
                iconColor={
                  qosNav === "quotidienne"
                    ? "activeAltButton"
                    : "altButtonColor"
                }
              />
              <MddButton
                buttonInnerText="mensuelle"
                buttonActionFunctionOne={setQosNav}
                buttonActionPropOne="mensuelle"
                iconColor={
                  qosNav === "mensuelle" ? "activeAltButton" : "altButtonColor"
                }
              />
            </div>
            <MddButton
              buttonInnerText={
                qosNav === "quotidienne"
                  ? dayEndingPoint
                    ? `${dayEndingPoint.toLocaleDateString()} ${dayEndingPoint
                        .toLocaleTimeString()
                        .slice(0, 5)}`
                    : initialDayEndingPoint
                    ? `${initialDayEndingPoint.toLocaleDateString()} ${initialDayEndingPoint
                        .toLocaleTimeString()
                        .slice(0, 5)}`
                    : ""
                  : monthEndingPoint
                  ? monthEndingPoint.toLocaleDateString()
                  : initialMonthEndingPoint
                  ? `${initialMonthEndingPoint.toLocaleDateString()} ${initialMonthEndingPoint
                      .toLocaleTimeString()
                      .slice(0, 5)}`
                  : ""
              }
              buttonSize="long"
              iconColor="altButtonColor"
              buttonActionFunctionOne={setShowCalendar}
              buttonActionPropOne={!endDate ? true : !showCalendar}
              buttonActionFunctionTwo={setEndDate}
              buttonActionPropTwo={true}
              iconName="bi bi-calendar-range"
            />
            <MddCalendar
              showModal={showCalendar}
              registeringDate={registeringDate}
              findingStartDate={findingStartDate}
              unparsingDate={unparsingDate}
              dateParsed={qosNav === "quotidienne" ? dayParsed : monthParsed}
              setDateParsed={
                qosNav === "quotidienne" ? setDayParsed : setMonthParsed
              }
              endDate={endDate}
            />
            <div className="qosSideNavigation">
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-right"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={1}
              />
              <MddButton
                buttonSize="small"
                iconName="bi bi-chevron-double-right"
                iconColor="altButtonColor"
                buttonActionFunctionOne={updatingStartingPoint}
                buttonActionPropOne={2}
              />
            </div>
          </div>
        </article>
        <article className="chartContainer">
          {qosNav === "quotidienne" && dayData.length > 0 && (
            <div>
              <Bar options={dailyConfig} data={dailySettings} />
            </div>
          )}
          {qosNav === "mensuelle" && monthData.length > 0 && (
            <div>
              <Bar options={monthlyConfig} data={monthlySettings} />
            </div>
          )}
        </article>
        <article>
          <p className="statusReason">
            {activeVersion && activeVersion.reason ? activeVersion.reason : ""}
          </p>
          <MddButton
            buttonInnerText="retour"
            buttonActionFunctionOne={setQosModal}
            buttonActionPropOne={false}
          />
        </article>
      </section>
    </div>
  );
}
