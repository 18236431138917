import { useState, useEffect, useContext, useReducer } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";
import dateParser from "../../utils/dateParser";
import { streamRegex } from "../../utils/streamStore/streamRegex";

import "../../styles/streamStore/dashboard/streamCard.css";
import "../../styles/streamStore/detailedStream/calendar.css";
import "../../styles/streamStore/detailedStream/editCard.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import StreamStoreSideBar from "../../components/streamStore/sideBar/StreamStoreSideBar";
import MddLoader from "../../components/reusableElements/MddLoader";

import MddButton from "../../components/reusableElements/MddButton";
import EditDetails from "../../components/streamStore/detailedStream/tabs/EditDetails";
import EditPackages from "../../components/streamStore/detailedStream/tabs/EditPackages";
import VersionPicker from "../../components/streamStore/detailedStream/elements/VersionPicker";
import ConfirmModal from "../../components/reusableElements/ConfirmModal";
import EditCode from "../../components/streamStore/detailedStream/tabs/EditCode";
import EditDocs from "../../components/streamStore/detailedStream/tabs/EditDocs";
import Metrics from "../../components/streamStore/detailedStream/elements/Metrics";
import QosModal from "../../components/streamStore/detailedStream/elements/QosModal";
// import TestQosModal from "../../components/streamStore/detailedStream/elements/TestQosModal";
import EditHeader from "../../components/streamStore/detailedStream/elements/EditHeader";
import EditVariables from "../../components/streamStore/detailedStream/tabs/EditVariables";

export default function DetailedStream() {
  const {
    showSideBar,
    fullScreen,
    activeStream,
    setActiveStream,
    userToken,
    closingSession,
    handling401,
    activeVersion,
    setActiveVersion,
    succesfullChallenge,
    // setChallengedCall,
    setShowSideBar,
    setFullSideBar,
    confirmModal,
    setConfirmModal,
    currentTeam,
    feedBackBuilder,
    lastVersions,
    setLastVersions,
  } = useContext(MddContext);
  let history = useHistory();
  const { version_reference } = useParams();
  let location = useLocation();

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [streamAssigned, setStreamAssigned] = useState(false);
  const [dateSelector, setDateSelector] = useState(null);
  const [calendarVerticalPosition, setCalendarVerticalPosition] =
    useState(null);
  const [streamName, setStreamName] = useState(
    activeStream ? activeStream.name : ""
  );
  const [streamVersion, setStreamVersion] = useState(
    activeStream ? activeStream.version : ""
  );
  const [streamProvider, setStreamProvider] = useState(
    activeStream ? activeStream.provider : ""
  );
  const [streamDescription, setStreamDescription] = useState(
    activeStream ? activeStream.description : ""
  );
  const [streamPreOps, setStreamPreOps] = useState(
    activeStream ? activeStream.preOpsDate : ""
  );
  const [streamProd, setStreamProd] = useState(
    activeStream ? activeStream.releaseDate : ""
  );
  const [streamEndOfSupport, setStreamEndOfSupport] = useState(
    activeStream ? activeStream.endOfSupportDate : ""
  );
  const [streamEndOfLife, setStreamEndOfLife] = useState(
    activeStream ? activeStream.endOfLifeDate : ""
  );
  const [editNav, setEditNav] = useState("");
  const [streamId, setStreamId] = useState(activeStream ? activeStream.id : "");
  const [streamTags, setStreamTags] = useState([]);
  const [versionPublishable, setVersionPublishable] = useState(null);
  const [versionPublished, setVersionPublished] = useState(null);
  const [versionDescription, setVersionDescription] = useState("");
  const [allVersions, setAllVersions] = useState([]);
  const [existingVersion, setExistingVersion] = useState(
    activeStream ? true : false
  );
  const [versionPeriod, setVersionPeriod] = useState("");
  const [versionDays, setVersionDays] = useState(null);
  const [openedModal, setOpenedModal] = useState(false);
  const [versionPackages, setVersionPackages] = useState([]);
  const [versionFactory, setVersionFactory] = useState("");
  const [packagesTest, setPackagesTest] = useState("");
  const [packagesAlgorithm, setPackagesAlgorithm] = useState(null);
  const [packagesClauses, setPackagesClauses] = useState([]);
  const [packagesErrorMessage, setPackagesErrorMessage] = useState("");
  const [packagesFilesCounter, setPackagesFilesCounter] = useState(1);
  const [packagesFilesInputs, setPackagesFilesInputs] = useState([]);
  const [streamValidInputs, setStreamValidInputs] = useState([
    "name",
    "provider",
    "description",
    "versionNumber",
    "versionDescription",
    "factory",
  ]);
  const [invalidTime, setInvalidTime] = useState([]);
  const [codeContent, setCodeContent] = useState("");
  const [codeName, setCodeName] = useState(
    activeVersion ? `${activeVersion.reference}.java` : ".java"
  );
  const [fileName, setFileName] = useState(codeName);
  const [codeReason, setCodeReason] = useState("");
  const [allCodes, setAllCodes] = useState([]);
  const [editedCode, setEditedCode] = useState(false);
  const [versionDocs, setVersionDocs] = useState([]);
  const [docsFilesInputs, setDocsFilesInputs] = useState([]);
  const [typeOfModal, setTypeOfModal] = useState("");
  const [docsUploadFiles, setDocsUploadFiles] = useState([]);
  const [docsFilesCounter, setDocsFilesCounter] = useState(1);
  const [packagesUploadFiles, setPackagesUploadFiles] = useState([]);
  const [qosModal, setQosModal] = useState(false);
  const [qosNav, setQosNav] = useState("quotidienne");
  const [tempStreamName, setTempStreamName] = useState("");
  const [sharedVersion, setSharedVersion] = useState(false);
  const [editedVariable, setEditedVariable] = useState(false);

  const retrievingStream = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      setStreamAssigned(false);
      mddStreamStoreApi
        .get(
          `/stream${
            activeStream
              ? `/${activeStream.id}`
              : `?reference=${version_reference}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          let stock = response.data.data;
          setActiveStream(stock);
          setStreamName(stock.name);
          setStreamDescription(stock.description);
          setStreamProvider(stock.provider);
          setStreamVersion(stock.version);
          setStreamAssigned(true);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          // setChallengedCall(() => retrievingStream());
          handling401(err, history);
        });
    }
  };

  const retrievingVersion = (retrievePackage) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(
          `/version?reference=${
            activeStream && lastVersions[activeStream.id] && !activeVersion
              ? lastVersions[activeStream.id]
              : version_reference
          }`,
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          let stock = response.data.data;
          //console.log("retrieving version : ", stock, new Date(stock.preopsAt));
          setActiveVersion(stock);
          let stockLast = lastVersions;
          stockLast[stock.stream] = stock.reference;
          setLastVersions(stockLast);
          retrievePackage && retrievingPackages(stock);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération de la version : ",
            err
          );
          // setChallengedCall(retrievingVersion);
          handling401(err, history);
        });
    }
  };
  const retrievingAllVersions = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/version?stream=${activeStream.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setAllVersions(stock);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération des versions : ",
            err
          );
          handling401(err, history);
        });
    }
  };
  const refreshingVersionAttributes = () => {
    const stockVersion = allVersions.find((version) => {
      return version.number === streamVersion;
    });
    if (streamVersion === "") {
      setStreamPreOps(dateParser(""));
      setStreamProd(dateParser(""));
      setStreamEndOfSupport(dateParser(""));
      setStreamEndOfLife(dateParser(""));
      setVersionPublished("");
    } else {
      setStreamPreOps(dateParser(stockVersion.preopsAt));
      setStreamProd(dateParser(stockVersion.releasedAt));
      setStreamEndOfSupport(dateParser(stockVersion.endOfSupportAt));
      setStreamEndOfLife(dateParser(stockVersion.endOfLifeAt));
      setVersionPublished(stockVersion.published);
      setCodeName(`${stockVersion.reference}.js`);
    }
  };
  const refreshingActiveStream = (inputId) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .get(`/stream/${inputId}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setActiveStream(stock);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de récupération du stream : ",
            err
          );
          handling401(err, history);
        });
    }
  };
  const changingActiveVersion = (inputVersion) => {
    if (inputVersion) {
      setActiveVersion(inputVersion);
      setStreamVersion(inputVersion.number);
      setStreamPreOps(dateParser(inputVersion.preopsAt));
      setStreamProd(dateParser(inputVersion.releasedAt));
      setStreamEndOfSupport(dateParser(inputVersion.endOfSupportAt));
      setStreamEndOfLife(dateParser(inputVersion.endOfLifeAt));
      setVersionPublished(inputVersion.published);
      setVersionDescription(inputVersion.description);
      setVersionPeriod(inputVersion.period);
      setVersionDays(inputVersion.days);
      setSharedVersion(inputVersion.shared);
      setExistingVersion(true);
      setVersionPublishable(inputVersion.publishable);
      setCodeName(`${inputVersion.reference}.java`);
      inputVersion.reference !== location.pathname.split("/")[2] &&
        history.push(`/streamstore/${inputVersion.reference}#general`);
      let stockLast = lastVersions;
      if (activeStream) {
        stockLast[activeStream.id] = inputVersion.reference;
      }
      setLastVersions(stockLast);
    } else if (existingVersion) {
      setActiveVersion(null);
      setStreamVersion("");
      setStreamPreOps("");
      setStreamProd("");
      setStreamEndOfSupport("");
      setStreamEndOfLife("");
      setVersionPublished(null);
      setVersionDescription("");
      setVersionPeriod("");
      setVersionDays("");
      setExistingVersion(false);
      setSharedVersion(false);
      setVersionPublishable(null);
      setCodeName(`.java`);
      let stockLast = lastVersions;
      if (activeStream) {
        delete stockLast[activeStream.id];
      }
      setLastVersions(stockLast);
    }
    setEditedVariable(false);
    setTempStreamName("");
    setPackagesUploadFiles([]);
    setDocsFilesCounter(1);
    setDocsUploadFiles([]);
    setDocsFilesInputs([]);
    setVersionDocs([]);
    setEditedCode(false);
    setAllCodes([]);
    setCodeReason("");
    setCodeContent("");
    setInvalidTime([]);
    setStreamValidInputs([
      "name",
      "provider",
      "description",
      "versionNumber",
      "versionDescription",
      "factory",
    ]);
    setPackagesFilesInputs([]);
    setPackagesFilesCounter(1);
    setPackagesErrorMessage("");
    setPackagesClauses([]);
    setPackagesAlgorithm(null);
    setPackagesTest("");
    setVersionFactory("");
    setVersionPackages([]);
  };
  const refreshingVersionList = (versionInput) => {
    let stockVersions = [...allVersions];
    let stockIndex = stockVersions.findIndex((version) => {
      return version.id === versionInput.id;
    });
    stockVersions.splice(stockIndex, 1, versionInput);
    setAllVersions([...stockVersions]);
  };
  const removeCalendar = (e) => {
    if (
      (e.target.getAttribute("class") &&
        (e.target.getAttribute("class") === "lifeCycleItem" ||
          e.target.getAttribute("class") === "lifeCycleItem activeCycle" ||
          e.target.getAttribute("class").includes("react-calendar"))) ||
      e.target.getAttribute("aria-label")
    ) {
    } else {
      setDateSelector(null);
    }
  };
  const calendarPositioning = () => {
    if (dateSelector === "preOpsDate") {
      return {
        alignSelf: "flex-start",
        top: `calc(${calendarVerticalPosition}px - 24rem)`,
      };
    } else if (dateSelector === "releaseDate") {
      return {
        alignSelf: "flex-start",
        marginLeft: "7.5%",
        top: `calc(${calendarVerticalPosition}px - 24rem)`,
      };
    } else if (dateSelector === "endOfSupportDate") {
      return {
        alignSelf: "flex-end",
        marginRight: "7.5%",
        top: `calc(${calendarVerticalPosition}px - 24rem)`,
      };
    } else if (dateSelector === "endOfLifeDate") {
      return {
        alignSelf: "flex-end",
        top: `calc(${calendarVerticalPosition}px - 24rem)`,
      };
    }
  };
  const registerDate = (newDate) => {
    const stockDate = `${
      newDate.getMonth() + 1 > 9
        ? newDate.getMonth() + 1
        : "0" + (newDate.getMonth() + 1)
    }/${
      newDate.getDate() > 9 ? newDate.getDate() : "0" + newDate.getDate()
    }/${newDate.getFullYear()}`;

    switch (dateSelector) {
      case "preOpsDate":
        setStreamPreOps(stockDate);
        setDateSelector(null);
        checkingEditedStatus(
          dateParser(activeVersion.preopsAt),
          streamPreOps,
          "version"
        );
        break;
      case "releaseDate":
        setStreamProd(stockDate);
        setDateSelector(null);
        checkingEditedStatus(
          dateParser(activeVersion.releasedAt),
          streamProd,
          "version"
        );
        break;
      case "endOfSupportDate":
        setStreamEndOfSupport(stockDate);
        setDateSelector(null);
        checkingEditedStatus(
          dateParser(activeVersion.endOfSupportAt),
          streamEndOfSupport,
          "version"
        );
        break;
      case "endOfLifeDate":
        setStreamEndOfLife(stockDate);
        setDateSelector(null);
        checkingEditedStatus(
          dateParser(activeVersion.endOfLifeAt),
          streamEndOfLife,
          "version"
        );
        break;
      default:
        setDateSelector(null);
    }
  };
  const versionRequestBuilder = (streamId) => {
    let stockRequest = { description: versionDescription };
    streamVersion && !existingVersion && (stockRequest.number = streamVersion);
    !existingVersion && (stockRequest.stream = streamId);
    existingVersion &&
      streamPreOps &&
      (stockRequest.preopsAt = dateParser(streamPreOps));
    existingVersion &&
      streamProd &&
      (stockRequest.releasedAt = dateParser(streamProd));
    existingVersion &&
      streamEndOfSupport &&
      (stockRequest.endOfSupportAt = dateParser(streamEndOfSupport));
    existingVersion &&
      streamEndOfLife &&
      (stockRequest.endOfLifeAt = dateParser(streamEndOfLife));
    return stockRequest;
  };
  const updatingStream = () => {
    if (closingSession() && !handleEditCreate("stream")) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .put(
          `/stream/${activeStream.id}`,
          {
            name: streamName,
            provider: streamProvider,
            description: streamDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          setActiveStream(response.data.data);
          feedBackBuilder(true, "Modification enregistrée", "Streams Store");
          !version_reference &&
            history.push(`/streamstore/${response.data.data.reference}`);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de mise à jour de stream : ",
            err
          );
          handling401(err, history, `Modification non enregistrée`);
        });
    }
  };
  const creatingStream = () => {
    if (closingSession() && !handleEditCreate("stream")) {
      history.push("/login");
    } else if (!tempStreamName || tempStreamName !== streamName) {
      mddStreamStoreApi
        .post(
          `/stream`,
          {
            name: streamName,
            provider: streamProvider,
            description: streamDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((streamResponse) => {
          setActiveStream(streamResponse.data.data);
          const stockStreamId = streamResponse.data.data.id;
          setStreamId(stockStreamId);
          setTempStreamName("");
          history.push(`/streamstore/${streamResponse.data.data.reference}`);
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.status === 409 &&
            err.response.data.error.code === "STREAM ALREADY EXISTS"
          ) {
            feedBackBuilder(
              false,
              "Un stream existe déjà avec cette référence",
              "Streams Store"
            );
            setTempStreamName(streamName);
          } else if (err.response && err.response.status === 400) {
            feedBackBuilder(
              false,
              "Le nom du stream doit avoir au moins 3 caractères",
              "Streams Store"
            );
            setTempStreamName(streamName);
          } else {
            console.log(
              "erreur au niveau de la requete API de création de stream : ",
              err
            );
            setTempStreamName("");
            handling401(err, history, `Création non enregistrée`);
          }
        });
    } else {
      feedBackBuilder(
        false,
        "Un stream existe déjà avec cette référence",
        "Streams Store"
      );
    }
  };
  const creatingVersion = () => {
    if (closingSession() && !handleEditCreate("version")) {
      history.push("/login");
    } else if (
      streamVersion !== "" &&
      !allVersions.some((version) => {
        return version.number === streamVersion;
      })
    ) {
      mddStreamStoreApi
        .post(`/version`, versionRequestBuilder(activeStream.id), {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((versionResponse) => {
          changingActiveVersion(versionResponse.data.data);
          feedBackBuilder(true, "Version créée", "Streams Store");
          retrievingAllVersions();
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de création de version : ",
            err,
            err.response.status,
            err.response.data.error.message
          );
          if (
            err &&
            err.response &&
            err.response.status === 400 &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message &&
            err.response.data.error.message ===
              "A valid version is expected (X.Y.Z)"
          ) {
            feedBackBuilder(
              false,
              `Le numéro de version n'est pas valide (format X.Y.Z)`,
              "Streams Store"
            );
          } else {
            handling401(err, history, `Création non enregistrée`);
          }
        });
    } else if (
      streamVersion !== "" &&
      allVersions.some((version) => {
        return version.number === streamVersion;
      })
    ) {
      feedBackBuilder(
        false,
        "Ce numéro de version existe déjà",
        "Streams Store"
      );
    }
  };
  const updatingVersion = () => {
    if (closingSession() && !handleEditCreate("version")) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .put(
          `/version/${activeVersion.id}`,
          versionRequestBuilder(activeStream.id),
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          if (response.data.data) {
            console.log("updating version : ", response.data.data);
            changingActiveVersion(response.data.data);
            refreshingVersionList(response.data.data);
            feedBackBuilder(true, "Modification enregistrée", "Streams Store");
          } else {
            console.log(
              "erreur au niveau de la requete API d'édition de la version' : ",
              response.data.error.message
            );
            feedBackBuilder(
              false,
              `La référence d'une version ne peut pas être modifiée`,
              "Streams Store"
            );
          }
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de mise à jour de version : ",
            err
          );
          handling401(err, history, `Modification non enregistrée`);
        });
    }
  };
  const publishingVersionAPI = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      !versionPublished &&
        mddStreamStoreApi
          .post(
            `/version/publish/${activeVersion.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            if (response.data.data) {
              setVersionPublished(true);
              refreshingActiveStream(response.data.data.stream);
              changingActiveVersion(response.data.data);
              feedBackBuilder(
                true,
                `Version ${activeVersion.number} publiée`,
                "Streams Store"
              );
            } else {
              console.log(
                "erreur au niveau de la requete API de publication de la version : ",
                response.data.error.message
              );
              feedBackBuilder(
                false,
                `La version ${activeVersion.number} est déjà publiée`,
                "Streams Store"
              );
            }
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de publication de version : ",
              err
            );
            handling401(
              err,
              history,
              `Version ${activeVersion.number} non publiée`
            );
          });
      versionPublished &&
        mddStreamStoreApi
          .post(
            `/version/unpublish/${activeVersion.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            if (response.data.data) {
              setVersionPublished(false);
              refreshingActiveStream(response.data.data.stream);
              // retrievingAllVersions();
              changingActiveVersion(response.data.data);
              feedBackBuilder(
                true,
                `Version ${activeVersion.number} retirée`,
                "Streams Store"
              );
            } else {
              console.log(
                "erreur au niveau de la requete API de retrait de la version : ",
                response.data.error.message
              );
              feedBackBuilder(
                false,
                `La version ${activeVersion.number} est déjà retirée`,
                "Streams Store"
              );
            }
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de retrait de version : ",
              err
            );
            handling401(
              err,
              history,
              `Version ${activeVersion.number} non retirée`
            );
          });
    }
  };
  const deletingStream = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/stream/${activeStream.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          if (response.data.data) {
            feedBackBuilder(true, `Stream supprimé`, "Streams Store");
          } else {
            console.log(
              "erreur au niveau de la requete API de suppression de stream : ",
              response.data.error.message
            );
            feedBackBuilder(
              false,
              `Il est nécessaire de retirer les versions publiées et tous les abonnements avant de supprimer le stream`,
              "Streams Store"
            );
          }
          return response;
        })
        .then((response) => {
          if (response.data.data) {
            setTimeout(handleExit(), 1000);
          }
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression de stream : ",
            err
          );
          handling401(err, history, `Stream non supprimé`);
        });
    }
  };
  const deletingVersion = (inputId) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/version/${inputId}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then(() => {
          feedBackBuilder(true, `Version supprimée`, "Streams Store");
          activeVersion &&
            inputId === activeVersion.id &&
            changingActiveVersion();
          retrievingAllVersions();
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression de version : ",
            err
          );
          handling401(err, history, `Version non supprimée`);
        });
    }
  };
  const handleEditCreate = (theme) => {
    if (
      streamValidInputs.includes("name") &&
      streamValidInputs.includes("provider") &&
      streamValidInputs.includes("description") &&
      theme === "stream"
    ) {
      return true;
    } else if (
      streamValidInputs.includes("versionNumber") &&
      streamValidInputs.includes("versionDescription") &&
      theme === "version"
    ) {
      return true;
    } else {
      let stockMessage = "";
      if (
        !streamValidInputs.some((element) => {
          return element === "name";
        })
      ) {
        stockMessage =
          stockMessage +
          " Le nom du stream doit comporter au moins 3 caractères.";
      }
      if (
        !streamValidInputs.some((element) => {
          return element === "provider";
        })
      ) {
        stockMessage =
          stockMessage +
          " Le nom du provider doit comporter au moins 3 caractères";
      }
      if (
        !streamValidInputs.some((element) => {
          return element === "versionNumber";
        })
      ) {
        stockMessage =
          stockMessage + " Le numéro de version doit être au format 'x.y.z'.";
      }
      if (
        !streamValidInputs.some((element) => {
          return element === "factory";
        })
      ) {
        stockMessage = stockMessage + " La factory n'est pas au bon format.";
      }
      if (
        !streamValidInputs.some((element) => {
          return element === "versionDescription";
        })
      ) {
        stockMessage =
          stockMessage +
          " La description de la version ne doit pas dépasser 3500 caractères.";
      }
      if (
        !streamValidInputs.some((element) => {
          return element === "description";
        })
      ) {
        stockMessage =
          stockMessage +
          " La description du stream ne doit pas dépasser 3500 caractères.";
      }
      feedBackBuilder(false, stockMessage, "Streams Store");
      return false;
    }
  };
  const handleExit = () => {
    history.push("/streamstore");
    activeStream && setActiveStream(null);
  };
  const checkingEditedStatus = (control, input, theme) => {
    if (theme === "stream") {
      control !== input && updatingStream();
    } else if (theme === "version") {
      control !== input && updatingVersion();
    }
  };
  const handleModalYes = () => {
    deletingStream();
    setTypeOfModal("");
  };
  const handlingSubscription = () => {
    if (closingSession()) {
      history.push("/login");
    } else if (currentTeam !== "all") {
      mddStreamStoreApi
        .post(
          `/subscription`,
          {
            name: `Nouvel abonnement`,
            version: activeVersion.id,
            description: "",
            consumer: currentTeam,
          },
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          feedBackBuilder(true, `Abonnement enregistré`, "Streams Store");
          history.push(`/subscriptions#${response.data.data.id}`);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API d'abonnement au stream : ",
            err
          );
          handling401(err, history, `Echec de la tentative d'abonnement`);
        });
    } else {
      feedBackBuilder(
        null,
        `Veuillez sélectionner une équipe pour vous abonner`,
        "Streams Store",
        true
      );
    }
  };
  const retrievingPackages = (versionInput) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      ((activeVersion && activeVersion.id) || versionInput) &&
        mddStreamStoreApi
          .get(
            `/version/packages/${
              activeVersion && activeVersion.id
                ? activeVersion.id
                : versionInput
                ? versionInput.id
                : null
            }`,
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            const stockPackages = response.data.data.packages;
            const stockFactory = response.data.data.factory;
            const stockTest = response.data.data.test;
            const stockAlgo = response.data.data.algorithm;
            const stockReason = decodingBase64(response.data.data.result);
            const stockClauses = response.data.data.algorithm.clauses;
            setVersionPackages(stockPackages);
            setVersionFactory(stockFactory);
            setPackagesErrorMessage(stockReason);
            setPackagesAlgorithm(stockAlgo);
            setPackagesClauses([...stockClauses]);
            stockPackages.length > 0
              ? setPackagesTest(stockTest)
              : setPackagesTest("");
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de récupération des packages : ",
              err
            );
            handling401(err, history);
          });
    }
  };
  const postingPackages = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      if (versionPublished) {
        feedBackBuilder(
          false,
          `Les packages d'une version publiée ne peuvent pas être modifiés`,
          "Streams Store"
        );
        return;
      }
      feedBackBuilder(null, `Compilation en cours`, "Streams Store", true);
      const formData = new FormData();
      if (packagesUploadFiles.length > 0) {
        for (let i = 0; i < packagesUploadFiles.length; i++) {
          if (
            packagesUploadFiles[i].size > 60000000 ||
            packagesUploadFiles[i].name.length > 100
          ) {
            feedBackBuilder(
              false,
              `Seuls les fichiers de moins de 60 Mo sont acceptés`,
              "Streams Store"
            );
            return;
          }
        }
        for (let i = 0; i < packagesUploadFiles.length; i++) {
          formData.append("packages", packagesUploadFiles[i]);
        }
      }
      if (versionFactory) {
        if (streamRegex.factory.test(versionFactory)) {
          formData.append("factory", versionFactory);
        } else {
          feedBackBuilder(
            false,
            `Entrez le FQN de la classe d'implémentation, ex: 'org.my.business.stream.Analytics`,
            "Streams Store"
          );
          return;
        }
      }
      if (packagesUploadFiles.length > 0 || versionFactory) {
        mddStreamStoreApi
          .post(
            `/version/packages/${activeVersion.id}?timeout=10000`,
            formData,
            {
              headers: {
                "Content-type": "multipart/form-data",
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
            feedBackBuilder(
              true,
              `Modification(s) enregistrée(s)`,
              "Streams Store"
            );
            const stockPackages = response.data.data.packages;
            const stockFactory = response.data.data.factory;
            const stockTest = response.data.data.test;
            const stockReason = decodingBase64(response.data.data.result);
            const stockAlgo = response.data.data.algorithm;
            const stockClauses = response.data.data.algorithm.clauses;
            setPackagesFilesCounter(0);
            setPackagesFilesInputs([]);
            setVersionPackages(stockPackages);
            setVersionFactory(stockFactory);
            setPackagesTest(stockTest);
            setPackagesErrorMessage(stockReason);
            console.log("posting package : ", stockClauses);
            setPackagesClauses([...stockClauses]);
            stockAlgo
              ? setPackagesAlgorithm(stockAlgo)
              : setPackagesAlgorithm(null);
            setVersionPublishable(response.data.data.publishable);
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API d'envoi de fichiers : ",
              err
            );
            handling401(
              err,
              history,
              `Echec dans la tentative d'envoi des fichiers`
            );
          });
      }
    }
  };
  const deletingPackages = (inputId) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/version/packages/${activeVersion.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
          data: { packages: [inputId] },
        })
        .then((response) => {
          console.log("deleting packages : ", response);
          feedBackBuilder(true, `Fichier supprimé`, "Streams Store");
          const stockPackages = response.data.data.packages;
          const stockFactory = response.data.data.factory;
          const stockTest = response.data.data.test;
          const stockAlgo = response.data.data.algorithm;
          const stockReason = decodingBase64(response.data.data.result);
          setVersionPackages(stockPackages);
          setVersionFactory(stockFactory);
          setPackagesErrorMessage(stockReason);
          setPackagesTest(stockTest);
          stockAlgo && setPackagesAlgorithm(stockAlgo);
          setVersionPublishable(response.data.data.publishable);
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression de fichiers : ",
            err
          );
          handling401(
            err,
            history,
            `Echec dans la tentative de suppression des fichiers`
          );
        });
    }
  };
  const postingImage = (inputFile) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      const stockFileExtension = inputFile.name.split(".");
      if (
        (stockFileExtension[stockFileExtension.length - 1] === "png" ||
          stockFileExtension[stockFileExtension.length - 1] === "jpg") &&
        inputFile.size < 5000001 &&
        inputFile.name.length < 100
      ) {
        const formData = new FormData();
        formData.append("image", inputFile);
        mddStreamStoreApi
          .post(`/stream/image/${streamId}`, formData, {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            let stock = response.data.data;
            setActiveStream(stock);
            feedBackBuilder(true, `Image enregistrée`, "Streams Store");
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API d'envoi d'image : ",
              err
            );
            handling401(
              err,
              history,
              `Echec dans la tentative d'envoi des images`
            );
          });
      } else {
        feedBackBuilder(
          false,
          `Seuls les fichiers de moins de 5 Mo et aux formats JPG ou PNG sont acceptés`,
          "Streams Store"
        );
      }
    }
  };
  const deletingImage = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/stream/image/${streamId}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          let stock = response.data.data;
          setActiveStream(stock);
          feedBackBuilder(true, `Image supprimée`, "Streams Store");
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression d'image : ",
            err
          );
          handling401(
            err,
            history,
            `Echec dans la tentative de suppression de l'image`
          );
        });
    }
  };
  const checkingInputs = () => {
    let stockInputs = streamValidInputs;
    const addValidInput = (inputName) => {
      if (
        !stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs.push(inputName);
        setStreamValidInputs(stockInputs);
      }
    };
    const removeInvalidInput = (inputName) => {
      if (
        stockInputs.some((element) => {
          return element === inputName;
        })
      ) {
        stockInputs = stockInputs.filter((element) => {
          return element !== inputName;
        });
        setStreamValidInputs(stockInputs);
      }
    };
    if (
      streamRegex.name.test(streamName) &&
      streamName.length > 2 &&
      streamName.length <= 50
    ) {
      addValidInput("name");
    } else {
      removeInvalidInput("name");
    }
    if (
      streamRegex.provider.test(streamProvider) &&
      streamProvider.length > 2 &&
      streamProvider.length <= 30
    ) {
      addValidInput("provider");
    } else {
      removeInvalidInput("provider");
    }
    if (
      streamRegex.description.test(streamDescription) &&
      streamDescription.length <= 3500
    ) {
      addValidInput("description");
    } else {
      removeInvalidInput("description");
    }
    if (
      streamRegex.versionDescription.test(versionDescription) &&
      versionDescription.length <= 3500
    ) {
      addValidInput("versionDescription");
    } else {
      removeInvalidInput("versionDescription");
    }
    if (streamRegex.versionNumber.test(streamVersion)) {
      addValidInput("versionNumber");
    } else {
      removeInvalidInput("versionNumber");
    }
    forceUpdate();
  };
  const checkingTimeline = (inputDateOne, inputDateTwo) => {
    let stockChecker = [];
    let stockInvalid = [];
    streamPreOps &&
      stockChecker.push({
        name: "pre-production",
        date: new Date(streamPreOps),
      });
    streamProd &&
      stockChecker.push({ name: "production", date: new Date(streamProd) });
    streamEndOfSupport &&
      stockChecker.push({
        name: "end-of-support",
        date: new Date(streamEndOfSupport),
      });
    streamEndOfLife &&
      stockChecker.push({
        name: "end-of-life",
        date: new Date(streamEndOfLife),
      });
    if (stockChecker.length === 0) {
      return;
    }
    if (stockChecker.length > 1) {
      for (let i = 1; i < stockChecker.length; i++) {
        if (stockChecker[i - 1].date > stockChecker[i].date) {
          feedBackBuilder(
            false,
            `Les dates du cycle de vie doivent être dans l'ordre`,
            "Streams Store"
          );
          stockInvalid.push(stockChecker[i]);
        }
      }
    }
    if (stockInvalid.length > 0) {
      setInvalidTime(stockInvalid);
      return;
    }
    setInvalidTime([]);
    checkingEditedStatus(inputDateOne, inputDateTwo, "version");
  };
  const encodingToBase64 = (str) => {
    if (str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
    return window.btoa(unescape(encodeURIComponent("")));
  };
  const decodingBase64 = (str) => {
    if (str) {
      return decodeURIComponent(escape(window.atob(str)));
    }
    return "";
  };
  const filteringCodes = (arrayInput) => {
    let stockArray = [];
    if (arrayInput && arrayInput.length > 0) {
      for (let i = 0; i < arrayInput.length; i++) {
        arrayInput[i].content && stockArray.push(arrayInput[i]);
      }
    }
    return stockArray;
  };
  const postingCode = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      if (fileName.trim() === "") {
        feedBackBuilder(
          false,
          `Veuillez entrer un nom de fichier`,
          "Streams Store"
        );
      } else {
        feedBackBuilder(null, `Compilation en cours`, "Streams Store", true);
        let encodedContent = encodingToBase64(codeContent);
        mddStreamStoreApi
          .post(
            `/version/code/${activeVersion.id}?timeout=60000`,
            {
              filename: fileName,
              code: encodedContent,
            },
            {
              headers: {
                Authorization: `Bearer ${revertingToken(userToken)}`,
              },
            }
          )
          .then((response) => {
           
            const stockAll = filteringCodes(response.data.data.packages);
            const stockReason = decodingBase64(response.data.data.result);
            const stockTest = response.data.data.test;
            const stockAlgo = response.data.data.algorithm;
            const stockClauses = response.data.data.algorithm.clauses;

            setCodeReason(stockReason);
            setAllCodes(stockAll);
            setPackagesTest(stockTest);
            setEditedCode(false);
            setVersionPublishable(response.data.data.publishable);
            stockAlgo
              ? setPackagesAlgorithm(stockAlgo)
              : setPackagesAlgorithm(null);
            setPackagesClauses([...stockClauses]);

            feedBackBuilder(
              true,
              `Modification(s) enregistrée(s)`,
              "Streams Store"
            );
            
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API d'envoi du code : ",
              err.response
            );
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.code
            ) {
              if (
                err.response.data.error.code &&
                err.response.data.error.code === "INVALID FILE EXTENSION"
              ) {
                feedBackBuilder(
                  false,
                  `Une extension de fichier est requise`,
                  "Streams Store"
                );
              } else if (
                err.response.data.error.code &&
                err.response.data.error.code === "INVALID FILENAME"
              ) {
                feedBackBuilder(
                  false,
                  `Le nom du fichier n'est pas valide (*.java)`,
                  "Streams Store"
                );
              } else if (
                err.response.data.error.code &&
                err.response.data.error.code === "INVALID CONTENT"
              ) {
                feedBackBuilder(
                  false,
                  `Le contenu du fichier n'est pas valide ${err.response.data.error.message}`,
                  "Streams Store"
                );
              } else if (
                err.response.data.error.code &&
                err.response.data.error.code === "FILE MAX SIZE"
              ) {
                feedBackBuilder(
                  false,
                  `Le fichier ne peut dépasser ${
                    err.response.data.error.message.split(",")[1]
                  }`,
                  "Streams Store"
                );
              } else {
                handling401(err, history, `Echec dans l'envoi du code`);
              }
            } else {
              handling401(err, history, `Echec dans l'envoi du code`);
            }
          });
      }
    }
  };
  const retrievingCode = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      activeVersion &&
        activeVersion.id &&
        !editedCode &&
        mddStreamStoreApi
          .get(`/version/code/${activeVersion.id}?template=java`, {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            const stockAll = filteringCodes(response.data.data.packages);
            const stockReason = decodingBase64(response.data.data.result);
            const stockTest = response.data.data.test;
            setCodeReason(stockReason);
            setPackagesTest(stockTest);
            if (stockAll.length === 1 && stockAll[0].template === true) {
              const stockContent = decodingBase64(stockAll[0].content);
              setCodeName(stockAll[0].filename);
              setCodeContent(stockContent);
              setAllCodes([]);
            } else if (stockAll.length > 0) {
              setAllCodes(stockAll);
              const stockName = stockAll[0].filename;
              const stockContent = decodingBase64(stockAll[0].content);
              setCodeName(stockName);
              setCodeContent(stockContent);
            }
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de récupération des fichiers : ",
              err
            );
            handling401(err, history);
          });
    }
  };
  const refreshingCodeAttributes = () => {
    if (allCodes.length > 0) {
      const stockCode = allCodes.find((code) => {
        return code.filename === codeName;
      });
      if (stockCode) {
        setFileName(codeName);
        setCodeContent(decodingBase64(stockCode.content));
      }      
    } else {
      //console.log("source file not found");
      setCodeContent("");
    }
  };
  const retrievingDocs = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      activeVersion &&
        activeVersion.id &&
        mddStreamStoreApi
          .get(`/version/docs/${activeVersion.id}`, {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          })
          .then((response) => {
            const stockDocs = response.data.data.docs;
            setVersionDocs(stockDocs);
          })
          .catch((err) => {
            console.log(
              "erreur au niveau de la requete API de récupération des documents : ",
              err
            );
            handling401(err, history);
          });
    }
  };
  const postingDocs = (inputFile) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      const formData = new FormData();
      if (inputFile.length > 0) {
        for (let i = 0; i < inputFile.length; i++) {
          if (inputFile[i].size > 60000000 || inputFile[i].name.length > 100) {
            feedBackBuilder(
              false,
              `Seuls les fichiers de moins de 60 Mo sont acceptés`,
              "Streams Store"
            );
            return;
          }
        }
        for (let i = 0; i < inputFile.length; i++) {
          formData.append("docs", inputFile[i]);
        }
      }
      mddStreamStoreApi
        .post(`/version/docs/${activeVersion.id}`, formData, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        })
        .then((response) => {
          setDocsFilesInputs([]);
          setDocsUploadFiles([]);
          setDocsFilesCounter(0);
          const stockDocs = response.data.data.docs;
          setVersionDocs(stockDocs);
          feedBackBuilder(
            true,
            `Modification(s) enregistrée(s)`,
            "Streams Store"
          );
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API d'envoi de fichiers : ",
            err
          );
          handling401(err, history, `Echec dans l'envoi des fichiers`);
        });
    }
  };
  const deletingDocs = (inputId) => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .delete(`/version/docs/${activeVersion.id}`, {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
          data: { docs: [inputId] },
        })
        .then((response) => {
          const stockDocs = response.data.data.docs;
          setVersionDocs(stockDocs);
          feedBackBuilder(true, `Fichier supprimé`, "Streams Store");
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete API de suppression de documents : ",
            err
          );
          handling401(err, history, `Echec dans la suppression du fichier`);
        });
    }
  };
  const handleCopyModal = (inputVersion) => {
    //console.log("handle copy modal : ", inputVersion);
  };
  const sharingVersion = () => {
    if (closingSession()) {
      history.push("/login");
    } else {
      mddStreamStoreApi
        .post(
          `/version/share/${activeVersion.id}?shared=${!sharedVersion}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${revertingToken(userToken)}`,
            },
          }
        )
        .then((response) => {
          setSharedVersion(response.data.data.shared);
          feedBackBuilder(
            true,
            `Statut de partage du code modifié`,
            "Streams Store"
          );
        })
        .catch((err) => {
          console.log(
            "erreur au niveau de la requete de partage du code : ",
            err
          );
          handling401(err, history, `Statut de partage du code non modifié`);
        });
    }
  };

  useEffect(() => {
    // !streamAssigned && version_reference && retrievingStream();
    // !version_reference && setStreamAssigned(true);
    if (closingSession()) {
      history.push("/login");
    }
    !streamAssigned && version_reference && retrievingStream();
    !version_reference && setStreamAssigned(true);
    document.addEventListener("click", removeCalendar);
    setShowSideBar(false);
    setFullSideBar(false);
    // activeStream && retrievingAllVersions();
    return function exitEdit() {
      document.removeEventListener("click", removeCalendar);
    };
  }, []);
  useEffect(() => {
    if (!activeStream && version_reference) {
      retrievingStream();
    }
  }, [activeStream]);

  // useEffect(() => {
  //   !streamAssigned && version_reference && retrievingStream();
  //   !version_reference && setStreamAssigned(true);
  // }, []);

  useEffect(() => {
    if (succesfullChallenge) {
      !streamAssigned && version_reference && retrievingStream();
      !version_reference && setStreamAssigned(true);
    }
  }, [succesfullChallenge]);

  // useEffect(() => {
  //   document.addEventListener("click", removeCalendar);
  //   setShowSideBar(false);
  //   setFullSideBar(false);
  //   // activeStream && retrievingAllVersions();
  //   return function exitEdit() {
  //     document.removeEventListener("click", removeCalendar);
  //   };
  // }, []);
  useEffect(() => {
    activeVersion && changingActiveVersion(activeVersion);
  }, [activeVersion]);
  useEffect(() => {
    // allVersions.length > 0 &&
    //   setExistingVersion(
    //     allVersions.some((version) => {
    //       return version.number === streamVersion;
    //     })
    //   );
    allVersions.length > 0 &&
      allVersions.some((version) => {
        return version.number === streamVersion;
      }) &&
      refreshingVersionAttributes();
  }, [streamVersion]);
  useEffect(() => {
    activeVersion &&
      streamPreOps &&
      checkingTimeline(dateParser(activeVersion.preopsAt), streamPreOps);
  }, [streamPreOps]);
  useEffect(() => {
    activeVersion &&
      streamProd &&
      checkingTimeline(dateParser(activeVersion.releasedAt), streamProd);
  }, [streamProd]);
  useEffect(() => {
    activeVersion &&
      streamEndOfSupport &&
      checkingTimeline(
        dateParser(activeVersion.endOfSupportAt),
        streamEndOfSupport
      );
  }, [streamEndOfSupport]);
  useEffect(() => {
    activeVersion &&
      streamEndOfLife &&
      checkingTimeline(dateParser(activeVersion.endOfLifeAt), streamEndOfLife);
  }, [streamEndOfLife]);
  useEffect(() => {
    streamName && checkingInputs();
  }, [
    streamName,
    streamProvider,
    streamDescription,
    versionDescription,
    streamVersion,
  ]);
  useEffect(() => {
    allCodes.length > 0 && refreshingCodeAttributes();
  }, [codeName]);
  useEffect(() => {
    // activeStream &&
    //   location &&
    //   !location.hash &&
    //   history.push(
    //     `/streamstore/${
    //       activeVersion ? activeVersion.reference : activeStream.reference
    //     }#general`
    //   );
    activeStream &&
      location &&
      !location.hash &&
      history.push(`${location.pathname}#general`);
    location && location.hash && setEditNav(location.hash.slice(1));
  }, [location, editNav]);
  useEffect(() => {
    // let autoRefreshingVersion = setInterval(() => {
    //   activeVersion && retrievingVersion();
    // }, streamDetailsRefresh);
    if (editNav === "general") {
      (!activeVersion ||
        (activeVersion &&
          version_reference &&
          activeVersion.reference !== version_reference)) &&
        !version_reference.endsWith("0.0.0") &&
        retrievingVersion();
    } else if (editNav === "packages") {
      retrievingPackages();
    } else if (editNav === "code") {
      retrievingCode();
      // clearInterval(autoRefreshingVersion);
    } else if (editNav === "documentation") {
      retrievingDocs();
      // clearInterval(autoRefreshingVersion);
    }
    if (editNav !== "packages" && packagesClauses.length > 0) {
      setPackagesClauses([]);
    }
    // return function exitStream() {
    //   clearInterval(autoRefreshingVersion);
    // };
  }, [editNav /*, activeVersion*/]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <StreamStoreSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer">
        {streamAssigned ? (
          <div
            className="detailedCardContainer"
            onClick={(e) => removeCalendar(e)}
          >
            <EditHeader
              streamName={streamName}
              streamVersion={streamVersion}
              // retrievingVersion={retrievingVersion}
              editNav={editNav}
              versionPublished={versionPublished}
              publishingVersionAPI={publishingVersionAPI}
              postingPackages={postingPackages}
              packagesUploadFiles={packagesUploadFiles}
              postingCode={postingCode}
              postingDocs={postingDocs}
              editedCode={editedCode}
              docsUploadFiles={docsUploadFiles}
              editedVariable={editedVariable}
              versionPublishable={versionPublishable}
            />
            {(editNav === "general" ||
              location.pathname === "/streamstore/create") && (
              <EditDetails
                streamDescription={streamDescription}
                streamEndOfLife={streamEndOfLife}
                streamEndOfSupport={streamEndOfSupport}
                streamName={streamName}
                streamVersion={streamVersion}
                streamProvider={streamProvider}
                registerDate={registerDate}
                setStreamDescription={setStreamDescription}
                setStreamName={setStreamName}
                setStreamVersion={setStreamVersion}
                setStreamProvider={setStreamProvider}
                setDateSelector={setDateSelector}
                dateSelector={dateSelector}
                streamPreOps={streamPreOps}
                streamProd={streamProd}
                calendarPositioning={calendarPositioning}
                existingVersion={existingVersion}
                setOpenedModal={setOpenedModal}
                versionDescription={versionDescription}
                versionDays={versionDays}
                versionPeriod={versionPeriod}
                checkingEditedStatus={checkingEditedStatus}
                creatingStream={creatingStream}
                postingImage={postingImage}
                streamValidInputs={streamValidInputs}
                allVersions={allVersions}
                deletingImage={deletingImage}
                invalidTime={invalidTime}
                streamTags={streamTags}
                setStreamTags={setStreamTags}
                calendarVerticalPosition={calendarVerticalPosition}
                setCalendarVerticalPosition={setCalendarVerticalPosition}
                retrievingAllVersions={retrievingAllVersions}
              />
            )}
            {editNav === "packages" && (
              <EditPackages
                versionPackages={versionPackages}
                versionFactory={versionFactory}
                setVersionFactory={setVersionFactory}
                postingPackages={postingPackages}
                deletingPackages={deletingPackages}
                versionPublished={versionPublished}
                packagesTest={packagesTest}
                packagesAlgorithm={packagesAlgorithm}
                packagesErrorMessage={packagesErrorMessage}
                setPackagesErrorMessage={setPackagesErrorMessage}
                packagesUploadFiles={packagesUploadFiles}
                setPackagesUploadFiles={setPackagesUploadFiles}
                setPackagesAlgorithm={setPackagesAlgorithm}
                packagesClauses={packagesClauses}
                setPackagesClauses={setPackagesClauses}
                packagesFilesCounter={packagesFilesCounter}
                setPackagesFilesCounter={setPackagesFilesCounter}
                packagesFilesInputs={packagesFilesInputs}
                setPackagesFilesInputs={setPackagesFilesInputs}
              />
            )}
            {editNav === "code" && (
              <EditCode
                codeContent={codeContent}
                setCodeContent={setCodeContent}
                codeName={codeName}
                setCodeName={setCodeName}
                fileName={fileName}
                setFileName={setFileName}
                allCodes={allCodes}
                codeReason={codeReason}
                editedCode={editedCode}
                setEditedCode={setEditedCode}
                packagesTest={packagesTest}
                versionPublished={versionPublished}
                sharedVersion={sharedVersion}
                sharingVersion={sharingVersion}
              />
            )}
            {editNav === "env" && (
              <EditVariables
                editedVariable={editedVariable}
                setEditedVariable={setEditedVariable}
              />
            )}
            {editNav === "documentation" && (
              <EditDocs
                postingDocs={postingDocs}
                versionDocs={versionDocs}
                deletingDocs={deletingDocs}
                docsUploadFiles={docsUploadFiles}
                setDocsUploadFiles={setDocsUploadFiles}
                docsFilesInputs={docsFilesInputs}
                setDocsFilesInputs={setDocsFilesInputs}
                docsFilesCounter={docsFilesCounter}
                setDocsFilesCounter={setDocsFilesCounter}
              />
            )}
            {openedModal && (
              <VersionPicker
                streamName={streamName}
                allVersions={allVersions}
                setStreamVersion={setStreamVersion}
                existingVersion={existingVersion}
                streamVersion={streamVersion}
                setOpenedModal={setOpenedModal}
                setVersionDescription={setVersionDescription}
                changingActiveVersion={changingActiveVersion}
                versionDescription={versionDescription}
                checkingEditedStatus={checkingEditedStatus}
                deletingVersion={deletingVersion}
                streamValidInputs={streamValidInputs}
                creatingVersion={creatingVersion}
              />
            )}
            <Metrics setQosModal={setQosModal} setQosNav={setQosNav} />
            {confirmModal &&
              (typeOfModal === "delete" ? (
                <ConfirmModal
                  noText="annuler"
                  yesText="supprimer"
                  handleModalYes={handleModalYes}
                  handleModalNo={setTypeOfModal}
                  noProp=""
                  confirmTitle="confirmation"
                  confirmMessage="Confirmez-vous la suppression du stream ?"
                  confirmInfo="Le stream va être supprimé avec toutes ses versions"
                />
              ) : (
                typeOfModal === "copy" && (
                  <ConfirmModal
                    noText="annuler"
                    yesText="copier"
                    handleModalYes={handleCopyModal}
                    yesProp={activeVersion.id}
                    handleModalNo={setTypeOfModal}
                    noProp=""
                    confirmTitle="confirmation"
                    confirmMessage="Confirmez-vous la copie du stream ?"
                    confirmInfo="Le stream et sa version sélectionnée vont être copiés pour créer un nouveau stream"
                  />
                )
              ))}
            {qosModal && (
              // <TestQosModal
              //   setQosModal={setQosModal}
              //   qosNav={qosNav}
              //   setQosNav={setQosNav}
              // />
              <QosModal
                setQosModal={setQosModal}
                qosNav={qosNav}
                setQosNav={setQosNav}
              />
            )}
            {editNav === "general" && (
              <article className="editBottomButtonsContainer">
                {activeStream && (
                  <div>
                    {activeStream.editable &&
                      (activeStream.provider === currentTeam ||
                        currentTeam === "all") && (
                        <MddButton
                          buttonInnerText="supprimer"
                          buttonActionFunctionOne={setConfirmModal}
                          buttonActionPropOne={true}
                          buttonActionFunctionTwo={setTypeOfModal}
                          buttonActionPropTwo="delete"
                          iconColor="secondaryButton"
                          inactive={
                            activeStream.published ||
                            activeStream.subscriptions > 0
                          }
                          inactiveTitle="Il est nécessaire de retirer les versions publiées et tous les abonnements avant de supprimer le stream"
                        />
                      )}
                    <MddButton
                      buttonInnerText="s'abonner"
                      buttonActionFunctionOne={handlingSubscription}
                      iconColor="secondaryButton"
                      inactive={!versionPublished}
                      inactiveTitle="Il est nécessaire de publier la version du stream pour s'y abonner"
                    />
                  </div>
                )}
                <MddButton
                  buttonInnerText="retour"
                  iconColor="basicButtonColor"
                  buttonActionFunctionOne={handleExit}
                />
              </article>
            )}
          </div>
        ) : (
          <MddLoader />
        )}
      </Dashboard>
    </>
  );
}
