export default function WidgetTypeButton({
  setNewWidget,
  // setWidgetsList,
  // widgetsList,
  type,
  newWidget,
  updatingDashboard,
}) {
  return (
    <button
      type="button"
      aria-label={`select ${type} graph widget`}
      onClick={(e) => {
        e.preventDefault();
        // setWidgetsList([...widgetsList, { type: type, position: newWidget }]);
        updatingDashboard("addingWidget", { type: type, position: newWidget });
        setNewWidget(null);
      }}
    >
      {type}
    </button>
  );
}
