import { useContext, useEffect, useState, useRef } from "react";

export default function DatavizList({
  multiList,
  label,
  displayValue,
  input,
  outputValue,
  updater,
  path,
}) {
  const [innerValue, setInnerValue] = useState(input ? input : "");

  return (
    <div className="datavizInput">
      <label>{label}</label>
      <select
        multiple={multiList}
        //   name=""
        //   id=""
        onChange={(e) => {
          setInnerValue(e.target.value);
        }}
      >
        {displayValue.map((item, index) => {
          return (
            <option
              displayValue={item}
              selected={item === innerValue}
              onClick={() => {
                outputValue[index] !== input &&
                  updater(path, outputValue[index]);
              }}
            >
              {item}
            </option>
          );
        })}
      </select>
    </div>
  );
}
