import "../styles/streamStore/dashboard/dashboard.css";
import { useContext } from "react";
import { MddContext } from "../context/MddContext";

export default function Dashboard({ children, containerClass }) {
  const { fullSideBar, showSideBar } = useContext(MddContext);

  return (
    <main
      className={
        showSideBar
          ? fullSideBar
            ? `${containerClass} dashboardWithLargeSideBar`
            : `${containerClass} dashboardWithSideBar`
          : `${containerClass} dashboardWithoutSideBar`
      }
    >
      {children}
    </main>
  );
}
