import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import MddButton from "../reusableElements/MddButton";
import revertingToken from "../../utils/auth/revertingToken";
import ArchiveElement from "./ArchiveElement";
import ConfirmModal from "../reusableElements/ConfirmModal";
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ArchiveCard({
  archive,
  onSynchronizeArchive,
  onRemoveArchive,
}) {
  const { setActiveStream, userToken, closingSession, handling401 } =
    useContext(MddContext);

  let history = useHistory();

  const [synced, setSynced] = useState(archive.synced);
  const [opened, setOpened] = useState(archive.opened);
  const [confirmModal, setConfirmModal] = useState(false);
  
  const synchronize = () => {
    onSynchronizeArchive(archive);
    //alert("sync archive!");
  };

  const toggle = () => {
    setOpened(! opened);
  };

  const download = () => {
    alert("download archive");
  };

  const confirmArchiveDeletion = () => {
    setConfirmModal(true);    
  };

  const cancelArchiveDeletion = () => {
    setConfirmModal(false);    
  };

  const remove = (archiveId) => {
    onRemoveArchive([archiveId]);
  };

  const returningArchiveElements = () => {
    let stockElements = [];
    for (let i = 0; i < archive.elements.length; i++) {
      //console.log(archive.elements[i]);
      if (! archive.elements[i].name.endsWith("-0.0.0")) {
        stockElements.push(
          <ArchiveElement
            element={archive.elements[i]}
            key={`element ${archive.elements[i].id}`}
          />
        );
      } 
      
    }
    return stockElements;
  };

  useEffect(() => {
   
  }, [opened, confirmModal, archive]);


  return (
    <div class={ opened ? "archiveCard archiveOpened" : "archiveCard archiveClosed"}>
      <div>
        <div>
          { opened && (<i class="archiveSyncBtn bi bi-check2-circle statusOk" onClick={() => { synchronize() }}></i>)
          
          /*opened && (synced ? 
            (<i class="archiveSyncBtn bi bi-check2-circle statusOk" onClick={() => { synchronize() }}></i>) :
            (<i class="archiveSyncBtn bi bi-arrow-repeat statusWarning" onClick={() => { synchronize() }}></i>)
          )*/
          }
          <p class="archiveTitle" onClick={() => { toggle() }}>{archive.id}</p>
          <a href={`${REACT_APP_BASE_URL}/archives/${archive.team}/${archive.id}`}><i class="archiveDownloadBtn bi bi-download"></i></a>
          <p class="archiveElementsCount">{ archive.elements.filter((element => ! element.name.endsWith("-0.0.0") )).length}</p>
          <i class="archiveCloseBtn bi bi-x-lg" onClick={() => { confirmArchiveDeletion() }}></i>
        </div>
      </div>

      <div class={ opened ? "archiveContent archiveOpened" : "archiveContent archiveClosed"}>
        <>
        {opened && returningArchiveElements()}
        </>
      </div>
      {confirmModal && (
        <ConfirmModal
          handleModalYes={remove}
          handleModalNo={cancelArchiveDeletion}
          yesProp={archive.id}
          confirmMessage={archive.id}
          noText="annuler"
          yesText="supprimer"
          confirmTitle={`Confirmez la suppression de l'archive (${archive.elements.length} éléments)`}
          confirmInfo={`L'archive sera supprimée définitivement`}
        />
      )}
    </div>    
  );
}
