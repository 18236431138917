import { useContext } from "react";
import { MddContext } from "../../../../context/MddContext";
import { Link } from "react-router-dom";

import "../../../../styles/streamStore/detailedStream/metrics.css";
import statusLogoPicker from "../../../../utils/statusLogoPicker";

function SingleMetric({
  qosValue,
  qosLabel,
  setQosNav,
  setQosModal,
  published,
}) {
  const { okLevel, warningLevel } = useContext(MddContext);
  const buildingClass = () => {
    if (!published || qosValue === -1) {
      return "statusOff";
    } else if (qosValue > okLevel) {
      return "statusOk";
    } else if (qosValue > warningLevel) {
      return "statusWarning";
    } else {
      return "statusAlert";
    }
  };
  return (
    <div
      onClick={() => {
        setQosNav("quotidienne");
        setQosModal(true);
      }}
    >
      <article className={`metricsChart ${buildingClass()}`}>
        <div className="metricsValue">
          <p className={buildingClass()}>
            {qosValue === -1 || !published
              ? "OFF"
              : qosValue.toString().split(".")[0]}
          </p>
          <p className={buildingClass()}>
            {qosValue === -1 || !published
              ? ""
              : qosValue.toString().split(".")[1]}
          </p>
        </div>
      </article>
      <p>{qosLabel}</p>
    </div>
  );
}

function SubscriptionsMetric({ version }) {
  const { setFilters, filters, setActiveFilter } = useContext(MddContext);

  const creatingFilter = () => {
    let stockFilter = [
      { field: "reference", operator: "=", criteria: version.reference },
    ];
    setFilters([
      ...filters,
      {
        key: filters.length + 1,
        name: `${stockFilter[0].field}${stockFilter[0].operator}${stockFilter[0].criteria}`,
        elements: stockFilter,
      },
    ]);
    setActiveFilter(filters.length + 1);
  };

  return (
    <article className={`subscriptionsMetric`}>
      <Link
        to={`/subscriptions`}
        onClick={() => {
          creatingFilter();
        }}
      >
        <p>{version.subscriptions}</p>
        <p>{version.subscriptions > 1 ? "Abonnements" : "Abonnement"}</p>
      </Link>
    </article>
  );
}

export default function Metrics({ setQosModal, setQosNav }) {
  const { activeVersion } = useContext(MddContext);

  return (
    <>
      {activeVersion && (
        <section className="metricsContainer">
          <SingleMetric
            qosValue={activeVersion.availability}
            qosLabel={activeVersion.published ? "Disponbilité" : "Non publié"}
            setQosNav={setQosNav}
            setQosModal={setQosModal}
            published={activeVersion.published}
          />
          <article className="metricsStatusContainer">
            {statusLogoPicker(activeVersion.status)}
            <p>
              {activeVersion.status === "OFF"
                ? "Hors ligne"
                : activeVersion.status === "OK"
                ? "En marche"
                : activeVersion.status === "WARNING"
                ? "Alerte"
                : activeVersion.status === "DEGRADED"
                ? "Dégradé"
                : activeVersion.status === "FATAL"
                ? "En panne"
                : activeVersion.status === "UNKNOWN"
                ? "État inconnu"
                : "État inconnu"}
            </p>
          </article>
          <SubscriptionsMetric version={activeVersion} />
        </section>
      )}
    </>
  );
}
