import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddLogsApi from "../../api/mddLogsApi";
import revertingToken from "../../utils/auth/revertingToken";
import "../../styles/streamStore/logs/logs.css";

import Dashboard from "../../components/Dashboard";
import SideBar from "../../components/SideBar";
import LogsSideBar from "../../components/streamStore/logs/LogsSideBar";
import LogsNode from "../../components/streamStore/logs/LogsNode";
import LogsHeader from "../../components/streamStore/logs/LogsHeader";


export default function Logs() {
  const {
    showSideBar,
    fullScreen,
    closingSession,
    setShowSideBar,
    setTypeOfScreen,
    logsLevels,
    setFullSideBar,
    feedBackBuilder,
    userToken,
    activeClause,
    reverseLogs,
    mergeLogs,
    clauseLogs,
    succesfullChallenge,
  } = useContext(MddContext);
  let history = useHistory();
  const { version_reference } = useParams();

  const [firstLogsDate, setFirstLogsDate] = useState(new Date());
  const [secondLogsDate, setSecondLogsDate] = useState(new Date());
  const [fromDate, setFromDate] = useState("now-1h");
  const [firstParsed, setFirstParsed] = useState(null);
  const [secondParsed, setSecondParsed] = useState(null);
  const [logsLines, setLogsLines] = useState(50);
  const [liveLogs, setLiveLogs] = useState(true);
  const [parsedLevel, setParsedLevel] = useState(
    logsLevels.length > 0 ? logsLevels.toString() : ""
  );
  const [searchRegex, setSearchRegex] = useState("");
  //const [logsMessage, setLogsMessage] = useState("");
  const [logsPage, setLogsPage] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [pagesList, setPagesList] = useState([0]);
  const [allLogs, setAllLogs] = useState([]);
  const [activeNode, setActiveNode] = useState(null);
  const [localeTime, setLocaleTime] = useState(true);
  const [reloadIncrement, setReloadIncrement] = useState(0);

  const returningMilliSeconds = (dateInput) => {
    let stockMilliSeconds = dateInput.getMilliseconds();
    if (stockMilliSeconds < 10) {
      stockMilliSeconds = "00" + stockMilliSeconds;
    } else if (stockMilliSeconds < 100) {
      stockMilliSeconds = "0" + stockMilliSeconds;
    }
    return stockMilliSeconds;
  };
  const parsingDate = () => {
    function returningDate(dateInput) {
      const stockDate = `${
        dateInput.getDate() > 9
          ? dateInput.getDate()
          : "0" + dateInput.getDate()
      }/${
        dateInput.getMonth() + 1 > 9
          ? dateInput.getMonth() + 1
          : "0" + (dateInput.getMonth() + 1)
      }/${dateInput.getFullYear()} ${
        dateInput.getHours() > 9
          ? dateInput.getHours()
          : "0" + dateInput.getHours()
      }:${
        dateInput.getMinutes() > 9
          ? dateInput.getMinutes()
          : "0" + dateInput.getMinutes()
      }:${
        dateInput.getSeconds() > 9
          ? dateInput.getSeconds()
          : "0" + dateInput.getSeconds()
      }.${returningMilliSeconds(dateInput)}`;
      return stockDate;
    }
    firstLogsDate && setFirstParsed(returningDate(firstLogsDate));
    secondLogsDate && setSecondParsed(returningDate(secondLogsDate));
  };

  const retrievingLogs = () => {
    mddLogsApi
      .post(
        buildingRequest(),
        searchRegex.length > 0
          ? {
              regex: searchRegex,
            }
          : {},
        {
          headers: {
            Authorization: `Bearer ${revertingToken(userToken)}`,
          },
        }
      )
      .then((response) => {
        var logs = response.data.data;
        logs.sort(function(a,b){
          //return new Date(b.dt) - new Date(a.dt);
          if (a.ts == b.ts)
            return 0
          return a.ts < b.ts ? 1 : -1;          
        });
      
        setAllLogs(logs);

        if (response.status==206) {
          feedBackBuilder(
            false,
            `Attention: les logs d'au moins un node ne sont pas disponibles`,
            "Streams Store"
          );
        }
      })
      .catch((err) => {
        switch(err.response.status) {
          case 413:
            feedBackBuilder(
              false,
              `Sélection trop grande. Veuillez réduire votre sélection`,
              "Streams Store"
            );
            break;
          case 503:
            feedBackBuilder(
              false,
              `Les logs d'au moins un node ne sont pas disponible)`,
              "Streams Store"
            );
            break;
          default:
            feedBackBuilder(
              false,
              `Erreur de listing des logs`,
              "Streams Store"
            );    
        }       
      });
  };

  const buldingLocaleDate = (dateInput) => {
    const stockYear = dateInput.slice(6, 10);
    const stockMonth = dateInput.slice(3, 5);
    const stockDay = dateInput.slice(0, 2);
    const stockHours = dateInput.slice(11, 13);
    const stockMinutes = dateInput.slice(14, 16);
    const stockSeconds = dateInput.slice(17, 19);
    const stockMilliSeconds = dateInput.slice(20, 23);
    return `${stockYear}-${stockMonth}-${stockDay}T${stockHours}:${stockMinutes}:${stockSeconds}.${stockMilliSeconds}+02:00`;
  };

  const validateFromDate = (fromDate) => {
    return fromDate.match(/now-\d+(ms|s|m|h|d|D|w|W|S|M|y|Y)/);
  };

  const buildingRequest = () => {
    let stockRequest = `/stream/logs?reference=${version_reference}&from=${
      liveLogs ? (fromDate ? fromDate : "now-1h") : (localeTime ? buldingLocaleDate(firstParsed) : firstLogsDate.toISOString())
    }&live=${liveLogs}`;
    if (clauseLogs) {
      stockRequest = stockRequest + `&lambda=${activeClause.uuid}`;
    }
    if (parsedLevel.length > 0) {
      stockRequest = stockRequest + `&level=${parsedLevel}`;
    }
    
    if (!liveLogs) {
      stockRequest =
        stockRequest +
        `&start=${
          localeTime
            ? buldingLocaleDate(firstParsed)
            : firstLogsDate.toISOString()
        }&end=${
          localeTime
            ? buldingLocaleDate(secondParsed)
            : secondLogsDate.toISOString()
        }`;
    }
    stockRequest = stockRequest + '&mddynamics.tx=any'
    return stockRequest;
  };

  const handlingFullScreen = (inputLog) => {
    //console.log("handling full screen : ", inputLog, activeNode);
    if (inputLog) {
      setActiveNode(inputLog);
      setShowSideBar(false);
    } else {
      setShowSideBar(true);
      setActiveNode(null);
    }
  };

  const filters = {
    fields: ["message", "node", "lambda", "endpoint"],
  };

  const returningLogs = () => {
    let stockLogs = [];
    if (mergeLogs) {
      stockLogs.push(
        <LogsNode
          log={allLogs}
          handlingFullScreen={handlingFullScreen}
          nodeKey={1}
          mergeLogs={mergeLogs}
          setLogsPage={setLogsPage}
          logsPage={logsPage}
          localeTime={localeTime}
          activeNode={activeNode}
          scrollDirection={scrollDirection}
          setScrollDirection={setScrollDirection}
          pagesList={pagesList}
          setPagesList={setPagesList}
        />
      );
    } else {
      for (let key in allLogs) {
        stockLogs.push(
          <LogsNode
            log={allLogs[key].data}
            handlingFullScreen={handlingFullScreen}
            nodeKey={key}
            mergeLogs={mergeLogs}
            setLogsPage={setLogsPage}
            logsPage={logsPage}
            localeTime={localeTime}
            logsNumber={allLogs.length}
            activeNode={activeNode}
            scrollDirection={scrollDirection}
            setScrollDirection={setScrollDirection}
            pagesList={pagesList}
            setPagesList={setPagesList}
          />
        );
      }
    }
    return stockLogs;
  };

  useEffect(() => {
    if (closingSession()) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    setShowSideBar(true);
    setFullSideBar(true);
    setTypeOfScreen("logs");
    return function exitEdit() {
      setTypeOfScreen("");
    };
  }, []);

  useEffect(() => {
    (firstLogsDate || secondLogsDate) && parsingDate();
  }, [firstLogsDate, secondLogsDate]);

  useEffect(() => {
    setParsedLevel(logsLevels.length > 0 ? logsLevels.toString() : "");
  }, [logsLevels]);

  useEffect(() => {
    activeClause && 
    validateFromDate(fromDate) &&
    retrievingLogs();
  }, [
    parsedLevel,
    logsLines,
    liveLogs,
    mergeLogs,
    reverseLogs,
    version_reference,
    firstLogsDate,
    secondLogsDate,
    activeClause,
    logsPage,
    reloadIncrement,
    clauseLogs,
    fromDate,
  ]);

  useEffect(() => {
    if (succesfullChallenge) {
      activeClause && retrievingLogs();
    }
  }, [succesfullChallenge]);

  return (
    <>
      {fullScreen ||
        (showSideBar && (
          <SideBar>
            <LogsSideBar />
          </SideBar>
        ))}
      <Dashboard containerClass="dashboardContainer logsDashboardContainer">
        <div className="logsGlobalContainer">
          {!activeNode && (
            <LogsHeader
              logsLines={logsLines}
              setLogsLines={setLogsLines}
              localeTime={localeTime}
              setLocaleTime={setLocaleTime}
              liveLogs={liveLogs}
              setLiveLogs={setLiveLogs}
              setReloadIncrement={setReloadIncrement}
              reloadIncrement={reloadIncrement}
              firstLogsDate={firstLogsDate}
              secondLogsDate={secondLogsDate}
              firstParsed={firstParsed}
              secondParsed={secondParsed}
              setFirstParsed={setFirstParsed}
              setSecondParsed={setSecondParsed}
              setFirstLogsDate={setFirstLogsDate}
              buldingLocaleDate={buldingLocaleDate}
              setSecondLogsDate={setSecondLogsDate}
              setFromDate={setFromDate}
              fromDate={fromDate}
            />
          )}
          <section class="search">
            <div>
              <input
                className="form-control"
                list="searchOption"
                id="searchOption"
                name="searchOption"
                placeholder="Recherche"
                value={searchRegex}
                onChange={(e) => {
                  setSearchRegex(e.target.value);
                }}
                onKeyUp={(e) => {
                  (e.key === "Enter" || e.keyCode === 13) &&
                  retrievingLogs();
                }}
              />
              <datalist id="searchOption">
                <option value="message"/>
              </datalist>
            </div>            
          </section>
          <section className="logsBody">
            <div>{returningLogs()}</div>
          </section>
        </div>
      </Dashboard>
    </>
  );
}
