import { useState } from "react";

export default function FlightCard({ flight, flightsNav }) {
  const [openedCard, setOpenedCard] = useState(false);

  const buildingTime = () => {
    let stockTime = null;
    if (flight) {
      if (flightsNav === "estimations") {
        stockTime = new Date(flight.estimatedTime);
        return `${stockTime.toLocaleDateString()} ${stockTime
          .toLocaleTimeString()
          .slice(0, 5)}${
          flight.targetedTime !== flight.estimatedTime ? " RETARDÉ" : ""
        }`;
      } else {
        stockTime = new Date(flight.targetedTime);
        return `${stockTime.toLocaleDateString()} ${stockTime
          .toLocaleTimeString()
          .slice(0, 5)}`;
      }
    }
  };

  return (
    <article
      className={`dependenciesCard streamDependency${
        openedCard ? " openedFlight" : ""
      }`}
      onClick={() => {
        setOpenedCard(!openedCard);
      }}
    >
      <p>{buildingTime()}</p>
      <p>{flight.destination}</p>
      <p>{flight.departure}</p>
      <p>{flight.company}</p>
      <p>{flight.flight}</p>
      <p>{flight.runway}</p>
      {openedCard && (
        <div>
          <p>départ : {flight.departureTime}</p>
          <p>durée prévue : {flight.duration}</p>
          <p>type d'appareil : {flight.aircraftType}</p>
          <p>numéro d'appareil : {flight.aircraftID}</p>
        </div>
      )}
    </article>
  );
}
