export const subscriptionsFilters = {
  fields: [
    "tout",
    "nom",
    "nom du stream",
    "reference",
    "abonné",
    "token",
    "fournisseur",
    "état",
    "statut",
    "description",
  ],
};
