import "../../styles/reusableElements/mddButton.css";

export default function MddButton({
  buttonInnerText,
  buttonActionFunctionOne,
  buttonActionFunctionTwo,
  buttonActionPropOne,
  buttonActionPropTwo,
  buttonSize,
  smallButtonDescription,
  iconName,
  iconColor,
  inactive,
  fakeButton,
  inactiveTitle,
  smallIcon,
  noPropagation,
  mousedown,
}) {
  const retrieveLength = (size) => {
    switch (size) {
      case "medium":
        return "btn btn-primary shadow-none mddButton";
      case "long":
        return "btn btn-primary shadow-none mddLongButton";
      case "small":
        return "btn btn-primary shadow-none mddSmallButton";
      default:
        return "btn btn-primary shadow-none mddButton";
    }
  };
  const buildClassName = () => {
    let stockClass = retrieveLength(buttonSize);
    if (inactive) {
      stockClass = stockClass + " inactiveButton";
    }
    if (fakeButton) {
      stockClass = stockClass + " fakeButton";
    }
    if (iconName && buttonInnerText) {
      stockClass = stockClass + " fullButton";
    }
    if (iconColor) {
      return `${stockClass} ${iconColor}`;
    }
    return stockClass;
  };

  return (
    <>
      {inactive || fakeButton ? (
        <article
          className={buildClassName()}
          aria-label={smallButtonDescription && smallButtonDescription}
          title={inactiveTitle}
        >
          {iconName && (
            <i
              className={`${iconName}${smallIcon ? " smallIcon" : ""}`}
              // style={{ fontSize: smallIcon ? "0.75rem" : "1.5rem" }}
            />
          )}
          {iconName && buttonInnerText && " "}
          {buttonInnerText && buttonInnerText}
        </article>
      ) : (
        <button
          className={buildClassName()}
          onClick={(e) => {
            noPropagation && e.stopPropagation();
            buttonActionFunctionOne &&
              buttonActionFunctionOne(
                buttonActionPropOne && buttonActionPropOne
              );
            if (!mousedown) {
              buttonActionFunctionTwo &&
                buttonActionFunctionTwo(
                  buttonActionPropTwo && buttonActionPropTwo
                );
            }
          }}
          onMouseDown={(e) => {
            noPropagation && e.stopPropagation();
            if (mousedown) {
              buttonActionFunctionTwo &&
                buttonActionFunctionTwo(
                  e,
                  buttonActionPropTwo && buttonActionPropTwo
                );
            }
          }}
          type="button"
          aria-label={smallButtonDescription && smallButtonDescription}
          title={inactiveTitle && inactiveTitle}
        >
          {iconName && (
            <i
              className={`${iconName}${smallIcon ? " smallIcon" : ""}`}
              // style={{ fontSize: smallIcon ? "0.75rem" : "1.5rem" }}
            />
          )}
          {buttonInnerText && buttonInnerText}
        </button>
      )}
    </>
  );
}
