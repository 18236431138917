import returningRandomData from "./returningRandomData";
import returningRandomColors from "./returningRandomColors";

export const radar = {
  settings: {
    labels: [
      "Un",
      "Deux",
      "Trois",
      "Quatre",
      "Cinq",
      "Six",
      "Sept",
      "Huit",
      "Neuf",
      "Dix",
    ],
    datasets: [
      {
        label: "Suivi",
        type: "radar",
        fill: {
          target: "origin",
          above: "#ffffffa4",
          below: "#ffffffa4",
        },
        borderColor: returningRandomColors(),
        pointBackgroundColor: returningRandomColors(),
        data: returningRandomData(false, "radar"),
        pointRadius: 4,
      },
    ],
  },
  config: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Suivi : ${context.formattedValue}`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  },
};
