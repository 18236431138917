import { useContext, useEffect, useState, useRef } from "react";

import MddButton from "../../../../../../components/reusableElements/MddButton";

export default function DatavizSwitch({
  displayValue,
  label,
  input,
  path,
  updater,
  outputValue,
}) {
  const [firstOption, setFirstOption] = useState(
    input && outputValue.length > 0 && input === outputValue[1] ? false : true
  );
  const [modified, setModified] = useState(false);

  const positioningToggle = () => {
    if (!firstOption) {
      return { left: "50%" };
    } else if (firstOption) {
      return { left: "0" };
    } else {
      return { opacity: "0" };
    }
  };

  const sortingOutput = () => {
    if (firstOption) {
      return outputValue[0];
    } else {
      return outputValue[1];
    }
  };

  useEffect(() => {
    if (modified && sortingOutput() !== input) {
      updater(path, sortingOutput());
    }
  }, [firstOption]);

  return (
    <div className="datavizSwitch">
      <label>{label}</label>
      <div>
        <div
          className={`datavizSwitchBackground ${
            !firstOption ? "active" : "inactive"
          }`}
          style={positioningToggle()}
        />
        <MddButton
          buttonInnerText={displayValue[0]}
          buttonActionFunctionOne={setFirstOption}
          buttonActionPropOne={true}
          buttonActionFunctionTwo={setModified}
          buttonActionPropTwo={true}
          iconColor={`${
            firstOption ? "activeAltButton" : "altButtonColor"
          } switchButton-${displayValue[0]}-${label}`}
        />
        <MddButton
          buttonInnerText={displayValue[1]}
          buttonActionFunctionOne={setFirstOption}
          buttonActionPropOne={false}
          buttonActionFunctionTwo={setModified}
          buttonActionPropTwo={true}
          iconColor={`${
            !firstOption ? "activeAltButton" : "altButtonColor"
          } switchButton-${displayValue[1]}-${label}`}
        />
      </div>
    </div>
  );
}
