import { useState, useContext } from "react";

import { MddContext } from "../../../context/MddContext";

import MddButton from "../../reusableElements/MddButton";
import MddCalendar from "../../reusableElements/MddCalendar";

export default function LogsHeader({
  logsLines,
  setLogsLines,
  localeTime,
  setLocaleTime,
  liveLogs,
  setLiveLogs,
  setReloadIncrement,
  reloadIncrement,
  firstLogsDate,
  secondLogsDate,
  firstParsed,
  secondParsed,
  setFirstParsed,
  setSecondParsed,  
  setFirstLogsDate,
  buldingLocaleDate,
  setSecondLogsDate,
  setFromDate,
  fromDate,
}) {
  const { activeClause } = useContext(MddContext);

  const [showLogsModal, setShowLogsModal] = useState(false);
  const [dateSelector, setDateSelector] = useState(null);
  const [focusedSearchBar, setFocusedSearchBar] = useState(false);

  const registeringDate = (newDate) => {
    switch (dateSelector) {
      case "firstDate":
        setFirstLogsDate(newDate);
        break;
      case "secondDate":
        setSecondLogsDate(newDate);
        break;
      default:
    }
  };
  const findingStartDate = () => {
    switch (dateSelector) {
      case "firstDate":
        return firstLogsDate ? firstLogsDate : new Date();
      case "secondDate":
        return secondLogsDate ? secondLogsDate : new Date();
      default:
        break;
    }
  };

  const unparsingDate = (dateInput) => {
    let stockDate = buldingLocaleDate(dateInput);
    switch (dateSelector) {
      case "firstDate":
        setFirstLogsDate(new Date(stockDate));
        setDateSelector(null);
        setShowLogsModal(false);
        break;
      case "secondDate":
        setSecondLogsDate(new Date(stockDate));
        setDateSelector(null);
        setShowLogsModal(false);
        break;
      default:
        setDateSelector(null);
        setShowLogsModal(false);
    }
  };

  const handlingModalOpening = (dateInput) => {
    if (!showLogsModal) {
      setShowLogsModal(true);
    } else if (dateInput === dateSelector) {
      setShowLogsModal(false);
    }
  };
  return (
    <section className="logsHeader">
      <div>
        <div class="lambda-path">{activeClause ? activeClause.path : "Sélectionnez une lambda"}</div>
      </div>
      <article>
        <input
          className="form-control"
          list="dateFromOptions"
          id="dateFromInput"
          name="dateFromInput"
          placeholder="From"
          value={fromDate}
          onChange={(e) => {
            setFromDate(e.target.value);
          }}
        />
        <datalist id="dateFromOptions">
          <option value="now-5m"/>
          <option value="now-15m"/>
          <option value="now-30m"/>
          <option value="now-1h"/>
          <option value="now-3h"/>
          <option value="now-12h"/>
          <option value="now-1d"/>
          <option value="now-2d"/>
          <option value="now-7d"/>
        </datalist>
        
       
        <article className="dataRefreshToggle">
          <div
            className={`exportNavBackground ${liveLogs ? "live" : "off"}`}
            style={liveLogs ? { left: "4rem" } : { left: "0" }}
          />
          <MddButton
            buttonInnerText="période"
            buttonActionFunctionOne={setLiveLogs}
            buttonActionPropOne={false}
            iconColor={!liveLogs ? "activeAltButton" : "altButtonColor"}
          />
          <MddButton
            buttonInnerText="live"
            buttonActionFunctionOne={setLiveLogs}
            buttonActionPropOne={true}
            buttonActionFunctionTwo={liveLogs ? setReloadIncrement : null}
            buttonActionPropTwo={liveLogs ? reloadIncrement + 1 : null}
            iconColor={liveLogs ? "activeAltButton" : "altButtonColor"}
          />
        </article>
        <MddButton
          buttonInnerText={`${firstLogsDate.toLocaleDateString()} ${firstLogsDate
            .toLocaleTimeString()
            .slice(0, 5)}`}
          buttonSize="long"
          iconColor={liveLogs ? "inactiveAltButton" : "altButtonColor"}
          buttonActionFunctionOne={setDateSelector}
          buttonActionPropOne={dateSelector === "firstDate" ? "" : "firstDate"}
          buttonActionFunctionTwo={handlingModalOpening}
          buttonActionPropTwo="firstDate"
          iconName="bi bi-calendar-range"
        />
        <MddButton
          buttonInnerText={`${secondLogsDate.toLocaleDateString()} ${secondLogsDate
            .toLocaleTimeString()
            .slice(0, 5)}`}
          iconColor={liveLogs ? "inactiveAltButton" : "altButtonColor"}
          buttonActionFunctionOne={setDateSelector}
          buttonActionPropOne={
            dateSelector === "secondDate" ? "" : "secondDate"
          }
          buttonActionFunctionTwo={handlingModalOpening}
          buttonActionPropTwo="secondDate"
        />
      </article>
      <MddCalendar
        showModal={showLogsModal}
        registeringDate={registeringDate}
        findingStartDate={findingStartDate}
        unparsingDate={unparsingDate}
        dateParsed={dateSelector === "firstDate" ? firstParsed : secondParsed}
        setDateParsed={
          dateSelector === "firstDate" ? setFirstParsed : setSecondParsed
        }
      />      
    </section>
  );
}
