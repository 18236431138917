import { useContext, useEffect, useState, useRef } from "react";

import Prism from "prismjs";
import { MddContext } from "../../../../../../context/MddContext";
import "../../../../../../styles/streamStore/detailedStream/prism.css";

import MddButton from "../../../../../../components/reusableElements/MddButton";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizList from "../inputs/DatavizList";

let prefix = Array.prototype.slice
  .call(window.getComputedStyle(document.documentElement, ""))
  .join("")
  .match(/-(moz|webkit|ms)-/)[1];

export default function RequestData({ name, datavizSidebar, updatingWidget }) {
  const { appRem } = useContext(MddContext);

  const codeInputRef = useRef();

  const [openedCategory, setOpenedCategory] = useState(false);
  const [requestCode, setRequestCode] = useState("");
  const [fullScreenCode, setFullScreenCode] = useState(false);
  const [checkSpell, setCheckSpell] = useState(false);
  const [editedCode, setEditedCode] = useState(false);
  const [tabChecker, setTabChecker] = useState(null);

  const returningMaxHeight = () => {
    if (openedCategory) {
      const stockCategory = document.getElementById(`openedCategory-${name}`);
      const stockInput = document.getElementById(`${name}-requestInput`);
      console.log("returning max height : ", stockInput);
      stockCategory.style.maxHeight =
        Math.round(
          ((stockCategory.childNodes.length - 1) * 2.45 + 3.7) * appRem +
            stockInput.offsetHeight
        ) + "px";
    } else {
      const stockCategory = document.getElementById(`${name}`);
      stockCategory.style.maxHeight = "2.2rem";
    }
  };

  const handleKeyDown = (e) => {
    prefix === "moz" && setCheckSpell(true);
    let value = requestCode,
      selStartPos = e.currentTarget.selectionStart;

    if (e.key === "Tab" || e.keyCode === 9) {
      console.log("handleKeyDown", e);
      value =
        value.substring(0, selStartPos) +
        "\t" +
        value.substring(selStartPos, value.length);
      e.preventDefault();
      setRequestCode(value);
      setTabChecker(selStartPos + 1);
    }
  };

  function setHeight() {
    let stockInput = document.getElementsByClassName("codeInput");
    let stockOutput = document.getElementsByClassName("codeOutput");
    for (let i = 0; i < stockInput.length; i++) {
      stockInput[i].style.height = stockInput[i].scrollHeight + "px";
      stockOutput[i].style.height = stockInput[i].scrollHeight + "px";
    }
  }

  const repositioningAfterTab = () => {
    codeInputRef.current.setSelectionRange(tabChecker, tabChecker);
    setTabChecker(null);
  };

  useEffect(() => {
    setHeight();
  });

  useEffect(() => {
    returningMaxHeight();
  }, [openedCategory]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    Prism.highlightAll();
  }, [requestCode]);

  useEffect(() => {
    tabChecker && repositioningAfterTab();
  }, [tabChecker]);

  return (
    <article
      className={`optionsCategory${openedCategory ? " openedCategory" : ""}`}
      id={`${openedCategory ? "openedCategory-" : ""}${name}`}
    >
      <div
        onClick={() => {
          setOpenedCategory(!openedCategory);
        }}
      >
        <MddButton
          iconName="bi bi-caret-down"
          buttonSize="small"
          smallButtonDescription="open category"
          buttonActionFunctionOne={setOpenedCategory}
          buttonActionPropOne={!openedCategory}
          iconColor="altButtonColor"
        />
        <h5>{name}</h5>
        <MddButton
          iconName="bi bi-x-lg"
          buttonSize="small"
          smallButtonDescription="delete request"
          // buttonActionFunctionOne={setOpenedCategory}
          // buttonActionPropOne={!openedCategory}
          iconColor="altButtonColor"
        />
      </div>
      <DatavizTextInput
        label={"métrique"}
        displayValue={"requête"}
        // input={datavizSidebar.widget.style.body.fontFamily}
        // path={["style", "body", "fontFamily"]}
        // updater={updatingWidget}
      />
      <div className="datavizInput">
        <label>datasource</label>
        <MddButton
          buttonInnerText="choisir"
          //   buttonActionFunctionOne={deletingDashboard}
          iconColor="basicButtonColor"
        />
      </div>
      <DatavizList
        label="type"
        displayValue={["série temporelle", "json", "keys", "valeurs", "grille"]}
        outputValue={["timeserie", "json", "json-keys", "json-values", "table"]}
        // input={set.borderJoinStyle}
        // path={["component", "settings", "datasets", index, "borderJoinStyle"]}
        // updater={updatingWidget}
      />
      <div className="datavizInput textAreaInput">
        <label>requête</label>
        {/* <textarea id={`${name}-requestInput`} /> */}
        <article
          id={`${name}-requestInput`}
          className={
            fullScreenCode
              ? "codeEditorContainer fullScreenCodeEditor"
              : "codeEditorContainer"
          }
        >
          <textarea
            ref={codeInputRef}
            className="codeInput"
            wrap="off"
            cols="200"
            spellCheck={prefix === "moz" ? checkSpell : false}
            value={requestCode}
            onChange={(e) => {
              setRequestCode(e.target.value);
              !editedCode && setEditedCode(true);
            }}
            onKeyDown={(e) => handleKeyDown(e)}
            onKeyUp={() => prefix === "moz" && setCheckSpell(false)}
          />
          <pre className="codeOutput">
            <code className={`language-javascript`}>{requestCode}</code>
          </pre>
          <div className="codeButtonsContainer">
            <MddButton
              iconName="bi bi-aspect-ratio"
              buttonSize="small"
              smallButtonDescription="Full screen code editor"
              buttonActionFunctionOne={setFullScreenCode}
              buttonActionPropOne={!fullScreenCode}
              iconColor="altButtonColor"
            />
          </div>
        </article>
      </div>
    </article>
  );
}
