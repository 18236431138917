import { useState, useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddAuth from "../../api/mddAuth";
import changingToken from "../../utils/auth/changingToken";
import synapseCouleurOriginal from "../../utils/pictures/synapseCouleurOriginal.png";
import "../../styles/auth/login.css";

import Dashboard from "../../components/Dashboard";
import MddButton from "../../components/reusableElements/MddButton";

export default function Login() {
  const {
    fullScreen,
    setFullScreen,
    setShowSideBar,
    currentUser,
    setCurrentUser,
    setUserToken,
    setTokenLimit,
    setCurrentSolution,
    setUserRole,
    feedBackBuilder,
  } = useContext(MddContext);
  let history = useHistory();

  const [activeLogin, setActiveLogin] = useState(false);
  const [userOtherValue, setUserOtherValue] = useState("");
  const [userValue, setUserValue] = useState("");
  const [loginStep, setLoginStep] = useState(1);
  const [incorrectInputs, setIncorrectInputs] = useState(false);

  const encodingToBase64 = (str) => {
    if (str) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
    return window.btoa(unescape(encodeURIComponent("")));
  };

  const decodingToken = (tokenInput) => {
    let base64Url = tokenInput.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  const handlingLogin = () => {
    setIncorrectInputs(false);
    if (loginStep === 1) {
      setLoginStep(2);
    } else if (loginStep === 2) {
      mddAuth
        .post(
          `/oauth2/login`,
          {},
          {
            headers: {
              Authorization: `Basic ${encodingToBase64(
                userOtherValue + ":" + userValue
              )}`,
            },
          }
        )
        .then((response) => {
          setCurrentSolution(response.data.solution);
          setUserToken(changingToken(response.data.data.token));
          let stockUser = decodingToken(response.data.data.token);
          setTokenLimit(stockUser.exp);
          setCurrentUser(stockUser.sub);
          setUserRole(stockUser.aud);
          feedBackBuilder(true, "Vous êtes connecté", "Profil");
          history.push(`/`);
        })
        .catch((err) => {
          console.log("erreur au niveau de la requête : ", err);
          if (err && err.response && err.response.status === 401) {
            setIncorrectInputs(true);
            feedBackBuilder(
              false,
              `Identifiant ou mot de passe erroné`,
              "Profil"
            );
          } else {
            feedBackBuilder(false, `La connexion a echoué`, "Profil");
          }
        });
    }
  };

  useEffect(() => {
    currentUser && history.push(`/`);
  }, [currentUser]);

  useEffect(() => {
    setFullScreen(true);
    setShowSideBar(false);
    return function exitLogin() {
      fullScreen && setFullScreen(false);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      !activeLogin && setActiveLogin(true);
    }, 3000);
  }, []);

  return (
    <Dashboard containerClass="dashboardContainer loginDashboardContainer">
      <div
        className={
          activeLogin
            ? "loginGlobalContainer activeLogin"
            : "loginGlobalContainer"
        }
        onMouseMove={() => {
          !activeLogin && setActiveLogin(true);
        }}
        onKeyPress={() => {
          !activeLogin && setActiveLogin(true);
        }}
      >
      <div></div>            
        {activeLogin && (
          <form
            className="loginInputContainer"
            onSubmit={(e) => e.preventDefault()}
          >
            {loginStep === 1 && <div className="loginPlaceholder" />}
            {loginStep === 2 && (
              <MddButton
                iconName="bi bi-arrow-left-circle-fill"
                buttonSize="small"
                smallButtonDescription="submit form"
                buttonActionFunctionOne={setLoginStep}
                buttonActionPropOne={1}
              />
            )}
            <article
              className={incorrectInputs ? "incorrectInput" : ""}
              title={
                incorrectInputs
                  ? "Le nom d'utilisateur ou le mot de passe est incorrect."
                  : ""
              }
            >
              {loginStep === 1 ? (
                <input
                  type="text"
                  id="loginForm"
                  placeholder="login"
                  required
                  value={userOtherValue}
                  onChange={(e) => setUserOtherValue(e.target.value)}
                  onKeyUp={(e) => {
                    (e.key === "Enter" || e.keyCode === 13) &&
                      loginStep === 1 &&
                      setLoginStep(2);
                  }}
                />
              ) : (
                <input
                  type="password"
                  id="valueForm"
                  placeholder="mot de passe"
                  required
                  value={userValue}
                  onChange={(e) => setUserValue(e.target.value)}
                  onKeyUp={(e) => {
                    (e.key === "Enter" || e.keyCode === 13) &&
                      loginStep === 2 &&
                      handlingLogin();
                  }}
                />
              )}
              <MddButton
                iconName="bi bi-arrow-right-circle-fill"
                buttonSize="small"
                smallButtonDescription="submit form"
                buttonActionFunctionOne={handlingLogin}
                iconColor="altButtonColor"
              />
            </article>
            <Link to={`/account/subscribe`}>Créer un compte</Link>
            <Link to={`/account/forgotten-password`}>
              Mot de passe oublié ?
            </Link>
          </form>
        )}
        <article className="loginTitleContainer">
          <h1>
            <div class="loginPageTitle">MD Dynamics Link</div>  
            <div class="loginPageSubtitle"><span>Real</span>time Streaming Analytics</div>  
            
          </h1>
          <div>
            <h5>
                MD <span>DYNAMICS</span>
            </h5>
            <img src={synapseCouleurOriginal} alt="logo MD Dynamics" />
          </div>
        </article>
      </div>
    </Dashboard>
  );
}
