import { useState, useContext, useEffect } from "react";

import "../../../styles/sideBar.css";
import { MddContext } from "../../../context/MddContext";

import MddButton from "../../reusableElements/MddButton";

export default function LogsSideBar() {
  const {
    setLogsLevels,
    reverseLogs,
    setReverseLogs,
    mergeLogs,
    setMergeLogs,
    clauseLogs,
    setClauseLogs,
  } = useContext(MddContext);

  const [traceFilter, setTraceFilter] = useState(false);
  const [debugFilter, setDebugFilter] = useState(false);
  const [infoFilter, setInfoFilter] = useState(false);
  const [warnFilter, setWarnFilter] = useState(false);
  const [errorFilter, setErrorFilter] = useState(false);
  const [fatalFilter, setFatalFilter] = useState(false);

  const buildingLevels = () => {
    let stockLevels = [];
    traceFilter && stockLevels.push("TRACE");
    debugFilter && stockLevels.push("DEBUG");
    infoFilter && stockLevels.push("INFO");
    warnFilter && stockLevels.push("WARN");
    errorFilter && stockLevels.push("ERROR");
    fatalFilter && stockLevels.push("FATAL");
    setLogsLevels(stockLevels);
  };

  useEffect(() => {
    buildingLevels();
  }, [
    traceFilter,
    debugFilter,
    infoFilter,
    warnFilter,
    errorFilter,
    fatalFilter,
  ]);

  return (
    <section className="streamStoreSideBarContainer">
      <article>
        <p>Niveaux</p>
        <MddButton
          buttonInnerText="TRACE"
          buttonActionFunctionOne={setTraceFilter}
          buttonActionPropOne={!traceFilter}
          iconColor={traceFilter ? "" : "secondaryButton"}
        />
        <MddButton
          buttonInnerText="DEBUG"
          buttonActionFunctionOne={setDebugFilter}
          buttonActionPropOne={!debugFilter}
          iconColor={debugFilter ? "" : "secondaryButton"}
        />
        <MddButton
          buttonInnerText="INFO"
          buttonActionFunctionOne={setInfoFilter}
          buttonActionPropOne={!infoFilter}
          iconColor={infoFilter ? "" : "secondaryButton"}
        />
        <MddButton
          buttonInnerText="WARN"
          buttonActionFunctionOne={setWarnFilter}
          buttonActionPropOne={!warnFilter}
          iconColor={warnFilter ? "" : "secondaryButton"}
        />
        <MddButton
          buttonInnerText="ERROR"
          buttonActionFunctionOne={setErrorFilter}
          buttonActionPropOne={!errorFilter}
          iconColor={errorFilter ? "" : "secondaryButton"}
        />
        <MddButton
          buttonInnerText="FATAL"
          buttonActionFunctionOne={setFatalFilter}
          buttonActionPropOne={!fatalFilter}
          iconColor={fatalFilter ? "" : "secondaryButton"}
        />
      </article>
      {/* <article>
        <p>Affichage</p>
        <MddButton
          iconName={mergeLogs ? "bi bi-square" : "bi bi-view-stacked"}
          buttonActionFunctionOne={setMergeLogs}
          buttonActionPropOne={!mergeLogs}
          iconColor={mergeLogs ? "" : "secondaryButton"}
        />
      </article> */}
      <article>
        <p>Options</p>
        <MddButton
          buttonInnerText="LAMBDA"
          buttonActionFunctionOne={setClauseLogs}
          buttonActionPropOne={!clauseLogs}
          iconColor={clauseLogs ? "" : "secondaryButton"}
        />
        {/*
        <MddButton
          buttonInnerText="REVERSE"
          buttonActionFunctionOne={setReverseLogs}
          buttonActionPropOne={!reverseLogs}
          iconColor={reverseLogs ? "" : "secondaryButton"}
        />
        */}
      </article>
    </section>
  );
}
