import { useContext, useEffect, useState, useRef } from "react";

import OptionsCategory from "../OptionsCategory";
import DatavizColorPicker from "../inputs/DatavizColorPicker";
import DatavizList from "../inputs/DatavizList";
import DatavizNumberInput from "../inputs/DatavizNumberInput";
import DatavizSwitch from "../inputs/DatavizSwitch";
import DatavizTextInput from "../inputs/DatavizTextInput";
import DatavizIconsSwitch from "../inputs/DatavizIconsSwitch";

export default function LineChart({
  datavizSidebar,
  updatingWidget,
  set,
  index,
}) {
  return (
    <OptionsCategory name={`Dataset : ${set.label}`}>
      <p>Ligne</p>
      <DatavizColorPicker
        label="couleur"
        input={set.borderColor}
        path={["component", "settings", "datasets", index, "borderColor"]}
        updater={updatingWidget}
      />
      <DatavizIconsSwitch
        label="axe"
        displayValue={[
          "bi bi-align-start",
          "bi bi-align-center",
          "bi bi-align-end",
        ]}
        outputValue={["left", "center", "right"]}
        input={
          datavizSidebar.widget.component.config.scales[set.yAxisID].position
        }
        path={["component", "config", "scales", set.yAxisID, "position"]}
        updater={updatingWidget}
      />
      <DatavizSwitch
        label="courbure"
        displayValue={["manuelle", "auto"]}
        outputValue={["default", "monotone"]}
        input={set.cubicInterpolationMode}
        path={[
          "component",
          "settings",
          "datasets",
          index,
          "cubicInterpolationMode",
        ]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label="tension"
        displayValue={5}
        outputValue={["multiply", 10]}
        input={set.tension}
        path={["component", "settings", "datasets", index, "tension"]}
        updater={updatingWidget}
      />
      <p>Remplissage</p>
      <DatavizSwitch
        label="visible"
        displayValue={["oui", "non"]}
        outputValue={["origin", "false"]}
        input={set.fill && set.fill.target && set.fill.target}
        path={["component", "settings", "datasets", index, "fill", "target"]}
        updater={updatingWidget}
      />
      <DatavizColorPicker
        label="couleur"
        input={set.fill && set.fill.above && set.fill.above}
        path={["component", "settings", "datasets", index, "fill", "above"]}
        updater={updatingWidget}
      />
      <p>Points</p>
      <DatavizColorPicker
        label="couleur"
        input={set.pointBorderColor}
        path={["component", "settings", "datasets", index, "pointBorderColor"]}
        updater={updatingWidget}
      />
      <DatavizNumberInput
        label="taille"
        displayValue={5}
        outputValue={["multiply", 1]}
        input={set.pointRadius}
        path={["component", "settings", "datasets", index, "pointRadius"]}
        updater={updatingWidget}
      />
      <DatavizColorPicker
        label="remplissage"
        input={set.pointBackgroundColor}
        path={[
          "component",
          "settings",
          "datasets",
          index,
          "pointBackgroundColor",
        ]}
        updater={updatingWidget}
      />
    </OptionsCategory>
  );
}
