import { useContext } from "react";
import { MddContext } from "../context/MddContext";

import "../styles/footer.css";

export default function Footer() {
  const { fullScreen, currentSolution } = useContext(MddContext);

  return (
    <div
      className="footerContainer"
      style={fullScreen ? { display: "none" } : null}
    >
      <p>{currentSolution} | </p>
      <a
        href="https://www.mddynamics.fr/pages/contact"
        rel="noreferrer"
        target="_blank"
      >
        Support technique
      </a>
    </div>
  );
}
