import { useEffect, useState, useContext, useRef } from "react";
import Prism from "prismjs";
import { MddContext } from "../../../../context/MddContext";

import "../../../../styles/streamStore/detailedStream/prism.css";
import "../../../../styles/streamStore/detailedStream/editCode.css";

import MddButton from "../../../reusableElements/MddButton";

let prefix = Array.prototype.slice
  .call(window.getComputedStyle(document.documentElement, ""))
  .join("")
  .match(/-(moz|webkit|ms)-/)[1];

export default function EditCode({
  codeContent,
  codeName,
  setCodeContent,
  setCodeName,
  fileName,
  setFileName,
  allCodes,
  codeReason,
  editedCode,
  setEditedCode,
  packagesTest,
  versionPublished,
  sharedVersion,
  sharingVersion,
}) {
  const codeInputRef = useRef();
  const { activeStream, currentTeam, activeVersion } = useContext(MddContext);

  const [fullScreenCode, setFullScreenCode] = useState(false);
  const [checkSpell, setCheckSpell] = useState(false);
  const [tabChecker, setTabChecker] = useState(null);

  const handleKeyDown = (e) => {
    prefix === "moz" && setCheckSpell(true);
    let value = codeContent,
      selStartPos = e.currentTarget.selectionStart;

    if (e.key === "Tab" || e.keyCode === 9) {
      //console.log("handleKeyDown", e);
      value =
        value.substring(0, selStartPos) +
        "\t" +
        value.substring(selStartPos, value.length);
      e.preventDefault();
      setCodeContent(value);
      setTabChecker(selStartPos + 1);
    }
  };

  function setHeight() {
    let stockInput = document.getElementsByClassName("codeInput");
    let stockOutput = document.getElementsByClassName("codeOutput");
    for (let i = 0; i < stockInput.length; i++) {
      stockInput[i].style.height = stockInput[i].scrollHeight + "px";
      stockOutput[i].style.height = stockInput[i].scrollHeight + "px";
    }
  }

  function setLabelsHeight() {
    let stockInputs = document.getElementsByClassName("inputsContainer");
    let stockLabels = document.getElementsByClassName("labelsContainer");
    stockLabels[0].style.height = stockInputs[0].scrollHeight + "px";
  }

  const handlingSelectChange = (e) => {
    if (e.target.value === "createFile") {
      setFileName("");
      setCodeName("");
      setCodeContent("");
    } else {
      // FIXME force 'codeEditorContainer' to reset scroll position to 0 when opening a file
      setCodeName(e.target.value);
    }
  };

  const repositioningAfterTab = () => {
    codeInputRef.current.setSelectionRange(tabChecker, tabChecker);
    setTabChecker(null);
  };

  const positioningToggle = () => {
    if (sharedVersion) {
      return { left: "3.5rem" };
    } else if (!sharedVersion) {
      return { left: "0" };
    } else {
      return { opacity: "0" };
    }
  };

  const displayLogs = (packagesTest, codeReason) => {
    let logs = [];
    //console.log(codeReason);
    if (codeReason) {
      let lines = codeReason.split("\n");
      for (let i = 0; i < lines.length; i++) {
        logs.push(<p className={packagesTest === "failed" ? "statusAlert" : "statusOk"}>{lines[i]}</p>);
      }
    }
    return logs;    
  };

  const getCodeContent = () => {
    
    return codeContent;
  }

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    Prism.highlightAll();    
  }, [codeContent]);

  useEffect(() => {
    activeVersion && setHeight();
    activeVersion && setLabelsHeight();
  });

  useEffect(() => {
    tabChecker && repositioningAfterTab();
  }, [tabChecker]);

  return (
    <section className="codeGlobalContainer">
      {activeVersion ? (
        <article className="codeNameContainer">
          <div className="labelsContainer">
            {allCodes.length > 0 && (
              <label htmlFor="selectCodeForm">Fichier</label>
            )}
            {(activeStream.provider === currentTeam || currentTeam === "all") &&
              !versionPublished && <label htmlFor="fileNameForm">Nom</label>}
          </div>
          <div className="inputsContainer">
            {allCodes.length > 0 && (
              <select
                name="selectCodeForm"
                id="selectCodeForm"
                onChange={(e) => handlingSelectChange(e)}
              >
                {allCodes.map((code, index) => {
                  return (
                    <option value={code.filename} key={code.id}>
                      {code.filename}
                    </option>
                  );
                })}
                {(activeStream.provider === currentTeam ||
                  currentTeam === "all") &&
                  !versionPublished && (
                    <option value="createFile">créer un fichier</option>
                  )}
              </select>
            )}
            {(activeStream.provider === currentTeam || currentTeam === "all") &&
              !versionPublished && (
                <input
                  type="text"
                  id="fileNameForm"
                  required
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  disabled={
                    activeStream.provider !== currentTeam || versionPublished
                  }
                />
              )}
          </div>
        </article>
      ) : (
        <article className="importStatusContainer codeNameContainer">
          <p>
            Pour éditer directement le code du stream, veuillez d'abord créer
            une première version du stream en allant dans [général] et cliquer
            sur le bouton [Créer la première version] du formulaire. Entrez un
            numéro de version et éventuellement une description.
          </p>
        </article>
      )}
      {activeVersion && (
        <article
          className={
            fullScreenCode
              ? "codeEditorContainer fullScreenCodeEditor"
              : "codeEditorContainer"
          }
        >
          <div id="top"></div>
          <a id="topper" href="#top"></a> 
          <textarea
            ref={codeInputRef}
            className="codeInput"
            wrap="off"
            cols="200"
            spellCheck={prefix === "moz" ? checkSpell : false}
            value={codeContent}
            onChange={(e) => {
              setCodeContent(e.target.value);
              !editedCode && setEditedCode(true);
            }}
            onKeyDown={(e) => handleKeyDown(e)}
            onKeyUp={() => prefix === "moz" && setCheckSpell(false)}
            disabled={
              (activeStream &&
                activeStream.provider !== currentTeam &&
                currentTeam !== "all") ||
              versionPublished
            }
          />
          <pre className="codeOutput">
            <code className={`language-javascript`}>{getCodeContent()}</code>
          </pre>
          <div className="codeButtonsContainer">
            {activeStream.editable &&
              (activeStream.provider === currentTeam ||
                currentTeam === "all") && (
                <article className="streamToggle">
                  <div
                    className={`streamToggleBackground ${
                      sharedVersion ? "sharedVersion" : "off"
                    }`}
                    style={positioningToggle()}
                  />
                  <MddButton
                    buttonInnerText="private"
                    buttonActionFunctionOne={
                      sharedVersion ? sharingVersion : null
                    }
                    iconColor={
                      !sharedVersion ? "activeAltButton" : "altButtonColor"
                    }
                  />
                  <MddButton
                    buttonInnerText="share"
                    buttonActionFunctionOne={
                      !sharedVersion ? sharingVersion : null
                    }
                    iconColor={
                      sharedVersion ? "activeAltButton" : "altButtonColor"
                    }
                  />
                </article>
              )}
            <MddButton
              iconName="bi bi-aspect-ratio"
              buttonSize="small"
              smallButtonDescription="Full screen code editor"
              buttonActionFunctionOne={setFullScreenCode}
              buttonActionPropOne={!fullScreenCode}
              iconColor="altButtonColor"
            />
          </div>
        </article>
      )}
      
      <article className="logsContainer">
      {codeReason && (displayLogs(packagesTest, codeReason))}
      </article>
    
    </section>
  );
}
