function ExportCard({ setExportNav, elements, elementsCount }) {
  return (
    <button
      type="button"
      className="exportCard"
      onClick={() => {
        setExportNav(elements);
      }}
    >
      <h5>{elementsCount}</h5>
      <p>{elements}</p>
    </button>
  );
}

export default function ExportConfirmation({
  setExportNav,
  selectedSubscriptions,
  selectedDataviz,
  selectedVersions,
}) {
  return (
    <div className="confirmationContainer">
      <ExportCard
        setExportNav={setExportNav}
        elements="streams"
        elementsCount={selectedVersions.length}
      />
      <ExportCard
        setExportNav={setExportNav}
        elements="abonnements"
        elementsCount={selectedSubscriptions.length}
      />
      <ExportCard
        setExportNav={setExportNav}
        elements="dataviz"
        elementsCount={selectedDataviz.length}
      />
    </div>
  );
}
