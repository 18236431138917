import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import IndicatorCard from "./IndicatorCard";
import MddButton from "../../reusableElements/MddButton";

export default function SolarIndicators({
  panelsMaxPower,
  panelsMaxProduction,
  batteriesAutonomy,
  panelsCurrentPower,
  setSolarNav,
  parsingTime,
  failedRequest,
  setFailedRequest,
  setDemoToken,
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    LineController,
    Title,
    Tooltip,
    Legend
  );

  const powerSettings = {
    datasets: [
      {
        label: "Puissance des panneaux",
        type: "line",
        backgroundColor: "#2bb0fc",
        borderColor: "#2bb0fc",
        pointBackgroundColor: "#2bb0fc",
        pointBorderColor: "#2bb0fc",
        pointRadius: 0,
        data: panelsCurrentPower,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
    ],
  };

  const powerConfig = {
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "W (puissance)",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Puissance : ${context.formattedValue}`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <section className="solarBody indicatorsBody">
      {/* {failedRequest ? (
        <MddButton
          buttonInnerText="réessayer"
          iconColor="basicButtonColor"
          buttonActionFunctionOne={setDemoToken}
          buttonActionPropOne={null}
          buttonActionFunctionTwo={setFailedRequest}
          buttonActionPropTwo={false}
        />
      ) : (
        <> */}
      {panelsMaxProduction && (
        <IndicatorCard
          setSolarNav={setSolarNav}
          category="production"
          value={panelsMaxProduction}
          unit="Wh"
          name="production maximum"
        />
      )}
      {panelsMaxPower && (
        <IndicatorCard
          setSolarNav={setSolarNav}
          category="production"
          value={panelsMaxPower}
          unit="kWh"
          name="puissance maximum"
        />
      )}
      {batteriesAutonomy && (
        <IndicatorCard
          setSolarNav={setSolarNav}
          category="batteries"
          value={parsingTime(batteriesAutonomy)}
          unit=""
          name="autonomie des batteries"
        />
      )}
      {failedRequest ? (
        <MddButton
          buttonInnerText="réessayer"
          iconColor="basicButtonColor"
          buttonActionFunctionOne={setDemoToken}
          buttonActionPropOne={null}
          buttonActionFunctionTwo={setFailedRequest}
          buttonActionPropTwo={false}
        />
      ) : (
        panelsCurrentPower.length > 0 && (
          <>
            <button
              type="button"
              onClick={() => {
                setSolarNav("production");
              }}
            >
              <p>Puissance des panneaux en temps réel :</p>
            </button>
            <article className="solarGraph">
              <Line options={powerConfig} data={powerSettings} />
            </article>
          </>
        )
      )}
      {/* </>
      )} */}
    </section>
  );
}
