import returningRandomData from "./returningRandomData";
import returningRandomColors from "./returningRandomColors";

export const bar = {
  settings: {
    datasets: [
      {
        label: "Suivi",
        type: "bar",
        backgroundColor: returningRandomColors(1),
        borderColor: returningRandomColors(1),
        pointRadius: 0,
        data: returningRandomData(),
        yAxisID: "1st-y-axis",
      },
    ],
  },
  config: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      scales: {
        "1st-y-axis": {
          type: "linear",
          position: "left",
          title: {
            display: false,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = "";
            if (
              context.dataset.backgroundColor[context.dataIndex] !== "#f4f4f4"
            ) {
              label = `Suivi : ${context.formattedValue}`;
            } else {
              label = "Pas d'information";
            }
            return label;
          },
        },
      },
    },
  },
};
