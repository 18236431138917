import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import "../../styles/reusableElements/tokenForm.css";
import MddButton from "./MddButton";

export default function TokenForm() {
  const { setDemoToken } = useContext(MddContext);
  let history = useHistory();

  const [tempToken, setTempToken] = useState("");

  return (
    <form className="tokenFormContainer">
      <label>
        entrez votre token
        <input
          type="text"
          id="tokenForm"
          placeholder="token"
          required
          value={tempToken}
          onChange={(e) => setTempToken(e.target.value)}
          onKeyUp={(e) => {
            (e.key === "Enter" || e.keyCode === 13) && setDemoToken(tempToken);
          }}
        />
      </label>
      <section>
        <MddButton
          buttonInnerText="retour"
          iconColor="secondaryButton"
          buttonActionFunctionOne={history.push}
          buttonActionPropOne="/dataviz"
        />
        <MddButton
          buttonInnerText="enregistrer"
          iconColor="basicButtonColor"
          buttonActionFunctionOne={setDemoToken}
          buttonActionPropOne={tempToken}
        />
      </section>
    </form>
  );
}
