import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { MddContext } from "../../context/MddContext";
import mddStreamStoreApi from "../../api/mddStreamStoreApi";
import revertingToken from "../../utils/auth/revertingToken";

import MddButton from "../reusableElements/MddButton";

export default function ArchiveElement({
  element
}) {
  const { setActiveStream, userToken, closingSession, handling401 } =
    useContext(MddContext);

  let history = useHistory();

  const [synced, setSynced] = useState(element.synced);

  const synchronize = () => {
    alert("sync element");
  };


  return (
    <div class="archiveElement">
      { synced ? 
        (<i class="archiveSyncBtn bi bi-check2-circle statusOk" onClick={() => { synchronize() }}></i>) :
        (<i class="archiveSyncBtn bi bi-arrow-repeat statusWarning" onClick={() => { synchronize() }}></i>)
      }
      <p class="title">{element.name}</p>
      <p class="description">{element.description}</p>
      <p class="team">{element.team}</p>
      <p class={`type ${element.type}`}>
        { element.type === "Stream" && <i class="bi bi-wind"></i>}
        { element.type === "Version" && <i class="bi bi-wind"></i>}
        { element.type === "Subscription" && <i class="bi bi-bag-check"></i>}
        { element.type === "Dataviz" && <i class="bi bi-clipboard-data"></i>}
      </p>              
    </div>
  );
}
